import {
  ZEvent,
  ZEventLocation,
  ZEventTypeValue,
  ZEventUser,
  ZEventUserStatusType,
  ZEventUserType,
} from '../types';

export interface MockZEvent extends ZEvent {
  zeventUsers?: ZEventUser[];
}

interface CreateMockZRetreatEventProps {
  id: string;
  createdBy: ZEventUser;
  host: ZEventUser;
  planner2?: ZEventUser;
  purpose: string;
  comments?: string;
  startDate: Date;
  endDate: Date;
  eventLocation: ZEventLocation;
  isCanceled: boolean;
  totalUserCount: string;
  userType: ZEventUserType;
  userStatus: ZEventUserStatusType;
  attendees?: ZEventUser[];
}

export const createMockZRetreatEvent = ({
  id,
  createdBy,
  host,
  planner2,
  purpose,
  comments,
  startDate,
  endDate,
  eventLocation,
  isCanceled,
  totalUserCount,
  userType,
  userStatus,
  attendees,
}: CreateMockZRetreatEventProps): MockZEvent => {
  return {
    id: id,
    type: ZEventTypeValue.COMPANY_EVENT_TYPE_ZRETREAT,
    name: undefined,
    purpose: purpose,
    comments: comments,
    invitationMessage: '',
    startDate: startDate,
    endDate: endDate,
    eventLocation: eventLocation,
    createdBy: createdBy,
    isCanceled: isCanceled,
    canceledDate: undefined,
    canceledBy: undefined,
    canceledReason: undefined,
    isDeleted: false,
    createdDate: undefined,
    updatedDate: undefined,
    hosts: [host],
    planners: planner2 ? [createdBy, planner2] : [createdBy],
    totalUserCount: totalUserCount,
    userType: userType,
    userStatus: userStatus,
    zeventUsers: attendees,
  };
};
