import { Action } from 'redux';
import { Office as OfficeContract } from '../../contract/hqengine_office';
import {
  OfficeVisit as OfficeVisitContract,
  AcknowledgeBookingResponseType as AcknowledgeBookingResponseTypeContract,
} from '../../contract/hqengine_office_visit';
import {
  BlockedDates as BlockedDatesContract,
  OfficeFloors as OfficeFloorsContract,
} from '../../contract/hqengine_hqweb';
import { Floor as FloorContract } from '../../contract/hqengine_floor';
import {
  RejectedBooking as RejectedBookingContract,
  RejectedBookingType as RejectedBookingTypeContract,
  OfficeUsage as OfficeUsageContract,
} from '../../contract/hqengine_office_visit';
import {
  MassUploadReport as MassUploadReportContract,
  EntryResult as EntryResultContract,
  MassUploadReportStatus as MassUploadReportStatusContract,
  EntryResultStatus as EntryResultStatusContract,
} from '../../contract/hqengine_mass_upload';

export enum BookingType {
  BOOKING_TYPE_UNSPECIFIED = 0,
  BOOKING_TYPE_SINGLE_DAY = 1,
  BOOKING_TYPE_MULTI_DAY = 2,
  BOOKING_TYPE_RECURRING = 3,
}

export type OfficeState = {
  offices: { [key: string]: Office };
  blockedDates: { [key: string]: BlockedDates };
  reservations: Reservation[];
  bookOfficeError: string;
  floors: { [key: string]: OfficeFloors };
  bookingType: BookingType;
};

export type Office = OfficeContract & {
  name: string; // require name
};
export type Reservation = OfficeVisitContract;
export type RejectedBooking = RejectedBookingContract;
export type RejectedBookingType = RejectedBookingTypeContract;
export type BlockedDates = BlockedDatesContract;
export type OfficeUsage = OfficeUsageContract;
export type OfficeFloors = OfficeFloorsContract;
export type Floor = FloorContract & {
  floorplanKeys: Map<OfficeFloorKeyType, OfficeFloorKey[]>;
};
export type AckReservationResultType = AcknowledgeBookingResponseTypeContract;
export const AckReservationResultTypeValue = AcknowledgeBookingResponseTypeContract;
export type MassUploadReport = MassUploadReportContract;
export type MassUploadReportStatusType = MassUploadReportStatusContract;
export const MassUploadReportStatusValue = MassUploadReportStatusContract;
export type EntryResult = EntryResultContract;
export type EntryResultStatusType = EntryResultStatusContract;
export const EntryResultStatusValue = EntryResultStatusContract;

export const OfficeContractToOffice = (oc: OfficeContract): Office => {
  return {
    ...oc,
    name: oc.name || 'Unknown',
  };
};

export type OfficeBooking = {
  office: Office;
  startDate: Date;
  endDate: Date | undefined;
  excluded: Date[];
  bookingType: BookingType;
};

export type AckReservationResult = {
  officeName: string | undefined;
  reservation: Reservation | undefined;
  resultType: AckReservationResultType;
};

export type OfficeFloorKey = {
  id: string;
  name: string;
  description: string;
  color: string;
  shape: 'square' | 'circle';
  type: OfficeFloorKeyType;
};

export enum OfficeFloorKeyType {
  OFFICE_FLOOR_KEY_TYPE_UNSPECIFIED = 'Unspecified',
  OFFICE_FLOOR_KEY_TYPE_NEIGHBORHOODS = 'Neighborhoods',
  OFFICE_FLOOR_KEY_TYPE_SPACES = 'Spaces',
  OFFICE_FLOOR_KEY_TYPE_SERVICES = 'Services',
}

export interface GetOfficesSuccess extends Action {
  type: 'GET_OFFICES_SUCCESS';
  offices: { [key: string]: Office };
}

export interface GetOfficesError extends Action {
  type: 'GET_OFFICES_ERROR';
  msg: string;
}

export interface MockGetOffices extends Action {
  type: 'MOCK_GET_OFFICES';
}

export type GetOfficesAction = GetOfficesSuccess | GetOfficesError | MockGetOffices;

export interface GetOfficesBlockedDatesSuccess extends Action {
  type: 'GET_OFFICES_BLOCKED_DATES_SUCCESS';
  blockedDates: { [key: string]: BlockedDates };
}

export interface GetOfficesBlockedDatesError extends Action {
  type: 'GET_OFFICES_BLOCKED_DATES_ERROR';
}

export interface MockGetOfficesBlockedDates extends Action {
  type: 'MOCK_GET_OFFICES_BLOCKED_DATES';
}

export type GetOfficesBlockedDatesAction =
  | GetOfficesBlockedDatesSuccess
  | GetOfficesBlockedDatesError
  | MockGetOfficesBlockedDates;

export interface GetOfficesFloorsSuccess extends Action {
  type: 'GET_OFFICES_FLOORS_SUCCESS';
  floors: { [key: string]: OfficeFloors };
}

export interface GetOfficesFloorsError extends Action {
  type: 'GET_OFFICES_FLOORS_ERROR';
}

export interface MockGetOfficesFloors extends Action {
  type: 'MOCK_GET_OFFICES_FLOORS';
}

export type GetOfficesFloorsAction =
  | GetOfficesFloorsSuccess
  | GetOfficesFloorsError
  | MockGetOfficesFloors;

export interface GetReservationsSuccess extends Action {
  type: 'GET_USER_RESERVATIONS_SUCCESS';
  reservations: Reservation[];
}

export interface GetReservationsError extends Action {
  type: 'GET_USER_RESERVATIONS_ERROR';
  msg: string;
}

export interface MockGetReservations extends Action {
  type: 'MOCK_GET_USER_RESERVATIONS';
}

export type GetReservationsAction =
  | GetReservationsSuccess
  | GetReservationsError
  | MockGetReservations;

export interface BookOfficeVisitSuccess extends Action {
  type: 'BOOK_OFFICE_VISIT_SUCCESS';
  visits: Reservation[];
}

export interface BookOfficeVisitError extends Action {
  type: 'BOOK_OFFICE_VISIT_ERROR';
  msg: string;
}

export interface MockBookOfficeVisitSuccess extends Action {
  type: 'MOCK_BOOK_OFFICE_VISIT_SUCCESS';
  booking: OfficeBooking;
}

export interface MockBookOfficeVisitError extends Action {
  type: 'MOCK_BOOK_OFFICE_VISIT_ERROR';
}

export interface CancelOfficeVisitSuccess extends Action {
  type: 'CANCEL_OFFICE_VISIT_SUCCESS';
  visitIds: string[];
}

export interface CancelOfficeVisitError extends Action {
  type: 'CANCEL_OFFICE_VISIT_ERROR';
  msg: string;
}

export interface MockCancelOfficeVisitSuccess extends Action {
  type: 'MOCK_CANCEL_OFFICE_VISIT_SUCCESS';
  visitIds: string[];
}

export interface MockCancelOfficeVisitError extends Action {
  type: 'MOCK_CANCEL_OFFICE_VISIT_ERROR';
}

export type OfficeBookingAction =
  | BookOfficeVisitSuccess
  | BookOfficeVisitError
  | MockBookOfficeVisitSuccess
  | MockBookOfficeVisitError
  | CancelOfficeVisitSuccess
  | CancelOfficeVisitError
  | MockCancelOfficeVisitSuccess
  | MockCancelOfficeVisitError;

export interface GetOfficeFullyBookedDatesSuccess extends Action {
  type: 'GET_OFFICE_FULLY_BOOKED_DATES_SUCCESS';
  dates: Date[];
}

export interface GetOfficeFullyBookedDatesError extends Action {
  type: 'GET_OFFICE_FULLY_BOOKED_DATES_ERROR';
  msg: string;
}

export interface MockGetOfficeFullyBookedSuccess extends Action {
  type: 'MOCK_GET_OFFICE_FULLY_BOOKED_DATES_SUCCESS';
  dates: Date[];
}

export interface MockGetOfficeFullyBookedError extends Action {
  type: 'MOCK_GET_OFFICE_FULLY_BOOKED_DATES_ERROR';
}

export interface GetOfficeUsagesSuccess extends Action {
  type: 'GET_OFFICE_USAGES_SUCCESS';
  usages: OfficeUsage[];
}

export interface GetOfficeUsagesError extends Action {
  type: 'GET_OFFICE_USAGES_ERROR';
  msg: string;
}

export interface MockGetOfficeUsagesSuccess extends Action {
  type: 'MOCK_GET_OFFICE_USAGES_SUCCESS';
  usages: OfficeUsage[];
}

export interface MockGetOfficeUsagesError extends Action {
  type: 'MOCK_GET_OFFICE_USAGES_ERROR';
}

export type OfficeCapacityAction =
  | GetOfficeFullyBookedDatesSuccess
  | GetOfficeFullyBookedDatesError
  | MockGetOfficeFullyBookedSuccess
  | MockGetOfficeFullyBookedError
  | GetOfficeUsagesSuccess
  | GetOfficeUsagesError
  | MockGetOfficeUsagesSuccess
  | MockGetOfficeUsagesError;

export interface UpdateBookingType extends Action {
  type: 'UPDATE_BOOKING_TYPE';
  value: BookingType;
}

export type UpdateBookingTypeAction = UpdateBookingType;

export interface BookOfficeVisitsBulkSuccess extends Action {
  type: 'BOOK_OFFICE_VISITS_BULK_SUCCESS';
}

export interface BookOfficeVisitsBulkError extends Action {
  type: 'BOOK_OFFICE_VISITS_BULK_ERROR';
}

export interface GetMassUploadReportSuccess extends Action {
  type: 'GET_MASS_UPLOAD_REPORT_SUCCESS';
}

export interface GetMassUploadReportError extends Action {
  type: 'GET_MASS_UPLOAD_REPORT_ERROR';
}

export type BookOfficeVisitsBulkAction =
  | BookOfficeVisitsBulkSuccess
  | BookOfficeVisitsBulkError
  | GetMassUploadReportSuccess
  | GetMassUploadReportError;
export interface AckReservationSuccess extends Action {
  type: 'ACK_RESERVATION_SUCCESS';
}

export interface AckReservationError extends Action {
  type: 'ACK_RESERVATION_ERROR';
  msg: string;
}

export type AckReservationAction = AckReservationSuccess | AckReservationError;

export type OfficeAction =
  | GetOfficesAction
  | GetOfficesBlockedDatesAction
  | GetOfficesFloorsAction
  | GetReservationsAction
  | OfficeBookingAction
  | OfficeCapacityAction
  | UpdateBookingTypeAction
  | BookOfficeVisitsBulkAction
  | AckReservationAction;
