import { Action } from 'redux';
import {
  CompanyEvent,
  CompanyEventType,
  CompanyEventUser,
  CompanyEventUserStatusType,
  CompanyEventUserType,
  EventLocation,
  EventLocationType,
} from '../../contract/hqengine_company_event';

export type ZEventState = {
  upcomingZEvents: ZEvent[];
  pastZEvents: ZEvent[];
  justBookedZEventID: string | undefined;
  currentZEvent: ZEvent;
  currentZEventUsers: ZEventUser[];
};

export type ZEventListType = 'Upcoming' | 'Past';
export type ZEventUserStatusStringType = 'Accepted' | 'Declined' | 'Awaiting Response' | 'Unknown';

export type ZEvent = CompanyEvent;
export type ZEventType = CompanyEventType;
export const ZEventTypeValue = CompanyEventType;
export type ZEventUser = CompanyEventUser;
export type ZEventUserStatusType = CompanyEventUserStatusType;
export const ZEventUserStatusTypeValue = CompanyEventUserStatusType;
export type ZEventUserType = CompanyEventUserType;
export const ZEventUserTypeValue = CompanyEventUserType;
export type ZEventLocation = EventLocation;
export type ZEventLocationType = EventLocationType;
export const ZEventLocationTypeValue = EventLocationType;

export interface GetZEventsSuccess extends Action {
  type: 'GET_ZEVENTS_SUCCESS';
  zevents: ZEvent[];
  listType: ZEventListType;
}

export interface GetZEventsError extends Action {
  type: 'GET_ZEVENTS_ERROR';
  msg: string;
}

export interface MockGetUpcomingZEvents extends Action {
  type: 'MOCK_GET_UPCOMING_ZEVENTS';
}

export interface MockGetPastZEvents extends Action {
  type: 'MOCK_GET_PAST_ZEVENTS';
}

export interface GetZEventByIDSuccess extends Action {
  type: 'GET_ZEVENT_BY_ID_SUCCESS';
  zevent: ZEvent;
  zeventUsers: ZEventUser[];
}

export interface GetZEventByIDError extends Action {
  type: 'GET_ZEVENT_BY_ID_ERROR';
}

export interface MockGetZEventByID extends Action {
  type: 'MOCK_GET_ZEVENT_BY_ID';
  eventId: string;
}

export type GetZEventsAction =
  | GetZEventsSuccess
  | GetZEventsError
  | MockGetUpcomingZEvents
  | MockGetPastZEvents
  | GetZEventByIDSuccess
  | GetZEventByIDError
  | MockGetZEventByID;

export interface BookZEventSuccess extends Action {
  type: 'BOOK_ZEVENT_SUCCESS';
  id: string;
}
export interface MockBookZEventSuccess extends Action {
  type: 'MOCK_BOOK_ZEVENT_SUCCESS';
  id: string;
  createdBy: ZEventUser;
  host: ZEventUser;
  planner2?: ZEventUser;
  purpose: string;
  comments: string;
  startDate: Date;
  endDate: Date;
  location: ZEventLocation;
}

export interface ResetJustBookedZEventID extends Action {
  type: 'RESET_JUST_BOOKED_ZEVENT_ID';
}

export type BookZEventAction = BookZEventSuccess | MockBookZEventSuccess | ResetJustBookedZEventID;

export interface CancelZEventSuccess extends Action {
  type: 'CANCEL_ZEVENT_SUCCESS';
  eventID: string;
  reason: string;
}

export interface MockCancelZEvent extends Action {
  type: 'MOCK_CANCEL_ZEVENT';
  eventID: string;
  reason: string;
}

export type CancelZEventAction = CancelZEventSuccess | MockCancelZEvent;

export interface SendInvitesSuccess extends Action {
  type: 'SEND_INVITES_SUCCESS';
  invitees: ZEventUser[];
}

export interface SendInvitesError extends Action {
  type: 'SEND_INVITES_ERROR';
  msg: string;
}

export interface MockSendInvites extends Action {
  type: 'MOCK_SEND_INVITES';
  invitees: ZEventUser[];
}

export type SendInvitesAction = SendInvitesSuccess | SendInvitesError | MockSendInvites;

export interface AckZEventSuccess extends Action {
  type: 'ACK_ZEVENT_SUCCESS';
  userId: string;
  zEventId: string;
  status: ZEventUserStatusType;
  comments?: string;
}

export interface AckZEventError extends Action {
  type: 'ACK_ZEVENT_ERROR';
}

export interface MockAckZEvent extends Action {
  type: 'MOCK_ACK_ZEVENT';
  userId: string;
  zEventId: string;
  status: ZEventUserStatusType;
  comments?: string;
}

export type AckZEventAction = AckZEventSuccess | AckZEventError | MockAckZEvent;

export type ZEventAction =
  | GetZEventsAction
  | BookZEventAction
  | CancelZEventAction
  | SendInvitesAction
  | AckZEventAction;
