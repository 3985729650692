import React from 'react';
import styled from 'styled-components';
import { Flex, Paragraph, Anchor } from '@zillow/constellation';
import DynamicConfig from '../../config/DynamicConfig';

const FooterWrapper = styled.footer`
  text-align: center;
  max-width: none;
`;

const FooterContent = styled(Flex)`
  max-width: 1280px;
  margin: auto;
`;

const SupportButton = styled(Anchor)`
  font-weight: 700;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper >
      <FooterContent paddingY="md" data-testid='render-footer-test'>
        <Paragraph data-testid='render-footer-text-test'>
          Powered by CorpApps Engineering{' | '}
          <SupportButton data-testid='render-footer-support-button'
            fontColor="blue500"
            href={DynamicConfig.GetConfig().PPL_OPS_SUPPORT_URL}
          >
            Support
          </SupportButton>
        </Paragraph>
      </FooterContent>
    </FooterWrapper>
  );
};
