import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import DynamicConfig from '../../config/DynamicConfig';

export const HQLandingPagesLogic = ({ history, location }: RouteComponentProps) => {
  /** init GA and set up page tracking */
  useEffect(() => {
    GoogleAnalyticsUtils.Initialize(DynamicConfig.GetConfig().GA_MEASUREMENT_ID);
    GoogleAnalyticsUtils.Pageview(location.pathname);

    history.listen((loc) => {
      GoogleAnalyticsUtils.Pageview(loc.pathname);
    });
  }, []);
};
