/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from './google/protobuf/timestamp';
import { StringValue, Int64Value } from './google/protobuf/wrappers';

export const protobufPackage = 'peng_hqengine_contract';

export enum CompanyEventType {
  COMPANY_EVENT_TYPE_UNSPECIFIED = 0,
  COMPANY_EVENT_TYPE_ZRETREAT = 1,
  UNRECOGNIZED = -1,
}

export function companyEventTypeFromJSON(object: any): CompanyEventType {
  switch (object) {
    case 0:
    case 'COMPANY_EVENT_TYPE_UNSPECIFIED':
      return CompanyEventType.COMPANY_EVENT_TYPE_UNSPECIFIED;
    case 1:
    case 'COMPANY_EVENT_TYPE_ZRETREAT':
      return CompanyEventType.COMPANY_EVENT_TYPE_ZRETREAT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CompanyEventType.UNRECOGNIZED;
  }
}

export function companyEventTypeToJSON(object: CompanyEventType): string {
  switch (object) {
    case CompanyEventType.COMPANY_EVENT_TYPE_UNSPECIFIED:
      return 'COMPANY_EVENT_TYPE_UNSPECIFIED';
    case CompanyEventType.COMPANY_EVENT_TYPE_ZRETREAT:
      return 'COMPANY_EVENT_TYPE_ZRETREAT';
    default:
      return 'UNKNOWN';
  }
}

export enum CompanyEventUserType {
  COMPANY_EVENT_USER_TYPE_UNSPECIFIED = 0,
  COMPANY_EVENT_USER_TYPE_PLANNER = 1,
  COMPANY_EVENT_USER_TYPE_HOST = 2,
  COMPANY_EVENT_USER_TYPE_ATTENDEE = 3,
  UNRECOGNIZED = -1,
}

export function companyEventUserTypeFromJSON(object: any): CompanyEventUserType {
  switch (object) {
    case 0:
    case 'COMPANY_EVENT_USER_TYPE_UNSPECIFIED':
      return CompanyEventUserType.COMPANY_EVENT_USER_TYPE_UNSPECIFIED;
    case 1:
    case 'COMPANY_EVENT_USER_TYPE_PLANNER':
      return CompanyEventUserType.COMPANY_EVENT_USER_TYPE_PLANNER;
    case 2:
    case 'COMPANY_EVENT_USER_TYPE_HOST':
      return CompanyEventUserType.COMPANY_EVENT_USER_TYPE_HOST;
    case 3:
    case 'COMPANY_EVENT_USER_TYPE_ATTENDEE':
      return CompanyEventUserType.COMPANY_EVENT_USER_TYPE_ATTENDEE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CompanyEventUserType.UNRECOGNIZED;
  }
}

export function companyEventUserTypeToJSON(object: CompanyEventUserType): string {
  switch (object) {
    case CompanyEventUserType.COMPANY_EVENT_USER_TYPE_UNSPECIFIED:
      return 'COMPANY_EVENT_USER_TYPE_UNSPECIFIED';
    case CompanyEventUserType.COMPANY_EVENT_USER_TYPE_PLANNER:
      return 'COMPANY_EVENT_USER_TYPE_PLANNER';
    case CompanyEventUserType.COMPANY_EVENT_USER_TYPE_HOST:
      return 'COMPANY_EVENT_USER_TYPE_HOST';
    case CompanyEventUserType.COMPANY_EVENT_USER_TYPE_ATTENDEE:
      return 'COMPANY_EVENT_USER_TYPE_ATTENDEE';
    default:
      return 'UNKNOWN';
  }
}

export enum CompanyEventUserStatusType {
  COMPANY_EVENT_USER_STATUS_TYPE_UNSPECIFIED = 0,
  COMPANY_EVENT_USER_STATUS_TYPE_PENDING = 1,
  COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED = 2,
  COMPANY_EVENT_USER_STATUS_TYPE_DECLINED = 3,
  UNRECOGNIZED = -1,
}

export function companyEventUserStatusTypeFromJSON(object: any): CompanyEventUserStatusType {
  switch (object) {
    case 0:
    case 'COMPANY_EVENT_USER_STATUS_TYPE_UNSPECIFIED':
      return CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_UNSPECIFIED;
    case 1:
    case 'COMPANY_EVENT_USER_STATUS_TYPE_PENDING':
      return CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_PENDING;
    case 2:
    case 'COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED':
      return CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED;
    case 3:
    case 'COMPANY_EVENT_USER_STATUS_TYPE_DECLINED':
      return CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CompanyEventUserStatusType.UNRECOGNIZED;
  }
}

export function companyEventUserStatusTypeToJSON(object: CompanyEventUserStatusType): string {
  switch (object) {
    case CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_UNSPECIFIED:
      return 'COMPANY_EVENT_USER_STATUS_TYPE_UNSPECIFIED';
    case CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_PENDING:
      return 'COMPANY_EVENT_USER_STATUS_TYPE_PENDING';
    case CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED:
      return 'COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED';
    case CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED:
      return 'COMPANY_EVENT_USER_STATUS_TYPE_DECLINED';
    default:
      return 'UNKNOWN';
  }
}

export enum AcknowledgeCompanyEventResponseType {
  ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_UNSPECIFIED = 0,
  ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_ACCEPTED_SUCCEESS = 1,
  ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_DECLINED_SUCCEESS = 2,
  ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_PAST = 3,
  ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_NOT_EXIST = 4,
  UNRECOGNIZED = -1,
}

export function acknowledgeCompanyEventResponseTypeFromJSON(
  object: any,
): AcknowledgeCompanyEventResponseType {
  switch (object) {
    case 0:
    case 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_UNSPECIFIED':
      return AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_UNSPECIFIED;
    case 1:
    case 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_ACCEPTED_SUCCEESS':
      return AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_ACCEPTED_SUCCEESS;
    case 2:
    case 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_DECLINED_SUCCEESS':
      return AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_DECLINED_SUCCEESS;
    case 3:
    case 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_PAST':
      return AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_PAST;
    case 4:
    case 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_NOT_EXIST':
      return AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_NOT_EXIST;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AcknowledgeCompanyEventResponseType.UNRECOGNIZED;
  }
}

export function acknowledgeCompanyEventResponseTypeToJSON(
  object: AcknowledgeCompanyEventResponseType,
): string {
  switch (object) {
    case AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_UNSPECIFIED:
      return 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_UNSPECIFIED';
    case AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_ACCEPTED_SUCCEESS:
      return 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_ACCEPTED_SUCCEESS';
    case AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_DECLINED_SUCCEESS:
      return 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_DECLINED_SUCCEESS';
    case AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_PAST:
      return 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_PAST';
    case AcknowledgeCompanyEventResponseType.ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_NOT_EXIST:
      return 'ACKNOWLEDGE_COMPANY_EVENT_RESPONSE_TYPE_NOT_EXIST';
    default:
      return 'UNKNOWN';
  }
}

export enum EventLocationType {
  EVENT_LOCATION_TYPE_UNSPECIFIED = 0,
  EVENT_LOCATION_TYPE_ZRETREAT = 1,
  UNRECOGNIZED = -1,
}

export function eventLocationTypeFromJSON(object: any): EventLocationType {
  switch (object) {
    case 0:
    case 'EVENT_LOCATION_TYPE_UNSPECIFIED':
      return EventLocationType.EVENT_LOCATION_TYPE_UNSPECIFIED;
    case 1:
    case 'EVENT_LOCATION_TYPE_ZRETREAT':
      return EventLocationType.EVENT_LOCATION_TYPE_ZRETREAT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EventLocationType.UNRECOGNIZED;
  }
}

export function eventLocationTypeToJSON(object: EventLocationType): string {
  switch (object) {
    case EventLocationType.EVENT_LOCATION_TYPE_UNSPECIFIED:
      return 'EVENT_LOCATION_TYPE_UNSPECIFIED';
    case EventLocationType.EVENT_LOCATION_TYPE_ZRETREAT:
      return 'EVENT_LOCATION_TYPE_ZRETREAT';
    default:
      return 'UNKNOWN';
  }
}

export interface CompanyEvent {
  id: string;
  type: CompanyEventType;
  name: string | undefined;
  purpose: string | undefined;
  invitationMessage: string | undefined;
  comments: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  eventLocation: EventLocation | undefined;
  createdBy: CompanyEventUser | undefined;
  isCanceled: boolean;
  canceledDate: Date | undefined;
  canceledBy: string | undefined;
  canceledReason: string | undefined;
  isDeleted: boolean;
  createdDate: Date | undefined;
  updatedDate: Date | undefined;
  hosts: CompanyEventUser[];
  planners: CompanyEventUser[];
  totalUserCount: string;
  userType: CompanyEventUserType;
  userStatus: CompanyEventUserStatusType;
}

export interface CompanyEventUser {
  id: string;
  type: CompanyEventUserType;
  status: CompanyEventUserStatusType;
  comments: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  createdDate: Date | undefined;
  updatedDate: Date | undefined;
}

export interface EventLocation {
  id: string;
  type: EventLocationType;
  name: string;
  referenceId: string | undefined;
  floorId: string | undefined;
  capacity: string | undefined;
  isDeleted: boolean;
  createdDate: Date | undefined;
  updatedDate: Date | undefined;
}

const baseCompanyEvent: object = {
  id: '0',
  type: 0,
  isCanceled: false,
  isDeleted: false,
  totalUserCount: '0',
  userType: 0,
  userStatus: 0,
};

export const CompanyEvent = {
  encode(message: CompanyEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.purpose !== undefined) {
      StringValue.encode({ value: message.purpose! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.invitationMessage !== undefined) {
      StringValue.encode({ value: message.invitationMessage! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.comments !== undefined) {
      StringValue.encode({ value: message.comments! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(58).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.eventLocation !== undefined) {
      EventLocation.encode(message.eventLocation, writer.uint32(74).fork()).ldelim();
    }
    if (message.createdBy !== undefined) {
      CompanyEventUser.encode(message.createdBy, writer.uint32(82).fork()).ldelim();
    }
    if (message.isCanceled === true) {
      writer.uint32(88).bool(message.isCanceled);
    }
    if (message.canceledDate !== undefined) {
      Timestamp.encode(toTimestamp(message.canceledDate), writer.uint32(98).fork()).ldelim();
    }
    if (message.canceledBy !== undefined) {
      Int64Value.encode({ value: message.canceledBy! }, writer.uint32(106).fork()).ldelim();
    }
    if (message.canceledReason !== undefined) {
      StringValue.encode({ value: message.canceledReason! }, writer.uint32(114).fork()).ldelim();
    }
    if (message.isDeleted === true) {
      writer.uint32(120).bool(message.isDeleted);
    }
    if (message.createdDate !== undefined) {
      Timestamp.encode(toTimestamp(message.createdDate), writer.uint32(130).fork()).ldelim();
    }
    if (message.updatedDate !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedDate), writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.hosts) {
      CompanyEventUser.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.planners) {
      CompanyEventUser.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    if (message.totalUserCount !== '0') {
      writer.uint32(160).int64(message.totalUserCount);
    }
    if (message.userType !== 0) {
      writer.uint32(168).int32(message.userType);
    }
    if (message.userStatus !== 0) {
      writer.uint32(176).int32(message.userStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompanyEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCompanyEvent } as CompanyEvent;
    message.hosts = [];
    message.planners = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.type = reader.int32() as any;
          break;
        case 3:
          message.name = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.purpose = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.invitationMessage = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.comments = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 8:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 9:
          message.eventLocation = EventLocation.decode(reader, reader.uint32());
          break;
        case 10:
          message.createdBy = CompanyEventUser.decode(reader, reader.uint32());
          break;
        case 11:
          message.isCanceled = reader.bool();
          break;
        case 12:
          message.canceledDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 13:
          message.canceledBy = Int64Value.decode(reader, reader.uint32()).value;
          break;
        case 14:
          message.canceledReason = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 15:
          message.isDeleted = reader.bool();
          break;
        case 16:
          message.createdDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 17:
          message.updatedDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 18:
          message.hosts.push(CompanyEventUser.decode(reader, reader.uint32()));
          break;
        case 19:
          message.planners.push(CompanyEventUser.decode(reader, reader.uint32()));
          break;
        case 20:
          message.totalUserCount = longToString(reader.int64() as Long);
          break;
        case 21:
          message.userType = reader.int32() as any;
          break;
        case 22:
          message.userStatus = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompanyEvent {
    const message = { ...baseCompanyEvent } as CompanyEvent;
    message.hosts = [];
    message.planners = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = companyEventTypeFromJSON(object.type);
    } else {
      message.type = 0;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = undefined;
    }
    if (object.purpose !== undefined && object.purpose !== null) {
      message.purpose = String(object.purpose);
    } else {
      message.purpose = undefined;
    }
    if (object.invitationMessage !== undefined && object.invitationMessage !== null) {
      message.invitationMessage = String(object.invitationMessage);
    } else {
      message.invitationMessage = undefined;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = String(object.comments);
    } else {
      message.comments = undefined;
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    if (object.eventLocation !== undefined && object.eventLocation !== null) {
      message.eventLocation = EventLocation.fromJSON(object.eventLocation);
    } else {
      message.eventLocation = undefined;
    }
    if (object.createdBy !== undefined && object.createdBy !== null) {
      message.createdBy = CompanyEventUser.fromJSON(object.createdBy);
    } else {
      message.createdBy = undefined;
    }
    if (object.isCanceled !== undefined && object.isCanceled !== null) {
      message.isCanceled = Boolean(object.isCanceled);
    } else {
      message.isCanceled = false;
    }
    if (object.canceledDate !== undefined && object.canceledDate !== null) {
      message.canceledDate = fromJsonTimestamp(object.canceledDate);
    } else {
      message.canceledDate = undefined;
    }
    if (object.canceledBy !== undefined && object.canceledBy !== null) {
      message.canceledBy = String(object.canceledBy);
    } else {
      message.canceledBy = undefined;
    }
    if (object.canceledReason !== undefined && object.canceledReason !== null) {
      message.canceledReason = String(object.canceledReason);
    } else {
      message.canceledReason = undefined;
    }
    if (object.isDeleted !== undefined && object.isDeleted !== null) {
      message.isDeleted = Boolean(object.isDeleted);
    } else {
      message.isDeleted = false;
    }
    if (object.createdDate !== undefined && object.createdDate !== null) {
      message.createdDate = fromJsonTimestamp(object.createdDate);
    } else {
      message.createdDate = undefined;
    }
    if (object.updatedDate !== undefined && object.updatedDate !== null) {
      message.updatedDate = fromJsonTimestamp(object.updatedDate);
    } else {
      message.updatedDate = undefined;
    }
    if (object.hosts !== undefined && object.hosts !== null) {
      for (const e of object.hosts) {
        message.hosts.push(CompanyEventUser.fromJSON(e));
      }
    }
    if (object.planners !== undefined && object.planners !== null) {
      for (const e of object.planners) {
        message.planners.push(CompanyEventUser.fromJSON(e));
      }
    }
    if (object.totalUserCount !== undefined && object.totalUserCount !== null) {
      message.totalUserCount = String(object.totalUserCount);
    } else {
      message.totalUserCount = '0';
    }
    if (object.userType !== undefined && object.userType !== null) {
      message.userType = companyEventUserTypeFromJSON(object.userType);
    } else {
      message.userType = 0;
    }
    if (object.userStatus !== undefined && object.userStatus !== null) {
      message.userStatus = companyEventUserStatusTypeFromJSON(object.userStatus);
    } else {
      message.userStatus = 0;
    }
    return message;
  },

  toJSON(message: CompanyEvent): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = companyEventTypeToJSON(message.type));
    message.name !== undefined && (obj.name = message.name);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.invitationMessage !== undefined && (obj.invitationMessage = message.invitationMessage);
    message.comments !== undefined && (obj.comments = message.comments);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    message.eventLocation !== undefined &&
      (obj.eventLocation = message.eventLocation
        ? EventLocation.toJSON(message.eventLocation)
        : undefined);
    message.createdBy !== undefined &&
      (obj.createdBy = message.createdBy ? CompanyEventUser.toJSON(message.createdBy) : undefined);
    message.isCanceled !== undefined && (obj.isCanceled = message.isCanceled);
    message.canceledDate !== undefined && (obj.canceledDate = message.canceledDate.toISOString());
    message.canceledBy !== undefined && (obj.canceledBy = message.canceledBy);
    message.canceledReason !== undefined && (obj.canceledReason = message.canceledReason);
    message.isDeleted !== undefined && (obj.isDeleted = message.isDeleted);
    message.createdDate !== undefined && (obj.createdDate = message.createdDate.toISOString());
    message.updatedDate !== undefined && (obj.updatedDate = message.updatedDate.toISOString());
    if (message.hosts) {
      obj.hosts = message.hosts.map((e) => (e ? CompanyEventUser.toJSON(e) : undefined));
    } else {
      obj.hosts = [];
    }
    if (message.planners) {
      obj.planners = message.planners.map((e) => (e ? CompanyEventUser.toJSON(e) : undefined));
    } else {
      obj.planners = [];
    }
    message.totalUserCount !== undefined && (obj.totalUserCount = message.totalUserCount);
    message.userType !== undefined && (obj.userType = companyEventUserTypeToJSON(message.userType));
    message.userStatus !== undefined &&
      (obj.userStatus = companyEventUserStatusTypeToJSON(message.userStatus));
    return obj;
  },

  fromPartial(object: DeepPartial<CompanyEvent>): CompanyEvent {
    const message = { ...baseCompanyEvent } as CompanyEvent;
    message.hosts = [];
    message.planners = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    } else {
      message.type = 0;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = undefined;
    }
    if (object.purpose !== undefined && object.purpose !== null) {
      message.purpose = object.purpose;
    } else {
      message.purpose = undefined;
    }
    if (object.invitationMessage !== undefined && object.invitationMessage !== null) {
      message.invitationMessage = object.invitationMessage;
    } else {
      message.invitationMessage = undefined;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = object.comments;
    } else {
      message.comments = undefined;
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    if (object.eventLocation !== undefined && object.eventLocation !== null) {
      message.eventLocation = EventLocation.fromPartial(object.eventLocation);
    } else {
      message.eventLocation = undefined;
    }
    if (object.createdBy !== undefined && object.createdBy !== null) {
      message.createdBy = CompanyEventUser.fromPartial(object.createdBy);
    } else {
      message.createdBy = undefined;
    }
    if (object.isCanceled !== undefined && object.isCanceled !== null) {
      message.isCanceled = object.isCanceled;
    } else {
      message.isCanceled = false;
    }
    if (object.canceledDate !== undefined && object.canceledDate !== null) {
      message.canceledDate = object.canceledDate;
    } else {
      message.canceledDate = undefined;
    }
    if (object.canceledBy !== undefined && object.canceledBy !== null) {
      message.canceledBy = object.canceledBy;
    } else {
      message.canceledBy = undefined;
    }
    if (object.canceledReason !== undefined && object.canceledReason !== null) {
      message.canceledReason = object.canceledReason;
    } else {
      message.canceledReason = undefined;
    }
    if (object.isDeleted !== undefined && object.isDeleted !== null) {
      message.isDeleted = object.isDeleted;
    } else {
      message.isDeleted = false;
    }
    if (object.createdDate !== undefined && object.createdDate !== null) {
      message.createdDate = object.createdDate;
    } else {
      message.createdDate = undefined;
    }
    if (object.updatedDate !== undefined && object.updatedDate !== null) {
      message.updatedDate = object.updatedDate;
    } else {
      message.updatedDate = undefined;
    }
    if (object.hosts !== undefined && object.hosts !== null) {
      for (const e of object.hosts) {
        message.hosts.push(CompanyEventUser.fromPartial(e));
      }
    }
    if (object.planners !== undefined && object.planners !== null) {
      for (const e of object.planners) {
        message.planners.push(CompanyEventUser.fromPartial(e));
      }
    }
    if (object.totalUserCount !== undefined && object.totalUserCount !== null) {
      message.totalUserCount = object.totalUserCount;
    } else {
      message.totalUserCount = '0';
    }
    if (object.userType !== undefined && object.userType !== null) {
      message.userType = object.userType;
    } else {
      message.userType = 0;
    }
    if (object.userStatus !== undefined && object.userStatus !== null) {
      message.userStatus = object.userStatus;
    } else {
      message.userStatus = 0;
    }
    return message;
  },
};

const baseCompanyEventUser: object = { id: '0', type: 0, status: 0 };

export const CompanyEventUser = {
  encode(message: CompanyEventUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.comments !== undefined) {
      StringValue.encode({ value: message.comments! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.firstName !== undefined) {
      StringValue.encode({ value: message.firstName! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.lastName !== undefined) {
      StringValue.encode({ value: message.lastName! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.email !== undefined) {
      StringValue.encode({ value: message.email! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.createdDate !== undefined) {
      Timestamp.encode(toTimestamp(message.createdDate), writer.uint32(74).fork()).ldelim();
    }
    if (message.updatedDate !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedDate), writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompanyEventUser {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCompanyEventUser } as CompanyEventUser;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.type = reader.int32() as any;
          break;
        case 3:
          message.status = reader.int32() as any;
          break;
        case 4:
          message.comments = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.firstName = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.lastName = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.email = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 9:
          message.createdDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 10:
          message.updatedDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompanyEventUser {
    const message = { ...baseCompanyEventUser } as CompanyEventUser;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = companyEventUserTypeFromJSON(object.type);
    } else {
      message.type = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = companyEventUserStatusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = String(object.comments);
    } else {
      message.comments = undefined;
    }
    if (object.firstName !== undefined && object.firstName !== null) {
      message.firstName = String(object.firstName);
    } else {
      message.firstName = undefined;
    }
    if (object.lastName !== undefined && object.lastName !== null) {
      message.lastName = String(object.lastName);
    } else {
      message.lastName = undefined;
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = undefined;
    }
    if (object.createdDate !== undefined && object.createdDate !== null) {
      message.createdDate = fromJsonTimestamp(object.createdDate);
    } else {
      message.createdDate = undefined;
    }
    if (object.updatedDate !== undefined && object.updatedDate !== null) {
      message.updatedDate = fromJsonTimestamp(object.updatedDate);
    } else {
      message.updatedDate = undefined;
    }
    return message;
  },

  toJSON(message: CompanyEventUser): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = companyEventUserTypeToJSON(message.type));
    message.status !== undefined && (obj.status = companyEventUserStatusTypeToJSON(message.status));
    message.comments !== undefined && (obj.comments = message.comments);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.email !== undefined && (obj.email = message.email);
    message.createdDate !== undefined && (obj.createdDate = message.createdDate.toISOString());
    message.updatedDate !== undefined && (obj.updatedDate = message.updatedDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<CompanyEventUser>): CompanyEventUser {
    const message = { ...baseCompanyEventUser } as CompanyEventUser;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    } else {
      message.type = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = object.comments;
    } else {
      message.comments = undefined;
    }
    if (object.firstName !== undefined && object.firstName !== null) {
      message.firstName = object.firstName;
    } else {
      message.firstName = undefined;
    }
    if (object.lastName !== undefined && object.lastName !== null) {
      message.lastName = object.lastName;
    } else {
      message.lastName = undefined;
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = undefined;
    }
    if (object.createdDate !== undefined && object.createdDate !== null) {
      message.createdDate = object.createdDate;
    } else {
      message.createdDate = undefined;
    }
    if (object.updatedDate !== undefined && object.updatedDate !== null) {
      message.updatedDate = object.updatedDate;
    } else {
      message.updatedDate = undefined;
    }
    return message;
  },
};

const baseEventLocation: object = { id: '0', type: 0, name: '', isDeleted: false };

export const EventLocation = {
  encode(message: EventLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.name !== '') {
      writer.uint32(26).string(message.name);
    }
    if (message.referenceId !== undefined) {
      StringValue.encode({ value: message.referenceId! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.floorId !== undefined) {
      Int64Value.encode({ value: message.floorId! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.capacity !== undefined) {
      Int64Value.encode({ value: message.capacity! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.isDeleted === true) {
      writer.uint32(56).bool(message.isDeleted);
    }
    if (message.createdDate !== undefined) {
      Timestamp.encode(toTimestamp(message.createdDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.updatedDate !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedDate), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventLocation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventLocation } as EventLocation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.type = reader.int32() as any;
          break;
        case 3:
          message.name = reader.string();
          break;
        case 4:
          message.referenceId = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.floorId = Int64Value.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.capacity = Int64Value.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.isDeleted = reader.bool();
          break;
        case 8:
          message.createdDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 9:
          message.updatedDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventLocation {
    const message = { ...baseEventLocation } as EventLocation;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = eventLocationTypeFromJSON(object.type);
    } else {
      message.type = 0;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = '';
    }
    if (object.referenceId !== undefined && object.referenceId !== null) {
      message.referenceId = String(object.referenceId);
    } else {
      message.referenceId = undefined;
    }
    if (object.floorId !== undefined && object.floorId !== null) {
      message.floorId = String(object.floorId);
    } else {
      message.floorId = undefined;
    }
    if (object.capacity !== undefined && object.capacity !== null) {
      message.capacity = String(object.capacity);
    } else {
      message.capacity = undefined;
    }
    if (object.isDeleted !== undefined && object.isDeleted !== null) {
      message.isDeleted = Boolean(object.isDeleted);
    } else {
      message.isDeleted = false;
    }
    if (object.createdDate !== undefined && object.createdDate !== null) {
      message.createdDate = fromJsonTimestamp(object.createdDate);
    } else {
      message.createdDate = undefined;
    }
    if (object.updatedDate !== undefined && object.updatedDate !== null) {
      message.updatedDate = fromJsonTimestamp(object.updatedDate);
    } else {
      message.updatedDate = undefined;
    }
    return message;
  },

  toJSON(message: EventLocation): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = eventLocationTypeToJSON(message.type));
    message.name !== undefined && (obj.name = message.name);
    message.referenceId !== undefined && (obj.referenceId = message.referenceId);
    message.floorId !== undefined && (obj.floorId = message.floorId);
    message.capacity !== undefined && (obj.capacity = message.capacity);
    message.isDeleted !== undefined && (obj.isDeleted = message.isDeleted);
    message.createdDate !== undefined && (obj.createdDate = message.createdDate.toISOString());
    message.updatedDate !== undefined && (obj.updatedDate = message.updatedDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<EventLocation>): EventLocation {
    const message = { ...baseEventLocation } as EventLocation;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    } else {
      message.type = 0;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = '';
    }
    if (object.referenceId !== undefined && object.referenceId !== null) {
      message.referenceId = object.referenceId;
    } else {
      message.referenceId = undefined;
    }
    if (object.floorId !== undefined && object.floorId !== null) {
      message.floorId = object.floorId;
    } else {
      message.floorId = undefined;
    }
    if (object.capacity !== undefined && object.capacity !== null) {
      message.capacity = object.capacity;
    } else {
      message.capacity = undefined;
    }
    if (object.isDeleted !== undefined && object.isDeleted !== null) {
      message.isDeleted = object.isDeleted;
    } else {
      message.isDeleted = false;
    }
    if (object.createdDate !== undefined && object.createdDate !== null) {
      message.createdDate = object.createdDate;
    } else {
      message.createdDate = undefined;
    }
    if (object.updatedDate !== undefined && object.updatedDate !== null) {
      message.updatedDate = object.updatedDate;
    } else {
      message.updatedDate = undefined;
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = Number(t.seconds) * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
