import React from 'react';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Image, Flex, mediaBreakpointMixin, spaceMixin, token } from '@zillow/constellation';
import { ErrorHandler } from '../../components/ErrorHandler';
import { NotFound } from '../../components/PageError';
import AckReservationContainer from '../../containers/AckReservationContainer';
import { PageWrapper, ContentWrapper } from '../HQApp/HQApp';
import { HQLandingPagesLogic } from './HQLandingPages.hooks';
import zallPassLogoImg from '../../assets/zallPassLogo.png';

export enum RoutePath {
  COMM_RESERVATION_CONFIRM = '/lp/comm/res/confirm',
  COMM_RESERVATION_CANCEL = '/lp/comm/res/cancel',
  NOT_FOUND = '/lp/notfound',
}

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  background: ${token('colors.white')};
  padding: 0 ${spaceMixin('sm')};
  border-bottom: 1px solid ${token('colors.gray300')};
`;

const LogoWrapper = styled(Flex)`
  margin: 0 auto;
  text-align: center;
`;

const ZallPassLogo = styled(Image)`
  @media ${mediaBreakpointMixin('xl')} {
    width: 255px;
  }
  margin: auto;
  display: block;
  width: 170px;
`;

const HQLandingPages: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  HQLandingPagesLogic(props);

  return (
    <PageWrapper backgroundColor={'backgroundBlue'}>
      <HeaderWrapper>
        <LogoWrapper paddingX="xs" paddingY={'sm'}>
          <ZallPassLogo alt={'zall pass logo'} title={'zall pass logo'} src={zallPassLogoImg} />
        </LogoWrapper>
      </HeaderWrapper>
      <ContentWrapper>
        <ErrorHandler>
          <Switch>
            <Route
              path={RoutePath.COMM_RESERVATION_CONFIRM}
              render={() => <AckReservationContainer action={'confirm'} />}
            />
            <Route
              path={RoutePath.COMM_RESERVATION_CANCEL}
              render={() => <AckReservationContainer action={'cancel'} />}
            />
            <Route component={NotFound} />
          </Switch>
        </ErrorHandler>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default withRouter(HQLandingPages);
