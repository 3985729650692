import React, { useState, useEffect, createRef } from 'react';
import styled from 'styled-components';
import { useGesture } from 'react-use-gesture';
export interface FloorPlanMobileProps {
  /** floor plan img src */
  imgSrc: string;
  /** floor plan img alt */
  imgAlt: string;
}

const FloorPlanNavHeight = '78px';

const PinchScrollContainer = styled.div`
  height: ${(props) =>
    `calc(100vh - ${props.theme.mobileHeaderHeight} - ${FloorPlanNavHeight} - ${props.theme.footerHeight})`};
  overflow: hidden;
  position: relative;
`;

const FloorPlanImg = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  touch-action: none;
`;

export const FloorPlanMobile: React.FC<FloorPlanMobileProps> = (props: FloorPlanMobileProps) => {
  const { imgSrc, imgAlt } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0, scale: 1 });
  const imageRef = createRef<HTMLImageElement>();
  useGesture(
    {
      onDrag: ({ offset: [dx, dy] }) => {
        setCrop((crop) => ({ ...crop, x: dx, y: dy }));
      },
      onPinch: ({ offset: [d] }) => {
        setCrop((crop) => ({ ...crop, scale: 1 + d / 100 }));
      },
    },
    {
      domTarget: imageRef,
      eventOptions: { passive: false },
    },
  );

  /** reset image styles when imgSrc changes */
  useEffect(() => {
    const { current } = imageRef;
    if (current) {
      current.style.left = 'initial';
      current.style.top = '0';
      current.style.transform = 'initial';
    }
  }, [imgSrc]);

  return (
    <PinchScrollContainer>
      <FloorPlanImg
        ref={imageRef}
        src={imgSrc}
        alt={imgAlt}
        style={{
          left: crop.x,
          top: crop.y,
          transform: `scale(${crop.scale})`,
        }}
      />
    </PinchScrollContainer>
  );
};
