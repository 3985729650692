import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmNavigationModal } from './ConfirmNavigationModal';

export interface InternalLinkProps {
  /** path to navigate to */
  pathname: string;
  /** should confirm navigation */
  toConfirmNavigation: boolean;
  /** standard children prop */
  children: React.ReactNode;
}

export const InternalLink: React.FC<InternalLinkProps> = ({
  pathname,
  toConfirmNavigation,
  children,
}: InternalLinkProps) => {
  const [isModalOpen, setIsModalOpen] = useState(toConfirmNavigation);
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (toConfirmNavigation) {
      setIsModalOpen(true);
    } else {
      history.push(pathname);
    }
  }, [pathname, toConfirmNavigation]);

  return (
    <>
      <span data-testid='render-children-comp-test' onClick={handleClick}>{children}</span>
      <ConfirmNavigationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onContinue={() => history.push(pathname)}
      />
    </>
  );
};
