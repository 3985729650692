import React, { useState, useRef } from 'react';
import { MassUploadModalContainerProps } from './MassUploadModalContainer';
import { MassUploadReportStatusType, MassUploadReportStatusValue } from '../../../store/office';

export const MassUploadModalContainerLogic = ({
  onClose,
  bookOfficeVisitsBulk,
}: MassUploadModalContainerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [reportID, setReportID] = useState<string | undefined>(undefined);
  const [reportStatus, setReportStatus] = useState<MassUploadReportStatusType>(
    MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_UNSPECIFIED,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const handleUploadClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (inputRef && inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileUploaded = e.target.files[0];
      setFile(fileUploaded);
    }
  };

  const handleFileSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    if (file) {
      setIsUploading(true);
      bookOfficeVisitsBulk(file)
        .then(({ reportID, result }) => {
          setReportID(reportID);
          setReportStatus(result);
        })
        .catch((err) => {
          setErrorMessage(err.message);
        })
        .finally(() => {
          setIsUploading(false);
          setFile(undefined);
        });
    }
  };

  const handleClose = () => {
    if (file) {
      if (window.confirm('Are you sure to cancel this mass upload request?')) {
        handleReset();
        onClose();
      }
    } else {
      handleReset();
      onClose();
    }
  };

  const handleReset = () => {
    setFile(undefined);
    setReportID(undefined);
    setReportStatus(MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_UNSPECIFIED);
    setErrorMessage('');
  };

  return {
    inputRef,
    file,
    reportID,
    reportStatus,
    errorMessage,
    isUploading,
    handleUploadClick,
    handleFileChange,
    handleFileSubmit,
    handleClose,
    handleReset,
  };
};
