import { Action } from 'redux';
import { RootState } from './index';
import {
  HealthStatus as HealthStatusContract,
  ServiceName as ServiceNameContract,
} from '../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/service_status';
import { createSelector, Selector } from 'reselect';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  HQWebClientImpl,
  GetSystemHealthRequest,
  GetSystemHealthResponse,
} from '../contract/hqengine_hqweb';
import { RpcImpl } from '../contract/rpc';
import { StatusType } from '../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { HQEngineErrorResponse, GetErrorStatusType } from './errorHelpers';
import DynamicConfig from '../config/DynamicConfig';

/** types */

export type ServiceName = ServiceNameContract;
export const ServiceNameValues = ServiceNameContract;
export type ServiceStatus = {
  serviceName: ServiceName;
  status: ServiceStatusType;
  message?: string;
};

export enum ServiceStatusType {
  SERVICE_STATUS_OK = 0,
  SERVICE_STATUS_ERROR = 1,
}

export const HealthStatusContractToServiceStatusType = (
  type: HealthStatusContract,
): ServiceStatusType => {
  switch (type) {
    case HealthStatusContract.HEALTH_STATUS_OK:
      return ServiceStatusType.SERVICE_STATUS_OK;
    default:
      return ServiceStatusType.SERVICE_STATUS_ERROR;
  }
};

export type SystemState = {
  services: ServiceStatus[];
};

/** actions */

export interface GetSystemStatusSuccess extends Action {
  type: 'GET_SYSTEM_STATUS_SUCCESS';
  services: ServiceStatus[];
}

export interface GetSystemStatusFailure extends Action {
  type: 'GET_SYSTEM_STATUS_FAILURE';
}

export interface MockGetSystemStatus extends Action {
  type: 'MOCK_GET_SYSTEM_STATUS';
}

export type SystemAction = GetSystemStatusSuccess | GetSystemStatusFailure | MockGetSystemStatus;

export const getSystemStatusSuccess = (services: ServiceStatus[]): GetSystemStatusSuccess => ({
  type: 'GET_SYSTEM_STATUS_SUCCESS',
  services: services,
});

export const getSystemStatus =
  (): ThunkAction<Promise<void>, SystemState, null, SystemAction> =>
  async (dispatch: ThunkDispatch<SystemState, null, SystemAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    return client
      .GetSystemHealth({} as GetSystemHealthRequest)
      .then((resp: GetSystemHealthResponse) => {
        const [services, err] = validateGetSystemHealthResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getSystemStatusSuccess(services));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const mockGetSystemStatus = (): MockGetSystemStatus => ({
  type: 'MOCK_GET_SYSTEM_STATUS',
});

const mockServiceStatus: ServiceStatus[] = [
  {
    serviceName: ServiceNameValues.SERVICE_NAME_EMPLOYEE_API,
    status: ServiceStatusType.SERVICE_STATUS_OK,
  },
];

export const validateGetSystemHealthResp = (
  resp: GetSystemHealthResponse,
): [ServiceStatus[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.serviceStatuses || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  const services: ServiceStatus[] = resp.serviceStatuses.map((ss) => {
    return {
      serviceName: ss.name,
      status: HealthStatusContractToServiceStatusType(ss.status),
      message: ss.message,
    };
  });

  return [services, errResp];
};

/** reducers */

const initialState: SystemState = {
  services: [],
};

export const systemReducer = (state = initialState, action: SystemAction): SystemState => {
  switch (action.type) {
    case 'GET_SYSTEM_STATUS_SUCCESS':
      return {
        ...state,
        services: action.services,
      };
    case 'MOCK_GET_SYSTEM_STATUS':
      return {
        ...state,
        services: mockServiceStatus,
      };
    default:
      return state;
  }
};

/** selectors */

export const selectSystemState = (state: RootState): SystemState => state.system;

export const selectServiceStatus = (state: RootState): ServiceStatus[] =>
  selectSystemState(state).services;

export const selectHasDependencyError: Selector<RootState, boolean> = createSelector(
  [selectServiceStatus],
  () => false,
  // TF-2765 vaccine req is not needed, remove system health check for now
  // (services) => {
  //   return services.some(
  //     (service) =>
  //       service.serviceName === ServiceNameValues.SERVICE_NAME_EMPLOYEE_API &&
  //       service.status === ServiceStatusType.SERVICE_STATUS_ERROR,
  //   );
  // },
);
