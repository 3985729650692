import React from 'react';
import { connect } from 'react-redux';
import {
  Popper,
  Popover,
  Text,
  TriggerText,
  Paragraph,
  Heading,
  Table,
} from '@zillow/constellation';
import { RootState } from '../../store';
import {
  getOfficeUsages,
  Office,
  OfficeUsage,
  selectOfficeMap,
  selectOfficeBlockedDatesMap,
} from '../../store/office';
import { IsMockContext } from '../../middleware/auth';
import {
  MockCapacityPopoverContainerLogic,
  CapacityPopoverContainerLogic,
} from './CapacityPopoverContainer.hooks';
import { DateUtils } from '../../utilities/dateutils';

interface StateProps {
  /** all offices */
  officeMap: Map<string, Office>;
  /** all offices blocked days */
  allBlockedDates: Map<string, Date[]>;
  /** get office booking counts in an interval for an office */
  getOfficeUsages: (officeId: string, dates: Date[]) => Promise<OfficeUsage[]>;
}

interface OwnProps {
  /** trigger text content */
  text: string;
  /** target office id */
  officeId: string;
  /** target dates to display usages, empty will just show max capacity for office */
  dates?: Date[];
}

export type CapacityPopoverContainerProps = StateProps & OwnProps;

const CapacityPopoverContainer: React.FC<CapacityPopoverContainerProps> = (
  props: CapacityPopoverContainerProps,
) => {
  const isMock = React.useContext(IsMockContext);
  const { text, officeId, dates, allBlockedDates } = props;

  const { max, officeName, officeUsages } = isMock
    ? MockCapacityPopoverContainerLogic(props)
    : CapacityPopoverContainerLogic(props);

  const infoPopover = (
    <Popover
      appearance="info"
      header={<Heading level={6}>Daily capacity</Heading>}
      body={
        !dates || !officeUsages ? (
          <Paragraph>
            {max} is the maximum amount of day passes allowed per day for the {officeName} office.
            Select a day or days to view the number of day passes currently booked.
          </Paragraph>
        ) : (
          <>
            <Paragraph>
              Below are the number of day passes currently booked for each day selected.
            </Paragraph>
            <Table appearance="horizontal" aria-label="capacity by date table" size="md">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Day passes booked</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {officeUsages && (
                <Table.Body>
                  {officeUsages.map(
                    (usage) =>
                      usage.date && (
                        <Table.Row key={`${usage.officeId}-${usage.date}`}>
                          <Table.HeaderCell>
                            {DateUtils.DateToUSLocaleStr(DateUtils.DateFromUTC(usage.date))}
                          </Table.HeaderCell>
                          <Table.Cell>
                            {allBlockedDates.get(officeId)?.includes(usage.date)
                              ? 'No bookings available'
                              : parseInt(usage.bookingCount) < max
                              ? `${usage.bookingCount} out of ${max}`
                              : 'Daily capacity met'}
                          </Table.Cell>
                        </Table.Row>
                      ),
                  )}
                </Table.Body>
              )}
            </Table>
          </>
        )
      }
    />
  );

  return (
    <Popper triggered={infoPopover} paddingY="xs">
      <Text fontType="bodySmall">
        <TriggerText>{text}</TriggerText>
      </Text>
    </Popper>
  );
};

const mapStateToProps = (state: RootState) => ({
  officeMap: selectOfficeMap(state),
  allBlockedDates: selectOfficeBlockedDatesMap(state),
});

const mapDispatchToProps = {
  getOfficeUsages: getOfficeUsages,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(CapacityPopoverContainer);
