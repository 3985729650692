/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'peng_hqengine_contract';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  photoUrl: string;
  username: string;
}

export interface EmployeeFound {
  id: string;
  fullName: string;
  title: string;
  email: string;
  photoUrl: string;
}

const baseUser: object = {
  id: '0',
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  photoUrl: '',
  username: '',
};

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.email !== '') {
      writer.uint32(18).string(message.email);
    }
    if (message.firstName !== '') {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== '') {
      writer.uint32(34).string(message.lastName);
    }
    if (message.title !== '') {
      writer.uint32(42).string(message.title);
    }
    if (message.photoUrl !== '') {
      writer.uint32(50).string(message.photoUrl);
    }
    if (message.username !== '') {
      writer.uint32(58).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUser } as User;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.email = reader.string();
          break;
        case 3:
          message.firstName = reader.string();
          break;
        case 4:
          message.lastName = reader.string();
          break;
        case 5:
          message.title = reader.string();
          break;
        case 6:
          message.photoUrl = reader.string();
          break;
        case 7:
          message.username = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): User {
    const message = { ...baseUser } as User;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = '';
    }
    if (object.firstName !== undefined && object.firstName !== null) {
      message.firstName = String(object.firstName);
    } else {
      message.firstName = '';
    }
    if (object.lastName !== undefined && object.lastName !== null) {
      message.lastName = String(object.lastName);
    } else {
      message.lastName = '';
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = '';
    }
    if (object.photoUrl !== undefined && object.photoUrl !== null) {
      message.photoUrl = String(object.photoUrl);
    } else {
      message.photoUrl = '';
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = String(object.username);
    } else {
      message.username = '';
    }
    return message;
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.email !== undefined && (obj.email = message.email);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.title !== undefined && (obj.title = message.title);
    message.photoUrl !== undefined && (obj.photoUrl = message.photoUrl);
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  fromPartial(object: DeepPartial<User>): User {
    const message = { ...baseUser } as User;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = '';
    }
    if (object.firstName !== undefined && object.firstName !== null) {
      message.firstName = object.firstName;
    } else {
      message.firstName = '';
    }
    if (object.lastName !== undefined && object.lastName !== null) {
      message.lastName = object.lastName;
    } else {
      message.lastName = '';
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = '';
    }
    if (object.photoUrl !== undefined && object.photoUrl !== null) {
      message.photoUrl = object.photoUrl;
    } else {
      message.photoUrl = '';
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = object.username;
    } else {
      message.username = '';
    }
    return message;
  },
};

const baseEmployeeFound: object = { id: '0', fullName: '', title: '', email: '', photoUrl: '' };

export const EmployeeFound = {
  encode(message: EmployeeFound, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.fullName !== '') {
      writer.uint32(18).string(message.fullName);
    }
    if (message.title !== '') {
      writer.uint32(26).string(message.title);
    }
    if (message.email !== '') {
      writer.uint32(34).string(message.email);
    }
    if (message.photoUrl !== '') {
      writer.uint32(42).string(message.photoUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmployeeFound {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEmployeeFound } as EmployeeFound;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.fullName = reader.string();
          break;
        case 3:
          message.title = reader.string();
          break;
        case 4:
          message.email = reader.string();
          break;
        case 5:
          message.photoUrl = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EmployeeFound {
    const message = { ...baseEmployeeFound } as EmployeeFound;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.fullName !== undefined && object.fullName !== null) {
      message.fullName = String(object.fullName);
    } else {
      message.fullName = '';
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = '';
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = '';
    }
    if (object.photoUrl !== undefined && object.photoUrl !== null) {
      message.photoUrl = String(object.photoUrl);
    } else {
      message.photoUrl = '';
    }
    return message;
  },

  toJSON(message: EmployeeFound): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.fullName !== undefined && (obj.fullName = message.fullName);
    message.title !== undefined && (obj.title = message.title);
    message.email !== undefined && (obj.email = message.email);
    message.photoUrl !== undefined && (obj.photoUrl = message.photoUrl);
    return obj;
  },

  fromPartial(object: DeepPartial<EmployeeFound>): EmployeeFound {
    const message = { ...baseEmployeeFound } as EmployeeFound;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.fullName !== undefined && object.fullName !== null) {
      message.fullName = object.fullName;
    } else {
      message.fullName = '';
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = '';
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = '';
    }
    if (object.photoUrl !== undefined && object.photoUrl !== null) {
      message.photoUrl = object.photoUrl;
    } else {
      message.photoUrl = '';
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
