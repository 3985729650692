import { parse, isValid, startOfDay, addDays } from 'date-fns';

export class DateUtils {
  /**
   * Check if the string is valid date string using [date-fns](https://date-fns.org/) library.
   *
   * @param str - The string to check
   * @param dateFormat - date format defined in [date-fns accepted patterns](https://date-fns.org/docs/parse)
   * @returns boolean
   */
  public static IsValidDateString(str: string, dateFormat: string): boolean {
    try {
      const parsed = parse(str, dateFormat, new Date());
      return isValid(parsed);
    } catch {
      return false;
    }
  }

  /**
   * Convert any Date to local time (browser/IP address)
   *
   * @param date - The date to convert
   * @returns date in local time
   */
  public static DateFromUTC(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }

  /**
   * Convert any Date to UTC
   *
   * @param date - The date to convert
   * @returns date in UTC
   */
  public static DateToUTC(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  }

  /**
   * Convert any Date to middle-endian format
   *
   * @param date - The date to convert
   * @returns date string in middle-endian format, i.e. '02/11/2013'
   */
  public static DateToUSLocaleStr(date: Date): string {
    return date.toLocaleDateString('en-US');
  }

  /**
   * Convert any Date to MM/DD/YY format
   *
   * @param date - The date to convert
   * @returns date string in MM/DD/YY format, i.e. '02/11/13'
   */
  public static DateToFormattedLocaleStr(date: Date): string {
    if (typeof date === 'string') date = new Date(date);
    const month = date.getMonth() + 1; // JavaScript counts months from 0 to 11
    const m = month < 10 ? `0${month}` : `${month}`;
    const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const y = date.getFullYear().toString().substring(-2);
    return `${m}/${d}/${y}`;
  }

  /**
   * Convert any Date to M/D format
   *
   * @param date - The date to convert
   * @returns date string in M/D format, i.e. '2/11'
   */
  public static DateToShorthandFormattedStr(date: Date): string {
    if (typeof date === 'string') date = new Date(date);
    const month = date.getMonth() + 1; // JavaScript counts months from 0 to 11
    return `${month}/${date.getDate()}`;
  }

  /**
   * Get dates between two dates, including start and end,
   * ignore hour/second
   *
   * @param startDate - The date to start
   * @param endDate - The date to end
   * @returns array of dates in between
   */
  public static GetDatesBetween(startDate: Date, endDate: Date): Date[] {
    if (startDate > endDate) {
      return [];
    }
    const dates: Date[] = [];
    let cur: Date = startOfDay(startDate);
    while (cur <= startOfDay(endDate)) {
      dates.push(cur);
      cur = addDays(cur, 1);
    }
    return dates;
  }
}
