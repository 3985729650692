/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { StringValue, DoubleValue, Int64Value } from './google/protobuf/wrappers';

export const protobufPackage = 'peng_hqengine_contract';

export interface Address {
  street: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
}

export interface Office {
  id: string;
  name: string | undefined;
  address: Address | undefined;
  /** office location */
  latitude: number | undefined;
  longitude: number | undefined;
  floorsInfo: string | undefined;
  hoursInfo: string | undefined;
  photoUrl: string | undefined;
  infoUrl: string | undefined;
  timezone: string | undefined;
  mapLinkUrl: string | undefined;
  isBookable: boolean;
  maxCapacity: string | undefined;
}

const baseAddress: object = {};

export const Address = {
  encode(message: Address, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.street !== undefined) {
      StringValue.encode({ value: message.street! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.city !== undefined) {
      StringValue.encode({ value: message.city! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      StringValue.encode({ value: message.state! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.zip !== undefined) {
      StringValue.encode({ value: message.zip! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddress } as Address;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.street = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.city = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.state = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.zip = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Address {
    const message = { ...baseAddress } as Address;
    if (object.street !== undefined && object.street !== null) {
      message.street = String(object.street);
    } else {
      message.street = undefined;
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = String(object.city);
    } else {
      message.city = undefined;
    }
    if (object.state !== undefined && object.state !== null) {
      message.state = String(object.state);
    } else {
      message.state = undefined;
    }
    if (object.zip !== undefined && object.zip !== null) {
      message.zip = String(object.zip);
    } else {
      message.zip = undefined;
    }
    return message;
  },

  toJSON(message: Address): unknown {
    const obj: any = {};
    message.street !== undefined && (obj.street = message.street);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state);
    message.zip !== undefined && (obj.zip = message.zip);
    return obj;
  },

  fromPartial(object: DeepPartial<Address>): Address {
    const message = { ...baseAddress } as Address;
    if (object.street !== undefined && object.street !== null) {
      message.street = object.street;
    } else {
      message.street = undefined;
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = object.city;
    } else {
      message.city = undefined;
    }
    if (object.state !== undefined && object.state !== null) {
      message.state = object.state;
    } else {
      message.state = undefined;
    }
    if (object.zip !== undefined && object.zip !== null) {
      message.zip = object.zip;
    } else {
      message.zip = undefined;
    }
    return message;
  },
};

const baseOffice: object = { id: '0', isBookable: false };

export const Office = {
  encode(message: Office, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(26).fork()).ldelim();
    }
    if (message.latitude !== undefined) {
      DoubleValue.encode({ value: message.latitude! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.longitude !== undefined) {
      DoubleValue.encode({ value: message.longitude! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.floorsInfo !== undefined) {
      StringValue.encode({ value: message.floorsInfo! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.hoursInfo !== undefined) {
      StringValue.encode({ value: message.hoursInfo! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.photoUrl !== undefined) {
      StringValue.encode({ value: message.photoUrl! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.infoUrl !== undefined) {
      StringValue.encode({ value: message.infoUrl! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.timezone !== undefined) {
      StringValue.encode({ value: message.timezone! }, writer.uint32(82).fork()).ldelim();
    }
    if (message.mapLinkUrl !== undefined) {
      StringValue.encode({ value: message.mapLinkUrl! }, writer.uint32(90).fork()).ldelim();
    }
    if (message.isBookable === true) {
      writer.uint32(96).bool(message.isBookable);
    }
    if (message.maxCapacity !== undefined) {
      Int64Value.encode({ value: message.maxCapacity! }, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Office {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOffice } as Office;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.name = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.address = Address.decode(reader, reader.uint32());
          break;
        case 4:
          message.latitude = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.longitude = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.floorsInfo = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.hoursInfo = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.photoUrl = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 9:
          message.infoUrl = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 10:
          message.timezone = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 11:
          message.mapLinkUrl = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 12:
          message.isBookable = reader.bool();
          break;
        case 13:
          message.maxCapacity = Int64Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Office {
    const message = { ...baseOffice } as Office;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = undefined;
    }
    if (object.address !== undefined && object.address !== null) {
      message.address = Address.fromJSON(object.address);
    } else {
      message.address = undefined;
    }
    if (object.latitude !== undefined && object.latitude !== null) {
      message.latitude = Number(object.latitude);
    } else {
      message.latitude = undefined;
    }
    if (object.longitude !== undefined && object.longitude !== null) {
      message.longitude = Number(object.longitude);
    } else {
      message.longitude = undefined;
    }
    if (object.floorsInfo !== undefined && object.floorsInfo !== null) {
      message.floorsInfo = String(object.floorsInfo);
    } else {
      message.floorsInfo = undefined;
    }
    if (object.hoursInfo !== undefined && object.hoursInfo !== null) {
      message.hoursInfo = String(object.hoursInfo);
    } else {
      message.hoursInfo = undefined;
    }
    if (object.photoUrl !== undefined && object.photoUrl !== null) {
      message.photoUrl = String(object.photoUrl);
    } else {
      message.photoUrl = undefined;
    }
    if (object.infoUrl !== undefined && object.infoUrl !== null) {
      message.infoUrl = String(object.infoUrl);
    } else {
      message.infoUrl = undefined;
    }
    if (object.timezone !== undefined && object.timezone !== null) {
      message.timezone = String(object.timezone);
    } else {
      message.timezone = undefined;
    }
    if (object.mapLinkUrl !== undefined && object.mapLinkUrl !== null) {
      message.mapLinkUrl = String(object.mapLinkUrl);
    } else {
      message.mapLinkUrl = undefined;
    }
    if (object.isBookable !== undefined && object.isBookable !== null) {
      message.isBookable = Boolean(object.isBookable);
    } else {
      message.isBookable = false;
    }
    if (object.maxCapacity !== undefined && object.maxCapacity !== null) {
      message.maxCapacity = String(object.maxCapacity);
    } else {
      message.maxCapacity = undefined;
    }
    return message;
  },

  toJSON(message: Office): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.address !== undefined &&
      (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    message.latitude !== undefined && (obj.latitude = message.latitude);
    message.longitude !== undefined && (obj.longitude = message.longitude);
    message.floorsInfo !== undefined && (obj.floorsInfo = message.floorsInfo);
    message.hoursInfo !== undefined && (obj.hoursInfo = message.hoursInfo);
    message.photoUrl !== undefined && (obj.photoUrl = message.photoUrl);
    message.infoUrl !== undefined && (obj.infoUrl = message.infoUrl);
    message.timezone !== undefined && (obj.timezone = message.timezone);
    message.mapLinkUrl !== undefined && (obj.mapLinkUrl = message.mapLinkUrl);
    message.isBookable !== undefined && (obj.isBookable = message.isBookable);
    message.maxCapacity !== undefined && (obj.maxCapacity = message.maxCapacity);
    return obj;
  },

  fromPartial(object: DeepPartial<Office>): Office {
    const message = { ...baseOffice } as Office;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = undefined;
    }
    if (object.address !== undefined && object.address !== null) {
      message.address = Address.fromPartial(object.address);
    } else {
      message.address = undefined;
    }
    if (object.latitude !== undefined && object.latitude !== null) {
      message.latitude = object.latitude;
    } else {
      message.latitude = undefined;
    }
    if (object.longitude !== undefined && object.longitude !== null) {
      message.longitude = object.longitude;
    } else {
      message.longitude = undefined;
    }
    if (object.floorsInfo !== undefined && object.floorsInfo !== null) {
      message.floorsInfo = object.floorsInfo;
    } else {
      message.floorsInfo = undefined;
    }
    if (object.hoursInfo !== undefined && object.hoursInfo !== null) {
      message.hoursInfo = object.hoursInfo;
    } else {
      message.hoursInfo = undefined;
    }
    if (object.photoUrl !== undefined && object.photoUrl !== null) {
      message.photoUrl = object.photoUrl;
    } else {
      message.photoUrl = undefined;
    }
    if (object.infoUrl !== undefined && object.infoUrl !== null) {
      message.infoUrl = object.infoUrl;
    } else {
      message.infoUrl = undefined;
    }
    if (object.timezone !== undefined && object.timezone !== null) {
      message.timezone = object.timezone;
    } else {
      message.timezone = undefined;
    }
    if (object.mapLinkUrl !== undefined && object.mapLinkUrl !== null) {
      message.mapLinkUrl = object.mapLinkUrl;
    } else {
      message.mapLinkUrl = undefined;
    }
    if (object.isBookable !== undefined && object.isBookable !== null) {
      message.isBookable = object.isBookable;
    } else {
      message.isBookable = false;
    }
    if (object.maxCapacity !== undefined && object.maxCapacity !== null) {
      message.maxCapacity = object.maxCapacity;
    } else {
      message.maxCapacity = undefined;
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
