import React from 'react';
import { Heading, Paragraph, IconSuccess, Flex, Divider, Anchor } from '@zillow/constellation';
import { Modal } from '../../components/Modal';
import DynamicConfig from '../../config/DynamicConfig';

export interface BookingConfirmationModalProps {
  /** if modal is open */
  isOpen: boolean;
  /** function to handle close */
  onClose: () => void;
}

export const BookingConfirmationModal: React.FC<BookingConfirmationModalProps> = ({
  isOpen,
  onClose,
}: BookingConfirmationModalProps) => {
  return (
    <Modal
      fullScreen={{ sm_lte: true }}
      header={<Heading level={6}>Approved</Heading>}
      body={
        <Flex margin="xs">
          <IconSuccess fontColor="green500" size="md" />
          <Heading level={4} marginY="sm" fontFamily="serif">
            You&apos;re all set!
          </Heading>
          <Paragraph>
            Thank you for booking your visit. You will receive a confirmation email shortly and an
            event will be added to your calendar.
          </Paragraph>
          <Divider marginY="sm" />
          <Paragraph>
            Looking to reserve a space? Use the instructions on{' '}
            <Anchor href={DynamicConfig.GetConfig().SPACE_BOOKING_INSTRUCTIONS_URL} target="_blank">
              Zallway
            </Anchor>{' '}
            to find the right space for you.
          </Paragraph>
        </Flex>
      }
      isOpen={isOpen}
      handleClose={onClose}
      shouldCloseOnOutsideClick={true}
    />
  );
};
