/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Card,
  Flex,
  Paragraph,
  Spacer,
  Heading,
  Select,
  FormField,
  Label,
  Divider,
  mediaBreakpointMixin,
  Alert,
  IconArrowRightOutline,
  Button,
  TextButton,
  ButtonGroup,
  withToast,
} from '@zillow/constellation';
import { RootState } from '../../store';
import {
  Office,
  Reservation,
  selectOfficesAlphabeticallyOrdered,
  getOfficesBlockedDates,
  getOfficeFullyBookedDates,
  selectReservations,
} from '../../store/office';
import { Loader } from '../../components/Loader';
import {
  UserInboxMessage,
  selectUserTrainingStatus,
  selectUserInboxMessages,
  acknowledgeUserInboxMessage,
} from '../../store/user';
import { IsMockContext } from '../../middleware/auth';
import {
  OfficeVisitContainerLogic,
  MockOfficeVisitContainerLogic,
} from './OfficeVisitContainer.hooks';
import BookingContainer from '../BookingContainer/BookingContainer';
import { OfficeDetailsPanel } from '../../components/OfficeDetailsPanel';
import { BookingPrereqError } from '../../components/BookingPrereqPanel';
import { Modal } from '../../components/Modal';
import { IsBookingInProgressContext } from '../HomeContainer/IsBookingInProgressContext';
import DynamicConfig from '../../config/DynamicConfig';

interface StateProps {
  /** list of offices */
  offices: Office[];
  /** current user has return to office training or not */
  hasZGUTraining: boolean;
  /** user inbox messages */
  userInboxMessages: UserInboxMessage[];
  /** acknowledge user inbox message */
  acknowledgeUserInboxMessage: (mesageId: string) => Promise<void>;
  /** get offices blocked dates */
  getOfficesBlockedDates: (startDate: Date | undefined, endDate: Date | undefined) => Promise<void>;
  /** get fully booked dates in an interval for an office */
  getOfficeFullyBookedDates: (
    officeId: string,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => Promise<Date[]>;
  /** list of reservations */
  reservations: Reservation[];

  /** constellation toast */
  enqueueToast: (msg: JSX.Element, opts: unknown) => void;
}
interface OwnProps {
  /** data loaded or not */
  isLoaded: boolean;
  /** has loading error or not */
  hasLoadingError: boolean;
  /** has dependency error or not */
  hasDependencyError: boolean;
}

export type OfficeVisitContainerProps = StateProps & OwnProps;

const CardContentWrapper = styled.div`
  @media ${mediaBreakpointMixin('lg')} {
    min-height: 728px;
  }
`;

const OfficeDetailsDivider = styled(Divider)`
  @media ${mediaBreakpointMixin('lg')} {
    display: block;
  }
  display: none;
`;

const MainContentWrapper = styled.div`
  @media ${mediaBreakpointMixin('lg')} {
    min-height: 400px;
  }
  min-height: auto;
`;

const MobileBookNowButton = styled(Button)`
  @media ${mediaBreakpointMixin('lg')} {
    display: none;
  }
`;

const DesktopBookingContainerWrapper = styled.span`
  @media ${mediaBreakpointMixin('lg')} {
    display: block;
  }
  display: none;
`;

export const MobileButtonGroup = styled(ButtonGroup)`
  @media ${mediaBreakpointMixin('sm_lte')} {
    /* extra space to help prevent Safari nav bar from popping up */
    margin-bottom: 44px;
  }
`;
const OfficeVisitContainer: React.FC<OfficeVisitContainerProps> = (
  props: OfficeVisitContainerProps,
) => {
  const isMock = React.useContext(IsMockContext);
  const { offices, hasZGUTraining, isLoaded, hasLoadingError, hasDependencyError } = props;
  const {
    isBlockedDatesLoaded,
    fullyBookedDates,
    setFullyBookedDates,
    selectedIdx,
    handleOfficeChange,
    officeSelected,
    isMobileBookingModalOpen,
    setIsMobileBookingModalOpen,
    mobileCTA,
    setMobileCTA,
  } = isMock ? MockOfficeVisitContainerLogic(props) : OfficeVisitContainerLogic(props);

  const isQualifiedToBook = DynamicConfig.GetConfig().ENABLE_ZGU_TRAINING_REQ == "false" || hasZGUTraining;
  const { setIsBookingInProgress } = React.useContext(IsBookingInProgressContext);

  const officeSelectionForm = React.useMemo(
    () => (
      <Select
        value={selectedIdx}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOfficeChange(parseInt(e.target.value))
        }
      >
        <option value={'DEFAULT'} disabled>
          Please select
        </option>
        {offices.map((office, i) => (
          <option key={i} value={i}>
            {office.name}
          </option>
        ))}
      </Select>
    ),
    [selectedIdx, offices, handleOfficeChange],
  );

  return (
    <Card
      cardType="secondary"
      paddingTop={{ default: 'md', lg: 'xl' }}
      paddingX={{ default: 'sm', lg: 'xl' }}
    >
      <CardContentWrapper>
        <Heading level="3" fontFamily="serif" fontType={{ default: 'h4', lg: 'h3' }}>
          Zillow Office Visit
        </Heading>
        <Paragraph marginTop="sm">
          Use this booking tool to schedule a day pass. Please note that individual working spaces
          are first-come-first-served and are not reservable (see floor plans for team neighborhood
          locations). Group and Zoom spaces, though, can be booked in Outlook. If a date is not
          available to select, capacity has been met. Zillow office visits are only available to
          employees at this time.
        </Paragraph>

        <MainContentWrapper>
          <Loader loading={!isLoaded || !isBlockedDatesLoaded || !officeSelected}>
            {hasLoadingError ? (
              <Spacer marginTop="md">
                <Alert appearance="error" body="Unable to load office booking info" />
              </Spacer>
            ) : (
              <>
                <Flex display="flex" flexDirection={{ default: 'column', lg: 'row' }}>
                  <Flex flex="1" paddingY={{ default: 'xs', lg: 'md' }} paddingRight="lg">
                    <Flex display="flex" flexDirection="column">
                      <Flex
                        order={{ default: 2, lg: 1 }}
                        marginBottom={{ default: 'xs', lg: 'md' }}
                      >
                        <FormField label={<Label>Office</Label>} control={officeSelectionForm} />
                      </Flex>
                      {hasDependencyError && (
                        <Flex order={{ default: 1, lg: 2 }} marginY="sm">
                          <BookingPrereqError />
                        </Flex>
                      )}
                    </Flex>
                    {!hasDependencyError && isQualifiedToBook && (
                      <DesktopBookingContainerWrapper>
                        <BookingContainer
                          targetOffice={officeSelected!}
                          fullyBookedDates={fullyBookedDates}
                          setFullyBookedDates={setFullyBookedDates}
                        />
                      </DesktopBookingContainerWrapper>
                    )}
                  </Flex>
                  <OfficeDetailsDivider orientation="vertical" marginY={{ lg: 'lg' }} />
                  <OfficeDetailsPanel
                    office={officeSelected!}
                    mobileCTA={
                      isQualifiedToBook ? (
                        <MobileBookNowButton
                          buttonType="primary"
                          icon={<IconArrowRightOutline />}
                          iconPosition="end"
                          marginY="sm"
                          onClick={() => setIsMobileBookingModalOpen(true)}
                        >
                          Start booking
                        </MobileBookNowButton>
                      ) : undefined
                    }
                  />
                </Flex>
                {/* booking modal for mobile only */}
                <Modal
                  fullScreen={{ sm_lte: true }}
                  header={<Heading level={6}>Zillow Office Visit</Heading>}
                  body={
                    <Spacer margin="xs">
                      <FormField label={<Label>Office</Label>} control={officeSelectionForm} />
                      <Spacer margin="md" />
                      <BookingContainer
                        targetOffice={officeSelected!}
                        fullyBookedDates={fullyBookedDates}
                        setFullyBookedDates={setFullyBookedDates}
                        setMobileCTA={setMobileCTA}
                        setIsMobileBookingModalOpen={setIsMobileBookingModalOpen}
                      />
                    </Spacer>
                  }
                  footer={
                    <MobileButtonGroup aria-label="Booking actions">
                      <TextButton
                        onClick={() => {
                          setIsMobileBookingModalOpen(false);
                          setIsBookingInProgress(false);
                        }}
                      >
                        Cancel
                      </TextButton>
                      {mobileCTA !== null && mobileCTA}
                    </MobileButtonGroup>
                  }
                  isOpen={isMobileBookingModalOpen}
                  handleClose={() => {
                    setIsMobileBookingModalOpen(false);
                    setIsBookingInProgress(false);
                  }}
                  shouldCloseOnOutsideClick={true}
                />
              </>
            )}
          </Loader>
        </MainContentWrapper>
      </CardContentWrapper>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  offices: selectOfficesAlphabeticallyOrdered(state),
  hasZGUTraining: selectUserTrainingStatus(state),
  userInboxMessages: selectUserInboxMessages(state),
  reservations: selectReservations(state),
});

const mapDispatchToProps = {
  getOfficesBlockedDates: getOfficesBlockedDates,
  getOfficeFullyBookedDates: getOfficeFullyBookedDates,
  acknowledgeUserInboxMessage: acknowledgeUserInboxMessage,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(withToast(OfficeVisitContainer));
