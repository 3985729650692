import { OfficeState, OfficeAction, BookingType } from './types';
import { mockOffices, mockOfficesBlockedDates } from './mock/office';
import { mockUserReservations, mockBookingToReservations } from './mock/reservation';
import { mockOfficesFloors } from './mock/floors';

export const initialState: OfficeState = {
  offices: {},
  blockedDates: {},
  bookOfficeError: '',
  reservations: [],
  floors: {},
  bookingType: BookingType.BOOKING_TYPE_SINGLE_DAY,
};

export const officeReducer = (state = initialState, action: OfficeAction): OfficeState => {
  switch (action.type) {
    case 'GET_OFFICES_SUCCESS':
      return {
        ...state,
        offices: action.offices,
      };
    case 'MOCK_GET_OFFICES':
      return {
        ...state,
        offices: mockOffices,
      };
    case 'GET_OFFICES_BLOCKED_DATES_SUCCESS':
      return {
        ...state,
        blockedDates: action.blockedDates,
      };
    case 'MOCK_GET_OFFICES_BLOCKED_DATES':
      return {
        ...state,
        blockedDates: mockOfficesBlockedDates,
      };
    case 'GET_OFFICES_FLOORS_SUCCESS':
      return {
        ...state,
        floors: action.floors,
      };
    case 'MOCK_GET_OFFICES_FLOORS':
      return {
        ...state,
        floors: mockOfficesFloors,
      };
    case 'GET_USER_RESERVATIONS_SUCCESS':
      return {
        ...state,
        reservations: action.reservations,
      };
    case 'MOCK_GET_USER_RESERVATIONS':
      return {
        ...state,
        reservations: mockUserReservations,
      };
    case 'BOOK_OFFICE_VISIT_SUCCESS':
      return {
        ...state,
        reservations: [...state.reservations, ...action.visits],
      };
    case 'BOOK_OFFICE_VISIT_ERROR':
      return {
        ...state,
        bookOfficeError: action.msg,
      };
    case 'MOCK_BOOK_OFFICE_VISIT_SUCCESS':
      return {
        ...state,
        reservations: [...state.reservations, ...mockBookingToReservations(action.booking)],
      };
    case 'MOCK_BOOK_OFFICE_VISIT_ERROR':
      return {
        ...state,
        bookOfficeError: 'Something went wrong',
      };
    case 'CANCEL_OFFICE_VISIT_ERROR':
      return {
        ...state,
        bookOfficeError: action.msg,
      };
    case 'CANCEL_OFFICE_VISIT_SUCCESS':
    case 'MOCK_CANCEL_OFFICE_VISIT_SUCCESS':
      return {
        ...state,
        reservations: state.reservations.filter((res) => !action.visitIds.includes(res.id)),
      };
    case 'MOCK_CANCEL_OFFICE_VISIT_ERROR':
      return {
        ...state,
        bookOfficeError: 'Something went wrong',
      };
    case 'UPDATE_BOOKING_TYPE':
      return {
        ...state,
        bookingType: action.value,
      };
    default:
      return state;
  }
};
