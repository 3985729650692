import { subDays, addDays, startOfToday } from 'date-fns';
import {
  ZEventLocation,
  ZEventLocationTypeValue,
  ZEventUser,
  ZEventUserStatusTypeValue,
  ZEventUserTypeValue,
} from '../types';
import { createMockZRetreatEvent, MockZEvent } from './helpers';

export const mockZEventLocations: ZEventLocation[] = [
  {
    id: '1',
    type: ZEventLocationTypeValue.EVENT_LOCATION_TYPE_ZRETREAT,
    name: 'ATL-1032',
    referenceId: '',
    floorId: '10',
    capacity: '26',
    isDeleted: false,
    createdDate: new Date(),
    updatedDate: new Date(),
  },
  {
    id: '2',
    type: ZEventLocationTypeValue.EVENT_LOCATION_TYPE_ZRETREAT,
    name: 'PHX-331',
    referenceId: '',
    floorId: '17',
    capacity: '30',
    isDeleted: false,
    createdDate: new Date(),
    updatedDate: new Date(),
  },
  {
    id: '3',
    type: ZEventLocationTypeValue.EVENT_LOCATION_TYPE_ZRETREAT,
    name: 'SEA-3611',
    referenceId: '',
    floorId: '2',
    capacity: '50',
    isDeleted: false,
    createdDate: new Date(),
    updatedDate: new Date(),
  },
  {
    id: '4',
    type: ZEventLocationTypeValue.EVENT_LOCATION_TYPE_ZRETREAT,
    name: 'NYC 123',
    referenceId: '',
    floorId: '15',
    capacity: '50',
    isDeleted: false,
    createdDate: new Date(),
    updatedDate: new Date(),
  },
];

export const mockCurrentZEventUser: ZEventUser = {
  id: 'richbarton',
  type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_UNSPECIFIED,
  status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_UNSPECIFIED,
  comments: undefined,
  firstName: 'Rich',
  lastName: 'Barton',
  email: undefined,
  createdDate: undefined,
  updatedDate: new Date(),
};

export const mockZEventPlanners: ZEventUser[] = [
  {
    id: 'annacasciari',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    comments: undefined,
    firstName: 'Anna',
    lastName: 'Casciari',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
  {
    id: 'juliaolson',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    comments: undefined,
    firstName: 'Julia',
    lastName: 'Olson',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
  {
    id: 'jolenebleistein',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    comments: undefined,
    firstName: 'Jolene',
    lastName: 'Bleistein',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
];

export const mockZEventHosts: ZEventUser[] = [
  {
    id: 'fernandomedina',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_HOST,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    comments: undefined,
    firstName: 'Fernando',
    lastName: 'Medina',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
  {
    id: 'danspaulding',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_HOST,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    comments: undefined,
    firstName: 'Dan',
    lastName: 'Spaulding',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
  {
    id: 'tobyroberts',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_HOST,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    comments: undefined,
    firstName: 'Toby',
    lastName: 'Roberts',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
];

export const mockZEventAttendees: ZEventUser[] = [
  {
    id: 'janekim',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING,
    comments: undefined,
    firstName: 'Jane',
    lastName: 'Kim',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
  {
    id: 'jessiechen',
    type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
    status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED,
    comments: "I'll be on DTO for the week",
    firstName: 'Jessie',
    lastName: 'Chen',
    email: undefined,
    createdDate: undefined,
    updatedDate: undefined,
  },
];

export const mockUpcomingZRetreatEvents: MockZEvent[] = [
  // zevent happening now
  createMockZRetreatEvent({
    id: '1',
    startDate: startOfToday(),
    endDate: addDays(new Date(), 5),
    eventLocation: mockZEventLocations[1],
    createdBy: mockZEventPlanners[1],
    host: mockZEventHosts[2],
    isCanceled: false,
    totalUserCount: '4',
    userType: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
    userStatus: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED,
    purpose: 'zRetreat purpose',
    attendees: [
      ...mockZEventAttendees,
      {
        ...mockCurrentZEventUser,
        type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
        status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED,
      },
    ],
  }),
  // zevent current user is attendee, status pending
  createMockZRetreatEvent({
    id: '13',
    startDate: addDays(new Date(), 30),
    endDate: addDays(new Date(), 31),
    eventLocation: mockZEventLocations[2],
    createdBy: mockZEventPlanners[0],
    host: mockZEventHosts[1],
    isCanceled: false,
    totalUserCount: '3',
    userType: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
    userStatus: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING,
    purpose: 'zRetreat purpose',
    attendees: [
      {
        ...mockCurrentZEventUser,
        type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
        status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING,
      },
    ],
  }),
  // zevent current user is planner, no attendee invited
  createMockZRetreatEvent({
    id: '71',
    startDate: addDays(new Date(), 90),
    endDate: addDays(new Date(), 91),
    eventLocation: mockZEventLocations[3],
    createdBy: {
      ...mockCurrentZEventUser,
      type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER,
      status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    },
    host: mockZEventHosts[0],
    isCanceled: false,
    totalUserCount: '2',
    userType: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER,
    userStatus: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    purpose: 'zRetreat purpose',
  }),
  // zevent current user is host
  createMockZRetreatEvent({
    id: '2',
    startDate: addDays(new Date(), 100),
    endDate: addDays(new Date(), 102),
    eventLocation: mockZEventLocations[0],
    createdBy: mockZEventPlanners[1],
    host: {
      ...mockCurrentZEventUser,
      type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_HOST,
      status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    },
    isCanceled: false,
    totalUserCount: '2',
    userType: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_HOST,
    userStatus: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    purpose: 'zRetreat purpose',
  }),
];

export const mockPastZRetreatEvents: MockZEvent[] = [
  createMockZRetreatEvent({
    id: '15',
    createdBy: mockZEventPlanners[1],
    host: mockZEventHosts[1],
    planner2: mockZEventPlanners[0],
    purpose: 'ZRetreat purpose',
    startDate: subDays(new Date(), 15),
    endDate: subDays(new Date(), 14),
    eventLocation: mockZEventLocations[3],
    isCanceled: false,
    totalUserCount: '50',
    userType: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER,
    userStatus: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    attendees: [...mockZEventAttendees],
  }),
  createMockZRetreatEvent({
    id: '16',
    createdBy: mockZEventPlanners[1],
    host: mockZEventHosts[0],
    planner2: mockZEventPlanners[2],
    purpose: 'ZRetreat purpose',
    startDate: subDays(new Date(), 22),
    endDate: subDays(new Date(), 20),
    eventLocation: mockZEventLocations[0],
    isCanceled: false,
    totalUserCount: '50',
    userType: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
    userStatus: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING,
    attendees: [
      {
        ...mockCurrentZEventUser,
        type: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE,
        status: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING,
      },
    ],
  }),
  createMockZRetreatEvent({
    id: '17',
    createdBy: mockZEventPlanners[1],
    host: mockZEventHosts[1],
    purpose: 'ZRetreat purpose',
    startDate: subDays(new Date(), 24),
    endDate: subDays(new Date(), 22),
    eventLocation: mockZEventLocations[1],
    isCanceled: true,
    totalUserCount: '50',
    userType: ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER,
    userStatus: ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
  }),
];
