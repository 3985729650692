/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Heading, LoadingButton, Flex, IconSuccess, Paragraph } from '@zillow/constellation';
import { MockExpandedModalLogic, ExpandedModalLogic } from './ExpandedModal.hooks';
import { UpcomingVisitsTable } from './UpcomingVisitsTable';
import { Modal } from '../../components/Modal';
import { Office, Reservation, Floor } from '../../store/office';
import { ZEvent } from '../../store/zevent';
import { DateUtils } from '../../utilities/dateutils';
import { IsMockContext } from '../../middleware/auth';

export interface ExpandedModalProps {
  /** if modal is open */
  isOpen: boolean;
  /** function to handle close */
  onClose: () => void;
  /** list of reservations */
  reservations: Reservation[];
  /** offices */
  officeMap: Map<string, Office>;
  /** floors office map */
  floorsOfficeMap: Map<string, Office | undefined>;
  /** office floors */
  floors: Map<string, Floor[]>;
  /** zretreat events */
  zevents: ZEvent[];
  /** cancel reservation */
  cancelReservation: (visitIds: string[]) => Promise<Reservation[]>;
}

export const ExpandedModal: React.FC<ExpandedModalProps> = (props: ExpandedModalProps) => {
  const { isOpen, onClose, reservations, officeMap, floorsOfficeMap, floors, zevents } = props;
  const isMock = React.useContext(IsMockContext);

  const {
    onCancelBooking,
    isSelectedMap,
    setIsSelectedMap,
    isConfirmed,
    setIsConfirmed,
    canceledVisits,
  } = isMock ? MockExpandedModalLogic(props) : ExpandedModalLogic(props);

  return (
    <Modal
      header={<Heading level={6}>Upcoming Visits</Heading>}
      body={
        !isConfirmed ? (
          <UpcomingVisitsTable
            reservations={reservations}
            zevents={zevents}
            officeMap={officeMap}
            floorsOfficeMap={floorsOfficeMap}
            floors={floors}
            isSelectedMap={isSelectedMap}
            setIsSelectedMap={setIsSelectedMap}
          />
        ) : (
          <Flex margin="xs">
            <IconSuccess fontColor="green500" size="md" />
            <Heading level={6} marginY="sm">
              The following bookings were successfully canceled:
            </Heading>
            {canceledVisits.length > 0 &&
              canceledVisits.map((v, i) => (
                <Paragraph key={i}>
                  {officeMap.get(v.officeId)!.name}{' '}
                  {v.visitDate && DateUtils.DateToUSLocaleStr(v.visitDate)}
                </Paragraph>
              ))}
          </Flex>
        )
      }
      footer={
        !isConfirmed && (
          <LoadingButton
            disabled={isSelectedMap.size > 0 && !Array.from(isSelectedMap.values()).includes(true)}
            buttonType="primary"
            onClick={onCancelBooking}
          >
            Cancel Visits
          </LoadingButton>
        )
      }
      isOpen={isOpen}
      handleClose={() => {
        onClose();
        setIsConfirmed(false);
      }}
      shouldCloseOnOutsideClick={true}
    />
  );
};
