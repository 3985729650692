import React from 'react';
import styled from 'styled-components';
import {
  Banner,
  PageContent,
  IconTeam,
  Flex,
  Text,
  TextButton,
  mediaBreakpointMixin,
} from '@zillow/constellation';
import { User } from '../../store/user';

interface ImpersonationBannerProps {
  /** user being impersonated */
  impersonatee: User;
  /** function to handle impersonate btn click */
  handleImpersonateClick: () => void;
  /** function to handle stop impersonation */
  handleStopImpersonation: () => void;
  /** is stopping impersonation */
  isStoppingImpersonation: boolean;
}

const ImpersonationBannerWrapper = styled(Banner)`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const BannerContent = styled(PageContent)`
  width: 100%;
  margin: 0 auto;
`;

const DesktopOnly = styled.span`
  @media ${mediaBreakpointMixin('lg_lte')} {
    display: none;
  }
`;

export const ImpersonationBanner: React.FC<ImpersonationBannerProps> = ({
  impersonatee,
  handleImpersonateClick,
  handleStopImpersonation,
  isStoppingImpersonation,
}: ImpersonationBannerProps) => {
  return (
    <ImpersonationBannerWrapper appearance="warning" role="status">
      <BannerContent display="flex" flexDirection={{ default: 'column', lg: 'row' }}>
        <Flex
          display="flex"
          flex="1 0 auto"
          gap={{ lg_gte: 'sm' }}
          flexDirection={{ default: 'column', lg: 'row' }}
        >
          <Flex>
            <IconTeam size="sm" marginRight={{ default: 'md', lg: 'sm' }} />
            <Text>
              Impersonating{' '}
              <strong>
                {impersonatee.firstName} {impersonatee.lastName}
              </strong>
            </Text>
          </Flex>
          <DesktopOnly>
            <Text>•</Text>
          </DesktopOnly>
          <Text marginLeft={{ md_lte: 'xl' }}>{impersonatee.email}</Text>
        </Flex>

        <Flex display="flex" flex="0 0 auto" gap="sm">
          <DesktopOnly>
            <TextButton onClick={handleImpersonateClick}>Impersonate</TextButton>
          </DesktopOnly>
          <DesktopOnly>
            <Text>•</Text>
          </DesktopOnly>
          <TextButton
            onClick={handleStopImpersonation}
            disabled={isStoppingImpersonation}
            marginLeft={{ md_lte: 'xl' }}
            marginTop={{ md_lte: 'sm' }}
          >
            {isStoppingImpersonation ? 'Stopping...' : 'Stop impersonating'}
          </TextButton>
        </Flex>
      </BannerContent>
    </ImpersonationBannerWrapper>
  );
};
