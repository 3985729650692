import React, { useEffect } from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { User, selectLoggedIn, loadUserByCookie, resetUser, loadMockUser } from '../store/user';
import { Loader } from '../components/Loader';
import DynamicConfig from '../config/DynamicConfig';
import { RoutePath } from '../pages/HQApp';
import { CookieUtils } from '../utilities/cookieutils';

export interface AuthMiddlewareProps extends RouteComponentProps {
  /** is user logged in */
  loggedIn: boolean;
  /** function to load user info */
  loadUser: () => Promise<User>;
  /** function to reset user info */
  resetUser: typeof resetUser;
  /** standard children prop */
  children?: React.ReactNode;
}

export const deleteJWTCookie = () => {
  document.cookie = 'zallpass_jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
};

export const getJWTCookie = (): string => {
  const jwtCookieName = 'zallpass_jwt';
  return CookieUtils.getCookie(jwtCookieName) || '';
};

export const IsMockContext = React.createContext(false);

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({
  history,
  location,
  loggedIn,
  loadUser,
  resetUser,
  children,
}: AuthMiddlewareProps) => {
  const query = new URLSearchParams(location.search);
  // only enable mock mode in lower envs
  const isMock = DynamicConfig.GetConfig().ENV !== 'prod' && query.get('test') === '1';
  const hasTraining = query.get('training') === '1';
  const hasVaccination = query.get('vaccine') === '1';
  const canPlanZEvents = query.get('planner') === '1';
  const pathRequiresAuth =
    location.pathname !== RoutePath.LOGIN && location.pathname !== `${RoutePath.LOGIN}/`;
  const jwtCookie = getJWTCookie();
  const dispatch = useDispatch();

  /** reset user if cookie expired during session */
  useEffect(() => {
    if (!isMock && pathRequiresAuth && jwtCookie === '' && loggedIn) {
      resetUser();
    }
  });

  /** initiate new login when redirected from Okta */
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const fromOktaTile = urlParams.get('iss');
    if (fromOktaTile) {
      deleteJWTCookie();
    }
  }, []);

  /** load user from cookie */
  useEffect(() => {
    if (!isMock && pathRequiresAuth && jwtCookie !== '') {
      loadUser().catch(() => {
        deleteJWTCookie();
        history.push(RoutePath.LOGIN, { from: location.pathname });
      });
    }
  }, []);

  /** load mock user with mock training, vaccine, and planner status */
  useEffect(() => {
    if (isMock) {
      dispatch(loadMockUser(hasTraining, hasVaccination, canPlanZEvents));
    }
  }, [dispatch, isMock, hasTraining, hasVaccination]);

  if (!isMock && pathRequiresAuth && jwtCookie === '') {
    return (
      <Redirect
        to={{
          pathname: RoutePath.LOGIN,
          state: { from: location.pathname },
        }}
      />
    );
  }

  return (
    <Loader loading={!loggedIn && pathRequiresAuth} centerOnPage={true}>
      <IsMockContext.Provider value={isMock}>{children}</IsMockContext.Provider>
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedIn: selectLoggedIn(state),
});

const mapDispatchToProps = {
  loadUser: loadUserByCookie,
  resetUser: resetUser,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

/* eslint-disable */
export default withRouter(
  connect<StateToPropsType, DispatchToPropsType, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthMiddleware),
);
