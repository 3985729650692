/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { StringValue } from './google/protobuf/wrappers';

export const protobufPackage = 'peng_hqengine_contract';

export interface Floor {
  id: string;
  officeId: string;
  name: string | undefined;
  planUrl: string | undefined;
  isOpen: boolean;
}

const baseFloor: object = { id: '0', officeId: '0', isOpen: false };

export const Floor = {
  encode(message: Floor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.officeId !== '0') {
      writer.uint32(16).int64(message.officeId);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.planUrl !== undefined) {
      StringValue.encode({ value: message.planUrl! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.isOpen === true) {
      writer.uint32(40).bool(message.isOpen);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Floor {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFloor } as Floor;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.officeId = longToString(reader.int64() as Long);
          break;
        case 3:
          message.name = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.planUrl = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.isOpen = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Floor {
    const message = { ...baseFloor } as Floor;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = '0';
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = undefined;
    }
    if (object.planUrl !== undefined && object.planUrl !== null) {
      message.planUrl = String(object.planUrl);
    } else {
      message.planUrl = undefined;
    }
    if (object.isOpen !== undefined && object.isOpen !== null) {
      message.isOpen = Boolean(object.isOpen);
    } else {
      message.isOpen = false;
    }
    return message;
  },

  toJSON(message: Floor): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.officeId !== undefined && (obj.officeId = message.officeId);
    message.name !== undefined && (obj.name = message.name);
    message.planUrl !== undefined && (obj.planUrl = message.planUrl);
    message.isOpen !== undefined && (obj.isOpen = message.isOpen);
    return obj;
  },

  fromPartial(object: DeepPartial<Floor>): Floor {
    const message = { ...baseFloor } as Floor;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = '0';
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = undefined;
    }
    if (object.planUrl !== undefined && object.planUrl !== null) {
      message.planUrl = object.planUrl;
    } else {
      message.planUrl = undefined;
    }
    if (object.isOpen !== undefined && object.isOpen !== null) {
      message.isOpen = object.isOpen;
    } else {
      message.isOpen = false;
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
