/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { connect } from 'react-redux';
import {
  LoadingButton,
  FormField,
  Label,
  AdornedInput,
  DatePicker,
  DatePickerInput,
  Adornment,
  IconCalendarOutline,
  Combobox,
  InlineFeedback,
  TextButton,
  Trigger,
  Input,
  Heading,
  Flex,
} from '@zillow/constellation';
import { RootState } from '../../store';
import {
  Office,
  Reservation,
  selectReservations,
  getOfficeFullyBookedDates,
} from '../../store/office';
import {
  MockRecurringBookingContainerLogic,
  RecurringBookingContainerLogic,
  weekdays,
} from '../RecurringBookingContainer/RecurringBookingContainer.hooks';
import { MAX_BOOKING_DATE } from '../BookingContainer/BookingContainer';
import { DesktopOnlyWrapper } from '../OneTimeBookingContainer/OneTimeBookingContainer';
import CapacityPopoverContainer from '../CapacityPopoverContainer';
import { IsMockContext } from '../../middleware/auth';
import { startOfToday, endOfDay, addDays } from 'date-fns';
import { useEffect } from 'react';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import { Modal } from '../../components/Modal';
import { useMediaQuery } from '../../utilities/useMedia';

interface StateProps {
  /** list of reservations */
  reservations: Reservation[];
  /** get fully booked dates in an interval for an office */
  getOfficeFullyBookedDates: (
    officeId: string,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => Promise<Date[]>;
}

interface OwnProps {
  /** office selected */
  targetOffice: Office;
  /** list of reservations */
  reservations: Reservation[];
  /** check if a date is blocked */
  isBlocked: (date: Date) => boolean;
  /** check if a date is already booked by the user */
  isAlreadyBooked: (date: Date) => boolean;
  /** check if a date is disabled on the Datepicker */
  isDateDisabled: (
    date: Date,
    {
      minDate,
      maxDate,
    }: {
      minDate: Date;
      maxDate: Date;
    },
  ) => boolean;
  /** update necessary excluded dates for warning */
  updateExcludedDates: (dates: Date[]) => void;
  /** update same day reservations for warning */
  updateSameDayReservations: (reservations: Reservation[]) => void;
  /** submit booking request */
  handleSubmitBooking: (startDate: Date, endDate: Date, excludedDates: Date[]) => Promise<void>;
  /** setter for showing error modal */
  setHasUnexpectedError: React.Dispatch<React.SetStateAction<boolean>>;
  /** setter for showing warning modal */
  setHasUnexpectedWarning: React.Dispatch<React.SetStateAction<boolean>>;
  /** setter for unexpected conflicted dates*/
  setUnexpectedConflicts: React.Dispatch<React.SetStateAction<Date[]>>;
  /** setter for continue booking callback */
  setOnContinueBooking: React.Dispatch<React.SetStateAction<() => void>>;
  /** setter for is weekend selected */
  setIsWeekendSelected: React.Dispatch<React.SetStateAction<boolean>>;
  /** setter for CTA component - mobile only */
  setMobileCTA?: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export type RecurringBookingContainerProps = StateProps & OwnProps;

const RecurringBookingContainer: React.FC<RecurringBookingContainerProps> = (
  props: RecurringBookingContainerProps,
) => {
  const isMock = React.useContext(IsMockContext);
  const isDesktop = useMediaQuery('(min-width: 769px)');
  const { isDateDisabled, setMobileCTA, targetOffice } = props;
  const {
    selectedDays,
    setSelectedDays,
    isConfirmingDates,
    isSubmitting,
    onSubmitBooking,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    endDateInputKey,
    selectedDates,
    isCTADisabled,
    hasNoDateInRange,
    hasNoDateAvailableInRange,
    isStartDateModalOpen,
    setIsStartDateModalOpen,
    isEndDateModalOpen,
    setIsEndDateModalOpen,
  } = isMock ? MockRecurringBookingContainerLogic(props) : RecurringBookingContainerLogic(props);

  const cta = (
    <LoadingButton 
      data-testid='book-now-button'
      buttonType="primary"
      disabled={isCTADisabled}
      onClick={onSubmitBooking}
      loading={isSubmitting || isConfirmingDates}
    >
      Book now
    </LoadingButton>
  );

  useEffect(() => {
    if (setMobileCTA !== undefined) setMobileCTA(cta);
  }, [selectedDays, startDate, endDate, onSubmitBooking, isSubmitting, isConfirmingDates]);

  const startDateModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={<Heading level={6}>Select a date</Heading>}
      body={
        <DatePicker
          dateFormat={'MM/dd/yyyy'}
          selected={startDate}
          onChange={(e: { date: Date; formatted: string }) => {
            setStartDate(e.formatted);
            onClose();
          }}
          minDate={startOfToday()}
          maxDate={endDate || MAX_BOOKING_DATE}
          disabledDates={isDateDisabled}
        />
      }
      footer={<TextButton onClick={() => onClose()}>Cancel</TextButton>}
    />
  );

  const endDateModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={<Heading level={6}>Select a date</Heading>}
      body={
        <DatePicker
          dateFormat={'MM/dd/yyyy'}
          selected={endDate || addDays(new Date(startDate), 1)}
          onChange={(e: { date: Date; formatted: string }) => {
            setEndDate(e.formatted);
            onClose();
          }}
          minDate={startDate ? endOfDay(new Date(startDate)) : startOfToday()}
          maxDate={MAX_BOOKING_DATE}
          disabledDates={isDateDisabled}
        />
      }
      footer={<TextButton onClick={() => onClose()}>Cancel</TextButton>}
    />
  );

  return (
    <>
      <FormField
        data-testid="recurring-booking"
        label={<Label>Recur every</Label>}
        control={
          <Combobox
            freeForm={false}
            placeholder={selectedDays.length ? '' : 'Select option'}
            options={Object.keys(weekdays)}
            value={selectedDays}
            onChange={(arr: string[]) => {
              setSelectedDays(arr);
              if (arr.length > selectedDays.length) {
                GoogleAnalyticsUtils.SelectRecurringDay(targetOffice.name);
              }
            }}
          />
        }
      />
      <FormField
        data-testid="recurring-booking-start-date"
        marginTop="sm"
        label={<Label>Start date</Label>}
        control={
          isDesktop ? (
            <AdornedInput
              input={
                <DatePickerInput
                  placeholder="MM/DD/YYYY"
                  value={startDate}
                  onChange={(v: string) => setStartDate(v)}
                  datepicker={
                    <DatePicker
                      minDate={startOfToday()}
                      maxDate={endDate || MAX_BOOKING_DATE}
                      disabledDates={isDateDisabled}
                    />
                  }
                />
              }
              rightAdornment={
                <Adornment>
                  <IconCalendarOutline size="sm" />
                </Adornment>
              }
              error={hasNoDateInRange || hasNoDateAvailableInRange}
            />
          ) : (
            <Trigger
              triggered={startDateModal}
              isOpen={isStartDateModalOpen}
              onOpen={() => setIsStartDateModalOpen(true)}
              onClose={() => setIsStartDateModalOpen(false)}
            >
              <AdornedInput
                input={<Input placeholder="MM/DD/YYYY" value={startDate} onChange={() => null} />}
                rightAdornment={
                  <Adornment>
                    <IconCalendarOutline size="sm" />
                  </Adornment>
                }
                error={hasNoDateInRange || hasNoDateAvailableInRange}
              />
            </Trigger>
          )
        }
      />
      <FormField
        data-testid="recurring-booking-end-date"
        marginTop="sm"
        label={<Label>End date</Label>}
        control={
          isDesktop ? (
            <AdornedInput
              input={
                <DatePickerInput
                  key={endDateInputKey} // force resetting the component
                  placeholder="MM/DD/YYYY"
                  value={endDate === '' ? undefined : endDate} // set undefined to show defaultSelected
                  onChange={(v: string) => setEndDate(v)}
                  datepicker={
                    <DatePicker
                      minDate={startDate ? endOfDay(new Date(startDate)) : startOfToday()}
                      maxDate={MAX_BOOKING_DATE}
                      disabledDates={isDateDisabled}
                      defaultSelected={addDays(new Date(startDate), 1)}
                    />
                  }
                />
              }
              rightAdornment={
                <Adornment>
                  <IconCalendarOutline size="sm" />
                </Adornment>
              }
              error={hasNoDateInRange || hasNoDateAvailableInRange}
            />
          ) : (
            <Trigger
              triggered={endDateModal}
              isOpen={isEndDateModalOpen}
              onOpen={() => setIsEndDateModalOpen(true)}
              onClose={() => setIsEndDateModalOpen(false)}
            >
              <AdornedInput
                input={<Input placeholder="MM/DD/YYYY" value={endDate} onChange={() => null} />}
                rightAdornment={
                  <Adornment>
                    <IconCalendarOutline size="sm" />
                  </Adornment>
                }
                error={hasNoDateInRange || hasNoDateAvailableInRange}
              />
            </Trigger>
          )
        }
      />
      <Flex display="flex" justifyContent="space-between" marginTop="md">
        <DesktopOnlyWrapper>{cta}</DesktopOnlyWrapper>
        <CapacityPopoverContainer
          data-testid="recurring-booking-capacity"
          officeId={targetOffice.id}
          text={'Daily capacity'}
          dates={selectedDates}
        />
      </Flex>
      {hasNoDateInRange && (
        <InlineFeedback appearance="error" marginTop="sm">
          Please select a date range that includes your recurring day of the week.
        </InlineFeedback>
      )}
      {hasNoDateAvailableInRange && (
        <InlineFeedback appearance="error" marginTop="sm">
          No day passes are available for your selected day and date range. Please try again.
        </InlineFeedback>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  reservations: selectReservations(state),
});

const mapDispatchToProps = {
  getOfficeFullyBookedDates: getOfficeFullyBookedDates,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(RecurringBookingContainer);
