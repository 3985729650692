import { combineReducers } from 'redux';
import { OfficeState, officeReducer } from './office';
import { UserState, userReducer } from './user';
import { SystemState, systemReducer } from './systemHealth';
import { ImpersonationState, impersonationReducer } from './impersonation';
import { zEventReducer, ZEventState } from './zevent';

export interface ApplicationState {
  user: UserState;
  office: OfficeState;
  zevent: ZEventState;
  system: SystemState;
  impersonation: ImpersonationState;
}

const rootReducer = combineReducers({
  user: userReducer,
  office: officeReducer,
  zevent: zEventReducer,
  system: systemReducer,
  impersonation: impersonationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export * from './user/types';
export * from './office/types';
export * from './zevent/types';
export * from './impersonation/types';
export default rootReducer;
