/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import styled from 'styled-components';
import { OfficeFloorKeyType, OfficeFloorKey } from '../../store/office';
import { token, Heading, MediaObject, Paragraph } from '@zillow/constellation';

interface FloorPlanKeysProps {
  keys: Map<OfficeFloorKeyType, OfficeFloorKey[]>;
}

const ColorIcon = styled.div<{ color: string; shape: 'square' | 'circle' }>`
  width: 24px;
  height: 24px;
  background: ${(props) => props.color};
  border: 1px solid ${token('colors.trueBlack')};
  box-sizing: border-box;
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : 'inherit')};
`;

export const FloorPlanKeys: React.FC<FloorPlanKeysProps> = ({ keys }: FloorPlanKeysProps) => {
  return (
    <>
      {keys.has(OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_NEIGHBORHOODS) && (
        <>
          <Heading level="5">Neighborhoods</Heading>
          {keys.get(OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_NEIGHBORHOODS)!.map((k) => (
            <MediaObject
              key={k.id}
              media={<ColorIcon color={k.color} shape={k.shape} />}
              align="start"
              marginY="sm"
            >
              <Paragraph>{k.name}</Paragraph>
            </MediaObject>
          ))}
        </>
      )}
      {keys.has(OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES) && (
        <>
          <Heading level="5">Spaces</Heading>
          {keys.get(OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES)!.map((k) => (
            <MediaObject
              key={k.id}
              media={<ColorIcon color={k.color} shape={k.shape} />}
              align="start"
              marginY="sm"
            >
              <Paragraph>{k.name}</Paragraph>
            </MediaObject>
          ))}
        </>
      )}
      {keys.has(OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES) && (
        <>
          <Heading level="5">Services</Heading>
          {keys
            .get(OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES)!
            .sort((k1, k2) => k2.shape.localeCompare(k1.shape))
            .map((k) => (
              <MediaObject
                key={k.id}
                media={<ColorIcon color={k.color} shape={k.shape} />}
                align="start"
                marginY="sm"
              >
                <Paragraph>{k.name}</Paragraph>
              </MediaObject>
            ))}
        </>
      )}
    </>
  );
};
