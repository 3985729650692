import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import {
  Office,
  Floor,
  getOffices,
  getOfficesFloors,
  selectOfficeFloorsMap,
  selectOfficesAlphabeticallyOrdered,
} from '../../store/office';
import {
  spaceMixin,
  mediaBreakpointMixin,
  Card,
  Flex,
  Select,
  Divider,
  Collapsible,
  TextButton,
  IconChevronDownOutline,
  IconChevronUpOutline,
} from '@zillow/constellation';
import { FloorPlanContainerLogic, MockFloorPlanContainerLogic } from './FloorPlanContainer.hooks';
import { FloorPlanError } from './FloorPlanError';
import { IsMockContext } from '../../middleware/auth';
import { FloorPlanDesktop } from './FloorPlanDesktop';
import { FloorPlanMobile } from './FloorPlanMobile';
import { FloorPlanKeys } from './FloorPlanKeys';
import { Loader } from '../../components/Loader';

export interface FloorPlanContainerProps extends RouteComponentProps {
  /** is internal domain */
  isInternal: boolean;
  /** list of offices */
  offices: Office[];
  /** office floors */
  floors: Map<string, Floor[]>;
  /** get offices request */
  getOffices: (isInternal: boolean) => Promise<void>;
  /** get offices floors request */
  getOfficesFloors: (isInternal: boolean) => Promise<void>;
}

const FloorPlanPageWrapper = styled.div`
  position: relative;
`;

const NavBox = styled(Flex)`
  @media ${mediaBreakpointMixin('xl')} {
    width: 300px;
    position: absolute;
    z-index: 999;
  }
  max-height: ${(props) => `calc(100vh - ${props.theme.footerHeight})`};
  overflow: scroll;
`;

const OfficeAndFloorSelectsCard = styled(Card)`
  @media ${mediaBreakpointMixin('xl')} {
    margin: ${spaceMixin('sm')};
  }
  margin: 0;
`;

const SelectWrapper = styled(Select)`
  @media ${mediaBreakpointMixin('xl')}, ${mediaBreakpointMixin('xs_lte')} {
    width: 100%;
  }
  width: auto;
`;

const DesktopOnly = styled.div`
  @media ${mediaBreakpointMixin('lg_lte')} {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media ${mediaBreakpointMixin('xl')} {
    display: none;
  }
`;

const FloorPlanContainer: React.FC<FloorPlanContainerProps> = (props: FloorPlanContainerProps) => {
  const { offices, floors } = props;
  const isMock = React.useContext(IsMockContext);
  const {
    isLoaded,
    selectedOffice,
    selectedFloor,
    hasFloorPlanError,
    onOfficeChange,
    onFloorChange,
    floorPlanImgSrc,
  } = isMock ? MockFloorPlanContainerLogic(props) : FloorPlanContainerLogic(props);

  return (
    <Loader loading={!isLoaded} centerOnPage={true}>
      <FloorPlanPageWrapper>
        <NavBox>
          <OfficeAndFloorSelectsCard
            data-testid="office-and-floor-selects-card"
            cardType="secondary"
            cardElevation={0}
            marginX="sm"
            paddingY={{ default: 'sm', xl: 'lg' }}
            paddingX={{ default: 'xs', xl: 'md' }}
          >
            <Flex
              display="flex"
              flexWrap="wrap"
              gap={{ default: 'xs', xl: 'sm' }}
              justifyContent="center"
            >
              <SelectWrapper
                value={selectedOffice?.id || 'DEFAULT'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onOfficeChange(e.target.value)
                }
                data-testid="office-select"
              >
                <option disabled value="DEFAULT">
                  Select an office
                </option>
                {offices.map((office) => (
                  <option key={office.id} value={office.id}>
                    {office.name}
                  </option>
                ))}
              </SelectWrapper>
              <SelectWrapper
                data-testid="floor-select"
                disabled={!selectedOffice}
                value={selectedFloor?.id || 'DEFAULT'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFloorChange(e.target.value)}
              >
                <option disabled value="DEFAULT">
                  Select a floor
                </option>
                {selectedOffice &&
                  floors.get(selectedOffice.id)?.map((floor) => (
                    <option key={floor.id} value={floor.id}>
                      {floor.name}
                    </option>
                  ))}
              </SelectWrapper>
            </Flex>
            {selectedFloor && selectedFloor.floorplanKeys.size > 0 && (
              <DesktopOnly>
                <Divider marginY="md" />
                <FloorPlanKeys keys={selectedFloor.floorplanKeys} />
              </DesktopOnly>
            )}
          </OfficeAndFloorSelectsCard>
        </NavBox>

        {selectedOffice && selectedFloor ? (
          <>
            <DesktopOnly>
              <FloorPlanDesktop
                imgSrc={floorPlanImgSrc}
                imgAlt={`${selectedOffice.name} ${selectedFloor.name} Floor Plan`}
              />
            </DesktopOnly>
            <MobileOnly>
              <FloorPlanMobile
                imgSrc={floorPlanImgSrc}
                imgAlt={`${selectedOffice.name} ${selectedFloor.name} Floor Plan`}
              />

              {selectedFloor && selectedFloor.floorplanKeys.size > 0 && (
                <Card appearance="borderless" cardElevation={0} cardType="secondary">
                  <Collapsible defaultCollapsed={true}>
                    {(collapsed: boolean, toggle: boolean) => (
                      <>
                        <TextButton
                          onClick={toggle}
                          icon={collapsed ? <IconChevronDownOutline /> : <IconChevronUpOutline />}
                          marginY="sm"
                        >
                          Map Key
                        </TextButton>
                        {collapsed ? null : <FloorPlanKeys keys={selectedFloor.floorplanKeys} />}
                      </>
                    )}
                  </Collapsible>
                </Card>
              )}
            </MobileOnly>
          </>
        ) : (
          <>{hasFloorPlanError && <FloorPlanError />}</>
        )}
      </FloorPlanPageWrapper>
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => ({
  offices: selectOfficesAlphabeticallyOrdered(state),
  floors: selectOfficeFloorsMap(state),
});

const mapDispatchToProps = {
  getOffices: getOffices,
  getOfficesFloors: getOfficesFloors,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default withRouter(
  connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(FloorPlanContainer),
);
