import { User } from '..';

export const mockUser: User = {
  id: 'richbarton',
  email: 'rich@zillowgroup.com',
  firstName: 'Rich',
  lastName: 'Barton',
  title: 'Chief Executive Officer',
  photoUrl: '',
  username: 'rich',
};
