import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { add, startOfToday } from 'date-fns';
import { HomeContainerProps } from './HomeContainer';
import { mockGetOffices, mockGetOfficesFloors, mockGetReservations } from '../../store/office';
import { mockGetUpcomingZEvents } from '../../store/zevent';
import { mockGetSystemStatus } from '../../store/systemHealth';
import { MAX_BOOKING_DATE } from '../BookingContainer/BookingContainer';
import DynamicConfig from '../../config/DynamicConfig';
import { DisplayError } from '../../store/errorHelpers';
import { useErrorStatus } from '../../components/ErrorHandler';

export const HomeContainerLogic = ({
  getUserTrainingStatus,
  getUserInboxMessages,
  getOffices,
  getReservations,
  getOfficesFloors,
  getSystemStatus,
  getZEvents,
}: HomeContainerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const { setErrorStatusCode } = useErrorStatus();

  const loadTrainingStatusCallback = useCallback(() => {
    return getUserTrainingStatus();
  }, [getUserTrainingStatus]);

  const loadUserInboxMessagesCallback = useCallback(() => {
    return getUserInboxMessages();
  }, [getUserInboxMessages]);

  const loadSystemStatusCallback = useCallback((): Promise<void> => {
    return getSystemStatus();
  }, [getSystemStatus]);

  /** load offices and reservations */
  useEffect(() => {
    let isSubscribed = true;
    if (!isLoaded) {
      if (DynamicConfig.GetConfig().ENABLE_ZGU_TRAINING_REQ == "true") {
        loadTrainingStatusCallback().catch(() => null);
      }
      loadUserInboxMessagesCallback().catch(() => null);
      loadSystemStatusCallback().catch(() => null);

      const today = startOfToday();
      const oneYearFromToday = add(today, { years: 1 });

      Promise.all([
        getOffices(false),
        getReservations(new Date(), MAX_BOOKING_DATE),
        getOfficesFloors(false),
        getZEvents(today, oneYearFromToday, 'Upcoming'),
      ])
        .catch((err: DisplayError) => {
          setHasLoadingError(true);
          setErrorStatusCode(err.code);
        })
        .finally(() => {
          if (isSubscribed) setIsLoaded(true);
        });

      return () => {
        isSubscribed = false; //  avoid memory leak on unmount
      };
    }
  }, []);

  return { isLoaded, hasLoadingError };
};

export const MockHomeContainerLogic = () => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasLoadingError] = useState(false);

  /** load offices and reservations */
  useEffect(() => {
    dispatch(mockGetOffices());
    dispatch(mockGetOfficesFloors());
    dispatch(mockGetReservations());
    dispatch(mockGetUpcomingZEvents());
    dispatch(mockGetSystemStatus());
    setIsLoaded(true);
  }, []);

  return { isLoaded, hasLoadingError };
};
