import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { OneTimeBookingContainerProps } from './OneTimeBookingContainer';
import { Reservation, BookingType } from '../../store/office';
import { DateUtils } from '../../utilities/dateutils';
import {
  isWithinInterval,
  startOfToday,
  isSameDay,
  isAfter,
  eachWeekendOfInterval,
} from 'date-fns';
import { MAX_BOOKING_DATE, DATE_FORMAT } from '../BookingContainer/BookingContainer';
import { v4 as uuidv4 } from 'uuid';
import { IsBookingInProgressContext } from '../HomeContainer/IsBookingInProgressContext';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';

export const OneTimeBookingContainerLogic = ({
  targetOffice,
  reservations,
  isBlocked,
  isAlreadyBooked,
  handleSubmitBooking,
  updateExcludedDates,
  updateSameDayReservations,
  getOfficeFullyBookedDates,
  setHasUnexpectedError,
  setHasUnexpectedWarning,
  setUnexpectedConflicts,
  setOnContinueBooking,
  setIsWeekendSelected,
  bookingType,
  updateBookingType,
}: OneTimeBookingContainerProps) => {
  const [isConfirmingDates, setIsConfirmingDates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDateText, setStartDateText] = useState('');
  const [endDateText, setEndDateText] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [endDateInputKey, setEndDateInputKey] = useState(uuidv4());
  const { setIsBookingInProgress } = useContext(IsBookingInProgressContext);
  const [isStartDateModalOpen, setIsStartDateModalOpen] = useState(false);
  const [isEndDateModalOpen, setIsEndDateModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[] | undefined>(undefined);

  const isDateStringAllowed = useCallback(
    (dateStr: string) =>
      DateUtils.IsValidDateString(dateStr, DATE_FORMAT) &&
      isWithinInterval(new Date(dateStr), { start: startOfToday(), end: MAX_BOOKING_DATE }),
    [],
  );

  /** set start date when text is formatted and valid date
   * unset if not qualified
   */
  useEffect(() => {
    if (isDateStringAllowed(startDateText)) {
      setStartDate(new Date(startDateText));
      GoogleAnalyticsUtils.SelectVisitDate(targetOffice.name);
    } else {
      setStartDate(undefined);
    }
  }, [startDateText]);

  /** set end date when text is formatted and valid date
   * unset if not qualified
   */
  useEffect(() => {
    if (isDateStringAllowed(endDateText)) {
      setEndDate(new Date(endDateText));
      GoogleAnalyticsUtils.SelectVisitEndDate(targetOffice.name);
    } else {
      setEndDate(undefined);
    }
  }, [endDateText]);

  const isMultiday = useMemo(
    () => bookingType === BookingType.BOOKING_TYPE_MULTI_DAY,
    [bookingType],
  );

  const onIsMultidayChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      // if checkbox unchecked, clear end date
      if (!checked) {
        setEndDateText('');
        setEndDateInputKey(uuidv4()); // force resetting the component
        updateBookingType(BookingType.BOOKING_TYPE_SINGLE_DAY);
      } else {
        updateBookingType(BookingType.BOOKING_TYPE_MULTI_DAY);
        GoogleAnalyticsUtils.CheckMultiday(targetOffice.name);
      }
    },
    [updateBookingType, targetOffice],
  );

  const isCTADisabled: boolean = useMemo(
    () =>
      startDate === undefined ||
      (isMultiday && endDate === undefined) ||
      (endDate !== undefined && isAfter(startDate, endDate)),
    [startDate, isMultiday, endDate],
  );

  const isWeekendSelected: boolean = useMemo(
    () =>
      startDate !== undefined &&
      eachWeekendOfInterval({ start: startDate, end: endDate || startDate }).length > 0,
    [startDate, endDate],
  );

  const resetForm = useCallback(() => {
    setStartDateText('');
    setEndDateText('');
    updateBookingType(BookingType.BOOKING_TYPE_SINGLE_DAY);
    setEndDateInputKey(uuidv4()); // force resetting the component
  }, []);

  /** reset the form when selected office changes */
  useEffect(() => resetForm(), [targetOffice]);

  /** get selected dates between the specified date range */
  const getSelectedDates = useCallback(() => {
    if (startDate) {
      const datesBetween = endDate ? DateUtils.GetDatesBetween(startDate, endDate) : [startDate];
      setSelectedDates(datesBetween);
    }
  }, [startDate, endDate]);

  /** get excluded dates between the specified date range
   *  decides what dates will be excluded when booking and notify user
   */
  const getExcludedDates = useCallback(() => {
    if (startDate) {
      const datesBetween = endDate ? DateUtils.GetDatesBetween(startDate, endDate) : [startDate];
      updateExcludedDates(datesBetween.filter((d) => isBlocked(d)));
    }
  }, [startDate, endDate]);

  /** get same day reservations in different offices */
  const getSameDayReservations = useCallback(() => {
    updateSameDayReservations([]);

    if (startDate) {
      const sameDayReservations: Reservation[] = [];
      for (const r of reservations) {
        if (
          r.visitDate &&
          startDate &&
          isWithinInterval(r.visitDate, { start: startDate, end: endDate || startDate }) &&
          targetOffice.id !== r.officeId &&
          !isBlocked(r.visitDate)
        ) {
          sameDayReservations.push(r);
        }
      }
      updateSameDayReservations(sameDayReservations);
    }
  }, [startDate, endDate, targetOffice, reservations, isBlocked]);

  const onSubmitBooking = useCallback(() => {
    if (startDate) {
      setIsConfirmingDates(true);
      const datesBetween = endDate ? DateUtils.GetDatesBetween(startDate, endDate) : [startDate];
      const excluded = datesBetween.filter((d) => isBlocked(d) || isAlreadyBooked(d));
      // double check for fully booked dates
      const unexpected: Date[] = [];
      getOfficeFullyBookedDates(targetOffice.id, startDate, endDate || startDate)
        .then((dates) => {
          for (const d of dates) {
            if (!excluded.some((ed) => isSameDay(ed, DateUtils.DateFromUTC(d)))) {
              unexpected.push(DateUtils.DateFromUTC(d));
            }
          }

          if (unexpected.length > 0) {
            setUnexpectedConflicts(unexpected);
            // all dates are gone
            if (datesBetween.length === excluded.length + unexpected.length) {
              setHasUnexpectedError(true);
              // partially unavailable
            } else {
              setHasUnexpectedWarning(true);
              // trigger submit again when user clicks continue
              const submitBooking = () => {
                submitBookingCallback(startDate, endDate || startDate, [
                  ...excluded,
                  ...unexpected,
                ]);

                GoogleAnalyticsUtils.ClickContinueOnPartialBooking(targetOffice.name);
              };
              setOnContinueBooking(() => submitBooking);
            }
          } else {
            submitBookingCallback(startDate, endDate || startDate, excluded);
          }
        })
        .catch(() => submitBookingCallback(startDate, endDate || startDate, excluded)) // try booking anyway
        .finally(() => setIsConfirmingDates(false));
    }

    GoogleAnalyticsUtils.ClickBookNow(targetOffice.name);
  }, [targetOffice, startDate, endDate, handleSubmitBooking, isBlocked, isAlreadyBooked]);

  const submitBookingCallback = useCallback(
    (startDate: Date, endDate: Date, excludedDates: Date[]) => {
      setIsSubmitting(true);
      handleSubmitBooking(startDate, endDate, excludedDates)
        .then(() => resetForm())
        .catch(() => null)
        .finally(() => setIsSubmitting(false));
    },
    [handleSubmitBooking],
  );

  /** update excluded dates when the date range or office is changed */
  useEffect(() => {
    if (!isCTADisabled) {
      getSelectedDates();
      getExcludedDates();
      getSameDayReservations();
      setIsWeekendSelected(isWeekendSelected);
    } else {
      setSelectedDates(undefined);
      updateExcludedDates([]);
      updateSameDayReservations([]);
      setIsWeekendSelected(false);
    }
  }, [
    startDate,
    endDate,
    targetOffice,
    getSelectedDates,
    getExcludedDates,
    getSameDayReservations,
  ]);

  useEffect(() => {
    setIsBookingInProgress(startDate !== undefined || endDate !== undefined);
  }, [startDate, endDate]);

  return {
    isConfirmingDates,
    isSubmitting,
    isMultiday,
    onIsMultidayChange,
    onSubmitBooking,
    startDate,
    startDateText,
    setStartDateText,
    endDate,
    endDateText,
    setEndDateText,
    endDateInputKey,
    selectedDates,
    isCTADisabled,
    isStartDateModalOpen,
    setIsStartDateModalOpen,
    isEndDateModalOpen,
    setIsEndDateModalOpen,
  };
};

export const MockOneTimeBookingContainerLogic = ({
  targetOffice,
  isBlocked,
  isAlreadyBooked,
  reservations,
  handleSubmitBooking,
  updateExcludedDates,
  updateSameDayReservations,
  setHasUnexpectedError,
  setHasUnexpectedWarning,
  setUnexpectedConflicts,
  setOnContinueBooking,
  setIsWeekendSelected,
  bookingType,
  updateBookingType,
}: OneTimeBookingContainerProps) => {
  const [isConfirmingDates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDateText, setStartDateText] = useState('');
  const [endDateText, setEndDateText] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [endDateInputKey, setEndDateInputKey] = useState(uuidv4());
  const { setIsBookingInProgress } = useContext(IsBookingInProgressContext);
  const [isStartDateModalOpen, setIsStartDateModalOpen] = useState(false);
  const [isEndDateModalOpen, setIsEndDateModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[] | undefined>([]);

  const isDateStringAllowed = useCallback(
    (dateStr: string) =>
      DateUtils.IsValidDateString(dateStr, DATE_FORMAT) &&
      isWithinInterval(new Date(dateStr), { start: startOfToday(), end: MAX_BOOKING_DATE }),
    [],
  );

  /** set start date and end date when text is formatted and valid date
   * unset them if not qualified
   */
  useEffect(() => {
    if (isDateStringAllowed(startDateText)) {
      setStartDate(new Date(startDateText));
    } else {
      setStartDate(undefined);
    }
    if (isDateStringAllowed(endDateText)) {
      setEndDate(new Date(endDateText));
    } else {
      setEndDate(undefined);
    }
  }, [startDateText, endDateText]);

  const isMultiday = useMemo(
    () => bookingType === BookingType.BOOKING_TYPE_MULTI_DAY,
    [bookingType],
  );

  const onIsMultidayChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      // if checkbox unchecked, clear end date
      if (!checked) {
        setEndDateText('');
        setEndDateInputKey(uuidv4()); // force resetting the component
      }

      updateBookingType(
        checked === true ? BookingType.BOOKING_TYPE_MULTI_DAY : BookingType.BOOKING_TYPE_SINGLE_DAY,
      );
    },
    [updateBookingType],
  );

  const isCTADisabled: boolean = useMemo(
    () =>
      startDate === undefined ||
      (isMultiday && endDate === undefined) ||
      (endDate !== undefined && isAfter(startDate, endDate)),
    [startDate, isMultiday, endDate],
  );

  const isWeekendSelected: boolean = useMemo(
    () =>
      startDate !== undefined &&
      eachWeekendOfInterval({ start: startDate, end: endDate || startDate }).length > 0,
    [startDate, endDate],
  );

  const resetForm = useCallback(() => {
    setStartDateText('');
    setEndDateText('');
    updateBookingType(BookingType.BOOKING_TYPE_SINGLE_DAY);
    setEndDateInputKey(uuidv4()); // force resetting the component
  }, []);

  /** reset the form when selected office changes */
  useEffect(() => resetForm(), [targetOffice]);

  /** get selected dates between the specified date range */
  const getSelectedDates = useCallback(() => {
    if (startDate) {
      const datesBetween = endDate ? DateUtils.GetDatesBetween(startDate, endDate) : [startDate];
      setSelectedDates(datesBetween);
    }
  }, [startDate, endDate]);

  /** get excluded dates between the specified date range
   *  decides what dates will be excluded when booking and notify user
   */
  const getExcludedDates = useCallback(() => {
    if (startDate) {
      const datesBetween = endDate ? DateUtils.GetDatesBetween(startDate, endDate) : [startDate];
      updateExcludedDates(datesBetween.filter((d) => isBlocked(d)));
    }
  }, [startDate, endDate]);

  /** get same day reservations in different offices */
  const getSameDayReservations = useCallback(() => {
    updateSameDayReservations([]);

    if (startDate) {
      const sameDayReservations: Reservation[] = [];
      for (const r of reservations) {
        if (
          r.visitDate &&
          isWithinInterval(r.visitDate, { start: startDate, end: endDate || startDate }) &&
          targetOffice.id !== r.officeId &&
          !isBlocked(r.visitDate)
        ) {
          sameDayReservations.push(r);
        }
      }
      updateSameDayReservations(sameDayReservations);
    }
  }, [startDate, endDate, targetOffice, reservations, isBlocked]);

  const onSubmitBooking = useCallback(() => {
    if (startDate) {
      const datesBetween = endDate ? DateUtils.GetDatesBetween(startDate, endDate) : [startDate];
      const excluded = datesBetween.filter((d) => isBlocked(d) || isAlreadyBooked(d));
      // double check for fully booked dates
      // MOCK for demo purpose only Feb 2022 has unexpected fully booked dates
      const unexpected: Date[] =
        startDate.getMonth() === 1
          ? [new Date(2022, startDate.getMonth(), 11), new Date(2022, startDate.getMonth(), 12)]
          : [];

      if (unexpected.length > 0) {
        setUnexpectedConflicts(unexpected);
        // all dates are gone
        if (datesBetween.length === excluded.length + unexpected.length) {
          setHasUnexpectedError(true);
          // partially unavailable
        } else {
          setHasUnexpectedWarning(true);
          // trigger submit again when user clicks continue
          const submitBooking = () =>
            submitBookingCallback(startDate, endDate || startDate, [...excluded, ...unexpected]);
          setOnContinueBooking(() => submitBooking);
        }
      } else {
        submitBookingCallback(startDate, endDate || startDate, excluded);
      }
    }
  }, [targetOffice, startDate, endDate, handleSubmitBooking, isBlocked, isAlreadyBooked]);

  const submitBookingCallback = useCallback(
    (startDate: Date, endDate: Date, excludedDates: Date[]) => {
      setIsSubmitting(true);
      handleSubmitBooking(startDate, endDate || startDate, excludedDates)
        .then(() => resetForm())
        .catch(() => null)
        .finally(() => setIsSubmitting(false));
    },
    [handleSubmitBooking],
  );

  /** update excluded dates when the date range or office is changed */
  useEffect(() => {
    if (!isCTADisabled) {
      getSelectedDates();
      getExcludedDates();
      getSameDayReservations();
      setIsWeekendSelected(isWeekendSelected);
    } else {
      setSelectedDates(undefined);
      updateExcludedDates([]);
      updateSameDayReservations([]);
      setIsWeekendSelected(false);
    }
  }, [
    startDate,
    endDate,
    targetOffice,
    getSelectedDates,
    getExcludedDates,
    getSameDayReservations,
  ]);

  useEffect(() => {
    setIsBookingInProgress(startDate !== undefined || endDate !== undefined);
  }, [startDate, endDate]);

  return {
    isConfirmingDates,
    isSubmitting,
    isMultiday,
    onIsMultidayChange,
    onSubmitBooking,
    startDate,
    startDateText,
    setStartDateText,
    endDate,
    endDateText,
    setEndDateText,
    endDateInputKey,
    selectedDates,
    isCTADisabled,
    isStartDateModalOpen,
    setIsStartDateModalOpen,
    isEndDateModalOpen,
    setIsEndDateModalOpen,
  };
};
