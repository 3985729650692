export class CookieUtils {
  /**
   * Parse a speific cookie by name, return undefined if not found.
   *
   * @param name - the name of the target cookie
   * @returns string | undefined
   */
  public static getCookie(name: string): string | undefined {
    try {
      const c = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));
      if (c) return c.split('=')[1];
      else return undefined;
    } catch {
      return undefined;
    }
  }
}
