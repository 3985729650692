import { isFuture, isAfter, isWithinInterval, startOfToday } from 'date-fns';
import {
  Floor,
  Office,
  ZEvent,
  ZEventLocation,
  ZEventUser,
  ZEventUserStatusType,
  ZEventUserStatusTypeValue,
  ZEventUserStatusStringType,
  ZEventUserTypeValue,
} from '../store';
import { DateUtils } from './dateutils';

export class ZEventUtils {
  public static getZRetreatOffice = (
    zeventLocation: ZEventLocation | undefined,
    floorsOfficeMap: Map<string, Office | undefined>,
  ): Office | undefined => {
    if (!zeventLocation) return undefined;

    const floorID = zeventLocation.floorId;
    if (floorID) {
      return floorsOfficeMap.get(floorID);
    }
    return undefined;
  };

  public static getZRetreatFloor = (
    zeventLocation: ZEventLocation | undefined,
    floorsOfficeMap: Map<string, Office | undefined>,
    floors: Map<string, Floor[]>,
  ): Floor | undefined => {
    if (!zeventLocation) return undefined;

    const office = ZEventUtils.getZRetreatOffice(zeventLocation, floorsOfficeMap);
    if (office) {
      return floors.get(office.id)?.find((floor) => floor.id === zeventLocation.floorId);
    }
    return undefined;
  };

  public static getZRetreatZEventUserName = (zEventUser: ZEventUser): string => {
    return `${zEventUser.firstName} ${zEventUser.lastName}`;
  };

  /** get customized zRetreat display name, e.g. Atlanta | Floor 10 */
  public static getZRetreatName = (
    zeventLocation: ZEventLocation | undefined,
    floorsOfficeMap: Map<string, Office | undefined>,
    floors: Map<string, Floor[]>,
  ): string => {
    if (!zeventLocation) return '';

    const office = ZEventUtils.getZRetreatOffice(zeventLocation, floorsOfficeMap);
    if (office === undefined) return '';
    const floor = floors.get(office.id)?.find((floor) => floor.id === zeventLocation.floorId);
    if (floor === undefined) return office.name;

    // if name is just a number, prefix with 'Floor '
    let floorName = floor.name;
    if (floor.name && !isNaN(parseInt(floor.name))) {
      floorName = `Floor ${floor.name}`;
    }

    return `${office.name} | ${floorName}`;
  };

  public static zEventUserStatusToString = (
    status: ZEventUserStatusType,
  ): ZEventUserStatusStringType => {
    switch (status) {
      case ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED:
        return 'Accepted';
      case ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED:
        return 'Declined';
      case ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING:
        return 'Awaiting Response';
      default:
        return 'Unknown';
    }
  };

  public static zEventUserStatusStringToEnum = (
    statusStr: ZEventUserStatusStringType,
  ): ZEventUserStatusType => {
    switch (statusStr) {
      case 'Accepted':
        return ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED;
      case 'Declined':
        return ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED;
      case 'Awaiting Response':
        return ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING;
      default:
        return ZEventUserStatusTypeValue.UNRECOGNIZED;
    }
  };

  /** zRetreat is a past event if end date is in the past */
  public static isZRetreatPastEvent = (zRetreat: ZEvent): boolean => {
    return zRetreat.endDate
      ? isAfter(startOfToday(), DateUtils.DateFromUTC(zRetreat.endDate))
      : false;
  };

  /** zRetreat is now happening */
  public static isZRetreatHappeningNow = (zRetreat: ZEvent): boolean => {
    if (!zRetreat.startDate || !zRetreat.endDate) return false;
    return isWithinInterval(startOfToday(), {
      start: DateUtils.DateFromUTC(zRetreat.startDate),
      end: DateUtils.DateFromUTC(zRetreat.endDate),
    });
  };

  /** zRetreat is a future event if start date is in the future */
  public static isZRetreatFutureEvent = (zRetreat: ZEvent): boolean => {
    return zRetreat.startDate ? isFuture(DateUtils.DateFromUTC(zRetreat.startDate)) : false;
  };

  public static isZRetreatCancelled = (zRetreat: ZEvent): boolean => {
    return zRetreat.isCanceled === true;
  };

  public static doesZRetreatHaveInvitedAttendees = (zRetreat: ZEvent): boolean => {
    const userCount = Number.parseInt(zRetreat.totalUserCount);
    return userCount - zRetreat.planners.length - zRetreat.hosts.length > 0;
  };

  public static isUserZRetreatPlanner = (zRetreat: ZEvent): boolean => {
    return zRetreat.userType === ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_PLANNER;
  };

  public static isUserZRetreatHost = (zRetreat: ZEvent): boolean => {
    return zRetreat.userType === ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_HOST;
  };

  public static isUserZRetreatAttendee = (zRetreat: ZEvent): boolean => {
    return zRetreat.userType === ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE;
  };

  public static isUserZRetreatPartner = (zRetreat: ZEvent): boolean => {
    return zRetreat.userType === ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_UNSPECIFIED;
  };

  public static isZRetreatAttendee = (user: ZEventUser): boolean => {
    return user.type === ZEventUserTypeValue.COMPANY_EVENT_USER_TYPE_ATTENDEE;
  };

  public static hasAccepted = (user: ZEventUser): boolean => {
    return user.status === ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED;
  };

  public static hasDeclined = (user: ZEventUser): boolean => {
    return user.status === ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_DECLINED;
  };

  public static isPending = (user: ZEventUser): boolean => {
    return user.status === ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_PENDING;
  };
}
