import { OfficeFloorKey, OfficeFloorKeyType } from './types';

export const officeKeyMap: { [key: string]: OfficeFloorKey } = {
  '1': {
    id: '1',
    name: 'Lounge',
    description: '',
    color: '#3f92fd',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '2': {
    id: '2',
    name: 'Synergy',
    description: '',
    color: '#98e0f5',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '3': {
    id: '3',
    name: 'Focus',
    description: '',
    color: '#a0b5d3',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '4': {
    id: '4',
    name: 'Meditation/Prayer',
    description: '',
    color: '#5b6c97',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '5': {
    id: '5',
    name: 'Kitchen',
    description: '',
    color: '#d7e3de',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '6': {
    id: '6',
    name: 'zRetreats',
    description: '',
    color: '#cccf7c',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_NEIGHBORHOODS,
  },
  '7': {
    id: '7',
    name: 'Welcoming Area',
    description: '',
    color: '#8aaea6',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '8': {
    id: '8',
    name: 'Flex',
    description: '',
    color: '#4f635e',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '9': {
    id: '9',
    name: "Mother's Room",
    description: '',
    color: '#f5c9b4',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '10': {
    id: '10',
    name: 'Teams',
    description: '',
    color: '#f4cb78',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_NEIGHBORHOODS,
  },
  '11': {
    id: '11',
    name: 'Dining Room',
    description: '',
    color: '#ef9c44',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '12': {
    id: '12',
    name: 'Library',
    description: '',
    color: '#f57668',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '13': {
    id: '13',
    name: 'Help Kiosk',
    description: '',
    color: '#fc4634',
    shape: 'circle',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '14': {
    id: '14',
    name: 'IT Peripheral Station',
    description: '',
    color: '#016aff',
    shape: 'circle',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '15': {
    id: '15',
    name: "Maker's Space",
    description: '',
    color: '#fac418',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '16': {
    id: '16',
    name: 'Fitness Center',
    description: '',
    color: '#c5abc1',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '17': {
    id: '17',
    name: 'Gender Neutral Restroom',
    description: '',
    color: '#b070f0',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '18': {
    id: '18',
    name: 'Collaboration',
    description: '',
    color: '#05439a',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '19': {
    id: '19',
    name: 'Catering Station',
    description: '',
    color: '#ffc200',
    shape: 'circle',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '20': {
    id: '20',
    name: 'Reflection',
    description: '',
    color: '#70ad47',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '21': {
    id: '21',
    name: 'Team',
    description: '',
    color: '#c696b1',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '22': {
    id: '22',
    name: "Reception",
    description: '',
    color: '#70ad47',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '23': {
    id: '23',
    name: 'Focus',
    description: '',
    color: '#ffc103',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SPACES,
  },
  '24': {
    id: '24',
    name: 'Library',
    description: '',
    color: '#fb8306',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
  '25': {
    id: '25',
    name: "Maker's Space",
    description: '',
    color: '#990001',
    shape: 'square',
    type: OfficeFloorKeyType.OFFICE_FLOOR_KEY_TYPE_SERVICES,
  },
};
