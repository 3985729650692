import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import {
  getMassUploadReport,
  MassUploadReport,
  EntryResultStatusValue,
  MassUploadReportStatusType,
  MassUploadReportStatusValue,
} from '../../../store/office';
import { Loader } from '../../../components/Loader';
import { token, spaceMixin, Heading, Spacer, Table, Paragraph } from '@zillow/constellation';
import { MassUploadReportContainerLogic } from './MassUploadReportContainer.hooks';
import { DateUtils } from '../../../utilities/dateutils';

export interface MassUploadReportContainerProps {
  /** get mass upload report by id request */
  getMassUploadReport: (rid: string) => Promise<MassUploadReport | undefined>;
}

const ReportHeadingsWrapper = styled(Spacer)`
  background-color: ${token('colors.backgroundWhite')};
  padding: ${spaceMixin('md')};
`;

export const reportStatusToString = (status: MassUploadReportStatusType): string => {
  switch (status) {
    case MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_SUCCESS:
      return 'Success';
    case MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_PARTIAL_ERROR:
      return 'Errors';
    case MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_ALL_ERROR:
      return 'Failed';
    default:
      return 'Unknown';
  }
};

const MassUploadReportContainer: React.FC<MassUploadReportContainerProps> = (
  props: MassUploadReportContainerProps,
) => {
  const { isLoaded, report, errorMessage } = MassUploadReportContainerLogic(props);

  return (
    <Loader loading={!isLoaded} centerOnPage={true}>
      {(report && (
        <>
          <ReportHeadingsWrapper>
            <Heading level="3">
              Overall status:{' '}
              <Heading as="span" level="3" fontColor="orange400">
                {reportStatusToString(report.status)}
              </Heading>
            </Heading>
            <Heading level="3">{report.filename}</Heading>
            <Spacer margin="xs" />
            <Heading level="5">
              Upload date: {report.uploadedDate && DateUtils.DateToUSLocaleStr(report.uploadedDate)}
            </Heading>
            <Heading level="5">
              Uploaded by: {report.uploader?.firstName} {report.uploader?.lastName}
            </Heading>
          </ReportHeadingsWrapper>
          <Table appearance="zebra" aria-label="mass upload report table" size="md">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Line ID</Table.HeaderCell>
                <Table.HeaderCell>Employee email</Table.HeaderCell>
                <Table.HeaderCell>Employee name</Table.HeaderCell>
                <Table.HeaderCell>Office</Table.HeaderCell>
                <Table.HeaderCell>Visit start date</Table.HeaderCell>
                <Table.HeaderCell>Visit end date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Error reason</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {report.details.map((r, idx) => (
                <Table.Row key={idx}>
                  <Table.HeaderCell>{r.lineNumber}</Table.HeaderCell>
                  <Table.Cell>{r.email}</Table.Cell>
                  <Table.Cell>{r.employeeName}</Table.Cell>
                  <Table.Cell>{r.officeName}</Table.Cell>
                  <Table.Cell>
                    {r.startDate && DateUtils.DateToUSLocaleStr(DateUtils.DateFromUTC(r.startDate))}
                  </Table.Cell>
                  <Table.Cell>
                    {r.endDate && DateUtils.DateToUSLocaleStr(DateUtils.DateFromUTC(r.endDate))}
                  </Table.Cell>
                  <Table.Cell>
                    {r.status === EntryResultStatusValue.ENTRY_RESULT_SUCCESS
                      ? 'Success'
                      : 'Failed'}
                  </Table.Cell>
                  <Table.Cell>{r.reason}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )) ||
        (errorMessage && <Paragraph>{errorMessage}</Paragraph>)}
    </Loader>
  );
};

const mapDispatchToProps = {
  getMassUploadReport: getMassUploadReport,
};

type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<null, DispatchToPropsType, unknown, RootState>(
  null,
  mapDispatchToProps,
)(MassUploadReportContainer);
