import React from 'react';
import {
  Heading,
  Paragraph,
  IconCloseCircle,
  IconWarning,
  Flex,
  TextButton,
  Button,
} from '@zillow/constellation';
import { MobileButtonGroup } from '../OfficeVisitContainer/OfficeVisitContainer';
import { Modal } from '../../components/Modal';
import { Office } from '../../store';
import { DateUtils } from '../../utilities/dateutils';

export interface BookingErrorModalProps {
  /** if modal is open */
  isOpen: boolean;
  /** function to handle close */
  onClose: () => void;
  /** target office */
  targetOffice: Office;
  /** dates that are unexpectedly unavailable */
  unexpectedConflicts: Date[];
  /** on submit handler */
  onContinueBooking?: () => void;
}

export const BookingErrorModal: React.FC<BookingErrorModalProps> = ({
  isOpen,
  onClose,
  targetOffice,
  unexpectedConflicts,
}: BookingErrorModalProps) => {
  return (
    <Modal
      fullScreen={{ sm_lte: true }}
      header={<Heading level={6}>Try again</Heading>}
      body={
        <Flex margin="xs">
          <IconCloseCircle fontColor="red500" size="md" />
          <Heading level={4} marginY="sm" fontFamily="serif">
            Unable to book
          </Heading>
          <Paragraph>
            Someone just snagged the last day pass for {targetOffice.name} on{' '}
            {unexpectedConflicts.map((d) => DateUtils.DateToUSLocaleStr(d)).join(', ')}. Please try
            rebooking for another date.
          </Paragraph>
        </Flex>
      }
      isOpen={isOpen}
      handleClose={onClose}
      shouldCloseOnOutsideClick={true}
    />
  );
};

export const PartialBookingWarningModal: React.FC<BookingErrorModalProps> = ({
  isOpen,
  onClose,
  targetOffice,
  unexpectedConflicts,
  onContinueBooking,
}: BookingErrorModalProps) => {
  return (
    <Modal
      fullScreen={{ sm_lte: true }}
      header={<Heading level={6}>Warning</Heading>}
      body={
        <Flex margin="xs">
          <IconWarning fontColor="orange400" size="md" />
          <Heading level={4} marginY="sm" fontFamily="serif">
            Partial booking error
          </Heading>
          <Paragraph>
            Someone just snagged the last day pass for {targetOffice.name} on{' '}
            {unexpectedConflicts.map((d) => DateUtils.DateToUSLocaleStr(d)).join(', ')}. Click
            continue to finish your booking without those dates. Click cancel to retry with
            different dates.
          </Paragraph>
        </Flex>
      }
      footer={
        <MobileButtonGroup aria-label="Permission to proceed booking actions">
          <TextButton onClick={onClose}>Cancel</TextButton>
          <Button
            buttonType="primary"
            onClick={() => {
              if (onContinueBooking) onContinueBooking();
              onClose();
            }}
          >
            Continue
          </Button>
        </MobileButtonGroup>
      }
      isOpen={isOpen}
      handleClose={onClose}
      shouldCloseOnOutsideClick={true}
    />
  );
};
