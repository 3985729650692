import React from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '@zillow/constellation';

interface LoaderProps {
  /** content is loading or not */
  loading: boolean;
  /** standard children prop */
  children?: React.ReactNode;
  /** spinner size */
  size?: string;
  /** if loader should be centered on page */
  centerOnPage?: boolean;
}

const Center = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SpinnerIcon = styled(Spinner)<{ centerOnPage?: boolean }>`
  display: block;
  margin: auto;
  ${(props) => (props.centerOnPage ? Center : null)};
`;

export const Loader: React.FC<LoaderProps> & {
  defaultProps: Partial<LoaderProps>;
} = ({ loading, children, size, centerOnPage }: LoaderProps) => {
  return loading ? <SpinnerIcon size={size} centerOnPage={centerOnPage} /> : <>{children}</>;
};

Loader.defaultProps = {
  size: 'lg',
};
