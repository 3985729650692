import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Floor, mockGetOffices, mockGetOfficesFloors, Office } from '../../store/office';
import { FloorPlanContainerProps } from './FloorPlanContainer';
import { DisplayError } from '../../store/errorHelpers';
import { useErrorStatus } from '../../components/ErrorHandler';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import DynamicConfig from '../../config/DynamicConfig';

export const FloorPlanContainerLogic = ({
  offices,
  floors,
  getOffices,
  getOfficesFloors,
  history,
  isInternal,
}: FloorPlanContainerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { officeID, floorID } = useParams<{ officeID: string; floorID: string }>();
  const [selectedOffice, setSelectedOffice] = useState<Office | undefined>(undefined);
  const [selectedFloor, setSelectedFloor] = useState<Floor | undefined>(undefined);
  const [hasFloorPlanError, setHasFloorPlanError] = useState(false);
  const { setErrorStatusCode } = useErrorStatus();

  /** load offices & office floors */
  useEffect(() => {
    if (!offices.length) {
      Promise.all([getOffices(isInternal), getOfficesFloors(isInternal)])
        .catch((err: DisplayError) => setErrorStatusCode(err.code))
        .finally(() => setIsLoaded(true));
    } else if (!floors.size) {
      getOfficesFloors(isInternal)
        .catch((err: DisplayError) => setErrorStatusCode(err.code))
        .finally(() => setIsLoaded(true));
    } else {
      // offices & floors already in store
      setIsLoaded(true);
    }
  }, []);

  const floorPlanImgSrc: string = selectedFloor
    ? `${DynamicConfig.GetConfig().HQENGINE_PROXY}${selectedFloor.planUrl}`
    : '';

  const floorPlanURL = useCallback(
    (officeID: string, floorID?: string) => ({
      pathname: `${isInternal ? '/internal' : ''}/floorplans/office/${officeID}${
        floorID ? `/floor/${floorID}` : ''
      }`,
    }),
    [isInternal],
  );

  const onOfficeChange = useCallback(
    (id: string) => {
      const url = floorPlanURL(id);
      history.push(url);
    },
    [floorPlanURL],
  );

  const onFloorChange = useCallback(
    (floorID: string) => {
      history.push(floorPlanURL(officeID, floorID));
    },
    [officeID, floorPlanURL],
  );

  /** get selected office & floor from URL params */
  useEffect(() => {
    if (offices.length == 0 || floors.size == 0) return;
    // if just redirected to default floor, don't re-run hook
    if (floorID && floorID === selectedFloor?.id) return;

    setHasFloorPlanError(false);

    // get office from officeID
    const office = offices.find((o) => o.id === officeID);
    setSelectedOffice(office);

    let floor: Floor | undefined;
    if (office) {
      // get floor from floorID OR default to office's lowest floor if floorID is missing
      floor = floors.get(office.id)?.find((f) => (floorID ? f.id.toString() === floorID : true));
      setSelectedFloor(floor);

      // redirect if using default floor
      if (!floorID && floor) {
        history.replace(floorPlanURL(office.id, floor.id));
      }
    }

    if ((officeID && !office) || (floorID && !floor)) {
      // error if floor plan is not found, setTimeout to avoid flashing msg
      setTimeout(() => setHasFloorPlanError(true), 300);
    }

    if (office?.name && floor?.name) {
      if (isInternal) GoogleAnalyticsUtils.ViewFloorPlanQR(office.name, floor.name);
      else GoogleAnalyticsUtils.ViewFloorPlan(office.name, floor.name);
    }
  }, [offices, floors, officeID, floorID]);

  return {
    isLoaded,
    selectedOffice,
    selectedFloor,
    hasFloorPlanError,
    onOfficeChange,
    onFloorChange,
    floorPlanImgSrc,
  };
};

export const MockFloorPlanContainerLogic = ({
  offices,
  floors,
  history,
}: FloorPlanContainerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { officeID, floorID } = useParams<{ officeID: string; floorID: string }>();
  const [selectedOffice, setSelectedOffice] = useState<Office | undefined>(undefined);
  const [selectedFloor, setSelectedFloor] = useState<Floor | undefined>(undefined);
  const [hasFloorPlanError, setHasFloorPlanError] = useState(false);

  const dispatch = useDispatch();

  /** load offices & office floors */
  useEffect(() => {
    dispatch(mockGetOffices());
    dispatch(mockGetOfficesFloors());
    setIsLoaded(true);
  }, []);

  const floorPlanImgSrc: string = selectedFloor?.planUrl || '';

  const floorPlanURL = (officeID: string, floorID?: string) => ({
    pathname: `/floorplans/office/${officeID}${floorID ? `/floor/${floorID}` : ''}`,
    search: `?test=1`,
  });

  const onOfficeChange = useCallback((id: string) => {
    history.push(floorPlanURL(id));
  }, []);

  const onFloorChange = useCallback(
    (floorID: string) => {
      history.push(floorPlanURL(officeID, floorID));
    },
    [officeID],
  );

  /** get selected office & floor from URL params */
  useEffect(() => {
    if (!offices || !floors) return;
    setHasFloorPlanError(false);

    // get office from officeID
    const office = offices.find((o) => o.id === officeID);
    setSelectedOffice(office);

    let floor: Floor | undefined;
    if (office) {
      // get floor from floorID OR default to office's lowest floor if floorID is missing
      floor = floors.get(office.id)?.find((f) => (floorID ? f.id.toString() === floorID : true));
      setSelectedFloor(floor);

      // redirect if using default floor
      if (!floorID && floor) {
        history.replace(floorPlanURL(office.id, floor.id));
      }
    }

    if ((officeID && !office) || (floorID && !floor)) {
      // error if floor plan is not found, setTimeout to avoid flashing msg
      setTimeout(() => setHasFloorPlanError(true), 300);
    }
  }, [offices, floors, officeID, floorID]);

  return {
    isLoaded,
    selectedOffice,
    selectedFloor,
    hasFloorPlanError,
    onOfficeChange,
    onFloorChange,
    floorPlanImgSrc,
  };
};
