/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from './google/protobuf/timestamp';
import { User } from './hqengine_user';

export const protobufPackage = 'peng_hqengine_contract';

export enum MassUploadReportStatus {
  MASS_UPLOAD_REPORT_STATUS_UNSPECIFIED = 0,
  MASS_UPLOAD_REPORT_STATUS_SUCCESS = 1,
  MASS_UPLOAD_REPORT_STATUS_PARTIAL_ERROR = 2,
  MASS_UPLOAD_REPORT_STATUS_ALL_ERROR = 3,
  UNRECOGNIZED = -1,
}

export function massUploadReportStatusFromJSON(object: any): MassUploadReportStatus {
  switch (object) {
    case 0:
    case 'MASS_UPLOAD_REPORT_STATUS_UNSPECIFIED':
      return MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_UNSPECIFIED;
    case 1:
    case 'MASS_UPLOAD_REPORT_STATUS_SUCCESS':
      return MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_SUCCESS;
    case 2:
    case 'MASS_UPLOAD_REPORT_STATUS_PARTIAL_ERROR':
      return MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_PARTIAL_ERROR;
    case 3:
    case 'MASS_UPLOAD_REPORT_STATUS_ALL_ERROR':
      return MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_ALL_ERROR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MassUploadReportStatus.UNRECOGNIZED;
  }
}

export function massUploadReportStatusToJSON(object: MassUploadReportStatus): string {
  switch (object) {
    case MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_UNSPECIFIED:
      return 'MASS_UPLOAD_REPORT_STATUS_UNSPECIFIED';
    case MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_SUCCESS:
      return 'MASS_UPLOAD_REPORT_STATUS_SUCCESS';
    case MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_PARTIAL_ERROR:
      return 'MASS_UPLOAD_REPORT_STATUS_PARTIAL_ERROR';
    case MassUploadReportStatus.MASS_UPLOAD_REPORT_STATUS_ALL_ERROR:
      return 'MASS_UPLOAD_REPORT_STATUS_ALL_ERROR';
    default:
      return 'UNKNOWN';
  }
}

export enum EntryResultStatus {
  ENTRY_RESULT_UNSPECIFIED = 0,
  ENTRY_RESULT_SUCCESS = 1,
  ENTRY_RESULT_ERROR = 2,
  UNRECOGNIZED = -1,
}

export function entryResultStatusFromJSON(object: any): EntryResultStatus {
  switch (object) {
    case 0:
    case 'ENTRY_RESULT_UNSPECIFIED':
      return EntryResultStatus.ENTRY_RESULT_UNSPECIFIED;
    case 1:
    case 'ENTRY_RESULT_SUCCESS':
      return EntryResultStatus.ENTRY_RESULT_SUCCESS;
    case 2:
    case 'ENTRY_RESULT_ERROR':
      return EntryResultStatus.ENTRY_RESULT_ERROR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EntryResultStatus.UNRECOGNIZED;
  }
}

export function entryResultStatusToJSON(object: EntryResultStatus): string {
  switch (object) {
    case EntryResultStatus.ENTRY_RESULT_UNSPECIFIED:
      return 'ENTRY_RESULT_UNSPECIFIED';
    case EntryResultStatus.ENTRY_RESULT_SUCCESS:
      return 'ENTRY_RESULT_SUCCESS';
    case EntryResultStatus.ENTRY_RESULT_ERROR:
      return 'ENTRY_RESULT_ERROR';
    default:
      return 'UNKNOWN';
  }
}

export interface MassUploadReport {
  uploader: User | undefined;
  uploadedDate: Date | undefined;
  status: MassUploadReportStatus;
  filename: string;
  totalCount: string;
  successCount: string;
  failureCount: string;
  details: EntryResult[];
  id: string;
}

export interface EntryResult {
  lineNumber: string;
  employeeName: string;
  email: string;
  officeName: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  status: EntryResultStatus;
  reason: string;
}

const baseMassUploadReport: object = {
  status: 0,
  filename: '',
  totalCount: '0',
  successCount: '0',
  failureCount: '0',
  id: '0',
};

export const MassUploadReport = {
  encode(message: MassUploadReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uploader !== undefined) {
      User.encode(message.uploader, writer.uint32(10).fork()).ldelim();
    }
    if (message.uploadedDate !== undefined) {
      Timestamp.encode(toTimestamp(message.uploadedDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.filename !== '') {
      writer.uint32(34).string(message.filename);
    }
    if (message.totalCount !== '0') {
      writer.uint32(40).int64(message.totalCount);
    }
    if (message.successCount !== '0') {
      writer.uint32(48).int64(message.successCount);
    }
    if (message.failureCount !== '0') {
      writer.uint32(56).int64(message.failureCount);
    }
    for (const v of message.details) {
      EntryResult.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.id !== '0') {
      writer.uint32(72).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MassUploadReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMassUploadReport } as MassUploadReport;
    message.details = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.uploader = User.decode(reader, reader.uint32());
          break;
        case 2:
          message.uploadedDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.status = reader.int32() as any;
          break;
        case 4:
          message.filename = reader.string();
          break;
        case 5:
          message.totalCount = longToString(reader.int64() as Long);
          break;
        case 6:
          message.successCount = longToString(reader.int64() as Long);
          break;
        case 7:
          message.failureCount = longToString(reader.int64() as Long);
          break;
        case 8:
          message.details.push(EntryResult.decode(reader, reader.uint32()));
          break;
        case 9:
          message.id = longToString(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MassUploadReport {
    const message = { ...baseMassUploadReport } as MassUploadReport;
    message.details = [];
    if (object.uploader !== undefined && object.uploader !== null) {
      message.uploader = User.fromJSON(object.uploader);
    } else {
      message.uploader = undefined;
    }
    if (object.uploadedDate !== undefined && object.uploadedDate !== null) {
      message.uploadedDate = fromJsonTimestamp(object.uploadedDate);
    } else {
      message.uploadedDate = undefined;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = massUploadReportStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = String(object.filename);
    } else {
      message.filename = '';
    }
    if (object.totalCount !== undefined && object.totalCount !== null) {
      message.totalCount = String(object.totalCount);
    } else {
      message.totalCount = '0';
    }
    if (object.successCount !== undefined && object.successCount !== null) {
      message.successCount = String(object.successCount);
    } else {
      message.successCount = '0';
    }
    if (object.failureCount !== undefined && object.failureCount !== null) {
      message.failureCount = String(object.failureCount);
    } else {
      message.failureCount = '0';
    }
    if (object.details !== undefined && object.details !== null) {
      for (const e of object.details) {
        message.details.push(EntryResult.fromJSON(e));
      }
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    return message;
  },

  toJSON(message: MassUploadReport): unknown {
    const obj: any = {};
    message.uploader !== undefined &&
      (obj.uploader = message.uploader ? User.toJSON(message.uploader) : undefined);
    message.uploadedDate !== undefined && (obj.uploadedDate = message.uploadedDate.toISOString());
    message.status !== undefined && (obj.status = massUploadReportStatusToJSON(message.status));
    message.filename !== undefined && (obj.filename = message.filename);
    message.totalCount !== undefined && (obj.totalCount = message.totalCount);
    message.successCount !== undefined && (obj.successCount = message.successCount);
    message.failureCount !== undefined && (obj.failureCount = message.failureCount);
    if (message.details) {
      obj.details = message.details.map((e) => (e ? EntryResult.toJSON(e) : undefined));
    } else {
      obj.details = [];
    }
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<MassUploadReport>): MassUploadReport {
    const message = { ...baseMassUploadReport } as MassUploadReport;
    message.details = [];
    if (object.uploader !== undefined && object.uploader !== null) {
      message.uploader = User.fromPartial(object.uploader);
    } else {
      message.uploader = undefined;
    }
    if (object.uploadedDate !== undefined && object.uploadedDate !== null) {
      message.uploadedDate = object.uploadedDate;
    } else {
      message.uploadedDate = undefined;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = object.filename;
    } else {
      message.filename = '';
    }
    if (object.totalCount !== undefined && object.totalCount !== null) {
      message.totalCount = object.totalCount;
    } else {
      message.totalCount = '0';
    }
    if (object.successCount !== undefined && object.successCount !== null) {
      message.successCount = object.successCount;
    } else {
      message.successCount = '0';
    }
    if (object.failureCount !== undefined && object.failureCount !== null) {
      message.failureCount = object.failureCount;
    } else {
      message.failureCount = '0';
    }
    if (object.details !== undefined && object.details !== null) {
      for (const e of object.details) {
        message.details.push(EntryResult.fromPartial(e));
      }
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    return message;
  },
};

const baseEntryResult: object = {
  lineNumber: '0',
  employeeName: '',
  email: '',
  officeName: '',
  status: 0,
  reason: '',
};

export const EntryResult = {
  encode(message: EntryResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lineNumber !== '0') {
      writer.uint32(8).int64(message.lineNumber);
    }
    if (message.employeeName !== '') {
      writer.uint32(18).string(message.employeeName);
    }
    if (message.email !== '') {
      writer.uint32(26).string(message.email);
    }
    if (message.officeName !== '') {
      writer.uint32(34).string(message.officeName);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(42).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(50).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(56).int32(message.status);
    }
    if (message.reason !== '') {
      writer.uint32(66).string(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntryResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEntryResult } as EntryResult;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.lineNumber = longToString(reader.int64() as Long);
          break;
        case 2:
          message.employeeName = reader.string();
          break;
        case 3:
          message.email = reader.string();
          break;
        case 4:
          message.officeName = reader.string();
          break;
        case 5:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 6:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 7:
          message.status = reader.int32() as any;
          break;
        case 8:
          message.reason = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EntryResult {
    const message = { ...baseEntryResult } as EntryResult;
    if (object.lineNumber !== undefined && object.lineNumber !== null) {
      message.lineNumber = String(object.lineNumber);
    } else {
      message.lineNumber = '0';
    }
    if (object.employeeName !== undefined && object.employeeName !== null) {
      message.employeeName = String(object.employeeName);
    } else {
      message.employeeName = '';
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = '';
    }
    if (object.officeName !== undefined && object.officeName !== null) {
      message.officeName = String(object.officeName);
    } else {
      message.officeName = '';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = entryResultStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = String(object.reason);
    } else {
      message.reason = '';
    }
    return message;
  },

  toJSON(message: EntryResult): unknown {
    const obj: any = {};
    message.lineNumber !== undefined && (obj.lineNumber = message.lineNumber);
    message.employeeName !== undefined && (obj.employeeName = message.employeeName);
    message.email !== undefined && (obj.email = message.email);
    message.officeName !== undefined && (obj.officeName = message.officeName);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    message.status !== undefined && (obj.status = entryResultStatusToJSON(message.status));
    message.reason !== undefined && (obj.reason = message.reason);
    return obj;
  },

  fromPartial(object: DeepPartial<EntryResult>): EntryResult {
    const message = { ...baseEntryResult } as EntryResult;
    if (object.lineNumber !== undefined && object.lineNumber !== null) {
      message.lineNumber = object.lineNumber;
    } else {
      message.lineNumber = '0';
    }
    if (object.employeeName !== undefined && object.employeeName !== null) {
      message.employeeName = object.employeeName;
    } else {
      message.employeeName = '';
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = '';
    }
    if (object.officeName !== undefined && object.officeName !== null) {
      message.officeName = object.officeName;
    } else {
      message.officeName = '';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = object.reason;
    } else {
      message.reason = '';
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = Number(t.seconds) * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
