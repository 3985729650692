import React, { useCallback } from 'react';
import {
  Combobox,
  AdornedInput,
  Input,
  Adornment,
  IconSearch,
  MediaObject,
  Spacer,
  Text,
  Paragraph,
} from '@zillow/constellation';
import { EmployeeFound } from '../../store/search';
import { AvatarDisk } from '../AvatarDisk';
import DynamicConfig from '../../config/DynamicConfig';

export interface SearchboxProps {
  /** search options list */
  searchOptions: SearchOption[];
  /** is loading options */
  isLoadingOptions?: boolean;
  /** searchbox input placeholder */
  inputPlaceholder?: string;
  /** autocomplete behavior */
  autoCompleteBehavior?: 'none' | 'manual' | 'automatic';
  /** handle searchbox input change */
  handleInputChange: (keyword: string) => void;
  /** handle select option */
  handleSelectOption: (option: SearchOption) => void;
}

export type SearchOption = {
  id: string;
  value: string;
  label: string;
  children: React.ReactNode;
};

export const employeeFoundToSearchOption = (employees: EmployeeFound[]): SearchOption[] => {
  return employees.map((e) => ({
    id: e.id,
    label: e.fullName,
    value: e.fullName,
    children: renderEmployeeSearchOption(e),
  }));
};

export const renderEmployeeSearchOption = (employee: EmployeeFound) => {
  const { fullName, email, photoUrl } = employee;
  return (
    <div>
      <MediaObject
        media={
          <Spacer marginTop="xs">
            <AvatarDisk
              fullName={fullName}
              photoUrl={photoUrl && `${DynamicConfig.GetConfig().HQENGINE_PROXY}${photoUrl}`}
              size="sm"
              shape={'square'}
            />
          </Spacer>
        }
        align="center"
      >
        <Text>{fullName}</Text>
        <Paragraph fontType="finePrint">{email}</Paragraph>
      </MediaObject>
    </div>
  );
};

export const Searchbox: React.FC<SearchboxProps> = ({
  searchOptions,
  isLoadingOptions,
  inputPlaceholder,
  autoCompleteBehavior,
  handleInputChange,
  handleSelectOption,
}: SearchboxProps) => {
  /**
   * pass onChange event to Input instead of Combobox to:
   *    1. trigger each time the search input is modified
   *    2. avoid triggering onChange event when an option is selected
   */
  const renderInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ onClear, onTagClose, onChange, ...rest }) => (
      <AdornedInput
        input={
          <Input
            placeholder={inputPlaceholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange(e.target.value);
              onChange(e);
            }}
            {...rest}
          />
        }
        rightAdornment={
          <Adornment>
            <IconSearch />
          </Adornment>
        }
      />
    ),
    [handleInputChange],
  );

  return (
    <Combobox
      focusFirstOption
      appearance="input"
      options={searchOptions}
      loading={isLoadingOptions}
      renderInput={renderInput}
      onOptionSelect={handleSelectOption}
      autoCompleteBehavior={autoCompleteBehavior || 'none'}
    />
  );
};
