import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  HQWebClientImpl,
  GetAllOfficesResponse,
  GetAllOfficesBlockedDatesRequest,
  GetAllOfficesBlockedDatesResponse,
  GetAllOfficeFloorsResponse,
  GetEmployeeVisitsRequest,
  GetEmployeeVisitsResponse,
  BookOfficeVisitRequest,
  BookOfficeVisitResponse,
  CancelOfficeVisitRequest,
  CancelOfficeVisitResponse,
  GetFullyBookedDatesRequest,
  GetFullyBookedDatesResponse,
  GetOfficeUsagesRequest,
  GetOfficeUsagesResponse,
  BlockedDates,
  OfficeFloors,
  UploadOfficeVisitsInBulkRequest,
  GetMassUploadReportRequest,
  UploadOfficeVisitsInBulkResponse,
  GetMassUploadReportResponse,
  GetAllMassUploadReportsResponse,
} from '../../contract/hqengine_hqweb';
import { HQWebInternalClientImpl } from '../../contract/hqengine_hqweb_internal';
import {
  HQWebExternalClientImpl,
  AcknowledgeOfficeVisitRequest,
  AcknowledgeOfficeVisitResponse,
} from '../../contract/hqengine_hqweb_external';
import { RpcImpl } from '../../contract/rpc';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import {
  OfficeState,
  Office,
  GetOfficesSuccess,
  GetOfficesAction,
  MockGetOffices,
  GetOfficesBlockedDatesSuccess,
  GetOfficesBlockedDatesAction,
  MockGetOfficesBlockedDates,
  GetOfficesFloorsSuccess,
  GetOfficesFloorsAction,
  MockGetOfficesFloors,
  GetReservationsSuccess,
  GetReservationsAction,
  MockGetReservations,
  BookOfficeVisitSuccess,
  BookOfficeVisitError,
  OfficeBookingAction,
  MockBookOfficeVisitSuccess,
  MockBookOfficeVisitError,
  CancelOfficeVisitSuccess,
  CancelOfficeVisitError,
  MockCancelOfficeVisitSuccess,
  MockCancelOfficeVisitError,
  Reservation,
  MockGetOfficeFullyBookedSuccess,
  MockGetOfficeFullyBookedError,
  OfficeAction,
  RejectedBooking,
  UpdateBookingTypeAction,
  AckReservationAction,
  AckReservationResult,
  AckReservationError,
  AckReservationSuccess,
  BookOfficeVisitsBulkAction,
  MassUploadReport,
  MassUploadReportStatusType,
  OfficeBooking,
  BookingType,
  OfficeUsage,
  MockGetOfficeUsagesSuccess,
  MockGetOfficeUsagesError,
} from './index';
import {
  validateGetOfficesResp,
  validateGetOfficesBlockedDatesResp,
  validateGetOfficesFloorsResp,
  validateGetReservationsResp,
  validateBookingOfficeVisitResp,
  validateCancelOfficeVisitResp,
  validateGetOfficeFullyBookedDatesResp,
  validateAckReservationResp,
  validateUploadOfficeVisitsInBulkResp,
  validateGetMassUploadReportResp,
  validateGetAllMassUploadReportsResp,
  validateGetOfficeUsagesResp,
} from './validators';
import { GetDisplayError } from '../errorHelpers';
import DynamicConfig from '../../config/DynamicConfig';
import { DateUtils } from '../../utilities/dateutils';

export const getOfficesSuccess = (offices: { [key: string]: Office }): GetOfficesSuccess => ({
  type: 'GET_OFFICES_SUCCESS',
  offices: offices,
});

export const getOffices =
  (isInternal: boolean): ThunkAction<Promise<void>, OfficeState, null, GetOfficesAction> =>
  async (dispatch: ThunkDispatch<OfficeState, null, GetOfficesAction>): Promise<void> => {
    const client = isInternal
      ? new HQWebInternalClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL))
      : new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));

    return client
      .GetAllOffices({})
      .then((resp: GetAllOfficesResponse) => {
        const [offices, err] = validateGetOfficesResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getOfficesSuccess(offices));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetOffices = (): MockGetOffices => ({
  type: 'MOCK_GET_OFFICES',
});

export const getOfficesBlockedDatesSuccess = (blockedDates: {
  [key: string]: BlockedDates;
}): GetOfficesBlockedDatesSuccess => ({
  type: 'GET_OFFICES_BLOCKED_DATES_SUCCESS',
  blockedDates: blockedDates,
});

export const getOfficesBlockedDates =
  (
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): ThunkAction<Promise<void>, OfficeState, null, GetOfficesBlockedDatesAction> =>
  async (
    dispatch: ThunkDispatch<OfficeState, null, GetOfficesBlockedDatesAction>,
  ): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetAllOfficesBlockedDatesRequest = {
      startDate: startDate ? DateUtils.DateToUTC(startDate) : undefined,
      endDate: endDate ? DateUtils.DateToUTC(endDate) : undefined,
    };

    return client
      .GetAllOfficesBlockedDates(req)
      .then((resp: GetAllOfficesBlockedDatesResponse) => {
        const [blockedDates, err] = validateGetOfficesBlockedDatesResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getOfficesBlockedDatesSuccess(blockedDates));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetOfficesBlockedDates = (): MockGetOfficesBlockedDates => ({
  type: 'MOCK_GET_OFFICES_BLOCKED_DATES',
});

export const getOfficesFloorsSuccess = (floors: {
  [key: string]: OfficeFloors;
}): GetOfficesFloorsSuccess => ({
  type: 'GET_OFFICES_FLOORS_SUCCESS',
  floors: floors,
});

export const getOfficesFloors =
  (isInternal: boolean): ThunkAction<Promise<void>, OfficeState, null, GetOfficesFloorsAction> =>
  async (dispatch: ThunkDispatch<OfficeState, null, GetOfficesFloorsAction>): Promise<void> => {
    const client = isInternal
      ? new HQWebInternalClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL))
      : new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));

    return client
      .GetAllOfficeFloors({})
      .then((resp: GetAllOfficeFloorsResponse) => {
        const [floors, err] = validateGetOfficesFloorsResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getOfficesFloorsSuccess(floors));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetOfficesFloors = (): MockGetOfficesFloors => ({
  type: 'MOCK_GET_OFFICES_FLOORS',
});

export const getReservationsSuccess = (reservations: Reservation[]): GetReservationsSuccess => ({
  type: 'GET_USER_RESERVATIONS_SUCCESS',
  reservations: reservations,
});

export const getReservations =
  (
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): ThunkAction<Promise<void>, OfficeState, null, GetReservationsAction> =>
  async (dispatch: ThunkDispatch<OfficeState, null, GetReservationsAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetEmployeeVisitsRequest = {
      startDate: startDate ? DateUtils.DateToUTC(startDate) : undefined,
      endDate: endDate ? DateUtils.DateToUTC(endDate) : undefined,
    };

    return client
      .GetEmployeeVisits(req)
      .then((resp: GetEmployeeVisitsResponse) => {
        const [reservations, err] = validateGetReservationsResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getReservationsSuccess(reservations));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetReservations = (): MockGetReservations => ({
  type: 'MOCK_GET_USER_RESERVATIONS',
});

export const bookOfficeVisitSuccess = (visits: Reservation[]): BookOfficeVisitSuccess => ({
  type: 'BOOK_OFFICE_VISIT_SUCCESS',
  visits: visits,
});

export const bookOfficeVisitError = (msg: string): BookOfficeVisitError => ({
  type: 'BOOK_OFFICE_VISIT_ERROR',
  msg: msg,
});

export type BookOfficeVisitPromiseResponse = {
  booked: Reservation[];
  rejected: RejectedBooking[];
};

export const bookOfficeVisit =
  (
    booking: OfficeBooking,
  ): ThunkAction<Promise<BookOfficeVisitPromiseResponse>, OfficeState, null, OfficeBookingAction> =>
  async (
    dispatch: ThunkDispatch<OfficeState, null, OfficeBookingAction>,
  ): Promise<BookOfficeVisitPromiseResponse> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: BookOfficeVisitRequest = {
      visit: {
        officeId: booking.office.id,
        startDate: DateUtils.DateToUTC(booking.startDate),
        endDate: booking.endDate
          ? DateUtils.DateToUTC(booking.endDate)
          : DateUtils.DateToUTC(booking.startDate),
        excludedDates: booking.excluded.map((d) => DateUtils.DateToUTC(d)),
        bookingType: booking.bookingType,
      },
    };

    return client
      .BookOfficeVisit(req)
      .then((resp: BookOfficeVisitResponse) => {
        const [reservations, rejectedBookings, err] = validateBookingOfficeVisitResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(bookOfficeVisitSuccess(reservations));
          return Promise.resolve({ booked: reservations, rejected: rejectedBookings });
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockBookOfficeVisitSuccess = (booking: OfficeBooking): MockBookOfficeVisitSuccess => ({
  type: 'MOCK_BOOK_OFFICE_VISIT_SUCCESS',
  booking: {
    ...booking,
    startDate: DateUtils.DateToUTC(booking.startDate),
    endDate: booking.endDate ? DateUtils.DateToUTC(booking.endDate) : undefined,
    excluded: booking.excluded.map((d) => DateUtils.DateToUTC(d)),
  },
});

export const mockBookOfficeVisitError = (): MockBookOfficeVisitError => ({
  type: 'MOCK_BOOK_OFFICE_VISIT_ERROR',
});

export const cancelOfficeVisitSuccess = (visitIds: string[]): CancelOfficeVisitSuccess => ({
  type: 'CANCEL_OFFICE_VISIT_SUCCESS',
  visitIds: visitIds,
});

export const cancelOfficeVisitError = (msg: string): CancelOfficeVisitError => ({
  type: 'CANCEL_OFFICE_VISIT_ERROR',
  msg: msg,
});

export const cancelOfficeVisit =
  (
    visitIds: string[],
  ): ThunkAction<Promise<Reservation[]>, OfficeState, null, OfficeBookingAction> =>
  async (
    dispatch: ThunkDispatch<OfficeState, null, OfficeBookingAction>,
  ): Promise<Reservation[]> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: CancelOfficeVisitRequest = {
      visitIds: visitIds,
    };

    return client
      .CancelOfficeVisit(req)
      .then((resp: CancelOfficeVisitResponse) => {
        const [canceledVisits, err] = validateCancelOfficeVisitResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          const canceledVisitIds = canceledVisits.map((visit) => visit.id);
          dispatch(cancelOfficeVisitSuccess(canceledVisitIds));
          return Promise.resolve(canceledVisits);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockCancelOfficeVisitSuccess = (visitIds: string[]): MockCancelOfficeVisitSuccess => ({
  type: 'MOCK_CANCEL_OFFICE_VISIT_SUCCESS',
  visitIds: visitIds,
});

export const mockCancelOfficeVisitError = (): MockCancelOfficeVisitError => ({
  type: 'MOCK_CANCEL_OFFICE_VISIT_ERROR',
});

export const getOfficeFullyBookedDates =
  (
    officeId: string,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): ThunkAction<Promise<Date[]>, OfficeState, null, OfficeAction> =>
  async (): Promise<Date[]> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetFullyBookedDatesRequest = {
      officeId: officeId,
      startDate: startDate ? DateUtils.DateToUTC(startDate) : undefined,
      endDate: endDate ? DateUtils.DateToUTC(endDate) : undefined,
    };

    return client
      .GetFullyBookedDates(req)
      .then((resp: GetFullyBookedDatesResponse) => {
        const [dates, err] = validateGetOfficeFullyBookedDatesResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve(dates);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetOfficeFullyBookedDatesSuccess = (
  dates: Date[],
): MockGetOfficeFullyBookedSuccess => ({
  type: 'MOCK_GET_OFFICE_FULLY_BOOKED_DATES_SUCCESS',
  dates: dates,
});

export const mockGetOfficeFullyBookedError = (): MockGetOfficeFullyBookedError => ({
  type: 'MOCK_GET_OFFICE_FULLY_BOOKED_DATES_ERROR',
});

export const getOfficeUsages =
  (
    officeId: string,
    dates: Date[],
  ): ThunkAction<Promise<OfficeUsage[]>, OfficeState, null, OfficeAction> =>
  async (): Promise<OfficeUsage[]> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetOfficeUsagesRequest = {
      officeId: officeId,
      dates: dates,
    };

    return client
      .GetOfficeUsages(req)
      .then((resp: GetOfficeUsagesResponse) => {
        const [usages, err] = validateGetOfficeUsagesResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve(usages);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetOfficeUsagesSuccess = (usages: OfficeUsage[]): MockGetOfficeUsagesSuccess => ({
  type: 'MOCK_GET_OFFICE_USAGES_SUCCESS',
  usages: usages,
});

export const mockGetOfficeUsagesError = (): MockGetOfficeUsagesError => ({
  type: 'MOCK_GET_OFFICE_USAGES_ERROR',
});

export const updateBookingType = (value: BookingType): UpdateBookingTypeAction => ({
  type: 'UPDATE_BOOKING_TYPE',
  value: value,
});

export const bookOfficeVisitsBulk =
  (
    file: File,
  ): ThunkAction<
    Promise<{ reportID: string; result: MassUploadReportStatusType }>,
    OfficeState,
    null,
    BookOfficeVisitsBulkAction
  > =>
  async (): Promise<{ reportID: string; result: MassUploadReportStatusType }> => {
    const arrBuff = await file.arrayBuffer();

    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: UploadOfficeVisitsInBulkRequest = {
      filename: file.name,
      file: new Uint8Array(arrBuff),
    };

    return client
      .UploadOfficeVisitsInBulk(req)
      .then((resp: UploadOfficeVisitsInBulkResponse) => {
        const err = validateUploadOfficeVisitsInBulkResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve({ reportID: resp.massUploadId, result: resp.result });
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const getMassUploadReport =
  (
    rid: string,
  ): ThunkAction<
    Promise<MassUploadReport | undefined>,
    OfficeState,
    null,
    BookOfficeVisitsBulkAction
  > =>
  async (): Promise<MassUploadReport | undefined> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetMassUploadReportRequest = {
      id: rid,
    };

    return client
      .GetMassUploadReport(req)
      .then((resp: GetMassUploadReportResponse) => {
        const [rep, err] = validateGetMassUploadReportResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve(rep);
        }
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const getAllMassUploadReports =
  (): ThunkAction<Promise<MassUploadReport[] | undefined>, OfficeState, null, Action> =>
  async (): Promise<MassUploadReport[] | undefined> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));

    return client
      .GetAllMassUploadReports({})
      .then((resp: GetAllMassUploadReportsResponse) => {
        const [reports, err] = validateGetAllMassUploadReportsResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve(reports);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const ackReservationSuccess = (): AckReservationSuccess => ({
  type: 'ACK_RESERVATION_SUCCESS',
});

export const ackReservationError = (msg: string): AckReservationError => ({
  type: 'ACK_RESERVATION_ERROR',
  msg: msg,
});

export const ackReservation =
  (
    token: string,
  ): ThunkAction<Promise<AckReservationResult>, OfficeState, null, AckReservationAction> =>
  async (): Promise<AckReservationResult> => {
    const client = new HQWebExternalClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: AcknowledgeOfficeVisitRequest = {
      token: token,
    };

    return client
      .AcknowledgeOfficeVisit(req)
      .then((resp: AcknowledgeOfficeVisitResponse) => {
        const err = validateAckReservationResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve({
            officeName: resp.officeName,
            reservation: resp.visit,
            resultType: resp.resultType,
          } as AckReservationResult);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };
