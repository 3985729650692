import React from 'react';
import styled from 'styled-components';
import {
  Card as ConstellationCard,
  Divider,
  Spacer,
  Heading,
  Text,
  TextButton,
  IconDollarSignCircleOutline,
  IconCarOutline,
  IconAskQuestionOutline,
  IconExternal,
  spaceMixin,
} from '@zillow/constellation';
import DynamicConfig from '../../config/DynamicConfig';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';

const Card = styled(ConstellationCard)`
  box-sizing: border-box;
  height: 100%;
`;

const LinksDivider = styled(Divider)`
  margin: ${spaceMixin(1.5)} 0;
`;

// should match with links list in Header
export const QuickLinksCard: React.FC = () => {
  return (
    <Card data-testid="render-card-test" cardType="secondary" paddingTop="md" paddingX={{ lg: 'lg' }}>
      <Heading level="4" fontFamily="serif">
        Helpful Apps
      </Heading>
      <Spacer margin="md" />
      <TextButton
        data-testid="render-bt-booking-button-test"
        as="a"
        href={DynamicConfig.GetConfig().BUSINESS_TRAVEL_URL}
        target="_blank"
        fontType="body"
        icon={<IconCarOutline marginRight="xs" fontColor="brand" />}
        onClick={() => GoogleAnalyticsUtils.ClickBusinessTravelBooking()}
      >
        <Text fontColor="blue500">
          Business travel booking <IconExternal />
        </Text>
      </TextButton>
      <LinksDivider />
      <TextButton
        data-testid="render-expense-guidelines-button-test"
        as="a"
        href={DynamicConfig.GetConfig().TRAVEL_AND_EXPENSE_POLICY_URL}
        target="_blank"
        fontType="body"
        icon={<IconDollarSignCircleOutline marginRight="xs" fontColor="brand" />}
        onClick={() => GoogleAnalyticsUtils.ClickTravelExpenseGuidelines()}
      >
        <Text fontColor="blue500">
          Travel &amp; expense guidelines <IconExternal />
        </Text>
      </TextButton>
      <LinksDivider />
      <TextButton
        data-testid="render-cloudhq-button-test"
        as="a"
        href={DynamicConfig.GetConfig().CLOUD_HQ_ZALLWAY_URL}
        target="_blank"
        fontType="body"
        icon={<IconAskQuestionOutline marginRight="xs" fontColor="brand" />}
        onClick={() => GoogleAnalyticsUtils.ClickCloudHQZallway()}
      >
        <Text fontColor="blue500">
          Cloud HQ Zallway Page <IconExternal />
        </Text>
      </TextButton>
    </Card>
  );
};
