import React, { useContext } from 'react';
import {
  Popper,
  Popover,
  Text,
  TriggerText,
  Paragraph,
  Heading,
  Anchor,
} from '@zillow/constellation';
import { InternalLink } from '../../components/Links';
import { IsMockContext } from '../../middleware/auth';
import { IsBookingInProgressContext } from '../../containers/HomeContainer/IsBookingInProgressContext';

interface ZRetreatPopoverProps {
  /** text to wrap the popover with */
  text: string;
  /** title of the popover */
  title: string;
  /** zRetreat event id */
  eventId?: string;
}

const ZRetreatPopover: React.FC<ZRetreatPopoverProps> = ({
  text,
  title,
  eventId,
}: ZRetreatPopoverProps) => {
  const isMock = useContext(IsMockContext);
  const { isBookingInProgress } = useContext(IsBookingInProgressContext);

  const infoPopover = (
    <Popover
      header={<Heading level={6}>{title}</Heading>}
      body={
        <Paragraph>
          To manage upcoming zRetreats, visit the{' '}
          <InternalLink
            pathname={`/zretreat/${eventId ? eventId : ''}${isMock ? window.location.search : ''}`}
            toConfirmNavigation={isBookingInProgress}
          >
            <Anchor>zRetreats management tool</Anchor>
          </InternalLink>
        </Paragraph>
      }
    />
  );

  return (
    <Popper triggered={infoPopover} paddingY="xs">
      <Text fontType="bodySmall">
        <TriggerText>{text}</TriggerText>
      </Text>
    </Popper>
  );
};

export default ZRetreatPopover;
