import { UserState, UserAction, UserInboxMessage } from './types';
import { mockUser } from './mock';

export const initialState: UserState = {
  loaded: false,
  loggedIn: false,
  userInfo: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    title: '',
    photoUrl: '',
    username: '',
  },
  authErrorMessage: '',
  statusZGU: false,
  statusVACC: false,
  statusHATT: false,
  userInboxMessages: [] as UserInboxMessage[],
  canImpersonate: false,
  canMassUpload: false,
  canPlanZEvent: false,
};

export const userReducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case 'AUTHENTICATE_USER_SUCCESS':
      return {
        ...state,
        loggedIn: true,
      };
    case 'AUTHENTICATE_USER_ERROR':
      return {
        ...state,
        loggedIn: false,
        authErrorMessage: action.msg,
      };
    case 'LOAD_USER_SUCCESS':
      return {
        ...state,
        loggedIn: true, // for refresh
        loaded: true,
        userInfo: action.user,
        canImpersonate: action.canImpersonate,
        canMassUpload: action.canMassUpload,
        canPlanZEvent: action.canPlanZEvent,
      };
    case 'LOAD_USER_ERROR':
      return {
        ...state,
        loggedIn: false,
        loaded: true,
        authErrorMessage: action.msg,
      };
    case 'LOAD_MOCK_USER':
      return {
        ...state,
        loggedIn: true, // for refresh
        loaded: true,
        userInfo: mockUser,
        statusZGU: action.hasTraining,
        statusVACC: action.hasVaccine,
        canPlanZEvent: action.canPlanZEvent,
      };
    case 'GET_USER_TRAINING_STATUS_SUCCESS':
      return {
        ...state,
        statusZGU: action.isTrained,
      };
    case 'GET_USER_INBOX_MESSAGE_SUCCESS':
      return {
        ...state,
        userInboxMessages: action.userInboxMessages,
      };
    case 'ACKNOWLEDGE_USER_INBOX_MESSAGE_SUCCESS':
      return {
        ...state,
        userInboxMessages: [
          ...state.userInboxMessages.filter((message) => message.id !== action.messageId),
        ],
      };
    case 'GET_USER_VACCINATION_STATUS_SUCCESS':
      return {
        ...state,
        statusVACC: action.isVaccinated,
      };
    case 'GET_USER_HATT_STATUS_SUCCESS':
      return {
        ...state,
        statusHATT: action.hasHATT,
      };
    case 'LOGOUT_USER_SUCCESS':
    case 'RESET_USER':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
