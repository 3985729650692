/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from './google/protobuf/timestamp';

export const protobufPackage = 'peng_hqengine_contract';

export enum BookingType {
  BOOKING_TYPE_UNSPECIFIED = 0,
  BOOKING_TYPE_SINGLE_DAY = 1,
  BOOKING_TYPE_MULTI_DAY = 2,
  BOOKING_TYPE_RECURRING = 3,
  BOOKING_TYPE_BULK = 4,
  UNRECOGNIZED = -1,
}

export function bookingTypeFromJSON(object: any): BookingType {
  switch (object) {
    case 0:
    case 'BOOKING_TYPE_UNSPECIFIED':
      return BookingType.BOOKING_TYPE_UNSPECIFIED;
    case 1:
    case 'BOOKING_TYPE_SINGLE_DAY':
      return BookingType.BOOKING_TYPE_SINGLE_DAY;
    case 2:
    case 'BOOKING_TYPE_MULTI_DAY':
      return BookingType.BOOKING_TYPE_MULTI_DAY;
    case 3:
    case 'BOOKING_TYPE_RECURRING':
      return BookingType.BOOKING_TYPE_RECURRING;
    case 4:
    case 'BOOKING_TYPE_BULK':
      return BookingType.BOOKING_TYPE_BULK;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BookingType.UNRECOGNIZED;
  }
}

export function bookingTypeToJSON(object: BookingType): string {
  switch (object) {
    case BookingType.BOOKING_TYPE_UNSPECIFIED:
      return 'BOOKING_TYPE_UNSPECIFIED';
    case BookingType.BOOKING_TYPE_SINGLE_DAY:
      return 'BOOKING_TYPE_SINGLE_DAY';
    case BookingType.BOOKING_TYPE_MULTI_DAY:
      return 'BOOKING_TYPE_MULTI_DAY';
    case BookingType.BOOKING_TYPE_RECURRING:
      return 'BOOKING_TYPE_RECURRING';
    case BookingType.BOOKING_TYPE_BULK:
      return 'BOOKING_TYPE_BULK';
    default:
      return 'UNKNOWN';
  }
}

export enum RejectedBookingType {
  REJECTED_BOOKING_TYPE_UNSPECIFIED = 0,
  REJECTED_BOOKING_TYPE_PAST = 1,
  REJECTED_BOOKING_TYPE_WEEKEND = 2,
  REJECTED_BOOKING_TYPE_BLOCKED = 3,
  REJECTED_BOOKING_TYPE_OVERCAPACITY = 4,
  REJECTED_BOOKING_TYPE_DUPLICATE = 5,
  UNRECOGNIZED = -1,
}

export function rejectedBookingTypeFromJSON(object: any): RejectedBookingType {
  switch (object) {
    case 0:
    case 'REJECTED_BOOKING_TYPE_UNSPECIFIED':
      return RejectedBookingType.REJECTED_BOOKING_TYPE_UNSPECIFIED;
    case 1:
    case 'REJECTED_BOOKING_TYPE_PAST':
      return RejectedBookingType.REJECTED_BOOKING_TYPE_PAST;
    case 2:
    case 'REJECTED_BOOKING_TYPE_WEEKEND':
      return RejectedBookingType.REJECTED_BOOKING_TYPE_WEEKEND;
    case 3:
    case 'REJECTED_BOOKING_TYPE_BLOCKED':
      return RejectedBookingType.REJECTED_BOOKING_TYPE_BLOCKED;
    case 4:
    case 'REJECTED_BOOKING_TYPE_OVERCAPACITY':
      return RejectedBookingType.REJECTED_BOOKING_TYPE_OVERCAPACITY;
    case 5:
    case 'REJECTED_BOOKING_TYPE_DUPLICATE':
      return RejectedBookingType.REJECTED_BOOKING_TYPE_DUPLICATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RejectedBookingType.UNRECOGNIZED;
  }
}

export function rejectedBookingTypeToJSON(object: RejectedBookingType): string {
  switch (object) {
    case RejectedBookingType.REJECTED_BOOKING_TYPE_UNSPECIFIED:
      return 'REJECTED_BOOKING_TYPE_UNSPECIFIED';
    case RejectedBookingType.REJECTED_BOOKING_TYPE_PAST:
      return 'REJECTED_BOOKING_TYPE_PAST';
    case RejectedBookingType.REJECTED_BOOKING_TYPE_WEEKEND:
      return 'REJECTED_BOOKING_TYPE_WEEKEND';
    case RejectedBookingType.REJECTED_BOOKING_TYPE_BLOCKED:
      return 'REJECTED_BOOKING_TYPE_BLOCKED';
    case RejectedBookingType.REJECTED_BOOKING_TYPE_OVERCAPACITY:
      return 'REJECTED_BOOKING_TYPE_OVERCAPACITY';
    case RejectedBookingType.REJECTED_BOOKING_TYPE_DUPLICATE:
      return 'REJECTED_BOOKING_TYPE_DUPLICATE';
    default:
      return 'UNKNOWN';
  }
}

export enum AcknowledgeBookingResponseType {
  ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_UNSPECIFIED = 0,
  ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRM_SUCCEESS = 1,
  ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CANCEL_SUCCEESS = 2,
  ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_PAST = 3,
  ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_NOT_EXIST = 4,
  ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRMED_BEFORE = 5,
  UNRECOGNIZED = -1,
}

export function acknowledgeBookingResponseTypeFromJSON(
  object: any,
): AcknowledgeBookingResponseType {
  switch (object) {
    case 0:
    case 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_UNSPECIFIED':
      return AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_UNSPECIFIED;
    case 1:
    case 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRM_SUCCEESS':
      return AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRM_SUCCEESS;
    case 2:
    case 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CANCEL_SUCCEESS':
      return AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CANCEL_SUCCEESS;
    case 3:
    case 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_PAST':
      return AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_PAST;
    case 4:
    case 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_NOT_EXIST':
      return AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_NOT_EXIST;
    case 5:
    case 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRMED_BEFORE':
      return AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRMED_BEFORE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AcknowledgeBookingResponseType.UNRECOGNIZED;
  }
}

export function acknowledgeBookingResponseTypeToJSON(
  object: AcknowledgeBookingResponseType,
): string {
  switch (object) {
    case AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_UNSPECIFIED:
      return 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_UNSPECIFIED';
    case AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRM_SUCCEESS:
      return 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRM_SUCCEESS';
    case AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CANCEL_SUCCEESS:
      return 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CANCEL_SUCCEESS';
    case AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_PAST:
      return 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_PAST';
    case AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_NOT_EXIST:
      return 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_NOT_EXIST';
    case AcknowledgeBookingResponseType.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRMED_BEFORE:
      return 'ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRMED_BEFORE';
    default:
      return 'UNKNOWN';
  }
}

export interface BookingOfficeVisit {
  officeId: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  excludedDates: Date[];
  bookingType: BookingType;
}

export interface OfficeVisit {
  id: string;
  officeId: string;
  visitDate: Date | undefined;
}

export interface RejectedBooking {
  type: RejectedBookingType;
  visits: OfficeVisit[];
}

export interface OfficeUsage {
  officeId: string;
  date: Date | undefined;
  bookingCount: string;
}

const baseBookingOfficeVisit: object = { officeId: '0', bookingType: 0 };

export const BookingOfficeVisit = {
  encode(message: BookingOfficeVisit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.officeId !== '0') {
      writer.uint32(8).int64(message.officeId);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.excludedDates) {
      Timestamp.encode(toTimestamp(v!), writer.uint32(34).fork()).ldelim();
    }
    if (message.bookingType !== 0) {
      writer.uint32(40).int32(message.bookingType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingOfficeVisit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseBookingOfficeVisit } as BookingOfficeVisit;
    message.excludedDates = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.officeId = longToString(reader.int64() as Long);
          break;
        case 2:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.excludedDates.push(fromTimestamp(Timestamp.decode(reader, reader.uint32())));
          break;
        case 5:
          message.bookingType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BookingOfficeVisit {
    const message = { ...baseBookingOfficeVisit } as BookingOfficeVisit;
    message.excludedDates = [];
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = '0';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    if (object.excludedDates !== undefined && object.excludedDates !== null) {
      for (const e of object.excludedDates) {
        message.excludedDates.push(fromJsonTimestamp(e));
      }
    }
    if (object.bookingType !== undefined && object.bookingType !== null) {
      message.bookingType = bookingTypeFromJSON(object.bookingType);
    } else {
      message.bookingType = 0;
    }
    return message;
  },

  toJSON(message: BookingOfficeVisit): unknown {
    const obj: any = {};
    message.officeId !== undefined && (obj.officeId = message.officeId);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    if (message.excludedDates) {
      obj.excludedDates = message.excludedDates.map((e) => e.toISOString());
    } else {
      obj.excludedDates = [];
    }
    message.bookingType !== undefined && (obj.bookingType = bookingTypeToJSON(message.bookingType));
    return obj;
  },

  fromPartial(object: DeepPartial<BookingOfficeVisit>): BookingOfficeVisit {
    const message = { ...baseBookingOfficeVisit } as BookingOfficeVisit;
    message.excludedDates = [];
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = '0';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    if (object.excludedDates !== undefined && object.excludedDates !== null) {
      for (const e of object.excludedDates) {
        message.excludedDates.push(e);
      }
    }
    if (object.bookingType !== undefined && object.bookingType !== null) {
      message.bookingType = object.bookingType;
    } else {
      message.bookingType = 0;
    }
    return message;
  },
};

const baseOfficeVisit: object = { id: '0', officeId: '0' };

export const OfficeVisit = {
  encode(message: OfficeVisit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.officeId !== '0') {
      writer.uint32(16).int64(message.officeId);
    }
    if (message.visitDate !== undefined) {
      Timestamp.encode(toTimestamp(message.visitDate), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfficeVisit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOfficeVisit } as OfficeVisit;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.officeId = longToString(reader.int64() as Long);
          break;
        case 3:
          message.visitDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OfficeVisit {
    const message = { ...baseOfficeVisit } as OfficeVisit;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = '0';
    }
    if (object.visitDate !== undefined && object.visitDate !== null) {
      message.visitDate = fromJsonTimestamp(object.visitDate);
    } else {
      message.visitDate = undefined;
    }
    return message;
  },

  toJSON(message: OfficeVisit): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.officeId !== undefined && (obj.officeId = message.officeId);
    message.visitDate !== undefined && (obj.visitDate = message.visitDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<OfficeVisit>): OfficeVisit {
    const message = { ...baseOfficeVisit } as OfficeVisit;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = '0';
    }
    if (object.visitDate !== undefined && object.visitDate !== null) {
      message.visitDate = object.visitDate;
    } else {
      message.visitDate = undefined;
    }
    return message;
  },
};

const baseRejectedBooking: object = { type: 0 };

export const RejectedBooking = {
  encode(message: RejectedBooking, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    for (const v of message.visits) {
      OfficeVisit.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RejectedBooking {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRejectedBooking } as RejectedBooking;
    message.visits = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.int32() as any;
          break;
        case 2:
          message.visits.push(OfficeVisit.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RejectedBooking {
    const message = { ...baseRejectedBooking } as RejectedBooking;
    message.visits = [];
    if (object.type !== undefined && object.type !== null) {
      message.type = rejectedBookingTypeFromJSON(object.type);
    } else {
      message.type = 0;
    }
    if (object.visits !== undefined && object.visits !== null) {
      for (const e of object.visits) {
        message.visits.push(OfficeVisit.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: RejectedBooking): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = rejectedBookingTypeToJSON(message.type));
    if (message.visits) {
      obj.visits = message.visits.map((e) => (e ? OfficeVisit.toJSON(e) : undefined));
    } else {
      obj.visits = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<RejectedBooking>): RejectedBooking {
    const message = { ...baseRejectedBooking } as RejectedBooking;
    message.visits = [];
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    } else {
      message.type = 0;
    }
    if (object.visits !== undefined && object.visits !== null) {
      for (const e of object.visits) {
        message.visits.push(OfficeVisit.fromPartial(e));
      }
    }
    return message;
  },
};

const baseOfficeUsage: object = { officeId: '0', bookingCount: '0' };

export const OfficeUsage = {
  encode(message: OfficeUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.officeId !== '0') {
      writer.uint32(8).int64(message.officeId);
    }
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(18).fork()).ldelim();
    }
    if (message.bookingCount !== '0') {
      writer.uint32(24).int64(message.bookingCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfficeUsage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOfficeUsage } as OfficeUsage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.officeId = longToString(reader.int64() as Long);
          break;
        case 2:
          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.bookingCount = longToString(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OfficeUsage {
    const message = { ...baseOfficeUsage } as OfficeUsage;
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = '0';
    }
    if (object.date !== undefined && object.date !== null) {
      message.date = fromJsonTimestamp(object.date);
    } else {
      message.date = undefined;
    }
    if (object.bookingCount !== undefined && object.bookingCount !== null) {
      message.bookingCount = String(object.bookingCount);
    } else {
      message.bookingCount = '0';
    }
    return message;
  },

  toJSON(message: OfficeUsage): unknown {
    const obj: any = {};
    message.officeId !== undefined && (obj.officeId = message.officeId);
    message.date !== undefined && (obj.date = message.date.toISOString());
    message.bookingCount !== undefined && (obj.bookingCount = message.bookingCount);
    return obj;
  },

  fromPartial(object: DeepPartial<OfficeUsage>): OfficeUsage {
    const message = { ...baseOfficeUsage } as OfficeUsage;
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = '0';
    }
    if (object.date !== undefined && object.date !== null) {
      message.date = object.date;
    } else {
      message.date = undefined;
    }
    if (object.bookingCount !== undefined && object.bookingCount !== null) {
      message.bookingCount = object.bookingCount;
    } else {
      message.bookingCount = '0';
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = Number(t.seconds) * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
