import React, { useEffect, useState } from 'react';
import { Avatar, Image } from '@zillow/constellation';
import styled from 'styled-components';

export interface AvatarDiskProps {
  /** person's full name */
  fullName?: string;
  /** disk size */
  size?: string;
  /** person's photo url */
  photoUrl?: string;
  /** shape */
  shape?: 'circle' | 'square';
}

const AvatarWrapper = styled(Avatar)<{ shape: 'circle' | 'square' }>`
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '0')};
`;

export const AvatarDisk: React.FC<AvatarDiskProps> = ({
  fullName,
  size,
  photoUrl,
  shape,
}: AvatarDiskProps) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [photoUrl]);

  return (
    <AvatarWrapper data-testid='render-avatar-disk-test' fullName={fullName} size={size} shape={shape}>
      {!photoUrl || hasError ? undefined : (
        <Image data-testid='render-avatar-img-test' alt={fullName} src={photoUrl} onError={() => setHasError(true)} />
      )}
    </AvatarWrapper>
  );
};

AvatarDisk.defaultProps = {
  shape: 'circle',
};
