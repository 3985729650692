import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AckReservationContainerProps } from './AckReservationContainer';
import {
  Reservation,
  AckReservationResultType,
  AckReservationResultTypeValue,
  AckReservationResult,
} from '../../store/office';

export const AckReservationContainerLogic = ({ ackReservation }: AckReservationContainerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [officeName, setOfficeName] = useState<string | undefined>(undefined);
  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [resultType, setResultType] = useState<AckReservationResultType>(
    AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_UNSPECIFIED,
  );
  
  const res = new URLSearchParams(useLocation().search);
  const token = res.get('t') || '';

  useEffect(() => {
    if (!isLoaded) {
      if (!token) {
        setResultType(AckReservationResultTypeValue.UNRECOGNIZED);
        setIsLoaded(true);
      } else {
        ackReservation(token)
          .then((resp: AckReservationResult) => {
            setOfficeName(resp.officeName);
            setReservation(resp.reservation);
            setResultType(resp.resultType);
          })
          .catch(() => setResultType(AckReservationResultTypeValue.UNRECOGNIZED))
          .finally(() => setIsLoaded(true));
      }
    }
  }, [isLoaded]);

  return {
    isLoaded,
    officeName,
    reservation,
    resultType,
  };
};

// mock for testing
export const MockAckReservationContainerLogic = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [officeName, setOfficeName] = useState<string | undefined>(undefined);
  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [resultType, setResultType] = useState<AckReservationResultType>(
    AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_UNSPECIFIED,
  );

  const res = new URLSearchParams(useLocation().search);
  const token = res.get('t') || '';

  useEffect(() => {
    if (!isLoaded) {
      if (!token) {
        setResultType(AckReservationResultTypeValue.UNRECOGNIZED);
        setIsLoaded(true);
      } else {
        setOfficeName('[Office Name]');
        setReservation({
          id: '99',
          officeId: '1',
          visitDate: new Date(),
        });
        setResultType(
          AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRM_SUCCEESS,
        );
        setIsLoaded(true);
      }
    }
  }, [isLoaded]);

  return {
    isLoaded,
    officeName,
    reservation,
    resultType,
  };
};
