import { useCallback, useState } from 'react';
import { DisplayError, ErrorType } from '../../store/errorHelpers';
import { EmployeeFound, SearchEmployeesTypeValue } from '../../store/search';
import { ImpersonationContainerProps } from './ImpersonationContainer';
import { SearchOption, employeeFoundToSearchOption } from '../../components/Searchbox';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import { debounce } from 'lodash';

export const ImpersonationContainerLogic = ({
  searchEmployees,
  impersonateUser,
  stopImpersonation,
  setIsImpersonationModalOpen,
}: ImpersonationContainerProps) => {
  const [searchInput, setSearchInput] = useState('');
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SearchOption | undefined>(undefined);
  const [isRequestingImpersonation, setIsRequestingImpersonation] = useState(false);
  const [isStoppingImpersonation, setIsStoppingImpersonation] = useState(false);
  const [startImpersonationErr, setStartImpersonationErr] = useState(ErrorType.NONE);

  const debounceSearch = useCallback(
    debounce((keyword: string) => {
      return searchEmployees(keyword, SearchEmployeesTypeValue.SEARCH_EMPLOYEES_TYPE_IMPERSONATION)
        .then((employees: EmployeeFound[]) => {
          setSearchOptions(employeeFoundToSearchOption(employees));
        })
        .finally(() => setIsLoadingOptions(false));
    }, 500),
    [],
  );

  const handleOnInputChange = useCallback((keyword: string) => {
    setSearchInput(keyword);

    if (keyword.length > 1) {
      setIsLoadingOptions(true);
      debounceSearch(keyword);
      return () => debounceSearch.cancel();
    } else {
      setSearchOptions([]);
    }
  }, []);

  const handleSelectOption = (option: SearchOption) => {
    setSelectedOption(option);
    setSearchInput('');
  };

  const handleResetSelectedOption = () => {
    setSelectedOption(undefined);
    setStartImpersonationErr(ErrorType.NONE);
  };

  const handleStartImpersonation = useCallback(() => {
    if (!selectedOption) return;
    setStartImpersonationErr(ErrorType.NONE);
    setIsRequestingImpersonation(true);

    impersonateUser(selectedOption.id)
      .then(() => location.reload())
      .catch((err: DisplayError) => setStartImpersonationErr(err.code))
      .finally(() => setIsRequestingImpersonation(false));
    GoogleAnalyticsUtils.ClickStartImpersonation();
  }, [selectedOption, impersonateUser]);

  const handleStopImpersonation = () => {
    setIsStoppingImpersonation(true);
    stopImpersonation()
      .then(() => location.reload())
      .catch((err: DisplayError) => {
        // session expired, reload anyway to re-login
        if (err.code === ErrorType.HTTP_STATUS_401) {
          location.reload();
        }
      })
      .finally(() => setIsStoppingImpersonation(false));
    GoogleAnalyticsUtils.ClickStopImpersonation();
  };

  const handleImpersonateClick = () => {
    setIsImpersonationModalOpen(true);
    GoogleAnalyticsUtils.ClickImpersonateOnBanner();
  };

  const handleCloseModal = () => {
    setIsImpersonationModalOpen(false);
    setSelectedOption(undefined);
    setStartImpersonationErr(ErrorType.NONE);
    setSearchOptions([]);
  };

  return {
    searchInput,
    isLoadingOptions,
    searchOptions,
    selectedOption,
    isRequestingImpersonation,
    isStoppingImpersonation,
    startImpersonationErr,
    handleOnInputChange,
    handleSelectOption,
    handleResetSelectedOption,
    handleStartImpersonation,
    handleStopImpersonation,
    handleImpersonateClick,
    handleCloseModal,
  };
};
