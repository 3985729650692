import { useState, useEffect, useCallback } from 'react';
import {
  MassUploadReportsModalContainerProps,
  SortDirectionType,
} from './MassUploadReportsModalContainer';
import { MassUploadReport } from '../../../store/office';

export const MassUploadReportsModalContainerLogic = ({
  history,
  isOpen,
  onClose,
  getAllMassUploadReports,
}: MassUploadReportsModalContainerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [reports, setReports] = useState<MassUploadReport[] | undefined>(undefined);
  const [sortedReports, setSortedReports] = useState<MassUploadReport[]>([]);
  const [loadReportsErr, setLoadReportsErr] = useState(false);

  const onSortCallback = useCallback(
    (nextSortDirection: SortDirectionType) => {
      if (!reports) return;

      setSortedReports(
        [...reports].sort((a, b) =>
          a.uploadedDate && b.uploadedDate
            ? nextSortDirection === 'ascending'
              ? a.uploadedDate.getTime() - b.uploadedDate.getTime()
              : b.uploadedDate.getTime() - a.uploadedDate.getTime()
            : 0,
        ),
      );
    },
    [reports],
  );

  const handleReportClick = (reportID: string) => {
    onClose();
    history.push(`/upload/report/${reportID}`);
  };

  /** load reports when modal opens */
  useEffect(() => {
    if (!isLoaded && isOpen) {
      getAllMassUploadReports()
        .then((report: MassUploadReport[] | undefined) => setReports(report))
        .catch((err) => setLoadReportsErr(err.message))
        .finally(() => setIsLoaded(true));
    }
  }, [isLoaded, isOpen]);

  /** sort reports when reports change */
  useEffect(() => {
    onSortCallback('ascending');
  }, [reports]);

  /** reset reports when modal closes */
  useEffect(() => {
    if (!isOpen) {
      setIsLoaded(false);
      setLoadReportsErr(false);
    }
  }, [isOpen]);

  return {
    isLoaded,
    reports,
    sortedReports,
    onSortCallback,
    handleReportClick,
    loadReportsErr,
  };
};
