import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeConstellation, ToastProvider } from '@zillow/constellation';
import './index.css';
import HQApp from './pages/HQApp';
import HQInternalApp from './pages/HQInternalApp';
import HQLandingPages from './pages/HQLandingPages';
import configureStore from './store/configureStore';
import reportWebVitals from './reportWebVitals';
import DynamicConfig from './config/DynamicConfig';

const store = configureStore();

// https://reactrouter.com/web/guides/scroll-restoration
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const Apps: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/internal" component={HQInternalApp} />
      <Route path="/lp" component={HQLandingPages} />
      <Route path="/" component={HQApp} />
    </Switch>
  );
};

DynamicConfig.InitConfig().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={ThemeConstellation}>
        <Provider store={store}>
          <ToastProvider placement="bottom">
            <BrowserRouter>
              <ScrollToTop />
              <Apps />
            </BrowserRouter>
          </ToastProvider>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
