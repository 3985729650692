import { StatusType } from '../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';

export interface HQEngineErrorResponse {
  code: StatusType;
  message: string;
}

export interface DisplayError {
  code: ErrorType;
  message: string;
}

export enum ErrorType {
  NONE = 0,
  UNAUTHORIZED_USER = 1,
  NOT_FOUND = 2,
  HTTP_STATUS_401 = 3,
  HTTP_STATUS_403 = 4,
  HTTP_STATUS_404 = 5,
  HTTP_STATUS_503 = 6,
  OTHER = 7,
}

export const GetErrorStatusType = (status: StatusType): StatusType => {
  if (status === StatusType.STATUS_TYPE_SUCCESS) {
    return StatusType.UNRECOGNIZED;
  }
  return status;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GetDisplayError = (err: any): DisplayError => {
  // if in StatusType form (caught from then block)
  if (err.code && err.message) {
    switch (err.code) {
      case StatusType.STATUS_TYPE_UNAUTHORIZED:
        return {
          code: ErrorType.UNAUTHORIZED_USER,
          message: 'Not Authorized',
        };
      case StatusType.STATUS_TYPE_NOT_FOUND:
        return {
          code: ErrorType.NOT_FOUND,
          message: 'Not Found',
        };
      default:
        return {
          code: ErrorType.OTHER,
          message: err.message,
        };
    }
  }

  // HTTP status codes
  if (err.response) {
    switch (err.response.status) {
      case 401:
        return {
          code: ErrorType.HTTP_STATUS_401,
          message: 'HTTP status 401',
        };
      case 403:
        return {
          code: ErrorType.HTTP_STATUS_403,
          message: 'HTTP status 403',
        };
      case 404:
        return {
          code: ErrorType.HTTP_STATUS_404,
          message: 'HTTP status 404',
        };
      case 503:
        return {
          code: ErrorType.HTTP_STATUS_503,
          message: 'HTTP status 503',
        };
    }
  }

  // parse fields from response body
  if (err.response && err.response.data) {
    const resp =
      err.response.data instanceof ArrayBuffer
        ? decodeBuffer(err.response.data)
        : err.response.data;
    if (resp.status === StatusType.STATUS_TYPE_UNAUTHORIZED) {
      return {
        code: ErrorType.UNAUTHORIZED_USER,
        message: 'Not Authorized',
      };
    }
  }

  return {
    code: ErrorType.OTHER,
    message: err.message,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const decodeBuffer = (buf: ArrayBuffer): any => {
  return JSON.parse(new TextDecoder().decode(buf));
};
