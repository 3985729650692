import React from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorType } from '../../store/errorHelpers';
import { Page503Error } from '../PageError';

/**
 * ErrorHandler component from
 * https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
 */

export interface ErrorHandlerProps {
  children: React.ReactNode;
}

export type ErrorHandlerContextType = {
  setErrorStatusCode: React.Dispatch<React.SetStateAction<ErrorType>>;
};

const ErrorStatusContext = React.createContext({} as ErrorHandlerContextType);

export const ErrorHandler = ({ children }: ErrorHandlerProps) => {
  const history = useHistory();
  const [errorStatusCode, setErrorStatusCode] = React.useState(ErrorType.NONE);

  // reset this status code whenever the user navigates to a new URL
  React.useEffect(() => {
    // Listen for changes to the current location.
    const unlisten = history.listen(() => setErrorStatusCode(ErrorType.NONE));
    // cleanup the listener on unmount
    return unlisten;
  }, []);

  const renderContent = () => {
    if (
      errorStatusCode === ErrorType.HTTP_STATUS_503 ||
      errorStatusCode === ErrorType.HTTP_STATUS_404
    ) {
      return <Page503Error />;
    }
    return children;
  };

  const contextPayload = React.useMemo(() => ({ setErrorStatusCode }), [setErrorStatusCode]);

  return (
    <ErrorStatusContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorStatusContext.Provider>
  );
};

export const useErrorStatus = () => React.useContext(ErrorStatusContext);
