import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  HQWebClientImpl,
  SearchEmployeesRequest,
  SearchEmployeesResponse,
  SearchEmployeesRequest_SearchEmployeesType,
} from '../contract/hqengine_hqweb';
import { RpcImpl } from '../contract/rpc';
import { EmployeeFound as EmployeeFoundContract } from '../contract/hqengine_user';
import { StatusType } from '../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { HQEngineErrorResponse, GetErrorStatusType, GetDisplayError } from './errorHelpers';
import DynamicConfig from '../config/DynamicConfig';

export type EmployeeFound = EmployeeFoundContract;
export type SearchEmployeesType = SearchEmployeesRequest_SearchEmployeesType;
export const SearchEmployeesTypeValue = SearchEmployeesRequest_SearchEmployeesType;

export const searchEmployees =
  (
    keyword: string,
    searchType?: SearchEmployeesType,
  ): ThunkAction<Promise<EmployeeFound[]>, null, null, Action> =>
  async (): Promise<EmployeeFound[]> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: SearchEmployeesRequest = {
      keyword: keyword,
      searchType:
        searchType || SearchEmployeesRequest_SearchEmployeesType.SEARCH_EMPLOYEES_TYPE_UNSPECIFIED,

    };

    return client
      .SearchEmployees(req)
      .then((resp: SearchEmployeesResponse) => {
        const [result, err] = validateSearchEmployeesResp(resp);
        if (
          err.code === StatusType.STATUS_TYPE_SUCCESS ||
          err.code === StatusType.STATUS_TYPE_NOT_FOUND
        ) {
          return Promise.resolve(result);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const error = GetDisplayError(err);
        return Promise.reject(error);
      });
  };

export const mockSearchEmployees =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_keyword: string): Promise<EmployeeFound[]> => {
    return Promise.resolve(mockEmployeesFound);
  };

export const validateSearchEmployeesResp = (
  resp: SearchEmployeesResponse,
): [EmployeeFound[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.employees || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.employees, errResp];
};

export const mockEmployeesFound: EmployeeFound[] = [
  {
    id: 'juliaolson',
    fullName: 'Julia Olson',
    title: '',
    photoUrl: '',
    email: 'juliao@zillowgroup.com',
  },
  {
    id: 'annacasciari',
    fullName: 'Anna Casciari',
    title: '',
    photoUrl: '',
    email: 'annac@zillowgroup.com',
  },
  {
    id: 'kristenfowler',
    fullName: 'Kristen Fowler',
    title: '',
    photoUrl: '',
    email: 'kristenf@zillowgroup.com',
  },
  {
    id: 'richbarton',
    fullName: 'Rich Barton',
    title: '',
    photoUrl: '',
    email: 'rich@zillowgroup.com',
  },
  {
    id: 'tobyroberts',
    fullName: 'Toby Roberts',
    title: '',
    photoUrl: '',
    email: 'tobyro@zillowgroup.com',
  },
];
