import React from 'react';
import { PageError } from '../../components/PageError';
import errorLostImage from '../../assets/errorLost.png';

export const FloorPlanError: React.FC = () => {
  return (
    <PageError
      title={'Floor unavailable'}
      description={<>Try selecting an office and floor using the dropdown options only.</>}
      imageSrc={errorLostImage}
    />
  );
};
