import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import {
  Anchor,
  Heading,
  Paragraph,
  Flex,
  Spacer,
  Button,
  TextButton,
  LoadingButton,
  ButtonGroup,
  Label,
  Input,
  Alert,
  IconCloseCircle,
  IconSuccess,
  IconWarning,
} from '@zillow/constellation';
import { MassUploadModalContainerLogic } from './MassUploadModalContainer.hooks';
import {
  bookOfficeVisitsBulk,
  MassUploadReportStatusType,
  MassUploadReportStatusValue,
} from '../../../store/office';
import { Modal } from '../../../components/Modal';
import { Loader } from '../../../components/Loader';

interface StateProps {
  /** upload mass booking file action */
  bookOfficeVisitsBulk: (
    file: File,
  ) => Promise<{ reportID: string; result: MassUploadReportStatusType }>;
}

interface OwnProps {
  /** if modal is open */
  isOpen: boolean;
  /** function to handle close */
  onClose: () => void;
}

export type MassUploadModalContainerProps = StateProps & OwnProps & RouteComponentProps;

const ModalBodyFlexWrapper = styled(Flex)`
  min-height: 180px;
`;

const MassUploadModalContainer: React.FC<MassUploadModalContainerProps> = (
  props: MassUploadModalContainerProps,
) => {
  const { isOpen, history } = props;
  const {
    inputRef,
    file,
    reportID,
    reportStatus,
    errorMessage,
    isUploading,
    handleUploadClick,
    handleFileChange,
    handleFileSubmit,
    handleClose,
    handleReset,
  } = MassUploadModalContainerLogic(props);

  const allBookingSuccessMessage = (
    <Flex margin="xs">
      <IconSuccess fontColor="green500" size="md" />
      <Heading level={4} marginY="xs" fontFamily="serif">
        Successfully completed!
      </Heading>
      <Paragraph>
        All employees have successfully booked day passes for the selected offices and days.
      </Paragraph>
    </Flex>
  );

  const completedWithErrorsMessage = (
    <Flex margin="xs">
      <IconWarning fontColor="orange400" size="md" />
      <Heading level={4} marginY="xs" fontFamily="serif">
        Completed with errors
      </Heading>
      <Paragraph>
        The file was successfully uploaded but there are one or more instances where a day pass is
        unavailable. Please click to view or download the below error report for more details.
      </Paragraph>
      <Spacer margin="xs" />
      <TextButton
        onClick={() => {
          handleClose();
          history.push(`/upload/report/${reportID}`);
        }}
      >
        View Report
      </TextButton>
    </Flex>
  );

  const cannotProcessFileMessage = (
    <Flex margin="xs">
      <Heading level={4} fontFamily="serif">
        Cannot process file
      </Heading>
      <Spacer margin="xs" />
      <Paragraph>We were unable to process your request.</Paragraph>
      <Spacer marginY="sm">
        {errorMessage && <Alert appearance="error" body={errorMessage} />}
      </Spacer>
      <Paragraph>
        Please verify your file and try again, or reach out to{' '}
        <Anchor
          href={`mailto:peopleengineering@zillowgroup.com?subject=Mass Upload Support Needed`}
        >
          People Engineering
        </Anchor>{' '}
        for further assistance.
      </Paragraph>
    </Flex>
  );

  return (
    <Modal
      fullScreen={{ sm_lte: true }}
      header={<Heading data-testid="render-mass-upload-modal" level={6}>Mass Upload</Heading>}
      body={
        <ModalBodyFlexWrapper display="flex" justifyContent="center" alignItems="center">
          <Loader loading={isUploading}>
            {(reportID &&
              (reportStatus ===
                MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_PARTIAL_ERROR ||
                reportStatus === MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_ALL_ERROR) &&
              completedWithErrorsMessage) ||
              (reportStatus === MassUploadReportStatusValue.MASS_UPLOAD_REPORT_STATUS_SUCCESS &&
                allBookingSuccessMessage) ||
              (errorMessage && cannotProcessFileMessage) || (
                <Flex margin="xs">
                  <Paragraph data-testid="render-mass-upload-modal-upload-file-text">
                    Please select a CSV file for mass upload below and then click submit. The file
                    should include columns for employee email, employee name, office, start date,
                    and end date.
                  </Paragraph>
                  {file ? (
                    <Flex display="flex" justifyContent="space-between" marginY="md">
                      <Paragraph as="b">{file.name}</Paragraph>
                      <IconCloseCircle
                        onClick={handleReset}
                        fontColor="red500"
                        size="sm"
                        role="button"
                      />
                    </Flex>
                  ) : (
                    <Flex display="flex" justifyContent="center" marginY="md">
                      <Input
                        ref={inputRef}
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        id="upload-file-input"
                      />
                      <Label htmlFor="upload-file-input">
                        <Button data-testid='upload-button' buttonType="primary" onClick={handleUploadClick}>
                          Upload
                        </Button>
                      </Label>
                    </Flex>
                  )}
                </Flex>
              )}
          </Loader>
        </ModalBodyFlexWrapper>
      }
      footer={
        !reportID && (
          <ButtonGroup aria-label="mass upload buttons">
            <TextButton data-testid="cancel-button" onClick={handleClose}>Cancel</TextButton>
            {errorMessage ? (
              <Button buttonType="primary" onClick={handleReset}>
                Try Again
              </Button>
            ) : (
              <LoadingButton data-testid="submit-button"
                buttonType="primary"
                onClick={handleFileSubmit}
                disabled={!file}
                loading={isUploading}
              >
                Submit
              </LoadingButton>
            )}
          </ButtonGroup>
        )
      }
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnOutsideClick={false}
    />
  );
};

const mapDispatchToProps = {
  bookOfficeVisitsBulk: bookOfficeVisitsBulk,
};

type DispatchToPropsType = typeof mapDispatchToProps;

export default withRouter(
  connect<null, DispatchToPropsType, unknown>(null, mapDispatchToProps)(MassUploadModalContainer),
);
