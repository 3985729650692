/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  spaceMixin,
  mediaBreakpointMixin,
  Flex,
  ToggleButtonGroup,
  ToggleButton,
  InlineFeedback,
  Alert,
} from '@zillow/constellation';
import { RootState } from '../../store';
import {
  Office,
  OfficeBooking,
  Reservation,
  selectOfficeMap,
  bookOfficeVisit,
  selectOfficeBlockedDatesMap,
  selectReservations,
  BookOfficeVisitPromiseResponse,
  selectBookingType,
  BookingType,
  updateBookingType,
} from '../../store/office';
import { IsMockContext } from '../../middleware/auth';
import { addDays } from 'date-fns';
import { BookingContainerLogic, MockBookingContainerLogic } from './BookingContainer.hooks';
import OneTimeBookingContainer from '../OneTimeBookingContainer/OneTimeBookingContainer';
import RecurringBookingContainer from '../RecurringBookingContainer/RecurringBookingContainer';
import { BookingConfirmationModal } from './BookingConfirmationModal';
import { BookingErrorModal, PartialBookingWarningModal } from './BookingErrorModals';
import { DateUtils } from '../../utilities/dateutils';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';

// up to 90 days advanced booking
export const MAX_BOOKING_DATE = addDays(new Date(), 90);

// what date string will be validated against, i.e. 9/20/2021, defined in https://date-fns.org/docs/format
export const DATE_FORMAT = 'M/dd/yyyy';

interface StateProps {
  /** office blocked dates */
  blockedDates: Map<string, Date[]>;
  /** booking request */
  submitBooking: (booking: OfficeBooking) => Promise<BookOfficeVisitPromiseResponse>;
  /** list of reservations */
  reservations: Reservation[];
  /** list of offices */
  officeMap: Map<string, Office>;
  /** booking type */
  bookingType: BookingType;
  /** update bookingType */
  updateBookingType: (value: BookingType) => void;
}

interface OwnProps {
  /** office selected */
  targetOffice: Office;
  /** office fully booked dates */
  fullyBookedDates: Date[];
  /** setter for fully booked dates */
  setFullyBookedDates: React.Dispatch<React.SetStateAction<Date[]>>;
  /** setter for CTA component - mobile only */
  setMobileCTA?: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  /** setter for is modal open - mobile only */
  setIsMobileBookingModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export type BookingContainerProps = StateProps & OwnProps;

const BookingMessagingWrapper = styled(Flex)`
  @media ${mediaBreakpointMixin('lg')} {
    min-height: ${spaceMixin('lg')};
  }
`;

const BookingContainer: React.FC<BookingContainerProps> = (props: BookingContainerProps) => {
  const isMock = React.useContext(IsMockContext);
  const {
    targetOffice,
    officeMap,
    setMobileCTA,
    setIsMobileBookingModalOpen,
    bookingType,
    updateBookingType,
  } = props;
  const {
    isConfirmed,
    setIsConfirmed,
    isBlocked,
    isAlreadyBooked,
    isDateDisabled,
    handleSubmitBooking,
    hasSubmitError,
    excludedDates,
    sameDayReservationsByOffice,
    updateExcludedDates,
    updateSameDayReservations,
    hasUnexpectedError,
    setHasUnexpectedError,
    hasUnexpectedWarning,
    setHasUnexpectedWarning,
    unexpectedConflicts,
    setUnexpectedConflicts,
    onContinueBooking,
    setOnContinueBooking,
    selectedToggleButton,
    isWeekendSelected,
    setIsWeekendSelected,
  } = isMock ? MockBookingContainerLogic(props) : BookingContainerLogic(props);

  return (
    <>
      <ToggleButtonGroup
        value={selectedToggleButton}
        onChange={(value: string) => {
          updateBookingType(parseInt(value));
          GoogleAnalyticsUtils.ToggleBookingType(BookingType[parseInt(value)]);
        }}
        aria-label="one time and recurring toggle button group"
        size="md"
        fluid
        marginBottom="md"
      >
        <ToggleButton data-testid="one-time-button" value={BookingType.BOOKING_TYPE_SINGLE_DAY.toString()}>One time</ToggleButton>
        <ToggleButton data-testid="recurring-button" value={BookingType.BOOKING_TYPE_RECURRING.toString()}>Recurring</ToggleButton>
      </ToggleButtonGroup>
      {bookingType === BookingType.BOOKING_TYPE_SINGLE_DAY ||
      bookingType === BookingType.BOOKING_TYPE_MULTI_DAY ? (
        <OneTimeBookingContainer
          {...{
            targetOffice,
            isBlocked,
            isAlreadyBooked,
            isDateDisabled,
            updateExcludedDates,
            updateSameDayReservations,
            handleSubmitBooking,
            setMobileCTA,
            setHasUnexpectedError,
            setHasUnexpectedWarning,
            setUnexpectedConflicts,
            setOnContinueBooking,
            setIsWeekendSelected,
          }}
        />
      ) : (
        <RecurringBookingContainer
          {...{
            targetOffice,
            isBlocked,
            isAlreadyBooked,
            isDateDisabled,
            updateExcludedDates,
            updateSameDayReservations,
            handleSubmitBooking,
            setMobileCTA,
            setHasUnexpectedError,
            setHasUnexpectedWarning,
            setUnexpectedConflicts,
            setOnContinueBooking,
            setIsWeekendSelected,
          }}
        />
      )}
      <BookingMessagingWrapper>
        {excludedDates.length > 0 && (
          <Flex marginTop="md">
            <InlineFeedback>
              The following dates will not be included in your booking due to office closures or
              capacity constraints:{' '}
              {excludedDates
                .sort((a, b) => a.getTime() - b.getTime())
                .map((d) => `${DateUtils.DateToUSLocaleStr(d)}`)
                .join(', ')}
              .
            </InlineFeedback>
          </Flex>
        )}

        {isWeekendSelected && (
          <Flex marginTop="md">
            <InlineFeedback>Heads up! You&apos;ve booked over a weekend.</InlineFeedback>
          </Flex>
        )}

        {Object.keys(sameDayReservationsByOffice).length === 1 && (
          <Flex marginTop="md">
            <InlineFeedback>
              Heads up! You already have a booking in the{' '}
              {Object.keys(sameDayReservationsByOffice).map(
                (officeId) =>
                  `${officeMap.get(officeId)?.name} office on ${sameDayReservationsByOffice[
                    officeId
                  ]
                    .map((res) => res.visitDate)
                    .filter((d) => d !== undefined)
                    .map((d) => DateUtils.DateToUSLocaleStr(d!))
                    .join(', ')}`,
              )}
              .
            </InlineFeedback>
          </Flex>
        )}

        {Object.keys(sameDayReservationsByOffice).length > 1 && (
          <Flex marginTop="md">
            <InlineFeedback>
              Heads up! You already have the following bookings -{' '}
              {Object.keys(sameDayReservationsByOffice)
                .map(
                  (officeId) =>
                    `${officeMap.get(officeId)?.name} office (${sameDayReservationsByOffice[
                      officeId
                    ]
                      .map((res) => res.visitDate)
                      .filter((d) => d !== undefined)
                      .map((d) => DateUtils.DateToUSLocaleStr(d!))
                      .join(', ')})`,
                )
                .reduce(
                  (prev, curr, idx, arr) =>
                    `${prev}${idx < arr.length - 1 ? ', ' : ', and '}${curr}`,
                )}
              .
            </InlineFeedback>
          </Flex>
        )}

        {hasSubmitError && (
          <Flex marginTop="sm">
            <Alert appearance="error" body="Something went wrong. Please try again later." />
          </Flex>
        )}
      </BookingMessagingWrapper>
      <BookingConfirmationModal
        isOpen={isConfirmed}
        onClose={() => {
          setIsConfirmed(false);
          if (setIsMobileBookingModalOpen) setIsMobileBookingModalOpen(false);
        }}
      />
      <BookingErrorModal
        isOpen={hasUnexpectedError}
        onClose={() => {
          setHasUnexpectedError(false);
        }}
        targetOffice={targetOffice}
        unexpectedConflicts={unexpectedConflicts}
      />
      <PartialBookingWarningModal
        isOpen={hasUnexpectedWarning}
        onClose={() => {
          setHasUnexpectedWarning(false);
        }}
        targetOffice={targetOffice}
        unexpectedConflicts={unexpectedConflicts}
        onContinueBooking={onContinueBooking}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  officeMap: selectOfficeMap(state),
  blockedDates: selectOfficeBlockedDatesMap(state),
  reservations: selectReservations(state),
  bookingType: selectBookingType(state),
});

const mapDispatchToProps = {
  submitBooking: bookOfficeVisit,
  updateBookingType: updateBookingType,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(BookingContainer);
