import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BookingContainerProps } from './BookingContainer';
import { Reservation, mockBookOfficeVisitSuccess, BookingType } from '../../store/office';
import { isSameDay, isBefore, isAfter } from 'date-fns';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';

export const BookingContainerLogic = ({
  targetOffice,
  blockedDates,
  fullyBookedDates,
  setFullyBookedDates,
  reservations,
  submitBooking,
  bookingType,
}: BookingContainerProps) => {
  const [excludedDates, setExcludedDates] = useState([] as Date[]);
  const [sameDayReservationsByOffice, setSameDayReservationsByOffice] = useState(
    {} as { [key: string]: Reservation[] },
  );
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [hasUnexpectedError, setHasUnexpectedError] = useState(false);
  const [hasUnexpectedWarning, setHasUnexpectedWarning] = useState(false);
  const [unexpectedConflicts, setUnexpectedConflicts] = useState([] as Date[]);
  const [onContinueBooking, setOnContinueBooking] = useState({} as () => () => void);
  const [isWeekendSelected, setIsWeekendSelected] = useState(false);

  /**
   * a date is blocked
   * if the selected office is blocked for office visits that day
   * or it is already fully booked
   */
  const isBlocked = useCallback(
    (date: Date): boolean =>
      (targetOffice && blockedDates.get(targetOffice.id)?.some((d) => isSameDay(date, d))) ||
      fullyBookedDates.some((d) => isSameDay(date, d)),
    [targetOffice, blockedDates, fullyBookedDates],
  );

  /** check if the date of the office has already been booked by user */
  const isAlreadyBooked = useCallback(
    (date: Date): boolean =>
      reservations.some(
        (visit) =>
          visit.officeId === targetOffice.id &&
          visit.visitDate !== undefined &&
          isSameDay(date, visit.visitDate),
      ),
    [targetOffice, reservations],
  );

  /* Constellation has a bug with Datepicker - minDate & maxDate aren't considered
  when used with isDisabled, so they need to be passed in with the isDisabled function */
  const isDateDisabled = (date: Date, { minDate, maxDate }: { minDate: Date; maxDate: Date }) =>
    isBefore(date, minDate) || isAfter(date, maxDate) || isBlocked(date) || isAlreadyBooked(date);

  const updateExcludedDates = (dates: Date[]) => {
    setExcludedDates(dates);
  };

  const updateSameDayReservations = (reservations: Reservation[]) => {
    setSameDayReservationsByOffice(
      reservations.reduce((pre, cur) => {
        pre[cur.officeId] = pre[cur.officeId] || [];
        pre[cur.officeId].push(cur);
        return pre;
      }, {} as { [key: string]: Reservation[] }),
    );
  };

  const handleSubmitBooking = useCallback(
    (startDate: Date, endDate: Date, excludedDates: Date[]) => {
      setHasSubmitError(false);
      return new Promise<void>((resolve, reject) => {
        submitBooking({
          office: targetOffice,
          startDate: startDate,
          endDate: endDate,
          excluded: excludedDates,
          bookingType: bookingType,
        })
          .then(() => {
            // TODO handle rejected booking
            setIsConfirmed(true);
            resolve();
          })
          .catch(() => {
            setHasSubmitError(true);
            reject();
          });
      });
    },
    [targetOffice, submitBooking, bookingType],
  );

  const selectedToggleButton = useMemo(
    () =>
      bookingType === BookingType.BOOKING_TYPE_RECURRING
        ? BookingType.BOOKING_TYPE_RECURRING.toString()
        : BookingType.BOOKING_TYPE_SINGLE_DAY.toString(),
    [bookingType],
  );

  /** update fully booked dates if unexpected conflicts happen */
  useEffect(() => {
    setFullyBookedDates([...fullyBookedDates, ...unexpectedConflicts]);
  }, [unexpectedConflicts]);

  useEffect(() => {
    if (excludedDates.length) {
      GoogleAnalyticsUtils.ExcludedDatesMsg();
    }
  }, [excludedDates]);

  useEffect(() => {
    if (Object.values(sameDayReservationsByOffice).length) {
      GoogleAnalyticsUtils.SameDayBookingMsg();
    }
  }, [sameDayReservationsByOffice]);

  useEffect(() => {
    if (hasUnexpectedWarning) {
      GoogleAnalyticsUtils.PartialBookingMsg();
    }
  }, [hasUnexpectedWarning]);

  return {
    isBlocked,
    isAlreadyBooked,
    isDateDisabled,
    handleSubmitBooking,
    hasSubmitError,
    isConfirmed,
    setIsConfirmed,
    excludedDates,
    sameDayReservationsByOffice,
    updateExcludedDates,
    updateSameDayReservations,
    hasUnexpectedError,
    setHasUnexpectedError,
    hasUnexpectedWarning,
    setHasUnexpectedWarning,
    unexpectedConflicts,
    setUnexpectedConflicts,
    onContinueBooking,
    setOnContinueBooking,
    selectedToggleButton,
    isWeekendSelected,
    setIsWeekendSelected,
  };
};

export const MockBookingContainerLogic = ({
  targetOffice,
  blockedDates,
  fullyBookedDates,
  setFullyBookedDates,
  reservations,
  bookingType,
}: BookingContainerProps) => {
  const [excludedDates, setExcludedDates] = useState([] as Date[]);
  const [sameDayReservationsByOffice, setSameDayReservationsByOffice] = useState(
    {} as { [key: string]: Reservation[] },
  );
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [hasUnexpectedError, setHasUnexpectedError] = useState(false);
  const [hasUnexpectedWarning, setHasUnexpectedWarning] = useState(false);
  const [unexpectedConflicts, setUnexpectedConflicts] = useState([] as Date[]);
  const [onContinueBooking, setOnContinueBooking] = useState({} as () => () => void);
  const [isWeekendSelected, setIsWeekendSelected] = useState(false);

  const dispatch = useDispatch();

  /**
   * a date is blocked
   * if the selected office is blocked for office visits that day
   * or it is already fully booked
   */
  const isBlocked = useCallback(
    (date: Date) =>
      (targetOffice && blockedDates.get(targetOffice.id)?.some((d) => isSameDay(date, d))) ||
      fullyBookedDates.some((d) => isSameDay(date, d)),
    [targetOffice, blockedDates, fullyBookedDates],
  );

  /** check if the date of the office has already been booked by user */
  const isAlreadyBooked = useCallback(
    (date: Date) =>
      reservations.some(
        (visit) =>
          visit.officeId === targetOffice.id &&
          visit.visitDate !== undefined &&
          isSameDay(date, visit.visitDate),
      ),
    [targetOffice, reservations],
  );

  /* Constellation has a bug with Datepicker - minDate & maxDate aren't considered
  when used with isDisabled, so they need to be passed in with the isDisabled function */
  const isDateDisabled = (date: Date, { minDate, maxDate }: { minDate: Date; maxDate: Date }) =>
    isBefore(date, minDate) || isAfter(date, maxDate) || isBlocked(date) || isAlreadyBooked(date);

  const updateExcludedDates = (dates: Date[]) => {
    setExcludedDates(dates);
  };

  const updateSameDayReservations = (reservations: Reservation[]) => {
    setSameDayReservationsByOffice(
      reservations.reduce((pre, cur) => {
        pre[cur.officeId] = pre[cur.officeId] || [];
        pre[cur.officeId].push(cur);
        return pre;
      }, {} as { [key: string]: Reservation[] }),
    );
  };

  const handleSubmitBooking = useCallback(
    (startDate: Date, endDate: Date, excludedDates: Date[]) => {
      setHasSubmitError(false);
      return new Promise<void>((resolve) => {
        dispatch(
          mockBookOfficeVisitSuccess({
            office: targetOffice,
            startDate: startDate,
            endDate: endDate,
            excluded: excludedDates,
            bookingType: bookingType,
          }),
        );
        setIsConfirmed(true);
        resolve();
      });
    },
    [dispatch, targetOffice, bookingType],
  );

  const selectedToggleButton = useMemo(
    () =>
      bookingType === BookingType.BOOKING_TYPE_RECURRING
        ? BookingType.BOOKING_TYPE_RECURRING.toString()
        : BookingType.BOOKING_TYPE_SINGLE_DAY.toString(),
    [bookingType],
  );

  /** update fully booked dates if unexpected conflicts happen */
  useEffect(() => {
    setFullyBookedDates([...fullyBookedDates, ...unexpectedConflicts]);
  }, [unexpectedConflicts]);

  return {
    bookingType,
    isBlocked,
    isAlreadyBooked,
    isDateDisabled,
    handleSubmitBooking,
    hasSubmitError,
    isConfirmed,
    setIsConfirmed,
    excludedDates,
    sameDayReservationsByOffice,
    updateExcludedDates,
    updateSameDayReservations,
    hasUnexpectedError,
    setHasUnexpectedError,
    hasUnexpectedWarning,
    setHasUnexpectedWarning,
    unexpectedConflicts,
    setUnexpectedConflicts,
    onContinueBooking,
    setOnContinueBooking,
    selectedToggleButton,
    isWeekendSelected,
    setIsWeekendSelected,
  };
};
