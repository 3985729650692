import { createSelector, Selector } from 'reselect';
import {
  Office,
  Reservation,
  Floor,
  BookingType,
  OfficeFloorKey,
  OfficeFloorKeyType,
} from './index';
import { RootState, OfficeState } from '../index';
import { DateUtils } from '../../utilities/dateutils';
import { officeKeyMap } from './floorKeys';

export const selectOfficeState = (state: RootState): OfficeState => state.office;

export const selectRawOffices: Selector<RootState, { [key: string]: Office }> = createSelector(
  [selectOfficeState],
  (officeState) => {
    return officeState.offices;
  },
);

export const selectOfficesAlphabeticallyOrdered: Selector<RootState, Office[]> = createSelector(
  [selectRawOffices],
  (offices) => {
    return Object.values(offices).sort((a, b) =>
      a.name && b.name ? a.name.localeCompare(b.name) : -1,
    );
  },
);

export const selectOfficeMap: Selector<RootState, Map<string, Office>> = createSelector(
  [selectRawOffices],
  (offices) => {
    const m = Object.keys(offices).map((id) => [id, offices[id]] as [string, Office]);
    return new Map<string, Office>(m);
  },
);

export const selectOfficeBlockedDatesMap: Selector<RootState, Map<string, Date[]>> = createSelector(
  [selectOfficeState],
  (officeState) => {
    const m = Object.keys(officeState.blockedDates).map(
      (id) =>
        [id, officeState.blockedDates[id].dates.map((d) => DateUtils.DateFromUTC(d))] as [
          string,
          Date[],
        ],
    );
    return new Map<string, Date[]>(m);
  },
);

export const selectOfficeFloorsMap: Selector<RootState, Map<string, Floor[]>> = createSelector(
  [selectOfficeState],
  (officeState) => {
    const naturalCompare = (a: string, b: string) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    const m = Object.keys(officeState.floors).map(
      (id) =>
        [
          id,
          officeState.floors[id].floors
            .sort((a, b) => (a.name && b.name ? naturalCompare(a.name, b.name) : -1))
            .map((floor) => {
              return {
                ...floor,
                floorplanKeys: floorPlanKeysToMap(getFloorPlanKeysFromPlanUrl(floor.planUrl)),
              } as Floor;
            }),
        ] as [string, Floor[]],
    );
    return new Map<string, Floor[]>(m);
  },
);

/** maps floorID to the corresponding office */
export const selectFloorsOfficeMap: Selector<
  RootState,
  Map<string, Office | undefined>
> = createSelector([selectOfficeState, selectOfficeMap], (officeState, officeMap) => {
  const m = new Map<string, Office | undefined>();
  for (const [officeID, value] of Object.entries(officeState.floors)) {
    value.floors.forEach((floor) => {
      m.set(floor.id, officeMap.get(officeID));
    });
  }

  return m;
});

/**
 * map office floor key type to office floor keys from key array
 *
 * @param keys - array of keys
 * @returns Map<OfficeFloorKeyType, OfficeFloorKey[]>
 */
export const floorPlanKeysToMap = (keys: string[]): Map<OfficeFloorKeyType, OfficeFloorKey[]> => {
  const m = new Map<OfficeFloorKeyType, OfficeFloorKey[]>();
  for (const k of keys) {
    const t: OfficeFloorKeyType = officeKeyMap[k]?.type;
    if (m.has(t)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      m.set(t, [...m.get(t)!, officeKeyMap[k]]);
    } else {
      m.set(t, [officeKeyMap[k]]);
    }
  }
  return m;
};

/**
 * Parse floor plan keys from image file name
 *
 * @param url - image path, e.g. floor_images/SEA_40_key_1_2_4_7.png, static/media/NYC_Broadway_11.09d5c30c.png (local)
 * @returns array of keys
 */
export const getFloorPlanKeysFromPlanUrl = (url: string | undefined): string[] => {
  if (url) {
    const matchArr = url.match(/key_(.*?)\./);
    if (matchArr && matchArr.length > 1) {
      return matchArr[1].split('_');
    }
  }
  return [];
};

export const selectRawReservations: Selector<RootState, Reservation[]> = createSelector(
  [selectOfficeState],
  (officeState) => officeState.reservations,
);

export const selectReservations: Selector<RootState, Reservation[]> = createSelector(
  [selectRawReservations],
  (reservations) => {
    return reservations.map((reservation) => ({
      ...reservation,
      visitDate: reservation.visitDate ? DateUtils.DateFromUTC(reservation.visitDate) : undefined,
    }));
  },
);

export const selectBookingType: Selector<RootState, BookingType> = createSelector(
  [selectOfficeState],
  (officeState) => officeState.bookingType,
);
