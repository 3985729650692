import React from 'react';
import styled from 'styled-components';
import {
  mediaBreakpointMixin,
  Flex,
  Paragraph,
  Spacer,
  Heading,
  TextButton,
  Text,
  Divider,
  Button,
  ButtonGroup,
  IconExternal,
} from '@zillow/constellation';
import { Office } from '../../store/office';
import { ZImage } from '../../components/ZImage';
import { IsMockContext } from '../../middleware/auth';
import grayBoxImage from '../../assets/grayBox.png';
import DynamicConfig from '../../config/DynamicConfig';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import { InternalLink } from '../Links';
import { IsBookingInProgressContext } from '../../containers/HomeContainer/IsBookingInProgressContext';

export interface OfficeDetailsPanelProps {
  /** office to display */
  office: Office;
  /** mobile only - start booking cta */
  mobileCTA: React.ReactNode | undefined;
}

const OfficeInfoWrapper = styled(Flex)`
  height: 100%;
`;

const OfficeImage = styled(ZImage)`
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: 100%;
`;

const OfficeImageWrapper = styled(Spacer)`
  text-align: center;
`;

const DesktopOfficeButtonGroup = styled.div`
  @media ${mediaBreakpointMixin('md')} {
    display: block;
  }
  display: none;
  text-align: center;
`;

const MobileOfficeButtonGroup = styled(ButtonGroup)`
  @media ${mediaBreakpointMixin('md')} {
    display: none;
  }
  display: flex;
  justify-content: center;
`;

export const OfficeDetailsPanel: React.FC<OfficeDetailsPanelProps> = ({
  office,
  mobileCTA,
}: OfficeDetailsPanelProps) => {
  const isMock = React.useContext(IsMockContext);
  const { isBookingInProgress } = React.useContext(IsBookingInProgressContext);

  return (
    <Flex
      flex="1"
      paddingLeft={{ lg: 'lg', xl: 'xl' }}
      paddingRight={{ xl: 'xs' }}
      marginY={{ lg: 'xs' }}
    >
      <OfficeInfoWrapper  data-testid='render-wrapper-test' display="flex" flexDirection="column">
        <Flex flex="1" marginY={{ default: 'xs', xl: 'md' }}>
          <Heading  data-testid='render-header-test' level="4" fontType={{ default: 'h5', lg: 'h4' }}>
            {office.name}
          </Heading>
          <TextButton data-testid='render-map-button-test' fontType="body" as="a" href={office.mapLinkUrl} target="_blank">
            {office.address?.street}
            <br />
            {`${office.address?.city}, ${office.address?.state} ${office.address?.zip}`}
          </TextButton>
          {office.floorsInfo && (
            <Paragraph>
              <Text as="strong">
                {`Floor${
                  office.floorsInfo.includes(',') || office.floorsInfo.includes('-') ? 's' : ''
                }`}
                :{' '}
              </Text>
              {office.floorsInfo}
            </Paragraph>
          )}
          {mobileCTA}
        </Flex>
        <Divider />
        <Flex flex="3" marginY="md">
          <OfficeImageWrapper data-testid='render-office-image-test' marginY={{ xl: 'xs' }}>
            <OfficeImage 
              imageUrl={
                isMock
                  ? office.photoUrl
                  : office.photoUrl && `${DynamicConfig.GetConfig().HQENGINE_PROXY}${office.photoUrl}`
              }
              alt={office.name}
              fallbackUrl={grayBoxImage}
            />
          </OfficeImageWrapper>
          <DesktopOfficeButtonGroup aria-label="Office floor plan and details buttons">
            <InternalLink
              pathname={`/floorplans/office/${office.id}${isMock ? '?test=1' : ''}`}
              toConfirmNavigation={isBookingInProgress}
            >
              <TextButton data-testid='render-floor-plans-button-test'
                fontType="bodySmallHeading"
                onClick={() => GoogleAnalyticsUtils.ClickViewFloorPlans(office.name)}
              >
                View floor plans
              </TextButton>
            </InternalLink>
            <Text> | </Text>
            <TextButton
              data-testid='render-office-details-button-test'
              as="a"
              href={office.infoUrl}
              target="_blank"
              onClick={() => GoogleAnalyticsUtils.ClickOfficeDetails(office.name)}
            >
              <Text fontColor="blue500" fontType="bodySmallHeading">
                Office details <IconExternal />
              </Text>
            </TextButton>
          </DesktopOfficeButtonGroup>
          <MobileOfficeButtonGroup
            paddingTop="lg"
            aria-label="Office floor plan and details buttons"
          >
            <InternalLink
              pathname={`/floorplans/office/${office.id}${isMock ? '?test=1' : ''}`}
              toConfirmNavigation={isBookingInProgress}
            >
              <Button data-testid='render-mobile-floor-plans-button-test' onClick={() => GoogleAnalyticsUtils.ClickViewFloorPlans(office.name)}>
                View floor plans
              </Button>
            </InternalLink>
            <Button
              data-testid='render-mobile-office-details-button-test'
              as="a"
              href={office.infoUrl}
              target="_blank"
              onClick={() => GoogleAnalyticsUtils.ClickOfficeDetails(office.name)}
            >
              Office details
            </Button>
          </MobileOfficeButtonGroup>
        </Flex>
      </OfficeInfoWrapper>
    </Flex>
  );
};
