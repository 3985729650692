import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { RecurringBookingContainerProps } from './RecurringBookingContainer';
import { Reservation } from '../../store/office';
import { DateUtils } from '../../utilities/dateutils';
import { getDay, isWithinInterval, startOfToday, isAfter, isSameDay } from 'date-fns';
import { MAX_BOOKING_DATE, DATE_FORMAT } from '../BookingContainer/BookingContainer';
import { IsBookingInProgressContext } from '../HomeContainer/IsBookingInProgressContext';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import { v4 as uuidv4 } from 'uuid';

// value is the day of week (0-6), matches getDay return values
export const weekdays: Record<string, number> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};

export const RecurringBookingContainerLogic = ({
  targetOffice,
  reservations,
  isBlocked,
  isAlreadyBooked,
  handleSubmitBooking,
  updateExcludedDates,
  updateSameDayReservations,
  getOfficeFullyBookedDates,
  setHasUnexpectedError,
  setHasUnexpectedWarning,
  setUnexpectedConflicts,
  setOnContinueBooking,
  setIsWeekendSelected,
}: RecurringBookingContainerProps) => {
  const [isConfirmingDates, setIsConfirmingDates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDays, setSelectedDays] = useState([] as string[]);
  const [endDateInputKey, setEndDateInputKey] = useState(uuidv4());
  const { isBookingInProgress, setIsBookingInProgress } = useContext(IsBookingInProgressContext);
  const [isStartDateModalOpen, setIsStartDateModalOpen] = useState(false);
  const [isEndDateModalOpen, setIsEndDateModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[] | undefined>(undefined);

  /** check if the date is valid for submission */
  const isDateStringAllowed = useCallback(
    (dateStr: string) =>
      DateUtils.IsValidDateString(dateStr, DATE_FORMAT) &&
      isWithinInterval(new Date(dateStr), { start: startOfToday(), end: MAX_BOOKING_DATE }),
    [],
  );

  /** check if the date is one of selected days */
  const isDateInSelectedDays = useCallback(
    (date: Date) => {
      const day = getDay(date);
      return selectedDays.some((d) => weekdays[d] === day);
    },
    [selectedDays],
  );

  /** check if start date and end date can be submitted */
  const hasInvalidInput: boolean = useMemo(
    () =>
      !selectedDays.length ||
      !isDateStringAllowed(startDate) ||
      !isDateStringAllowed(endDate) ||
      isAfter(new Date(startDate), new Date(endDate)),
    [selectedDays, startDate, endDate, isDateStringAllowed],
  );

  /** check if no selected days within range */
  const hasNoDateInRange: boolean = useMemo(
    () =>
      !hasInvalidInput &&
      !DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate)).some((d) =>
        isDateInSelectedDays(d),
      ),
    [isDateInSelectedDays, startDate, endDate],
  );

  /** check if no selected days available within range */
  const hasNoDateAvailableInRange: boolean = useMemo(
    () =>
      !hasInvalidInput &&
      !hasNoDateInRange &&
      DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate))
        .filter((d) => isDateInSelectedDays(d))
        .every((d) => isBlocked(d) || isAlreadyBooked(d)),
    [isBlocked, isAlreadyBooked, isDateInSelectedDays, startDate, endDate],
  );

  const isCTADisabled: boolean = hasInvalidInput || hasNoDateInRange || hasNoDateAvailableInRange;

  const isWeekendSelected: boolean = useMemo(
    () =>
      selectedDays.length > 0 && selectedDays.some((sd) => weekdays[sd] == 6 || weekdays[sd] == 0),
    [selectedDays],
  );

  const resetForm = useCallback(() => {
    setSelectedDays([]);
    setStartDate('');
    setEndDate('');
    setEndDateInputKey(uuidv4()); // force resetting the end date input component
  }, []);

  /** get selected dates between the specified date range */
  const getSelectedDates = useCallback(() => {
    const datesBetween = DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate));
    setSelectedDates(datesBetween.filter((d) => isDateInSelectedDays(d)));
  }, [startDate, endDate, isDateInSelectedDays]);

  /** get applicable blocked dates between the specified date range
   *  for notifying the user if any of their selected days are blocked
   */
  const getSelectExcludedDates = useCallback(() => {
    const datesBetween = DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate));
    updateExcludedDates(datesBetween.filter((d) => isBlocked(d) && isDateInSelectedDays(d)));
  }, [startDate, endDate, isBlocked, isDateInSelectedDays]);

  const getSameDayReservations = useCallback(() => {
    updateSameDayReservations([]);

    const sameDayReservations: Reservation[] = [];
    for (const r of reservations) {
      if (
        r.visitDate &&
        isDateInSelectedDays(r.visitDate) &&
        isWithinInterval(r.visitDate, { start: new Date(startDate), end: new Date(endDate) }) &&
        targetOffice.id !== r.officeId &&
        !isBlocked(r.visitDate)
      ) {
        sameDayReservations.push(r);
      }
    }
    updateSameDayReservations(sameDayReservations);
  }, [startDate, endDate, targetOffice, reservations, isBlocked, isDateInSelectedDays]);

  const onSubmitBooking = useCallback(() => {
    if (!startDate || !endDate) return;
    setIsConfirmingDates(true);
    const start = new Date(startDate);
    const end = new Date(endDate);

    const datesBetween = DateUtils.GetDatesBetween(start, end);
    const excluded = datesBetween.filter(
      (d) => !isDateInSelectedDays(d) || isBlocked(d) || isAlreadyBooked(d),
    );
    // double check for fully booked dates
    const unexpected: Date[] = [];
    getOfficeFullyBookedDates(targetOffice.id, start, end)
      .then((dates) => {
        for (const d of dates) {
          if (!excluded.some((ed) => isSameDay(ed, DateUtils.DateFromUTC(d)))) {
            unexpected.push(DateUtils.DateFromUTC(d));
          }
        }

        if (unexpected.length > 0) {
          setUnexpectedConflicts(unexpected);
          // all dates are gone
          if (datesBetween.length === excluded.length + unexpected.length) {
            setHasUnexpectedError(true);
            // partially unavailable
          } else {
            setHasUnexpectedWarning(true);
            // trigger submit again when user clicks continue
            const submitBooking = () =>
              submitBookingCallback(start, end, [...excluded, ...unexpected]);
            setOnContinueBooking(() => submitBooking);
          }
        } else {
          submitBookingCallback(start, end, excluded);
        }
      })
      .catch(() => submitBookingCallback(start, end, excluded)) // try booking anyway
      .finally(() => setIsConfirmingDates(false));

    GoogleAnalyticsUtils.ClickBookNowRecurring(targetOffice.name);
  }, [
    targetOffice,
    startDate,
    endDate,
    isDateInSelectedDays,
    isBlocked,
    isAlreadyBooked,
    handleSubmitBooking,
  ]);

  const submitBookingCallback = useCallback(
    (startDate: Date, endDate: Date, excludedDates: Date[]) => {
      setIsSubmitting(true);
      handleSubmitBooking(startDate, endDate, excludedDates)
        .then(() => resetForm())
        .catch(() => null)
        .finally(() => setIsSubmitting(false));
    },
    [handleSubmitBooking],
  );

  /** reset the form when selected office changes */
  useEffect(() => resetForm(), [targetOffice]);

  /** update excluded dates when the date range or office is changed */
  useEffect(() => {
    if (!isCTADisabled) {
      getSelectedDates();
      getSelectExcludedDates();
      getSameDayReservations();
      setIsWeekendSelected(isWeekendSelected);
    } else {
      setSelectedDates(undefined);
      updateExcludedDates([]);
      updateSameDayReservations([]);
      setIsWeekendSelected(false);
    }
  }, [
    startDate,
    endDate,
    selectedDays,
    targetOffice,
    getSelectedDates,
    getSelectExcludedDates,
    getSameDayReservations,
  ]);

  useEffect(() => {
    if ((startDate || endDate || selectedDays.length) && !isBookingInProgress) {
      setIsBookingInProgress(true);
    } else if (!startDate && !endDate && !selectedDays.length) {
      setIsBookingInProgress(false);
    }
  }, [startDate, endDate, selectedDays]);

  useEffect(() => {
    if (isDateStringAllowed(startDate)) {
      GoogleAnalyticsUtils.SelectStartDateRecurring(targetOffice.name);
    }
  }, [startDate]);

  useEffect(() => {
    if (isDateStringAllowed(endDate)) {
      GoogleAnalyticsUtils.SelectEndDateRecurring(targetOffice.name);
    }
  }, [endDate]);

  return {
    selectedDays,
    setSelectedDays,
    isConfirmingDates,
    isSubmitting,
    onSubmitBooking,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    endDateInputKey,
    selectedDates,
    isCTADisabled,
    hasNoDateInRange,
    hasNoDateAvailableInRange,
    isStartDateModalOpen,
    setIsStartDateModalOpen,
    isEndDateModalOpen,
    setIsEndDateModalOpen,
  };
};

export const MockRecurringBookingContainerLogic = ({
  targetOffice,
  reservations,
  isBlocked,
  isAlreadyBooked,
  handleSubmitBooking,
  updateExcludedDates,
  updateSameDayReservations,
  setHasUnexpectedError,
  setHasUnexpectedWarning,
  setUnexpectedConflicts,
  setOnContinueBooking,
  setIsWeekendSelected,
}: RecurringBookingContainerProps) => {
  const [isConfirmingDates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDays, setSelectedDays] = useState([] as string[]);
  const [endDateInputKey, setEndDateInputKey] = useState(uuidv4());
  const { isBookingInProgress, setIsBookingInProgress } = useContext(IsBookingInProgressContext);
  const [isStartDateModalOpen, setIsStartDateModalOpen] = useState(false);
  const [isEndDateModalOpen, setIsEndDateModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[] | undefined>(undefined);

  /** check if the date is valid for submission */
  const isDateStringAllowed = useCallback(
    (dateStr: string) =>
      DateUtils.IsValidDateString(dateStr, DATE_FORMAT) &&
      isWithinInterval(new Date(dateStr), { start: startOfToday(), end: MAX_BOOKING_DATE }),
    [],
  );

  /** check if the date is one of selected days */
  const isDateInSelectedDays = useCallback(
    (date: Date) => {
      const day = getDay(date);
      return selectedDays.some((d) => weekdays[d] === day);
    },
    [selectedDays],
  );

  /** check if start date and end date can be submitted */
  const hasInvalidInput: boolean = useMemo(
    () =>
      !selectedDays.length ||
      !isDateStringAllowed(startDate) ||
      !isDateStringAllowed(endDate) ||
      isAfter(new Date(startDate), new Date(endDate)),
    [selectedDays, startDate, endDate, isDateStringAllowed],
  );

  /** check if no selected days within range */
  const hasNoDateInRange: boolean = useMemo(
    () =>
      !hasInvalidInput &&
      !DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate)).some((d) =>
        isDateInSelectedDays(d),
      ),
    [isDateInSelectedDays, startDate, endDate],
  );

  /** check if no selected days available within range */
  const hasNoDateAvailableInRange: boolean = useMemo(
    () =>
      !hasInvalidInput &&
      !hasNoDateInRange &&
      DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate))
        .filter((d) => isDateInSelectedDays(d))
        .every((d) => isBlocked(d) || isAlreadyBooked(d)),
    [isBlocked, isAlreadyBooked, isDateInSelectedDays, startDate, endDate],
  );

  const isCTADisabled: boolean = hasInvalidInput || hasNoDateInRange || hasNoDateAvailableInRange;

  const isWeekendSelected: boolean = useMemo(
    () =>
      selectedDays.length > 0 &&
      selectedDays.some(
        (sd) => weekdays[sd] == weekdays.Saturday || weekdays[sd] == weekdays.Sunday,
      ),
    [selectedDays],
  );

  const resetForm = useCallback(() => {
    setSelectedDays([]);
    setStartDate('');
    setEndDate('');
    setEndDateInputKey(uuidv4()); // force resetting the end date input component
  }, []);

  /** get selected dates between the specified date range */
  const getSelectedDates = useCallback(() => {
    const datesBetween = DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate));
    setSelectedDates(datesBetween.filter((d) => isDateInSelectedDays(d)));
  }, [startDate, endDate, isDateInSelectedDays]);

  /** get applicable blocked dates between the specified date range
   *  for notifying the user if any of their selected days are blocked
   */
  const getSelectExcludedDates = useCallback(() => {
    const datesBetween = DateUtils.GetDatesBetween(new Date(startDate), new Date(endDate));
    updateExcludedDates(datesBetween.filter((d) => isBlocked(d) && isDateInSelectedDays(d)));
  }, [startDate, endDate, isBlocked, isDateInSelectedDays]);

  const getSameDayReservations = useCallback(() => {
    updateSameDayReservations([]);

    const sameDayReservations: Reservation[] = [];
    for (const r of reservations) {
      if (
        r.visitDate &&
        isDateInSelectedDays(r.visitDate) &&
        isWithinInterval(r.visitDate, { start: new Date(startDate), end: new Date(endDate) }) &&
        targetOffice.id !== r.officeId &&
        !isBlocked(r.visitDate)
      ) {
        sameDayReservations.push(r);
      }
    }
    updateSameDayReservations(sameDayReservations);
  }, [startDate, endDate, targetOffice, reservations, isBlocked, isDateInSelectedDays]);

  const onSubmitBooking = useCallback(() => {
    if (!startDate || !endDate) return;
    const start = new Date(startDate);
    const end = new Date(endDate);

    const datesBetween = DateUtils.GetDatesBetween(start, end);
    const excluded = datesBetween.filter(
      (d) => !isDateInSelectedDays(d) || isBlocked(d) || isAlreadyBooked(d),
    );
    // double check for fully booked dates
    // MOCK for demo purpose only October 2021 has unexpected fully booked dates
    const unexpected: Date[] =
      start.getMonth() === 9
        ? [new Date(2021, start.getMonth(), 11), new Date(2021, start.getMonth(), 12)]
        : [];

    if (unexpected.length > 0) {
      setUnexpectedConflicts(unexpected);
      // all dates are gone
      if (datesBetween.length === excluded.length + unexpected.length) {
        setHasUnexpectedError(true);
        // partially unavailable
      } else {
        setHasUnexpectedWarning(true);
        // trigger submit again when user clicks continue
        const submitBooking = () => submitBookingCallback(start, end, [...excluded, ...unexpected]);
        setOnContinueBooking(() => submitBooking);
      }
    } else {
      submitBookingCallback(start, end, excluded);
    }
  }, [startDate, endDate, isDateInSelectedDays, isBlocked, isAlreadyBooked, handleSubmitBooking]);

  const submitBookingCallback = useCallback(
    (startDate: Date, endDate: Date, excludedDates: Date[]) => {
      setIsSubmitting(true);
      handleSubmitBooking(startDate, endDate, excludedDates).then(() => {
        setIsSubmitting(false);
        resetForm();
      });
    },
    [handleSubmitBooking],
  );

  /** reset the form when selected office changes */
  useEffect(() => resetForm(), [targetOffice]);

  /** update excluded dates when the date range or office is changed */
  useEffect(() => {
    if (!isCTADisabled) {
      getSelectedDates();
      getSelectExcludedDates();
      getSameDayReservations();
      setIsWeekendSelected(isWeekendSelected);
    } else {
      setSelectedDates(undefined);
      updateExcludedDates([]);
      updateSameDayReservations([]);
      setIsWeekendSelected(false);
    }
  }, [
    startDate,
    endDate,
    selectedDays,
    targetOffice,
    getSelectExcludedDates,
    getSameDayReservations,
    getSelectedDates,
  ]);

  useEffect(() => {
    if ((startDate || endDate || selectedDays.length) && !isBookingInProgress) {
      setIsBookingInProgress(true);
    } else if (!startDate && !endDate && !selectedDays.length) {
      setIsBookingInProgress(false);
    }
  }, [startDate, endDate, selectedDays]);

  return {
    selectedDays,
    setSelectedDays,
    isConfirmingDates,
    isSubmitting,
    onSubmitBooking,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    endDateInputKey,
    selectedDates,
    isCTADisabled,
    hasNoDateInRange,
    hasNoDateAvailableInRange,
    isStartDateModalOpen,
    setIsStartDateModalOpen,
    isEndDateModalOpen,
    setIsEndDateModalOpen,
  };
};
