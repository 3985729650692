import React from 'react';
import {
  Flex,
  Paragraph,
  Heading,
  TextButton,
  Button,
  ButtonGroup,
  IconWarning,
} from '@zillow/constellation';
import { Modal } from '../../Modal';

export interface ConfirmNavigationModalProps {
  /** if modal is open */
  isOpen: boolean;
  /** function to handle close */
  onClose: () => void;
  /** function to proceed navigation */
  onContinue: () => void;
}

export const ConfirmNavigationModal: React.FC<ConfirmNavigationModalProps> = ({
  isOpen,
  onClose,
  onContinue,
}: ConfirmNavigationModalProps) => {
  return (
    <Modal
      fullScreen={{ sm_lte: true }}
      header={<Heading data-testid='render-modal-header-test' level={5}>Warning</Heading>}
      body={
        <Flex data-testid='render-modal-body-test' margin="xs">
          <IconWarning fontColor="orange400" size="md" />
          <Heading level={4} marginTop="xs" fontFamily="serif">
            Confirm navigation
          </Heading>
          <Paragraph marginTop="sm">
            You have started booking a visit. Opening a new page will discard your work.
          </Paragraph>
        </Flex>
      }
      footer={
        <ButtonGroup data-testid='render-modal-footer-test' aria-label="Confirm navigation buttons">
          <TextButton data-testid='render-stay-button-test' onClick={onClose}>Stay on page</TextButton>
          <Button data-testid='render-leave-button-test' buttonType="primary" onClick={onContinue}>
            Leave this page
          </Button>
        </ButtonGroup>
      }
      isOpen={isOpen}
      handleClose={onClose}
      shouldCloseOnOutsideClick={false}
    />
  );
};
