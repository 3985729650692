import { OfficeBooking, Reservation } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { DateUtils } from '../../../utilities/dateutils';
import { isSameDay, addDays } from 'date-fns';

export const mockReservation = (date: Date, officeId: string): Reservation => {
  return {
    officeId: officeId,
    visitDate: date,
    id: uuidv4(),
  } as Reservation;
};

export const mockUserReservations: Reservation[] = [
  mockReservation(addDays(new Date(), 4), '5'),
  mockReservation(addDays(new Date(), 7), '1'),
  mockReservation(addDays(new Date(), 7), '3'),
  mockReservation(addDays(new Date(), 8), '1'),
  mockReservation(addDays(new Date(), 12), '2'),
  mockReservation(addDays(new Date(), 13), '2'),
];

export const mockBookingToReservations = (booking: OfficeBooking): Reservation[] => {
  if (booking.endDate === undefined || isSameDay(booking.startDate, booking.endDate)) {
    return [
      {
        id: uuidv4(),
        officeId: booking.office.id,
        visitDate: booking.startDate,
      } as Reservation,
    ];
  }

  const datesBetween = DateUtils.GetDatesBetween(booking.startDate, booking.endDate);
  const validDates = datesBetween.filter(
    (date) => !booking.excluded.some((d) => isSameDay(date, d)),
  );

  return validDates.map((date) => {
    return {
      id: uuidv4(),
      officeId: booking.office.id,
      visitDate: date,
    } as Reservation;
  });
};
