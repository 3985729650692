import React from 'react';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { ErrorHandler } from '../../components/ErrorHandler';
import { NotFound } from '../../components/PageError';
import FloorPlanContainer from '../../containers/FloorPlanContainer';
import { PageWrapper, ContentWrapper } from '../HQApp/HQApp';
import { HQInternalAppLogic } from './HQInternalApp.hooks';

export enum RoutePath {
  FLOOR_PLANS = '/internal/floorplans/(office)?/:officeID?/(floor)?/:floorID?',
  NOT_FOUND = '/internal/notfound',
}

const HQInternalApp: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  HQInternalAppLogic(props);

  return (
    <PageWrapper backgroundColor={'backgroundBlue'}>
      <ContentWrapper>
        <ErrorHandler>
          <Switch>
            <Route
              path={RoutePath.FLOOR_PLANS}
              render={() => <FloorPlanContainer isInternal={true} />}
            />
            <Route component={NotFound} />
          </Switch>
        </ErrorHandler>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default withRouter(HQInternalApp);
