import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MassUploadReportContainerProps } from './MassUploadReportContainer';
import { MassUploadReport } from '../../../store/office';

export const MassUploadReportContainerLogic = ({
  getMassUploadReport,
}: MassUploadReportContainerProps) => {
  const { reportID } = useParams<{ reportID: string }>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [report, setReport] = useState<MassUploadReport | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!isLoaded) {
      getMassUploadReport(reportID)
        .then((report: MassUploadReport | undefined) => {
          setReport(report);
        })
        .catch((err) => setErrorMessage(err.message))
        .finally(() => setIsLoaded(true));
    }
  }, [reportID, isLoaded]);

  /** reset report if report id changes */
  useEffect(() => {
    setIsLoaded(false);
    setReport(undefined);
  }, [reportID]);

  return {
    isLoaded,
    report,
    errorMessage,
  };
};
