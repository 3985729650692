/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  mediaBreakpointMixin,
  Card as ConstellationCard,
  Spacer,
  Heading,
  Paragraph,
  Alert,
  UnstyledButton,
  IconExpandOutline,
  LoadingButton,
  MediaObject,
  Image,
  Divider,
  spaceMixin,
} from '@zillow/constellation';
import { ExpandedModal } from './ExpandedModal';
import { CancelConfirmationModal } from './CancelConfirmationModal';
import { UpcomingVisitsTable } from './UpcomingVisitsTable';
import {
  MockUpcomingVisitsContainerLogic,
  UpcomingVisitsContainerLogic,
} from './UpcomingVisitsContainer.hooks';
import { RootState, ZEvent } from '../../store';
import {
  Office,
  Floor,
  Reservation,
  cancelOfficeVisit,
  selectOfficeMap,
  selectReservations,
  selectFloorsOfficeMap,
  selectOfficeFloorsMap,
} from '../../store/office';
import { selectUpcomingAcceptedZEvents } from '../../store/zevent';
import { Loader } from '../../components/Loader';
import { IsMockContext } from '../../middleware/auth';
import calendarImage from '../../assets/calendar.png';

interface StateProps {
  /** list of reservations */
  reservations: Reservation[];
  /** offices */
  officeMap: Map<string, Office>;
  /** zretreat events */
  zevents: ZEvent[];
  /** floors office map */
  floorsOfficeMap: Map<string, Office | undefined>;
  /** office floors */
  floors: Map<string, Floor[]>;
  /** cancel reservation */
  cancelReservation: (visitIds: string[]) => Promise<Reservation[]>;
}
interface OwnProps {
  /** data loaded or not */
  isLoaded: boolean;
  /** has loading error or not */
  hasLoadingError: boolean;
}

export type UpcomingVisitsContainerProps = StateProps & OwnProps;

const Card = styled(ConstellationCard)`
  box-sizing: border-box;
  height: 100%;
`;

const ExpandButton = styled(UnstyledButton)`
  @media ${mediaBreakpointMixin('lg')} {
    display: block;
    margin-top: -${spaceMixin('sm')};
    margin-right: -${spaceMixin('sm')};
  }
  display: none;
  float: right;
`;

const TableWrapper = styled.div`
  @media ${mediaBreakpointMixin('lg')} {
    max-height: 325px;
  }
  overflow-y: scroll;
  min-height: 240px;
  &::-webkit-scrollbar {
    width: 5px;
    border-bottom: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

const TableDivider = styled(Divider)`
  width: calc(100% - 5px);
`;

const CancelButtonWrapper = styled.div`
  text-align: right;
  min-height: 54px;
`;

const PlaceholderImage = styled(Image)`
  height: 90px;
`;

const UpcomingVisitsContainer: React.FC<UpcomingVisitsContainerProps> = (
  props: UpcomingVisitsContainerProps,
) => {
  const isMock = React.useContext(IsMockContext);
  const {
    reservations,
    officeMap,
    floorsOfficeMap,
    floors,
    zevents,
    cancelReservation,
    isLoaded,
    hasLoadingError,
  } = props;
  const {
    onCancelBooking,
    expanded,
    setExpanded,
    isSelectedMap,
    setIsSelectedMap,
    isConfirmed,
    setIsConfirmed,
    canceledVisits,
    hasError,
  } = isMock ? MockUpcomingVisitsContainerLogic(props) : UpcomingVisitsContainerLogic(props);

  return (
    <Card cardType="secondary" paddingTop="lg" paddingX="lg">
      {reservations.length > 0 && (
        <ExpandButton onClick={() => setExpanded(true)}>
          <IconExpandOutline fontColor="brand" size={{ xs: 'xs', sm: 'sm' }} />
        </ExpandButton>
      )}
      <Heading level="4" fontFamily="serif" marginBottom="xs">
        Upcoming Visits
      </Heading>
      <Spacer margin="sm" />
      <Loader loading={!isLoaded}>
        {hasLoadingError ? (
          <Alert appearance="error" body="Unable to load upcoming visits" />
        ) : (
          <>
            {reservations.length === 0 && zevents.length === 0 ? (
              <MediaObject
                media={
                  <PlaceholderImage alt={'calendar image'} title={'calendar'} src={calendarImage} />
                }
                direction="column"
              >
                <Heading level="5">Nothing booked right now.</Heading>
                <Spacer margin="xs" />
                <Paragraph>
                  Use the Zillow Office Visit tool to book a day pass for an office. You can manage
                  upcoming visits here once they are booked.
                </Paragraph>
              </MediaObject>
            ) : (
              <>
                <TableWrapper>
                  <UpcomingVisitsTable
                    reservations={reservations}
                    zevents={zevents}
                    officeMap={officeMap}
                    floorsOfficeMap={floorsOfficeMap}
                    floors={floors}
                    isSelectedMap={isSelectedMap}
                    setIsSelectedMap={setIsSelectedMap}
                  />
                </TableWrapper>
                <TableDivider />
                <CancelButtonWrapper>
                  {isSelectedMap.size > 0 && Array.from(isSelectedMap.values()).includes(true) && (
                    <LoadingButton marginTop="sm" buttonType="primary" onClick={onCancelBooking}>
                      Cancel Visits
                    </LoadingButton>
                  )}
                </CancelButtonWrapper>
              </>
            )}
          </>
        )}
        <ExpandedModal
          isOpen={expanded}
          onClose={() => setExpanded(false)}
          reservations={reservations}
          officeMap={officeMap}
          floorsOfficeMap={floorsOfficeMap}
          floors={floors}
          zevents={zevents}
          cancelReservation={cancelReservation}
        />
        <CancelConfirmationModal
          isOpen={isConfirmed}
          onClose={() => setIsConfirmed(false)}
          canceledVisits={canceledVisits}
          officeMap={officeMap}
          hasError={hasError}
        />
      </Loader>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  reservations: selectReservations(state),
  officeMap: selectOfficeMap(state),
  floorsOfficeMap: selectFloorsOfficeMap(state),
  floors: selectOfficeFloorsMap(state),
  zevents: selectUpcomingAcceptedZEvents(state),
});

const mapDispatchToProps = {
  cancelReservation: cancelOfficeVisit,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingVisitsContainer);
