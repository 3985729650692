import { createSelector, Selector } from 'reselect';
import { User, UserInboxMessage } from './index';
import { RootState, UserState } from '../index';

export const selectUserState = (state: RootState): UserState => state.user;

export const selectLoggedIn = (state: RootState): boolean => selectUserState(state).loggedIn;

export const selectUserLoaded = (state: RootState): boolean => selectUserState(state).loaded;

export const selectUserInfo: Selector<RootState, User> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.userInfo;
  },
);

export const selectUserId: Selector<RootState, string> = createSelector(
  [selectUserInfo],
  (userInfo) => {
    return userInfo.id;
  },
);

export const selectUserTrainingStatus: Selector<RootState, boolean> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.statusZGU;
  },
);

export const selectUserInboxMessages: Selector<RootState, UserInboxMessage[]> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.userInboxMessages;
  },
);

export const selectUserVaccinationStatus: Selector<RootState, boolean> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.statusVACC;
  },
);

export const selectUserHATTStatus: Selector<RootState, boolean> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.statusHATT;
  },
);

export const selectCanImpersonate: Selector<RootState, boolean> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.canImpersonate;
  },
);

export const selectCanMassUpload: Selector<RootState, boolean> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.canMassUpload;
  },
);

export const selectCanPlanZEvent: Selector<RootState, boolean> = createSelector(
  [selectUserState],
  (userState) => {
    return userState.canPlanZEvent;
  },
);
