import ReactGA from 'react-ga4';

export enum Category {
  BOOKING = 'Booking Office Visit',
  CANCELLATION = 'Office Visit Cancellation',
  LINKS = 'Links',
  FLOOR_PLANS = 'Floor Plans',
  IMPERSONATION = 'Impersonation',
}

export class GoogleAnalyticsUtils {
  public static Initialize(measurementId: string) {
    ReactGA.initialize(measurementId);
  }

  public static Pageview(pathname: string) {
    ReactGA.set({ page: pathname });
    ReactGA.send({ hitType: 'pageview', page: pathname, title: pathname });
  }

  private static WarningMessage(label: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Displayed warning message',
      label: label,
      nonInteraction: true,
    });
  }
  public static ExcludedDatesMsg() {
    GoogleAnalyticsUtils.WarningMessage('Excluded dates');
  }
  public static SameDayBookingMsg() {
    GoogleAnalyticsUtils.WarningMessage('Day booked for another office');
  }
  public static PartialBookingMsg() {
    GoogleAnalyticsUtils.WarningMessage('Partial booking');
  }

  public static SelectOffice(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Selected an office',
      label: officeName,
    });
  }

  public static SelectVisitDate(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Selected a visit date',
      label: officeName,
    });
  }

  public static CheckMultiday(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Checked multiday visit',
      label: officeName,
    });
  }

  public static SelectVisitEndDate(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Selected a visit end date',
      label: officeName,
    });
  }

  public static ClickBookNow(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Clicked Book Now',
      label: officeName,
    });
  }

  public static ToggleBookingType(bookingType: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Toggled booking type',
      label: bookingType,
    });
  }

  public static SelectRecurringDay(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Selected a recurring day',
      label: officeName,
    });
  }

  public static SelectStartDateRecurring(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Selected recurring booking start date',
      label: officeName,
    });
  }

  public static SelectEndDateRecurring(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Selected recurring booking end date',
      label: officeName,
    });
  }

  public static ClickBookNowRecurring(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Clicked Book Now on recurring booking',
      label: officeName,
    });
  }

  public static ClickContinueOnPartialBooking(officeName: string) {
    ReactGA.event({
      category: Category.BOOKING,
      action: 'Clicked Continue on Partial Booking',
      label: officeName,
    });
  }

  /* depreciated */
  public static ClickDWPortal() {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked Distributed Workforce Portal',
    });
  }

  /* depreciated */
  public static ClickSubmitProofOfVacc() {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked Submit Proof of Vaccination',
    });
  }

  public static ClickBusinessTravelBooking() {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked Business Travel Booking',
    });
  }

  public static ClickTravelExpenseGuidelines() {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked Travel & Expense Guidelines',
    });
  }

  public static ClickCloudHQZallway() {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked Cloud HQ Zallway Page',
    });
  }

  public static ClickCompleteAttestation() {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked Complete Attestation',
    });
  }

  public static ClickViewFloorPlans(officeName: string) {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked View Floor Plans',
      label: officeName,
    });
  }

  public static ClickOfficeDetails(officeName: string) {
    ReactGA.event({
      category: Category.LINKS,
      action: 'Clicked Office Details',
      label: officeName,
    });
  }

  public static ClickCancelVisits() {
    ReactGA.event({
      category: Category.CANCELLATION,
      action: 'Clicked Cancel Visits',
    });
  }

  public static ClickConfirmCancelVisits(visitsToCancel: number) {
    ReactGA.event({
      category: Category.CANCELLATION,
      action: 'Clicked Confirm Cancel Visits',
      value: visitsToCancel,
    });
  }

  public static ViewFloorPlan(officeName: string, floorName: string) {
    ReactGA.event({
      category: Category.FLOOR_PLANS,
      action: 'Viewed office floor plan',
      label: `${officeName} ${floorName}`,
    });
  }

  public static ViewFloorPlanQR(officeName: string, floorName: string) {
    ReactGA.event({
      category: Category.FLOOR_PLANS,
      action: 'Viewed office floor plan using QR code',
      label: `${officeName} ${floorName}`,
    });
  }

  public static ClickImpersonateOnBanner() {
    ReactGA.event({
      category: Category.IMPERSONATION,
      action: 'Clicked Impersonate on Impersonation Banner',
    });
  }

  public static ClickStartImpersonation() {
    ReactGA.event({
      category: Category.IMPERSONATION,
      action: 'Clicked Start impersonation button',
    });
  }

  public static ClickStopImpersonation() {
    ReactGA.event({
      category: Category.IMPERSONATION,
      action: 'Clicked Stop impersonation',
    });
  }
}
