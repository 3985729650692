import { createSelector, Selector } from 'reselect';
import { ZEventState } from './index';
import { RootState } from '../index';
import { ZEvent, ZEventUser, ZEventUserStatusTypeValue } from './types';
import { DateUtils } from '../../utilities/dateutils';

export const selectZEventState = (state: RootState): ZEventState => state.zevent;

const zEventDatesFromUTC = (event: ZEvent) => ({
  ...event,
  startDate: event.startDate ? DateUtils.DateFromUTC(event.startDate) : undefined,
  endDate: event.endDate ? DateUtils.DateFromUTC(event.endDate) : undefined,
  canceledDate: event.canceledDate ? DateUtils.DateFromUTC(event.canceledDate) : undefined,
  createdDate: event.createdDate ? DateUtils.DateFromUTC(event.createdDate) : undefined,
  updatedDate: event.updatedDate ? DateUtils.DateFromUTC(event.updatedDate) : undefined,
});

export const selectUpcomingZEvents: Selector<RootState, ZEvent[]> = createSelector(
  [selectZEventState],
  (zEventState) => {
    return zEventState.upcomingZEvents.map((event) => zEventDatesFromUTC(event));
  },
);

export const selectUpcomingAcceptedZEvents: Selector<RootState, ZEvent[]> = createSelector(
  [selectUpcomingZEvents],
  (upcomingZEvents) => {
    return upcomingZEvents.filter(
      (e) =>
        !e.isDeleted &&
        !e.isCanceled &&
        e.userStatus === ZEventUserStatusTypeValue.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
    );
  },
);

export const selectPastZEvents: Selector<RootState, ZEvent[]> = createSelector(
  [selectZEventState],
  (zEventState) => {
    return zEventState.pastZEvents.map((event) => zEventDatesFromUTC(event));
  },
);

export const selectCurrentZEvent: Selector<RootState, ZEvent> = createSelector(
  [selectZEventState],
  (zEventState) => {
    return zEventDatesFromUTC(zEventState.currentZEvent);
  },
);

export const selectCurrentZEventUsers: Selector<RootState, ZEventUser[]> = createSelector(
  [selectZEventState],
  (zEventState) => {
    return zEventState.currentZEventUsers.map((user) => ({
      ...user,
      createdDate: user.createdDate ? DateUtils.DateFromUTC(user.createdDate) : undefined,
      updatedDate: user.updatedDate ? DateUtils.DateFromUTC(user.updatedDate) : undefined,
    }));
  },
);

export const selectJustBookedZEventID: Selector<RootState, string | undefined> = createSelector(
  [selectZEventState],
  (zEventState) => {
    return zEventState.justBookedZEventID;
  },
);
