import { BlockedDates } from '../../../contract/hqengine_hqweb';
import { Office } from '../types';

export const mockOffices: { [key: string]: Office } = {
  '1': {
    id: '1',
    name: 'Seattle',
    address: {
      street: '1301 2nd Avenue',
      city: 'Seattle',
      state: 'WA',
      zip: '98101',
    },
    photoUrl: 'https://picsum.photos/id/1048/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '37',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/bJRzHhiqqTwD3gVa7',
    isBookable: true,
    infoUrl: 'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/2198/seattle-office',
    maxCapacity: '500',
  },
  '2': {
    id: '2',
    name: 'Irvine',
    address: {
      street: '2600 Michelson Drive',
      city: 'Irvine',
      state: 'CA',
      zip: '92612',
    },
    photoUrl: 'https://picsum.photos/id/1061/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '10',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/2zeFo92xTDdtnKD98',
    isBookable: true,
    infoUrl: 'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3017/irvine-office',
    maxCapacity: '120',
  },
  '3': {
    id: '3',
    name: 'Atlanta',
    address: {
      street: '3 Ravinia Drive',
      city: 'Atlanta',
      state: 'GA',
      zip: '30346',
    },
    photoUrl: 'https://picsum.photos/id/1062/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '7, 10',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/w7sQW4B4K6nHZ8dg7',
    isBookable: true,
    infoUrl: 'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3012/atlanta-office',
    maxCapacity: '50',
  },
  '4': {
    id: '4',
    name: 'Cincinnati',
    address: {
      street: '700 West Pete Rose Way',
      city: 'Cincinnati',
      state: 'OH',
      zip: '45203',
    },
    photoUrl: 'https://picsum.photos/id/1063/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: 'Ground floor',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/Z8EJoPSAggC4yN6W6',
    isBookable: true,
    infoUrl:
      'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3016/cincinnati-office',
    maxCapacity: '20',
  },
  '5': {
    id: '5',
    name: 'Dallas',
    address: {
      street: '9001 Cypress Waters Boulevard, Suite 3A',
      city: 'Coppell',
      state: 'TX',
      zip: '75063',
    },
    photoUrl: 'https://picsum.photos/id/1064/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: 'TBD',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/TEZ7fqBR5GVaREhp7',
    isBookable: true,
    infoUrl: 'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/2198/seattle-office',
    maxCapacity: '20',
  },
  '6': {
    id: '6',
    name: 'Denver',
    address: {
      street: '10771 E Easter Avenue',
      city: 'Centennial',
      state: 'CO',
      zip: '80112',
    },
    photoUrl: 'https://picsum.photos/id/1065/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '4',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/VNWmpEPex41WR3f67',
    isBookable: true,
    infoUrl: '', // zallway link doesn't exist - because this office doesn't exist.
    maxCapacity: '20',
  },
  '7': {
    id: '7',
    name: 'Kansas City',
    address: {
      street: '10975 El Monte Street',
      city: 'Overland Park',
      state: 'KS',
      zip: '66211',
    },
    photoUrl: 'https://picsum.photos/id/1066/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '1',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/7qhpE1GQ39Sj4FuU7',
    isBookable: true,
    infoUrl:
      'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3018/kansas-city-office',
    maxCapacity: '20',
  },
  '8': {
    id: '8',
    name: 'Lincoln',
    address: {
      street: '5960 S 57th St',
      city: 'Lincoln',
      state: 'NE',
      zip: '68516',
    },
    photoUrl: 'https://picsum.photos/id/1067/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: 'TBD',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/xhSFDgYoz8G2UaW86',
    isBookable: true,
    infoUrl: '', // zallway link doesn't exist - because this office doesn't exist.
    maxCapacity: '20',
  },
  '9': {
    id: '9',
    name: 'New York City',
    address: {
      street: '1250 Broadway',
      city: 'New York',
      state: 'NY',
      zip: '10001',
    },
    photoUrl: 'https://picsum.photos/id/1068/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '9',
    hoursInfo: 'M - F, 7:00 AM - 9:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/oroKrfG6y6L95F8S9',
    isBookable: true,
    infoUrl:
      'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3022/new-york-city-office',
    maxCapacity: '200',
  },
  '10': {
    id: '10',
    name: 'Phoenix',
    address: {
      street: '4343 North Scottsdale Road',
      city: 'Scottsdale',
      state: 'AZ',
      zip: '85251',
    },
    photoUrl: 'https://picsum.photos/id/1069/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: 'Ste 390',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/FN2HMppx2nJs8n3h9',
    isBookable: true,
    infoUrl: 'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3019/phoenix-office',
    maxCapacity: '20',
  },
  '11': {
    id: '11',
    name: 'San Francisco',
    address: {
      street: '535 Mission Street, Suite 700',
      city: 'San Francisco',
      state: 'CA',
      zip: '94105',
    },
    photoUrl: 'https://picsum.photos/id/1070/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '10',
    hoursInfo: 'M - F, 7:00 AM - 10:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/o17WAHmKzQH1qYVN6',
    isBookable: true,
    infoUrl:
      'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3020/san-francisco-office',
    maxCapacity: '120',
  },
  '12': {
    id: '12',
    name: 'Vancouver',
    address: {
      street: '1122 Mainland Street Suite 460',
      city: 'Vancouver',
      state: 'BC',
      zip: 'V6B 5L1',
    },
    photoUrl: 'https://picsum.photos/id/1071/300/300',
    latitude: 33.672217,
    longitude: -117.847549,
    floorsInfo: '4',
    hoursInfo: 'M - F, 6:00 AM - 8:00 PM',
    timezone: '',
    mapLinkUrl: 'https://goo.gl/maps/hCSmi67t8VZ6ehW6A',
    isBookable: true,
    infoUrl:
      'https://zallway.zillowgroup.com/sites/how-we-work/SitePageModern/3021/vancouver-office',
    maxCapacity: '2',
  },
};

export const mockOfficesBlockedDates: { [key: string]: BlockedDates } = {
  '1': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '2': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '3': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '4': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '5': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '6': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '7': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '8': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '9': {
    dates: [
      new Date('2021-06-18T00:00:00Z'),
      new Date('2021-06-30T00:00:00Z'),
      new Date('2021-07-05T00:00:00Z'),
    ],
  },
  '10': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '11': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
  '12': {
    dates: [new Date('2021-06-18T00:00:00Z'), new Date('2021-07-05T00:00:00Z')],
  },
};
