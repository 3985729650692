/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Heading, Paragraph, IconSuccess, IconError, Flex } from '@zillow/constellation';
import { Modal } from '../../components/Modal';
import { Reservation, Office } from '../../store/office';
import { DateUtils } from '../../utilities/dateutils';

export interface CancelConfirmationModalProps {
  /** if modal is open */
  isOpen: boolean;
  /** function to handle close */
  onClose: () => void;
  /** list of canceled reservations */
  canceledVisits: Reservation[];
  /** offices */
  officeMap: Map<string, Office>;
  /** if there's a cancellation error */
  hasError?: boolean;
}

export const CancelConfirmationModal: React.FC<CancelConfirmationModalProps> = ({
  isOpen,
  onClose,
  canceledVisits,
  officeMap,
  hasError,
}: CancelConfirmationModalProps) => {
  return (
    <Modal
      fullScreen={{ sm_lte: true }}
      header={<Heading level={6}>Upcoming Visits</Heading>}
      body={
        <Flex paddingBottom="md">
          {hasError ? (
            <>
              <IconError fontColor="red500" size="md" />
              <Heading level={4} marginTop="xs" marginBottom="sm" fontFamily="serif">
                Something went wrong.
              </Heading>
              <Paragraph>Please try again later.</Paragraph>
            </>
          ) : (
            <>
              <IconSuccess fontColor="green500" size="md" />
              <Heading level={4} marginTop="xs" marginBottom="sm" fontFamily="serif">
                The following bookings were successfully canceled:
              </Heading>
              {canceledVisits.length > 0 &&
                canceledVisits.map((v, i) => (
                  <Paragraph key={i}>
                    {officeMap.get(v.officeId)!.name}{' '}
                    {v.visitDate && DateUtils.DateToUSLocaleStr(v.visitDate)}
                  </Paragraph>
                ))}
            </>
          )}
        </Flex>
      }
      isOpen={isOpen}
      handleClose={onClose}
      shouldCloseOnOutsideClick={true}
    />
  );
};
