import { OfficeFloors } from '../../../contract/hqengine_hqweb';
import SEA_Floor_34 from './floorsImages/SEA_floor_34_key_13_14.png';
import SEA_Floor_36 from './floorsImages/SEA_Floor_36.png';
import SEA_Floor_37 from './floorsImages/SEA_Floor_37.png';
import SEA_Floor_38 from './floorsImages/SEA_Floor_38_key_1_2_3_5_8_10_11_12_13_15.png';
import SEA_Floor_39 from './floorsImages/SEA_Floor_39.png';
import SEA_Floor_40 from './floorsImages/SEA_Floor_40_key_1_2_3_4_5_6_8_10_11_13.png';
import IRV_Floor_10 from './floorsImages/IRV_floor_10_key_1_5_9_10_13.png';
import IRV_Floor_11 from './floorsImages/IRV_Floor_11.png';
import IRV_Floor_12 from './floorsImages/IRV_Floor_12.png';
import ATL_Floor_9 from './floorsImages/ATL_floor_9_key_13_14.png';
import ATL_Floor_10 from './floorsImages/ATL_Floor_10.png';
import CIN_Floor_3 from './floorsImages/CIN_Floor_3.png';
import CIN_Floor_4 from './floorsImages/CIN_floor_4_key_1_3_7_8_9_10_11_13_14.png';
import KC_Floor_1 from './floorsImages/KC_floor_1_key_3_10_11_13_14.png';
import NYC_Floor_11 from './floorsImages/NYC_floor_11_key_1_3_7_9_10_11_12_13.png';
import NYC_Floor_12 from './floorsImages/NYC_floor_12.png';
import PHX_Ste_355 from './floorsImages/PHX_Ste_355.png';
import PHX_Ste_390 from './floorsImages/PHX_ste_390_key_1_2_3_4_5_6_7_8_9_10_11_12_13_14.png';
import SFO_Floor_7 from './floorsImages/SF_floor_7_key_1_6_11_13_14.png';
import SFO_Floor_9 from './floorsImages/SFO_Floor_9.png';
import SFO_Floor_10 from './floorsImages/SF_floor_10_key_3_9_10_11_12_13.png';
import VAN_Ste_4 from './floorsImages/VAN_ste_4_key_11.png';

export const mockOfficesFloors: { [key: string]: OfficeFloors } = {
  '1': {
    floors: [
      {
        id: '1',
        officeId: '1',
        name: '34',
        planUrl: SEA_Floor_34,
        isOpen: true,
      },
      {
        id: '2',
        officeId: '1',
        name: '36',
        planUrl: SEA_Floor_36,
        isOpen: true,
      },
      {
        id: '3',
        officeId: '1',
        name: '37',
        planUrl: SEA_Floor_37,
        isOpen: true,
      },
      {
        id: '4',
        officeId: '1',
        name: '38',
        planUrl: SEA_Floor_38,
        isOpen: true,
      },
      {
        id: '5',
        officeId: '1',
        name: '39',
        planUrl: SEA_Floor_39,
        isOpen: true,
      },
      {
        id: '6',
        officeId: '1',
        name: '40',
        planUrl: SEA_Floor_40,
        isOpen: true,
      },
    ],
  },
  '2': {
    floors: [
      {
        id: '6',
        officeId: '2',
        name: '10',
        planUrl: IRV_Floor_10,
        isOpen: true,
      },
      {
        id: '7',
        officeId: '2',
        name: '11',
        planUrl: IRV_Floor_11,
        isOpen: true,
      },
      {
        id: '8',
        officeId: '2',
        name: '12',
        planUrl: IRV_Floor_12,
        isOpen: true,
      },
    ],
  },
  '3': {
    floors: [
      {
        id: '9',
        officeId: '3',
        name: '9',
        planUrl: ATL_Floor_9,
        isOpen: true,
      },
      {
        id: '10',
        officeId: '3',
        name: '10',
        planUrl: ATL_Floor_10,
        isOpen: false,
      },
    ],
  },
  '4': {
    floors: [
      {
        id: '11',
        officeId: '4',
        name: '3',
        planUrl: CIN_Floor_3,
        isOpen: true,
      },
      {
        id: '12',
        officeId: '4',
        name: '4',
        planUrl: CIN_Floor_4,
        isOpen: true,
      },
    ],
  },
  '5': {
    floors: [],
  },
  '6': {
    floors: [],
  },
  '7': {
    floors: [
      {
        id: '13',
        officeId: '7',
        name: '1',
        planUrl: KC_Floor_1,
        isOpen: true,
      },
    ],
  },
  '8': {
    floors: [],
  },
  '9': {
    floors: [
      {
        id: '14',
        officeId: '9',
        name: '11',
        planUrl: NYC_Floor_11,
        isOpen: true,
      },
      {
        id: '15',
        officeId: '9',
        name: '12',
        planUrl: NYC_Floor_12,
        isOpen: true,
      },
    ],
  },
  '10': {
    floors: [
      {
        id: '16',
        officeId: '10',
        name: 'Ste 355',
        planUrl: PHX_Ste_355,
        isOpen: true,
      },
      {
        id: '17',
        officeId: '10',
        name: 'Ste 390',
        planUrl: PHX_Ste_390,
        isOpen: true,
      },
    ],
  },
  '11': {
    floors: [
      {
        id: '18',
        officeId: '11',
        name: '7',
        planUrl: SFO_Floor_7,
        isOpen: true,
      },
      {
        id: '19',
        officeId: '11',
        name: '9',
        planUrl: SFO_Floor_9,
        isOpen: true,
      },
      {
        id: '20',
        officeId: '11',
        name: '10',
        planUrl: SFO_Floor_10,
        isOpen: true,
      },
    ],
  },
  '12': {
    floors: [
      {
        id: '21',
        officeId: '12',
        name: '4',
        planUrl: VAN_Ste_4,
        isOpen: true,
      },
    ],
  },
};
