import { ZEventState, ZEventAction, ZEvent, ZEventUser } from './types';
import { MockZEvent } from './mock/helpers';
import { mockPastZRetreatEvents, mockUpcomingZRetreatEvents } from './mock/zevent';
import { createMockZRetreatEvent } from './mock/helpers';
import {
  CompanyEventUserStatusType,
  CompanyEventUserType,
} from '../../contract/hqengine_company_event';
import { startOfToday } from 'date-fns';
import { DateUtils } from '../../utilities/dateutils';

export const initialState: ZEventState = {
  upcomingZEvents: [] as ZEvent[],
  pastZEvents: [] as ZEvent[],
  justBookedZEventID: undefined,
  currentZEvent: {} as ZEvent,
  currentZEventUsers: [] as ZEventUser[],
};

export const zEventReducer = (state = initialState, action: ZEventAction): ZEventState => {
  switch (action.type) {
    case 'GET_ZEVENTS_SUCCESS':
      return {
        ...state,
        upcomingZEvents: action.listType === 'Upcoming' ? action.zevents : state.upcomingZEvents,
        pastZEvents:
          action.listType === 'Past'
            ? action.zevents.filter((e) =>
                e.endDate ? DateUtils.DateFromUTC(e.endDate) < startOfToday() : false,
              )
            : state.pastZEvents,
      };
    case 'MOCK_GET_UPCOMING_ZEVENTS':
      return {
        ...state,
        upcomingZEvents: mockUpcomingZRetreatEvents,
      };
    case 'MOCK_GET_PAST_ZEVENTS':
      return {
        ...state,
        pastZEvents: mockPastZRetreatEvents,
      };
    case 'GET_ZEVENT_BY_ID_SUCCESS':
      return {
        ...state,
        currentZEvent: action.zevent,
        currentZEventUsers: action.zeventUsers,
      };
    case 'MOCK_GET_ZEVENT_BY_ID': {
      const pastZEvents = state.pastZEvents.length > 0 ? state.pastZEvents : mockPastZRetreatEvents;
      const upcomingZEvents =
        state.upcomingZEvents.length > 0 ? state.upcomingZEvents : mockUpcomingZRetreatEvents;
      const targetEvent: MockZEvent | undefined = pastZEvents
        .concat(upcomingZEvents)
        .find((zevent) => zevent.id === action.eventId);
      return targetEvent === undefined
        ? { ...state }
        : {
            ...state,
            currentZEvent: targetEvent,
            currentZEventUsers: [
              ...targetEvent.hosts,
              ...targetEvent.planners,
              ...(targetEvent.zeventUsers || []),
            ],
          };
    }
    case 'BOOK_ZEVENT_SUCCESS':
      return {
        ...state,
        justBookedZEventID: action.id,
      };
    case 'MOCK_BOOK_ZEVENT_SUCCESS':
      return {
        ...state,
        upcomingZEvents: [
          ...state.upcomingZEvents,
          createMockZRetreatEvent({
            id: action.id,
            createdBy: action.createdBy,
            host: action.host,
            planner2: action.planner2,
            purpose: action.purpose,
            comments: action.comments,
            startDate: action.startDate,
            endDate: action.endDate,
            eventLocation: action.location,
            isCanceled: false,
            totalUserCount: (action.planner2 ? 3 : 2).toString(),
            userType: CompanyEventUserType.COMPANY_EVENT_USER_TYPE_PLANNER,
            userStatus: CompanyEventUserStatusType.COMPANY_EVENT_USER_STATUS_TYPE_ACCEPTED,
          }),
        ],
        justBookedZEventID: action.id,
      };
    case 'CANCEL_ZEVENT_SUCCESS':
    case 'MOCK_CANCEL_ZEVENT':
      return {
        ...state,
        upcomingZEvents: state.upcomingZEvents.map((event) =>
          event.id === action.eventID
            ? {
                ...event,
                isCanceled: true,
                canceledReason: action.reason,
              }
            : event,
        ),
        currentZEvent: {
          ...state.currentZEvent,
          isCanceled: true,
          canceledReason: action.reason,
        },
      };
    case 'RESET_JUST_BOOKED_ZEVENT_ID':
      return {
        ...state,
        justBookedZEventID: undefined,
      };
    case 'SEND_INVITES_SUCCESS':
    case 'MOCK_SEND_INVITES':
      return {
        ...state,
        currentZEventUsers: [...state.currentZEventUsers, ...action.invitees],
        currentZEvent: {
          ...state.currentZEvent,
          totalUserCount: (
            Number.parseInt(state.currentZEvent.totalUserCount) + action.invitees.length
          ).toString(),
        },
      };
    case 'ACK_ZEVENT_SUCCESS':
    case 'MOCK_ACK_ZEVENT':
      return {
        ...state,
        currentZEvent: {
          ...state.currentZEvent,
          userStatus: action.status,
        },
        currentZEventUsers: state.currentZEventUsers.map((u) => {
          if (u.id === action.userId) {
            return {
              ...u,
              status: action.status,
              comments: action.comments,
            };
          }
          return u;
        }),
      };
    default:
      return state;
  }
};
