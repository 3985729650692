import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../../themes/default';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { mediaBreakpointMixin, Page } from '@zillow/constellation';
import { Footer } from '../../components/Footer';
import { ErrorHandler } from '../../components/ErrorHandler';
import { NotFound } from '../../components/PageError';
import { HQAppLogic } from './HQApp.hooks';
import HeaderContainer from '../../containers/HeaderContainer';
import ImpersonationContainer from '../../containers/ImpersonationContainer';
import HomeContainer from '../../containers/HomeContainer';
// import ZRetreatHomeContainer from '../../containers/ZRetreatHomeContainer';
// import ZRetreatDetailsContainer from '../../containers/ZRetreatDetailsContainer';
// import ZRetreatBookingContainer from '../../containers/ZRetreatBookingContainer';
import LoginContainer from '../../containers/LoginContainer';
import FloorPlanContainer from '../../containers/FloorPlanContainer';
import { MassUploadReportContainer } from '../../containers/MassUploadReportContainers';
import AuthMiddleware from '../../middleware/auth';

export const PageWrapper = styled(Page)`
  min-height: 100vh;
`;

export const ContentWrapper = styled.span<{ hidden?: boolean }>`
  @media ${mediaBreakpointMixin('lg')} {
    display: initial;
  }
  display: ${(props) => (props.hidden ? 'none' : 'initial')};
`;

// min-height hack to push footer to the bottom.
export const FooterPushDown = styled.div`
  min-height: ${(props) => `calc(100vh - ${props.theme.footerHeight})`};
`;

export enum RoutePath {
  HOME = '/',
  LOGIN = '/login',
  FLOOR_PLANS = '/floorplans/(office)?/:officeID?/(floor)?/:floorID?',
  MASS_UPLOAD_REPORT = '/upload/report/:reportID',
  Z_RETREAT_HOME = '/zretreat',
  Z_RETREAT_DETAILS = '/zretreat/:eventID',
  Z_RETREAT_BOOKING = '/zretreat/booking',
  NOT_FOUND = '/notfound',
}

const HQApp: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const { isMobileNavOpen, setIsMobileNavOpen, ...impersonationModalProps } = HQAppLogic(props);

  return (
    <AuthMiddleware>
      <ThemeProvider theme={defaultTheme}>
        <ImpersonationContainer {...impersonationModalProps} />
        <PageWrapper
          backgroundColor={
            location.pathname.includes('/floorplans') ? 'backgroundBlue' : 'backgroundLight'
          }
        >
          <FooterPushDown>
            <HeaderContainer
              isMobileNavOpen={isMobileNavOpen}
              setIsMobileNavOpen={setIsMobileNavOpen}
              {...impersonationModalProps}
            />
            <ContentWrapper hidden={isMobileNavOpen}>
              <ErrorHandler>
                <Switch>
                  <Route path={RoutePath.LOGIN} component={LoginContainer} />
                  <Route path={RoutePath.FLOOR_PLANS} component={FloorPlanContainer} />
                  <Route
                    path={RoutePath.MASS_UPLOAD_REPORT}
                    component={MassUploadReportContainer}
                  />
                  <Route exact path={RoutePath.HOME} component={HomeContainer} />
                  {/* <Route exact path={RoutePath.Z_RETREAT_HOME} component={ZRetreatHomeContainer} />
                  <Route path={RoutePath.Z_RETREAT_BOOKING} component={ZRetreatBookingContainer} />
                  <Route path={RoutePath.Z_RETREAT_DETAILS} component={ZRetreatDetailsContainer} /> */}
                  <Route component={NotFound} />
                </Switch>
              </ErrorHandler>
            </ContentWrapper>
          </FooterPushDown>
          <Footer />
        </PageWrapper>
      </ThemeProvider>
    </AuthMiddleware>
  );
};

export default withRouter(HQApp);
