import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  CreateCompanyEventRequest,
  CreateCompanyEventResponse,
  GetAvailableLocationsRequest,
  GetAvailableLocationsResponse,
  CancelCompanyEventRequest,
  CancelCompanyEventResponse,
  GetCompanyEventRequest,
  GetCompanyEventResponse,
  GetCompanyEventsRequest,
  GetCompanyEventsResponse,
  InviteAttendeesForCompanyEventRequest,
  InviteAttendeesForCompanyEventResponse,
  HQWebClientImpl,
  AcknowledgeCompanyEventRequest,
} from '../../contract/hqengine_hqweb';
import { RpcImpl } from '../../contract/rpc';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { GetDisplayError } from '../errorHelpers';
import {
  ZEventState,
  ZEvent,
  ZEventUser,
  ZEventTypeValue,
  ZEventLocation,
  SendInvitesSuccess,
  SendInvitesError,
  SendInvitesAction,
  MockSendInvites,
  ZEventListType,
  GetZEventsAction,
  GetZEventsSuccess,
  BookZEventSuccess,
  ResetJustBookedZEventID,
  AckZEventSuccess,
  AckZEventError,
  AckZEventAction,
  ZEventUserStatusType,
  MockAckZEvent,
  GetZEventByIDSuccess,
  GetZEventByIDError,
  MockGetZEventByID,
  MockGetUpcomingZEvents,
  MockGetPastZEvents,
  MockBookZEventSuccess,
  CancelZEventSuccess,
  MockCancelZEvent,
  CancelZEventAction,
} from './types';
import { mockZEventLocations } from './mock/zevent';
import {
  validateCancelCompanyEventResponse,
  validateGetCompanyEventResponse,
  validateGetCompanyEventsResponse,
  validateInviteAttendeesForCompanyEventResponse,
  validateCreateCompanyEventResponse,
  validateGetAvailableLocationsResponse,
  validateAcknowledgeCompanyEventResponse,
} from './validators';
import { DateUtils } from '../../utilities/dateutils';
import DynamicConfig from '../../config/DynamicConfig';
import { v4 as uuidv4 } from 'uuid';
import { Action } from 'redux';

export const getZEventsSuccess = (
  zevents: ZEvent[],
  listType: ZEventListType,
): GetZEventsSuccess => ({
  type: 'GET_ZEVENTS_SUCCESS',
  zevents: zevents,
  listType: listType,
});

export const getZEvents =
  (
    startDate: Date,
    endDate: Date,
    listType: ZEventListType,
  ): ThunkAction<Promise<void>, ZEventState, null, GetZEventsAction> =>
  async (dispatch: ThunkDispatch<ZEventState, null, GetZEventsAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetCompanyEventsRequest = {
      startDate: DateUtils.DateToUTC(startDate),
      endDate: DateUtils.DateToUTC(endDate),
    };

    return client
      .GetCompanyEvents(req)
      .then((resp: GetCompanyEventsResponse) => {
        const [zevents, err] = validateGetCompanyEventsResponse(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getZEventsSuccess(zevents, listType));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetUpcomingZEvents = (): MockGetUpcomingZEvents => ({
  type: 'MOCK_GET_UPCOMING_ZEVENTS',
});

export const mockGetPastZEvents = (): MockGetPastZEvents => ({
  type: 'MOCK_GET_PAST_ZEVENTS',
});

export const getZEventByIDSuccess = (
  zevent: ZEvent,
  zeventUsers: ZEventUser[],
): GetZEventByIDSuccess => ({
  type: 'GET_ZEVENT_BY_ID_SUCCESS',
  zevent: zevent,
  zeventUsers: zeventUsers,
});

export const getZEventByIDError = (): GetZEventByIDError => ({
  type: 'GET_ZEVENT_BY_ID_ERROR',
});

export const getZEventByID =
  (eventID: string): ThunkAction<Promise<void>, ZEventState, null, Action> =>
  async (dispatch: ThunkDispatch<ZEventState, null, GetZEventsAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetCompanyEventRequest = {
      eventId: eventID,
    };

    return client
      .GetCompanyEvent(req)
      .then((resp: GetCompanyEventResponse) => {
        const [zevent, zeventUsers, err] = validateGetCompanyEventResponse(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getZEventByIDSuccess(zevent, zeventUsers));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetZEventByID = (eventId: string): MockGetZEventByID => ({
  type: 'MOCK_GET_ZEVENT_BY_ID',
  eventId: eventId,
});

export const getAvailableLocations =
  (
    startDate: Date,
    endDate: Date,
  ): ThunkAction<Promise<ZEventLocation[]>, ZEventState, null, Action> =>
  async (): Promise<ZEventLocation[]> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: GetAvailableLocationsRequest = {
      startDate: DateUtils.DateToUTC(startDate),
      endDate: DateUtils.DateToUTC(endDate),
    };

    return client
      .GetAvailableLocations(req)
      .then((resp: GetAvailableLocationsResponse) => {
        const [zeventLocations, err] = validateGetAvailableLocationsResponse(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve(zeventLocations);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockGetAvailableLocations = () => {
  return Promise.resolve(mockZEventLocations);
};

export const bookZEventSuccess = (zEventID: string): BookZEventSuccess => ({
  type: 'BOOK_ZEVENT_SUCCESS',
  id: zEventID,
});

export const bookZEvent =
  (
    purpose: string,
    comments: string,
    startDate: Date,
    endDate: Date,
    locationID: string,
    hostID: string,
    plannerIDs: string[],
    officeID?: string,
  ): ThunkAction<Promise<string>, ZEventState, null, BookZEventSuccess> =>
  async (dispatch: ThunkDispatch<ZEventState, null, BookZEventSuccess>): Promise<string> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: CreateCompanyEventRequest = {
      type: ZEventTypeValue.COMPANY_EVENT_TYPE_ZRETREAT,
      name: '',
      purpose: purpose,
      comments: comments,
      startDate: DateUtils.DateToUTC(startDate),
      endDate: DateUtils.DateToUTC(endDate),
      locationId: locationID,
      host: hostID,
      planners: plannerIDs,
      officeId: officeID,
    };

    return client
      .CreateCompanyEvent(req)
      .then((resp: CreateCompanyEventResponse) => {
        const [zEventID, err] = validateCreateCompanyEventResponse(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(bookZEventSuccess(zEventID));
          return Promise.resolve(zEventID);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockBookZEventSuccess = (
  createdBy: ZEventUser,
  host: ZEventUser,
  planner2: ZEventUser | undefined,
  purpose: string,
  comments: string,
  startDate: Date,
  endDate: Date,
  location: ZEventLocation,
): MockBookZEventSuccess => {
  const eventID = uuidv4();

  return {
    type: 'MOCK_BOOK_ZEVENT_SUCCESS',
    id: eventID,
    createdBy: createdBy,
    host: host,
    planner2: planner2,
    purpose: purpose,
    comments: comments,
    startDate: startDate,
    endDate: endDate,
    location: location,
  };
};

export const resetJustBookedZEventID = (): ResetJustBookedZEventID => ({
  type: 'RESET_JUST_BOOKED_ZEVENT_ID',
});

export const cancelZEventSuccess = (eventID: string, reason: string): CancelZEventSuccess => ({
  type: 'CANCEL_ZEVENT_SUCCESS',
  eventID: eventID,
  reason: reason,
});

export const cancelZEvent =
  (eventID: string, reason: string): ThunkAction<Promise<void>, ZEventState, null, Action> =>
  async (dispatch: ThunkDispatch<ZEventState, null, CancelZEventAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: CancelCompanyEventRequest = {
      eventId: eventID,
      reason: reason,
    };

    return client
      .CancelCompanyEvent(req)
      .then((resp: CancelCompanyEventResponse) => {
        const err = validateCancelCompanyEventResponse(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(cancelZEventSuccess(eventID, reason));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockCancelZEvent = (eventID: string, reason: string): MockCancelZEvent => ({
  type: 'MOCK_CANCEL_ZEVENT',
  eventID: eventID,
  reason: reason,
});

export const sendInvitesSuccess = (invitees: ZEventUser[]): SendInvitesSuccess => ({
  type: 'SEND_INVITES_SUCCESS',
  invitees: invitees,
});

export const sendInvitesError = (msg: string): SendInvitesError => ({
  type: 'SEND_INVITES_ERROR',
  msg: msg,
});

export const sendInvites =
  (
    eventId: string,
    invitees: ZEventUser[],
    inviteMsg: string | undefined,
  ): ThunkAction<Promise<void>, ZEventState, null, SendInvitesAction> =>
  async (dispatch: ThunkDispatch<ZEventState, null, SendInvitesAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: InviteAttendeesForCompanyEventRequest = {
      eventId: eventId,
      attendeeIds: invitees.map((u) => u.id),
      message: inviteMsg,
    };

    return client
      .InviteAttendeesForCompanyEvent(req)
      .then((resp: InviteAttendeesForCompanyEventResponse) => {
        const err = validateInviteAttendeesForCompanyEventResponse(resp);

        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(sendInvitesSuccess(invitees));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockSendInvites = (invitees: ZEventUser[]): MockSendInvites => ({
  type: 'MOCK_SEND_INVITES',
  invitees: invitees,
});

export const ackZEventSuccess = (
  userId: string,
  zEventId: string,
  status: ZEventUserStatusType,
  comments?: string,
): AckZEventSuccess => ({
  type: 'ACK_ZEVENT_SUCCESS',
  userId: userId,
  zEventId: zEventId,
  status: status,
  comments: comments,
});

export const ackZEventError = (): AckZEventError => ({
  type: 'ACK_ZEVENT_ERROR',
});

export const ackZEvent =
  (
    userId: string,
    zEventId: string,
    status: ZEventUserStatusType,
    comments?: string,
    officeID?: string,
  ): ThunkAction<Promise<void>, ZEventState, null, AckZEventAction> =>
  async (dispatch: ThunkDispatch<ZEventState, null, AckZEventAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    const req: AcknowledgeCompanyEventRequest = {
      eventId: zEventId,
      status: status,
      comments: comments,
      officeId: officeID,
    };

    return client
      .AcknowledgeCompanyEvent(req)
      .then((resp) => {
        const err = validateAcknowledgeCompanyEventResponse(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(ackZEventSuccess(userId, zEventId, status, comments));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const errResp = GetDisplayError(err);
        return Promise.reject(errResp);
      });
  };

export const mockAckZEvent = (
  userId: string,
  zEventId: string,
  status: ZEventUserStatusType,
  comments?: string,
): MockAckZEvent => ({
  type: 'MOCK_ACK_ZEVENT',
  userId: userId,
  zEventId: zEventId,
  status: status,
  comments: comments,
});
