import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import {
  HQWebClientImpl,
  AcknowledgeUserInboxMessageRequest,
  AcknowledgeUserInboxMessageResponse,
  GetUserInfoRequest,
  GetUserInfoResponse,
  GetTrainingStatusRequest,
  GetTrainingStatusResponse,
  GetUserInboxMessagesRequest,
  GetUserInboxMessagesResponse,
  GetVaccinationStatusRequest,
  GetVaccinationStatusResponse,
  GetHATTStatusRequest,
  GetHATTStatusResponse,
} from '../../contract/hqengine_hqweb';
import { RpcImpl } from '../../contract/rpc';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import {
  UserState,
  User,
  UserInboxMessage,
  AuthenticateUserAction,
  AuthenticateUserSuccess,
  AuthenticateUserError,
  LoadUserSuccess,
  LoadUserError,
  LoadMockUser,
  LogoutUserSuccess,
  ResetUser,
  LoadUserAction,
  UserStatusAction,
  GetUserTrainingStatusSuccess,
  GetUserInboxMessagesSuccess,
  AcknowledgeUserInboxMessageSuccess,
  GetUserVaccinationStatusSuccess,
  GetUserHATTStatusSuccess,
} from './index';
import {
  validateAuthResp,
  validateAcknowledgeUserInboxMessageResp,
  validateGetUserInfoResp,
  validateGetTrainingStatusResp,
  validateGetUserInboxMessagesResp,
  validateGetVaccinationStatusResp,
  validateGetHATTStatusResp,
} from './validators';
import { StartImpersonationMode, startImpersonationMode } from '../impersonation';
import { GetDisplayError } from '../errorHelpers';
import DynamicConfig from '../../config/DynamicConfig';

export const authenticateUserSuccess = (): AuthenticateUserSuccess => ({
  type: 'AUTHENTICATE_USER_SUCCESS',
});

export const authenticateUserError = (msg: string): AuthenticateUserError => ({
  type: 'AUTHENTICATE_USER_ERROR',
  msg,
});

export const authenticateUser =
  (
    authCode: string,
    redirectUrl: string,
  ): ThunkAction<Promise<void>, UserState, undefined, AuthenticateUserAction> =>
  async (dispatch: ThunkDispatch<UserState, undefined, AuthenticateUserAction>): Promise<void> => {
    const headers = {
      withCredentials: true,
    };
    const url = `${DynamicConfig.GetConfig().HQENGINE_PROXY}/api/auth?auth_code=${authCode}&redirect_uri=${redirectUrl}`;
    return axios
      .get(url, headers)
      .then((resp) => {
        const err = validateAuthResp(resp.data);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(authenticateUserSuccess());
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const error = GetDisplayError(err);
        return Promise.reject(error);
      });
  };

export const loadUserSuccess = (
  user: User,
  canImpersonate: boolean,
  canMassUpload: boolean,
  canPlanZEvent: boolean,
): LoadUserSuccess => ({
  type: 'LOAD_USER_SUCCESS',
  user: user,
  canImpersonate: canImpersonate,
  canMassUpload: canMassUpload,
  canPlanZEvent: canPlanZEvent,
});

export const loadUserError = (msg: string): LoadUserError => ({
  type: 'LOAD_USER_ERROR',
  msg,
});

export const loadMockUser = (
  hasTraining: boolean,
  hasVaccine: boolean,
  canPlanZEvent: boolean,
): LoadMockUser => ({
  type: 'LOAD_MOCK_USER',
  hasTraining: hasTraining,
  hasVaccine: hasVaccine,
  canPlanZEvent: canPlanZEvent,
});

export const loadUserByCookie =
  (): ThunkAction<Promise<User>, UserState, null, LoadUserAction> =>
  async (
    dispatch: ThunkDispatch<UserState, null, LoadUserAction | StartImpersonationMode>,
  ): Promise<User> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    return client
      .GetUserInfo({} as GetUserInfoRequest)
      .then((resp: GetUserInfoResponse) => {
        const [user, canImpersonate, canMassUpload, canPlanZEvent, impersonator, err] =
          validateGetUserInfoResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(loadUserSuccess(user, canImpersonate, canMassUpload, canPlanZEvent));

          if (impersonator !== undefined) {
            dispatch(startImpersonationMode(impersonator));
          }
          return Promise.resolve(user);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const error = GetDisplayError(err);
        dispatch(loadUserError(`ErrorType: ${error.code}`));
        return Promise.reject(error);
      });
  };

export const logoutUserSuccess = (): LogoutUserSuccess => ({
  type: 'LOGOUT_USER_SUCCESS',
});

export const resetUser = (): ResetUser => ({
  type: 'RESET_USER',
});

// export const logoutUser = (): ThunkAction<
//   Promise<void>,
//   UserState,
//   undefined,
//   LogoutUserAction
// > => async (dispatch: ThunkDispatch<UserState, null, LogoutUserAction>): Promise<void> => {
//   return {} as Promise<void>;
// };

export const getUserTrainingStatus =
  (): ThunkAction<Promise<void>, UserState, null, UserStatusAction> =>
  async (dispatch: ThunkDispatch<UserState, null, UserStatusAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    return client
      .GetTrainingStatus({} as GetTrainingStatusRequest)
      .then((resp: GetTrainingStatusResponse) => {
        const [isTrained, err] = validateGetTrainingStatusResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getUserTrainingSuccess(isTrained));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const getUserTrainingSuccess = (isTrained: boolean): GetUserTrainingStatusSuccess => ({
  type: 'GET_USER_TRAINING_STATUS_SUCCESS',
  isTrained: isTrained,
});

export const getUserInboxMessages =
  (): ThunkAction<Promise<void>, UserState, null, UserStatusAction> =>
  async (dispatch: ThunkDispatch<UserState, null, UserStatusAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    return client
      .GetUserInboxMessages({} as GetUserInboxMessagesRequest)
      .then((resp: GetUserInboxMessagesResponse) => {
        const [inboxMessages, err] = validateGetUserInboxMessagesResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getUserInboxMessagesSuccess(inboxMessages));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const getUserInboxMessagesSuccess = (
  inboxMessages: UserInboxMessage[],
): GetUserInboxMessagesSuccess => ({
  type: 'GET_USER_INBOX_MESSAGE_SUCCESS',
  userInboxMessages: inboxMessages,
});

export const acknowledgeUserInboxMessage =
  (messageId: string): ThunkAction<Promise<void>, UserState, null, UserStatusAction> =>
  async (dispatch: ThunkDispatch<UserState, null, UserStatusAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    return client
      .AcknowledgeUserInboxMessage({
        inboxMessageId: messageId,
      } as AcknowledgeUserInboxMessageRequest)
      .then((resp: AcknowledgeUserInboxMessageResponse) => {
        const [err] = validateAcknowledgeUserInboxMessageResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(acknowledgeUserInboxMessageSuccess(messageId));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const acknowledgeUserInboxMessageSuccess = (
  messageId: string,
): AcknowledgeUserInboxMessageSuccess => ({
  type: 'ACKNOWLEDGE_USER_INBOX_MESSAGE_SUCCESS',
  messageId: messageId,
});

export const getUserVaccinationStatus =
  (): ThunkAction<Promise<void>, UserState, null, UserStatusAction> =>
  async (dispatch: ThunkDispatch<UserState, null, UserStatusAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    return client
      .GetVaccinationStatus({} as GetVaccinationStatusRequest)
      .then((resp: GetVaccinationStatusResponse) => {
        const [isVaccinated, err] = validateGetVaccinationStatusResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getUserVaccinationStatusSuccess(isVaccinated));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const getUserVaccinationStatusSuccess = (
  isVaccinated: boolean,
): GetUserVaccinationStatusSuccess => ({
  type: 'GET_USER_VACCINATION_STATUS_SUCCESS',
  isVaccinated: isVaccinated,
});

export const getUserHATTStatus =
  (): ThunkAction<Promise<void>, UserState, null, UserStatusAction> =>
  async (dispatch: ThunkDispatch<UserState, null, UserStatusAction>): Promise<void> => {
    const client = new HQWebClientImpl(new RpcImpl(DynamicConfig.GetConfig().HQENGINE_PROXY_RPC_URL));
    return client
      .GetHATTStatus({} as GetHATTStatusRequest)
      .then((resp: GetHATTStatusResponse) => {
        const [hasHATT, err] = validateGetHATTStatusResp(resp);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(getUserHATTStatusSuccess(hasHATT));
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const getUserHATTStatusSuccess = (hasHATT: boolean): GetUserHATTStatusSuccess => ({
  type: 'GET_USER_HATT_STATUS_SUCCESS',
  hasHATT: hasHATT,
});
