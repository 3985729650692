import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Heading,
  FormField,
  Label,
  LoadingButton,
  Flex,
  TextButton,
  Paragraph,
  IconButton,
  IconClose,
  Alert,
  ButtonGroup,
} from '@zillow/constellation';
import { RootState } from '../../store';
import { searchEmployees, EmployeeFound, SearchEmployeesType } from '../../store/search';
import { User, selectUserInfo } from '../../store/user';
import {
  selectIsImpersonating,
  selectImpersonator,
  impersonateUser,
  stopImpersonation,
} from '../../store/impersonation';
import { Modal } from '../../components/Modal';
import { ImpersonationContainerLogic } from './ImpersonationContainer.hooks';
import { ImpersonationBanner } from './ImpersonationBanner';
import { ErrorType } from '../../store/errorHelpers';
import { Searchbox } from '../../components/Searchbox';

export interface StateProps {
  /** is currently in impersonation mode */
  isImpersonating: boolean;
  /** user being impersonated (only if isImpersonating is true) */
  impersonatee: User;
  /** user impersonation request */
  impersonateUser: (employeeId: string) => Promise<void>;
  /** stop impersonation */
  stopImpersonation: () => Promise<void>;
  /** search employees */
  searchEmployees: (keyword: string, searchType?: SearchEmployeesType) => Promise<EmployeeFound[]>;
}

interface OwnProps {
  /** is impersonation modal open */
  isImpersonationModalOpen: boolean;
  /** setter for is impersonation modal open */
  setIsImpersonationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ImpersonationContainerProps = StateProps & OwnProps;

const ModalBody = styled(Flex)`
  min-height: 350px;
`;

const ImpersonationContainer: React.FC<ImpersonationContainerProps> = (
  props: ImpersonationContainerProps,
) => {
  const { isImpersonating, isImpersonationModalOpen, impersonatee } = props;
  const {
    searchInput,
    isLoadingOptions,
    searchOptions,
    selectedOption,
    isRequestingImpersonation,
    isStoppingImpersonation,
    startImpersonationErr,
    handleOnInputChange,
    handleSelectOption,
    handleResetSelectedOption,
    handleStartImpersonation,
    handleStopImpersonation,
    handleImpersonateClick,
    handleCloseModal,
  } = ImpersonationContainerLogic(props);

  return (
    <>
      {isImpersonating && (
        <ImpersonationBanner
          {...{
            impersonatee,
            handleImpersonateClick,
            handleStopImpersonation,
            isStoppingImpersonation,
          }}
        />
      )}

      <Modal
        isOpen={isImpersonationModalOpen}
        shouldCloseOnOutsideClick={false}
        fullScreen={{ sm_lte: true }}
        overflowY={'initial'}
        header={<Heading level={6}>Impersonation</Heading>}
        body={
          <ModalBody display="flex" flexDirection="column" justifyContent="space-between">
            <Flex>
              <Paragraph marginBottom="md">
                Use this function to book or cancel a day pass on behalf of another person. You can
                only search and impersonate employees you have permissions for.
              </Paragraph>
              {selectedOption ? (
                <>
                  <Heading level={6}>Employee to impersonate:</Heading>
                  <Flex
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="sm"
                  >
                    {selectedOption.children}
                    <IconButton
                      buttonType="caution"
                      appearance="circle"
                      size="sm"
                      title={'Reset employee to impersonate'}
                      icon={<IconClose />}
                      onClick={handleResetSelectedOption}
                    />
                  </Flex>
                </>
              ) : (
                <FormField
                  label={<Label>Whom do you want to impersonate?</Label>}
                  control={
                    <Searchbox
                      searchOptions={searchInput.length ? searchOptions : []}
                      isLoadingOptions={isLoadingOptions}
                      inputPlaceholder={'Search employee name'}
                      handleInputChange={handleOnInputChange}
                      handleSelectOption={handleSelectOption}
                    />
                  }
                />
              )}
            </Flex>

            {startImpersonationErr !== ErrorType.NONE && (
              <Alert appearance="error" body="Something went wrong. Please try again later." />
            )}
          </ModalBody>
        }
        handleClose={handleCloseModal}
        footer={
          <ButtonGroup aria-label="Impersonation actions">
            <TextButton onClick={handleCloseModal}>Cancel</TextButton>
            <LoadingButton
              buttonType="primary"
              disabled={!selectedOption}
              loading={isRequestingImpersonation}
              onClick={handleStartImpersonation}
            >
              Start
            </LoadingButton>
          </ButtonGroup>
        }
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isImpersonating: selectIsImpersonating(state),
  impersonator: selectImpersonator(state),
  impersonatee: selectUserInfo(state),
});

const mapDispatchToProps = {
  searchEmployees: searchEmployees,
  impersonateUser: impersonateUser,
  stopImpersonation: stopImpersonation,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(ImpersonationContainer);
