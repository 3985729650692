import React, { createContext, useState } from 'react';

type IsBookingInProgressContextType = {
  isBookingInProgress: boolean;
  setIsBookingInProgress: React.Dispatch<React.SetStateAction<boolean>>;
};

export const IsBookingInProgressContext = createContext({} as IsBookingInProgressContextType);

export const IsBookingInProgressProvider = ({ children }: { children: React.ReactNode }) => {
  const [isBookingInProgress, setIsBookingInProgress] = useState(false);

  return (
    <IsBookingInProgressContext.Provider value={{ isBookingInProgress, setIsBookingInProgress }}>
      {children}
    </IsBookingInProgressContext.Provider>
  );
};
