import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import DynamicConfig from '../../config/DynamicConfig';

export const HQAppLogic = ({ history, location }: RouteComponentProps) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isImpersonationModalOpen, setIsImpersonationModalOpen] = useState(false);

  /** init GA and set up page tracking */
  useEffect(() => {
    GoogleAnalyticsUtils.Initialize(DynamicConfig.GetConfig().GA_MEASUREMENT_ID);
    GoogleAnalyticsUtils.Pageview(location.pathname);

    history.listen((loc) => {
      GoogleAnalyticsUtils.Pageview(loc.pathname);
    });
  }, []);

  return {
    isMobileNavOpen,
    setIsMobileNavOpen,
    isImpersonationModalOpen,
    setIsImpersonationModalOpen,
  };
};
