/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  GetAllOfficesResponse,
  GetAllOfficeFloorsResponse,
  GetAllOfficesRequest,
  GetAllOfficeFloorsRequest,
} from './hqengine_hqweb';

export const protobufPackage = 'peng_hqengine_contract';

export interface HQWebInternal {
  GetAllOffices(request: GetAllOfficesRequest): Promise<GetAllOfficesResponse>;
  GetAllOfficeFloors(request: GetAllOfficeFloorsRequest): Promise<GetAllOfficeFloorsResponse>;
}

export class HQWebInternalClientImpl implements HQWebInternal {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetAllOffices = this.GetAllOffices.bind(this);
    this.GetAllOfficeFloors = this.GetAllOfficeFloors.bind(this);
  }
  GetAllOffices(request: GetAllOfficesRequest): Promise<GetAllOfficesResponse> {
    const data = GetAllOfficesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWebInternal', 'GetAllOffices', data);
    return promise.then((data) => GetAllOfficesResponse.decode(new _m0.Reader(data)));
  }

  GetAllOfficeFloors(request: GetAllOfficeFloorsRequest): Promise<GetAllOfficeFloorsResponse> {
    const data = GetAllOfficeFloorsRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWebInternal', 'GetAllOfficeFloors', data);
    return promise.then((data) => GetAllOfficeFloorsResponse.decode(new _m0.Reader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
