import React from 'react';
import styled from 'styled-components';
import { Image, Heading, Paragraph, mediaBreakpointMixin, token } from '@zillow/constellation';

export interface PageErrorProps {
  /** error type */
  title: string;
  /** error description */
  description: React.ReactNode;
  /** error image source */
  imageSrc: string;
}

const PageWrapper = styled.div`
  height: 100vh;
  background-color: ${token('colors.backgroundBlue')};
`;

const ErrorWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;

  @media ${mediaBreakpointMixin('md')} {
    top: 45%;
  }
`;

const Clipart = styled(Image)`
  width: 50%;
  max-width: 175px;
`;

export const PageError: React.FC<PageErrorProps> = ({
  title,
  description,
  imageSrc,
}: PageErrorProps) => {
  return (
    <PageWrapper>
      <ErrorWrapper data-testid={"render-"+title+"-test"}>
        <Clipart alt={'error image'} title={title} src={imageSrc} />
        <Heading
          data-testid="render-error-header-test"
          level={2}
          marginTop={{ default: 'xs', lg: 'sm' }}
          fontType={{ default: 'h3', xl: 'h2', sm_lte: 'h5' }}
        >
          {title}
        </Heading>
        <Paragraph
          data-testid="render-error-description-test"
          marginTop={{ default: 'xs', lg: 'md' }}
          fontType={{ default: 'h5', xl: 'h4', sm_lte: 'body' }}
        >
          {description}
        </Paragraph>
      </ErrorWrapper>
    </PageWrapper>
  );
};
