import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { ImpersonationState, StartImpersonationMode, StopImpersonationAction } from './index';
import { validateStartImpersonationResp, validateStopImpersonationResp } from './validators';
import { User } from '../user';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { GetDisplayError } from '../errorHelpers';
import DynamicConfig from '../../config/DynamicConfig';

export const impersonateUser =
  (employeeId: string): ThunkAction<Promise<void>, ImpersonationState, undefined, Action> =>
  async (): Promise<void> => {
    const headers = {
      withCredentials: true,
    };
    const url = `${DynamicConfig.GetConfig().HQENGINE_PROXY}/impersonation/start?impersonatee_id=${employeeId}`;
    return axios
      .get(url, headers)
      .then((resp) => {
        const err = validateStartImpersonationResp(resp.data);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const error = GetDisplayError(err);
        return Promise.reject(error);
      });
  };

export const startImpersonationMode = (impersonator: User): StartImpersonationMode => ({
  type: 'START_IMPERSONATION_MODE',
  impersonator: impersonator,
});

export const stopImpersonationSuccess = (): StopImpersonationAction => ({
  type: 'STOP_IMPERSONATION_SUCCESS',
});

export const stopImpersonation =
  (): ThunkAction<Promise<void>, ImpersonationState, undefined, StopImpersonationAction> =>
  async (
    dispatch: ThunkDispatch<ImpersonationState, undefined, StopImpersonationAction>,
  ): Promise<void> => {
    const headers = {
      withCredentials: true,
    };
    const url = `${DynamicConfig.GetConfig().HQENGINE_PROXY}/impersonation/stop`;
    return axios
      .get(url, headers)
      .then((resp) => {
        const err = validateStopImpersonationResp(resp.data);
        if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
          dispatch(stopImpersonationSuccess());
          return Promise.resolve();
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        const error = GetDisplayError(err);
        return Promise.reject(error);
      });
  };
