/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  StatusType,
  statusTypeFromJSON,
  statusTypeToJSON,
} from './gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import {
  AcknowledgeBookingResponseType,
  OfficeVisit,
  acknowledgeBookingResponseTypeFromJSON,
  acknowledgeBookingResponseTypeToJSON,
} from './hqengine_office_visit';
import { StringValue } from './google/protobuf/wrappers';

export const protobufPackage = 'peng_hqengine_contract';

/** AcknowledgeOfficeVisit */
export interface AcknowledgeOfficeVisitRequest {
  token: string;
}

export interface AcknowledgeOfficeVisitResponse {
  status: StatusType;
  message: string;
  resultType: AcknowledgeBookingResponseType;
  visit: OfficeVisit | undefined;
  officeName: string | undefined;
}

const baseAcknowledgeOfficeVisitRequest: object = { token: '' };

export const AcknowledgeOfficeVisitRequest = {
  encode(
    message: AcknowledgeOfficeVisitRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.token !== '') {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcknowledgeOfficeVisitRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAcknowledgeOfficeVisitRequest } as AcknowledgeOfficeVisitRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeOfficeVisitRequest {
    const message = { ...baseAcknowledgeOfficeVisitRequest } as AcknowledgeOfficeVisitRequest;
    if (object.token !== undefined && object.token !== null) {
      message.token = String(object.token);
    } else {
      message.token = '';
    }
    return message;
  },

  toJSON(message: AcknowledgeOfficeVisitRequest): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },

  fromPartial(object: DeepPartial<AcknowledgeOfficeVisitRequest>): AcknowledgeOfficeVisitRequest {
    const message = { ...baseAcknowledgeOfficeVisitRequest } as AcknowledgeOfficeVisitRequest;
    if (object.token !== undefined && object.token !== null) {
      message.token = object.token;
    } else {
      message.token = '';
    }
    return message;
  },
};

const baseAcknowledgeOfficeVisitResponse: object = { status: 0, message: '', resultType: 0 };

export const AcknowledgeOfficeVisitResponse = {
  encode(
    message: AcknowledgeOfficeVisitResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.resultType !== 0) {
      writer.uint32(24).int32(message.resultType);
    }
    if (message.visit !== undefined) {
      OfficeVisit.encode(message.visit, writer.uint32(34).fork()).ldelim();
    }
    if (message.officeName !== undefined) {
      StringValue.encode({ value: message.officeName! }, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcknowledgeOfficeVisitResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAcknowledgeOfficeVisitResponse } as AcknowledgeOfficeVisitResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.resultType = reader.int32() as any;
          break;
        case 4:
          message.visit = OfficeVisit.decode(reader, reader.uint32());
          break;
        case 5:
          message.officeName = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeOfficeVisitResponse {
    const message = { ...baseAcknowledgeOfficeVisitResponse } as AcknowledgeOfficeVisitResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.resultType !== undefined && object.resultType !== null) {
      message.resultType = acknowledgeBookingResponseTypeFromJSON(object.resultType);
    } else {
      message.resultType = 0;
    }
    if (object.visit !== undefined && object.visit !== null) {
      message.visit = OfficeVisit.fromJSON(object.visit);
    } else {
      message.visit = undefined;
    }
    if (object.officeName !== undefined && object.officeName !== null) {
      message.officeName = String(object.officeName);
    } else {
      message.officeName = undefined;
    }
    return message;
  },

  toJSON(message: AcknowledgeOfficeVisitResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.resultType !== undefined &&
      (obj.resultType = acknowledgeBookingResponseTypeToJSON(message.resultType));
    message.visit !== undefined &&
      (obj.visit = message.visit ? OfficeVisit.toJSON(message.visit) : undefined);
    message.officeName !== undefined && (obj.officeName = message.officeName);
    return obj;
  },

  fromPartial(object: DeepPartial<AcknowledgeOfficeVisitResponse>): AcknowledgeOfficeVisitResponse {
    const message = { ...baseAcknowledgeOfficeVisitResponse } as AcknowledgeOfficeVisitResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.resultType !== undefined && object.resultType !== null) {
      message.resultType = object.resultType;
    } else {
      message.resultType = 0;
    }
    if (object.visit !== undefined && object.visit !== null) {
      message.visit = OfficeVisit.fromPartial(object.visit);
    } else {
      message.visit = undefined;
    }
    if (object.officeName !== undefined && object.officeName !== null) {
      message.officeName = object.officeName;
    } else {
      message.officeName = undefined;
    }
    return message;
  },
};

export interface HQWebExternal {
  AcknowledgeOfficeVisit(
    request: AcknowledgeOfficeVisitRequest,
  ): Promise<AcknowledgeOfficeVisitResponse>;
}

export class HQWebExternalClientImpl implements HQWebExternal {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.AcknowledgeOfficeVisit = this.AcknowledgeOfficeVisit.bind(this);
  }
  AcknowledgeOfficeVisit(
    request: AcknowledgeOfficeVisitRequest,
  ): Promise<AcknowledgeOfficeVisitResponse> {
    const data = AcknowledgeOfficeVisitRequest.encode(request).finish();
    const promise = this.rpc.request(
      'peng_hqengine_contract.HQWebExternal',
      'AcknowledgeOfficeVisit',
      data,
    );
    return promise.then((data) => AcknowledgeOfficeVisitResponse.decode(new _m0.Reader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
