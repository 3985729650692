import React from 'react';
import { Anchor, Paragraph, Spacer, Heading } from '@zillow/constellation';
import { PrereqWrapper, PlaceholderImage } from './index';
import errorImage from '../../assets/404Error.png';
import DynamicConfig from '../../config/DynamicConfig';

export const BookingPrereqError: React.FC = () => {
  return (
    <PrereqWrapper
      data-testid='render-prereq-error-test'
      media={<PlaceholderImage alt={'system error'} title={'system error'} src={errorImage} />}
      direction="column"
      padding="md"
    >
      <Heading level="5">Booking tool error</Heading>
      <Spacer margin="xs" />
      <Paragraph data-testid='render-prereq-error-text-test'>
        We are unable to confirm your eligibility to book an office visit at this time. If you have
        an immediate booking need, please submit a support ticket to{' '}
        <Anchor href={DynamicConfig.GetConfig().PPL_OPS_SUPPORT_URL}>
          People Operations
        </Anchor>{' '}
        for assistance.
      </Paragraph>
    </PrereqWrapper>
  );
};
