import React, { useEffect, createRef } from 'react';
import styled from 'styled-components';
import {
  spaceMixin,
  Flex,
  IconButton,
  IconPlus,
  IconMinus,
  Divider,
  token,
  ButtonGroup,
} from '@zillow/constellation';
import ScrollContainer from 'react-indiana-drag-scroll';

export interface FloorPlanDesktopProps {
  /** floor plan img src */
  imgSrc: string;
  /** floor plan img alt */
  imgAlt: string;
}

const ZOOM_FACTOR = 350; // number of pixels to zoom in/out by
const MIN_IMG_WIDTH = 800;
const MAX_IMG_WIDTH = 2500;

const LeftPanelWidth = '350px';

const DragscrollContainer = styled(ScrollContainer)`
  box-sizing: border-box;
  padding: ${spaceMixin('md')};
  height: ${(props) => `calc(100vh - ${props.theme.desktopHeaderHeight})`};
  overflow: hidden;
`;

const FloorPlanImgFlex = styled(Flex)`
  display: flex;
  height: 100%;
`;

const LeftPanelSpace = styled(Flex)`
  flex: 0 1 ${LeftPanelWidth};
`;

const ImgWrapper = styled(Flex)`
  display: flex;
  flex: 1 0 auto;
  height: 100%;
`;

const FloorPlanImg = styled.img`
  cursor: grab;
  height: auto;
  width: auto;
  margin: auto;
  transition: 0.5s;
`;

const ZoomButtonGroup = styled(ButtonGroup)`
  position: fixed;
  right: 2.5%;
  bottom: 3.7%;
  background: ${token('colors.white')};
  box-shadow: ${token('shadows.shadow100')};
  border-radius: 6px;
`;

const ZoomButton = styled(IconButton)`
  padding: 0 ${spaceMixin('md')};
`;

export const FloorPlanDesktop: React.FC<FloorPlanDesktopProps> = (props: FloorPlanDesktopProps) => {
  const { imgSrc, imgAlt } = props;
  const imageRef = createRef<HTMLImageElement>();

  const zoomIn = () => {
    const { current } = imageRef;
    if (!current) return;
    current.style.maxWidth = 'initial';
    const currWidth = current.clientWidth;
    if (currWidth < MAX_IMG_WIDTH) {
      current.style.width = `${currWidth + ZOOM_FACTOR}px`;
    }
  };

  const zoomOut = () => {
    const { current } = imageRef;
    if (!current) return;
    const currWidth = current.clientWidth;
    if (currWidth > MIN_IMG_WIDTH) {
      current.style.width = `${currWidth - ZOOM_FACTOR}px`;
    }
  };

  /** set width from 'auto' to the current width
   *  so that the css transition will be smooth */
  const initImgWidth = () => {
    const { current } = imageRef;
    if (current) {
      const currWidth = current.clientWidth;
      current.style.width = currWidth ? `${currWidth}px` : 'auto';
    }
  };

  /** reset image styles when imgSrc changes */
  useEffect(() => {
    const { current } = imageRef;
    if (current) {
      current.style.width = 'auto';
      current.style.maxWidth = `calc(100vw - ${LeftPanelWidth})`;
    }
  }, [imgSrc]);

  return (
    <>
      <DragscrollContainer>
        <FloorPlanImgFlex>
          <LeftPanelSpace />
          <ImgWrapper>
            <FloorPlanImg ref={imageRef} src={imgSrc} alt={imgAlt} onLoad={initImgWidth} />
          </ImgWrapper>
        </FloorPlanImgFlex>
      </DragscrollContainer>
      <ZoomButtonGroup aria-label="Floor plan zoom controls" conjoined direction="column">
        <ZoomButton bare title="Zoom In" icon={<IconPlus />} onClick={zoomIn} />
        <Divider length="fluid" />
        <ZoomButton bare title="Zoom Out" icon={<IconMinus />} onClick={zoomOut} />
      </ZoomButtonGroup>
    </>
  );
};
