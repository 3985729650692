import axios from 'axios';
import { HQWEB_API_BASE_URL } from './config.common';

const CONFIG_URL = `${HQWEB_API_BASE_URL}/configs`;
type Config = {
    ENV: string;
    ENABLE_ZGU_TRAINING_REQ: string;
    HQENGINE_PROXY: string;
    HQENGINE_PROXY_RPC_URL: string;
    HQENGINE_HOST: string;
    OKTA_OAUTH_URL: string;
    OKTA_CLIENT_ID: string;
    GA_MEASUREMENT_ID: string;
    DW_PORTAL_URL: string;
    HEALTH_ATTESTATION_URL: string;
    BUSINESS_TRAVEL_URL: string;
    RETURN_TO_OFFICE_TRAINING_URL: string;
    TRAVEL_AND_EXPENSE_POLICY_URL: string;
    CLOUD_HQ_ZALLWAY_URL: string;
    SPACE_BOOKING_INSTRUCTIONS_URL: string;
    ZRETREAT_PLANNING_MATERIALS_URL: string;
    PEOPLE_OPS_EMAIL: string;
    FLEXIBLE_WORK_TEAM_EMAIL: string;
    PPL_OPS_SUPPORT_URL: string;
};

class DynamicConfig {
    InitConfig() {
        return axios.get(CONFIG_URL).then((response) => {
            const config: Config = response.data;
            localStorage.setItem('config', JSON.stringify(config));
            return config;
        });
    }

    GetConfig() {
        if (localStorage.getItem('config')) {
            const config = JSON.parse(localStorage.getItem('config') as string) as Config;
            return config;
        }
        return {} as Config;
    }
}

export default new DynamicConfig();
