import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import {
  CreateCompanyEventResponse,
  GetAvailableLocationsResponse,
  CancelCompanyEventResponse,
  GetCompanyEventResponse,
  GetCompanyEventsResponse,
  InviteAttendeesForCompanyEventResponse,
  AcknowledgeCompanyEventResponse,
} from '../../contract/hqengine_hqweb';
import { GetErrorStatusType, HQEngineErrorResponse } from '../errorHelpers';
import { ZEvent, ZEventLocation, ZEventUser } from './types';

export const validateGetCompanyEventsResponse = (
  resp: GetCompanyEventsResponse,
): [ZEvent[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    (resp.status !== StatusType.STATUS_TYPE_SUCCESS &&
      resp.status !== StatusType.STATUS_TYPE_NOT_FOUND)
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.events, errResp];
};

export const validateGetCompanyEventResponse = (
  resp: GetCompanyEventResponse,
): [ZEvent, ZEventUser[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.event || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as ZEvent, [], errResp];
  }

  return [resp.event, resp.users, errResp];
};

export const validateInviteAttendeesForCompanyEventResponse = (
  resp: InviteAttendeesForCompanyEventResponse,
): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateGetAvailableLocationsResponse = (
  resp: GetAvailableLocationsResponse,
): [ZEventLocation[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    (resp.status !== StatusType.STATUS_TYPE_SUCCESS &&
      resp.status !== StatusType.STATUS_TYPE_NOT_FOUND)
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.locations, errResp];
};

export const validateCreateCompanyEventResponse = (
  resp: CreateCompanyEventResponse,
): [string, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.eventId || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return ['', errResp];
  }

  return [resp.eventId, errResp];
};

export const validateCancelCompanyEventResponse = (
  resp: CancelCompanyEventResponse,
): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateAcknowledgeCompanyEventResponse = (
  resp: AcknowledgeCompanyEventResponse,
): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};
