import React from 'react';
import styled from 'styled-components';
import {
  token,
  MediaObject,
  Paragraph,
  Spacer,
  Heading,
  Button,
  IconArrowRightOutline,
  Image,
} from '@zillow/constellation';
import clipboardImage from '../../assets/clipboard.png';
import DynamicConfig from '../../config/DynamicConfig';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';

export interface BookingPrereqPanelProps {
  /** has return to office training or not */
  hasZGUTraining: boolean;
  /** has vaccine or not */
  hasVaccination: boolean;
}

export const PrereqWrapper = styled(MediaObject)`
  background: ${token('colors.backgroundBlue')};
`;

export const PlaceholderImage = styled(Image)`
  height: 90px;
`;

export const BookingPrereqPanel: React.FC<BookingPrereqPanelProps> = ({
  hasZGUTraining,
  hasVaccination,
}: BookingPrereqPanelProps) => {
  return (
    <PrereqWrapper data-testid='render-prereq-panel-test'
      media={<PlaceholderImage alt={'clipboard image'} title={'clipboard'} src={clipboardImage} />}
      direction="column"
      padding="md"
    >
      <Heading level="5">Booking requirement</Heading>
      <Spacer margin="xs" />
      <Paragraph data-testid='render-proof-vaccine-text-test' >
        Submit proof of vaccination to book an office visit. Once complete, you will see the booking
        tool populate here.
      </Paragraph>
      {!hasZGUTraining && (
        <Button
          data-testid='render-no-training-button-test'
          as="a"
          href="#"
          target="_blank"
          buttonType="secondary"
          icon={<IconArrowRightOutline />}
          iconPosition="end"
          marginTop="sm"
          onClick={() =>
            alert('When available, this button will direct the user to ZG University.')
          }
        >
          Take training
        </Button>
      )}
      {!hasVaccination && (
        <Button
          data-testid='render-no-vaccine-button-test'
          as="a"
          href={DynamicConfig.GetConfig().DW_PORTAL_URL}
          target="_blank"
          buttonType="secondary"
          icon={<IconArrowRightOutline />}
          iconPosition="end"
          marginTop="sm"
          fontType="bodySmallHeading"
          onClick={() => GoogleAnalyticsUtils.ClickCompleteAttestation()}
        >
          Complete attestation
        </Button>
      )}
    </PrereqWrapper>
  );
};
