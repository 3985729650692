import { AuthResponse } from '../../contract/hqengine_hqweb';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { HQEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';

export const validateStartImpersonationResp = (resp: AuthResponse): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.user || !resp.impersonator || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateStopImpersonationResp = (resp: AuthResponse): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.user || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};
