import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import {
  Page,
  Image,
  IconMenuOutline,
  mediaBreakpointMixin,
  spaceMixin,
  token,
  IconClose,
  TextButton,
  List,
  IconTeamOutline,
  IconDollarSignCircleOutline,
  IconCarOutline,
  IconAskQuestionOutline,
  PageContent,
  IconButton,
  Menu,
  MenuPopper,
  MenuItem,
  IconChevronDownOutline,
  Flex,
} from '@zillow/constellation';
import { NoStyleLink } from '../../components/Links';
import { AvatarDisk } from '../../components/AvatarDisk';
import { IsMockContext } from '../../middleware/auth';
import zallPassLogoImg from '../../assets/zallPassLogo.png';
import DynamicConfig from '../../config/DynamicConfig';
import { RoutePath } from '../../pages/HQApp';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import {
  MassUploadModalContainer,
  MassUploadReportsModalContainer,
} from '../MassUploadReportContainers';
import {
  User,
  selectUserInfo,
  selectLoggedIn,
  selectCanImpersonate,
  selectCanMassUpload,
} from '../../store/user';
import { selectIsImpersonating } from '../../store/impersonation';

interface StateProps {
  /** is user logged in */
  loggedIn: boolean;
  /** user info */
  user: User;
  /** is user allowed to impersonate */
  canImpersonate: boolean;
  /** is currently in impersonation mode */
  isImpersonating: boolean;
  /** is user allowed to mass upload */
  canMassUpload: boolean;
}

interface OwnProps {
  /** is mobile nav open */
  isMobileNavOpen: boolean;
  /** setter to open/close mobile nav */
  setIsMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** setter for is impersonation modal open */
  setIsImpersonationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type HeaderContainerProps = StateProps & OwnProps;

const HeaderWrapper = styled.header`
  background: ${token('colors.white')};
  border-bottom: 1px solid ${token('colors.gray300')};
`;

const HeaderContent = styled(PageContent)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  margin: 0 auto;
`;

const LogoWrapper = styled.div`
  grid-column: 2;
  padding: ${spaceMixin('sm')} ${spaceMixin('xs')};
`;

const ZallPassLogo = styled(Image)`
  @media ${mediaBreakpointMixin('xl')} {
    width: 255px;
  }
  margin: auto;
  display: block;
  width: 170px;
`;

const HideOnLargeScreens = css`
  @media ${mediaBreakpointMixin('lg')} {
    display: none;
  }
`;

const MobileMenuControl = styled.div`
  ${HideOnLargeScreens}
  grid-column: 1;
  margin: auto auto auto 0;
`;

const MobileNavWrapper = styled(Page)<{ isImpersonating: boolean }>`
  ${HideOnLargeScreens}
  position: absolute;
  left: 0;
  top: ${(props) => (props.isImpersonating ? '192px' : props.theme.mobileHeaderHeight)};
  width: 100%;
  height: ${(props) => `calc(100vh - ${props.theme.mobileHeaderHeight})`};
  z-index: 1;
`;

const MobileNavListWrapper = styled(List)`
  ${HideOnLargeScreens}
  background-color: ${token('colors.backgroundWhite')};
  border-top: 1px solid ${token('colors.gray300')};
  border-bottom: 1px solid ${token('colors.gray300')};
`;

const AvatarWrapper = styled(Flex)`
  grid-column: 3;
  margin: auto 12px auto auto;

  @media ${mediaBreakpointMixin('md_lte')} {
    display: none;
  }
`;

const MenuIcon = styled.div`
  :hover {
    cursor: pointer;
  }
  height: fit-content;
  margin-top: auto;
`;

// should match with links list in QuickLinksCard
const HeaderContainer: React.FC<HeaderContainerProps> = ({
  loggedIn,
  user,
  canImpersonate,
  isImpersonating,
  canMassUpload,
  isMobileNavOpen,
  setIsMobileNavOpen,
  setIsImpersonationModalOpen,
}: HeaderContainerProps) => {
  const isMock = React.useContext(IsMockContext);
  const [massUploadModalOpen, setMassUploadModalOpen] = React.useState(false);
  const [massUploadReportsModalOpen, setMassUploadReportsModalOpen] = React.useState(false);
  const showDropdownMenu = useMemo(
    () => canImpersonate || canMassUpload,
    [canImpersonate, canMassUpload],
  );

  return (
    <>
      <HeaderWrapper>
        <HeaderContent>
          <MobileMenuControl>
            <IconButton
              bare
              data-testid="mobileMenuControl-Button"
              size="md"
              buttonType="secondary"
              title="mobile menu control"
              icon={isMobileNavOpen ? <IconClose fontColor="blue600" /> : <IconMenuOutline />}
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            />
          </MobileMenuControl>

          <LogoWrapper>
            <NoStyleLink to={isMock ? `/?test=1&vaccine=1` : RoutePath.HOME}>
              <ZallPassLogo data-testid="zallPassLogo" alt={'zall pass logo'} title={'zall pass logo'} src={zallPassLogoImg} />
            </NoStyleLink>
          </LogoWrapper>

          {isMobileNavOpen && (
            <MobileNavWrapper isImpersonating={isImpersonating}>
              <MobileNavListWrapper appearance="horizontalTable">
                {canImpersonate && (
                  <List.Item paddingY="sm">
                    <TextButton
                      fontType="body"
                      icon={<IconTeamOutline fontColor="brand" marginRight="xs" />}
                      marginLeft="sm"
                      onClick={() => setIsImpersonationModalOpen(true)}
                    >
                      Impersonate
                    </TextButton>
                  </List.Item>
                )}
                <List.Item paddingY="sm">
                  <TextButton
                    as="a"
                    href={DynamicConfig.GetConfig().BUSINESS_TRAVEL_URL}
                    target="_blank"
                    fontType="body"
                    icon={<IconCarOutline fontColor="brand" marginRight="xs" />}
                    marginLeft="sm"
                    onClick={() => GoogleAnalyticsUtils.ClickBusinessTravelBooking()}
                  >
                    Business travel booking
                  </TextButton>
                </List.Item>
                <List.Item paddingY="sm">
                  <TextButton
                    as="a"
                    href={DynamicConfig.GetConfig().TRAVEL_AND_EXPENSE_POLICY_URL}
                    target="_blank"
                    fontType="body"
                    icon={<IconDollarSignCircleOutline fontColor="brand" marginRight="xs" />}
                    marginLeft="sm"
                    onClick={() => GoogleAnalyticsUtils.ClickTravelExpenseGuidelines()}
                  >
                    Travel &amp; expense guidelines
                  </TextButton>
                </List.Item>
                <List.Item paddingY="sm">
                  <TextButton
                    as="a"
                    href={DynamicConfig.GetConfig().CLOUD_HQ_ZALLWAY_URL}
                    target="_blank"
                    fontType="body"
                    icon={<IconAskQuestionOutline fontColor="brand" marginRight="xs" />}
                    marginLeft="sm"
                    onClick={() => GoogleAnalyticsUtils.ClickCloudHQZallway()}
                  >
                    Cloud HQ Zallway Page
                  </TextButton>
                </List.Item>
              </MobileNavListWrapper>
            </MobileNavWrapper>
          )}

          {loggedIn && (
            <AvatarWrapper display="flex" flexDirection="row">
              <AvatarDisk
                fullName={`${user.firstName} ${user.lastName}`}
                size="md"
                photoUrl={user.photoUrl && `${DynamicConfig.GetConfig().HQENGINE_PROXY}${user.photoUrl}`}
              />
              {showDropdownMenu && (
                <MenuPopper data-testid="impersonate-dropdown"
                  triggered={
                    <Menu>
                      {canImpersonate && (
                        <MenuItem onClick={() => setIsImpersonationModalOpen(true)}>
                          Impersonate
                        </MenuItem>
                      )}
                      {canMassUpload && (
                        <MenuItem onClick={() => setMassUploadModalOpen(true)}>
                          New Mass Upload
                        </MenuItem>
                      )}
                      {canMassUpload && (
                        <MenuItem onClick={() => setMassUploadReportsModalOpen(true)}>
                          Mass Upload Reports
                        </MenuItem>
                      )}
                    </Menu>
                  }
                  offset={[-180, 10]}
                >
                  <MenuIcon>
                    <IconChevronDownOutline
                      marginLeft="xs"
                      aria-hidden="false"
                      aria-describedby="open-menu-title open-menu-desc"
                      fontColor="textDark"
                      size="xs"
                    >
                      <title id="open-menu-title">Open menu</title>
                      <desc id="open-menu-desc">Chevron pointing down</desc>
                    </IconChevronDownOutline>
                  </MenuIcon>
                </MenuPopper>
              )}
            </AvatarWrapper>
          )}
        </HeaderContent>
      </HeaderWrapper>
      <MassUploadModalContainer
        isOpen={massUploadModalOpen}
        onClose={() => setMassUploadModalOpen(false)}
      />
      <MassUploadReportsModalContainer
        isOpen={massUploadReportsModalOpen}
        onClose={() => setMassUploadReportsModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedIn: selectLoggedIn(state),
  user: selectUserInfo(state),
  canImpersonate: selectCanImpersonate(state),
  isImpersonating: selectIsImpersonating(state),
  canMassUpload: selectCanMassUpload(state),
});

const mapDispatchToProps = {};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderContainer);
