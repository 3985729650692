import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UpcomingVisitsContainerProps } from './UpcomingVisitsContainer';
import { mockCancelOfficeVisitSuccess, Reservation } from '../../store/office';
import { DateUtils } from '../../utilities/dateutils';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';

export const UpcomingVisitsContainerLogic = ({
  reservations,
  cancelReservation,
}: UpcomingVisitsContainerProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isSelectedMap, setIsSelectedMap] = useState({} as Map<string, boolean>);
  const [canceledVisits, setCanceledVisits] = useState([] as Reservation[]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onCancelBooking = useCallback(() => {
    GoogleAnalyticsUtils.ClickCancelVisits();
    if (window.confirm('Are you sure to cancel this office visit?')) {
      setHasError(false);
      const selectedVisits = [] as Reservation[];
      reservations.forEach((visit) => {
        if (isSelectedMap.has(visit.id) && isSelectedMap.get(visit.id)) selectedVisits.push(visit);
      });
      cancelReservation(selectedVisits.map((v) => v.id))
        .then((visits) => {
          const canceledVisits = visits.map((v) =>
            v.visitDate ? { ...v, visitDate: DateUtils.DateFromUTC(v.visitDate) } : v,
          );
          setCanceledVisits(canceledVisits);
        })
        .catch(() => setHasError(true))
        .finally(() => setIsConfirmed(true));

      GoogleAnalyticsUtils.ClickConfirmCancelVisits(selectedVisits.length);
    }
  }, [isSelectedMap]);

  /** reset selected rows when reservations change */
  useEffect(() => {
    setIsSelectedMap(
      new Map<string, boolean>(reservations.map((res) => [res.id, false] as [string, boolean])),
    );
  }, [reservations]);

  return {
    onCancelBooking,
    expanded,
    setExpanded,
    isSelectedMap,
    setIsSelectedMap,
    isConfirmed,
    setIsConfirmed,
    canceledVisits,
    hasError,
  };
};

export const MockUpcomingVisitsContainerLogic = ({
  reservations,
}: UpcomingVisitsContainerProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isSelectedMap, setIsSelectedMap] = useState({} as Map<string, boolean>);
  const [canceledVisits, setCanceledVisits] = useState([] as Reservation[]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();

  const onCancelBooking = useCallback(() => {
    if (window.confirm('Are you sure to cancel this office visit?')) {
      const selectedVisits = [] as Reservation[];
      reservations.forEach((visit) => {
        if (isSelectedMap.has(visit.id) && isSelectedMap.get(visit.id)) selectedVisits.push(visit);
      });
      dispatch(mockCancelOfficeVisitSuccess(selectedVisits.map((v) => v.id)));
      setCanceledVisits(selectedVisits);
      setIsConfirmed(true);
      setHasError(false);
    }
  }, [dispatch, isSelectedMap, reservations]);

  /** reset selected rows when reservations change */
  useEffect(() => {
    setIsSelectedMap(
      new Map<string, boolean>(reservations.map((res) => [res.id, false] as [string, boolean])),
    );
  }, [reservations]);

  return {
    onCancelBooking,
    expanded,
    setExpanded,
    isSelectedMap,
    setIsSelectedMap,
    isConfirmed,
    setIsConfirmed,
    canceledVisits,
    hasError,
  };
};
