import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  mediaBreakpointMixin,
  LoadingButton,
  FormField,
  Label,
  AdornedInput,
  DatePicker,
  DatePickerInput,
  Adornment,
  IconCalendarOutline,
  LabeledControl,
  Checkbox,
  TextButton,
  Trigger,
  Input,
  Heading,
  Flex,
} from '@zillow/constellation';
import { RootState } from '../../store';
import {
  Office,
  Reservation,
  selectReservations,
  getOfficeFullyBookedDates,
  selectBookingType,
  BookingType,
  updateBookingType,
} from '../../store/office';
import {
  MockOneTimeBookingContainerLogic,
  OneTimeBookingContainerLogic,
} from './OneTimeBookingContainer.hooks';
import { MAX_BOOKING_DATE } from '../BookingContainer/BookingContainer';
import { IsMockContext } from '../../middleware/auth';
import { startOfToday, endOfDay, addDays } from 'date-fns';
import { Modal } from '../../components/Modal';
import { useMediaQuery } from '../../utilities/useMedia';
import CapacityPopoverContainer from '../CapacityPopoverContainer';

interface StateProps {
  /** list of reservations */
  reservations: Reservation[];
  /** get fully booked dates in an interval for an office */
  getOfficeFullyBookedDates: (
    officeId: string,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => Promise<Date[]>;
}
interface OwnProps {
  /** office selected */
  targetOffice: Office;
  /** booking type */
  bookingType: BookingType;
  /** update bookingType */
  updateBookingType: (value: BookingType) => void;
  /** check if a date is blocked */
  isBlocked: (date: Date) => boolean;
  /** check if a date is already booked by the user */
  isAlreadyBooked: (date: Date) => boolean;
  /** check if a date is disabled on the Datepicker */
  isDateDisabled: (
    date: Date,
    {
      minDate,
      maxDate,
    }: {
      minDate: Date;
      maxDate: Date;
    },
  ) => boolean;
  /** update necessary excluded dates for warning */
  updateExcludedDates: (dates: Date[]) => void;
  /** update same day reservations for warning */
  updateSameDayReservations: (reservations: Reservation[]) => void;
  /** submit booking request */
  handleSubmitBooking: (startDate: Date, endDate: Date, excludedDates: Date[]) => Promise<void>;
  /** setter for showing error modal */
  setHasUnexpectedError: React.Dispatch<React.SetStateAction<boolean>>;
  /** setter for showing warning modal */
  setHasUnexpectedWarning: React.Dispatch<React.SetStateAction<boolean>>;
  /** setter for unexpected conflicted dates*/
  setUnexpectedConflicts: React.Dispatch<React.SetStateAction<Date[]>>;
  /** setter for continue booking callback */
  setOnContinueBooking: React.Dispatch<React.SetStateAction<() => void>>;
  /** setter for is weekend selected */
  setIsWeekendSelected: React.Dispatch<React.SetStateAction<boolean>>;
  /** setter for CTA component - mobile only */
  setMobileCTA?: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export type OneTimeBookingContainerProps = StateProps & OwnProps;

export const DesktopOnlyWrapper = styled.span`
  @media ${mediaBreakpointMixin('lg')} {
    display: inline-block;
  }
  display: none;
`;

const OneTimeBookingContainer: React.FC<OneTimeBookingContainerProps> = (
  props: OneTimeBookingContainerProps,
) => {
  const isMock = React.useContext(IsMockContext);
  const isDesktop = useMediaQuery('(min-width: 769px)');
  const { targetOffice, isDateDisabled, setMobileCTA } = props;

  const {
    isConfirmingDates,
    isSubmitting,
    isMultiday,
    onIsMultidayChange,
    onSubmitBooking,
    startDate,
    startDateText,
    setStartDateText,
    endDate,
    endDateText,
    setEndDateText,
    endDateInputKey,
    selectedDates,
    isCTADisabled,
    isStartDateModalOpen,
    setIsStartDateModalOpen,
    isEndDateModalOpen,
    setIsEndDateModalOpen,
  } = isMock ? MockOneTimeBookingContainerLogic(props) : OneTimeBookingContainerLogic(props);

  const cta = (
    <LoadingButton
      buttonType="primary"
      disabled={isCTADisabled}
      onClick={onSubmitBooking}
      loading={isSubmitting || isConfirmingDates}
    >
      Book now
    </LoadingButton>
  );

  useEffect(() => {
    if (setMobileCTA !== undefined) setMobileCTA(cta);
  }, [startDate, endDate, onSubmitBooking, isSubmitting, isConfirmingDates]);

  const startDateModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={<Heading level={6}>Select a date</Heading>}
      body={
        <DatePicker
          dateFormat={'MM/dd/yyyy'}
          selected={startDateText}
          onChange={(e: { date: Date; formatted: string }) => {
            setStartDateText(e.formatted);
            onClose();
          }}
          minDate={startOfToday()}
          maxDate={endDate || MAX_BOOKING_DATE}
          disabledDates={isDateDisabled}
        />
      }
      footer={<TextButton onClick={() => onClose()}>Cancel</TextButton>}
    />
  );

  const endDateModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={<Heading level={6}>Select a date</Heading>}
      body={
        <DatePicker
          dateFormat={'MM/dd/yyyy'}
          selected={endDateText || (startDate && addDays(startDate, 1))}
          onChange={(e: { date: Date; formatted: string }) => {
            setEndDateText(e.formatted);
            onClose();
          }}
          minDate={startDate ? endOfDay(new Date(startDate)) : startOfToday()}
          maxDate={MAX_BOOKING_DATE}
          disabledDates={isDateDisabled}
        />
      }
      footer={<TextButton onClick={() => onClose()}>Cancel</TextButton>}
    />
  );

  return (
    <>
      <FormField
        label={<Label>{isMultiday ? 'Visit start date' : 'Visit date'}</Label>}
        control={
          isDesktop ? (
            <AdornedInput
              input={
                <DatePickerInput
                  placeholder="MM/DD/YYYY"
                  datepicker={
                    <DatePicker
                      minDate={startOfToday()}
                      maxDate={endDate || MAX_BOOKING_DATE}
                      disabledDates={isDateDisabled}
                    />
                  }
                  value={startDateText}
                  onChange={(v: string) => setStartDateText(v)}
                />
              }
              rightAdornment={
                <Adornment>
                  <IconCalendarOutline size="sm" />
                </Adornment>
              }
            />
          ) : (
            <Trigger
              data-testid="one-time-booking"
              triggered={startDateModal}
              isOpen={isStartDateModalOpen}
              onOpen={() => setIsStartDateModalOpen(true)}
              onClose={() => setIsStartDateModalOpen(false)}
            >
              <AdornedInput
                input={
                  <Input placeholder="MM/DD/YYYY" value={startDateText} onChange={() => null} />
                }
                rightAdornment={
                  <Adornment>
                    <IconCalendarOutline size="sm" />
                  </Adornment>
                }
              />
            </Trigger>
          )
        }
      />
      <LabeledControl
        label={<Label>Multiday visit</Label>}
        control={<Checkbox checked={isMultiday} onChange={onIsMultidayChange} />}
        marginY="md"
      />
      <FormField
        disabled={!isMultiday}
        label={<Label>Visit end date</Label>}
        control={
          isDesktop ? (
            <AdornedInput
              input={
                <DatePickerInput
                  key={endDateInputKey} // force resetting the component
                  placeholder="MM/DD/YYYY"
                  datepicker={
                    <DatePicker
                      minDate={startDate ? endOfDay(new Date(startDate)) : startOfToday()}
                      maxDate={MAX_BOOKING_DATE}
                      disabledDates={isDateDisabled}
                      defaultSelected={startDate && addDays(new Date(startDate), 1)}
                    />
                  }
                  value={endDateText === '' ? undefined : endDateText} // set undefined to show defaultSelected
                  onChange={(v: string) => setEndDateText(v)}
                />
              }
              rightAdornment={
                <Adornment>
                  <IconCalendarOutline size="sm" />
                </Adornment>
              }
            />
          ) : (
            <Trigger
              triggered={endDateModal}
              isOpen={isEndDateModalOpen}
              onOpen={() => setIsEndDateModalOpen(true)}
              onClose={() => setIsEndDateModalOpen(false)}
            >
              <AdornedInput
                input={<Input placeholder="MM/DD/YYYY" value={endDateText} onChange={() => null} />}
                rightAdornment={
                  <Adornment>
                    <IconCalendarOutline size="sm" />
                  </Adornment>
                }
              />
            </Trigger>
          )
        }
      />
      <Flex display="flex" justifyContent="space-between" marginTop="md">
        <DesktopOnlyWrapper>{cta}</DesktopOnlyWrapper>
        <CapacityPopoverContainer
          officeId={targetOffice.id}
          text={'Daily capacity'}
          dates={selectedDates}
        />
      </Flex>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  reservations: selectReservations(state),
  bookingType: selectBookingType(state),
});

const mapDispatchToProps = {
  getOfficeFullyBookedDates: getOfficeFullyBookedDates,
  updateBookingType: updateBookingType,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(OneTimeBookingContainer);
