import React from 'react';
import { Anchor } from '@zillow/constellation';
import { PageError } from './PageError';
import errorImage from '../../assets/404Error.png';
import DynamicConfig from '../../config/DynamicConfig';

export const Page503Error: React.FC = () => {
  return (
    <PageError
    data-testid="render-error-wrapper-test"
      title={'503 error'}
      description={
        <>
          The requested service is unavailable at this time. If you have an immediate booking need,
          please submit a ticket to{' '}
          <Anchor href={DynamicConfig.GetConfig().PPL_OPS_SUPPORT_URL}>
            People Operations
          </Anchor>{' '}
          for assistance.
        </>
      }
      imageSrc={errorImage}
    />
  );
};
