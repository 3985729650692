/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'peng_hqengine_contract';

export enum UserInboxMessageType {
  USER_INBOX_MESSAGE_TYPE_UNSPECIFIED = 0,
  USER_INBOX_MESSAGE_TYPE_EMPLOYEE_VACCINATION_APPROVED = 1,
  UNRECOGNIZED = -1,
}

export function userInboxMessageTypeFromJSON(object: any): UserInboxMessageType {
  switch (object) {
    case 0:
    case 'USER_INBOX_MESSAGE_TYPE_UNSPECIFIED':
      return UserInboxMessageType.USER_INBOX_MESSAGE_TYPE_UNSPECIFIED;
    case 1:
    case 'USER_INBOX_MESSAGE_TYPE_EMPLOYEE_VACCINATION_APPROVED':
      return UserInboxMessageType.USER_INBOX_MESSAGE_TYPE_EMPLOYEE_VACCINATION_APPROVED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return UserInboxMessageType.UNRECOGNIZED;
  }
}

export function userInboxMessageTypeToJSON(object: UserInboxMessageType): string {
  switch (object) {
    case UserInboxMessageType.USER_INBOX_MESSAGE_TYPE_UNSPECIFIED:
      return 'USER_INBOX_MESSAGE_TYPE_UNSPECIFIED';
    case UserInboxMessageType.USER_INBOX_MESSAGE_TYPE_EMPLOYEE_VACCINATION_APPROVED:
      return 'USER_INBOX_MESSAGE_TYPE_EMPLOYEE_VACCINATION_APPROVED';
    default:
      return 'UNKNOWN';
  }
}

export interface UserInboxMessage {
  id: string;
  messageType: UserInboxMessageType;
  messageText: string;
}

const baseUserInboxMessage: object = { id: '0', messageType: 0, messageText: '' };

export const UserInboxMessage = {
  encode(message: UserInboxMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    if (message.messageType !== 0) {
      writer.uint32(16).int32(message.messageType);
    }
    if (message.messageText !== '') {
      writer.uint32(26).string(message.messageText);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInboxMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserInboxMessage } as UserInboxMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        case 2:
          message.messageType = reader.int32() as any;
          break;
        case 3:
          message.messageText = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserInboxMessage {
    const message = { ...baseUserInboxMessage } as UserInboxMessage;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    if (object.messageType !== undefined && object.messageType !== null) {
      message.messageType = userInboxMessageTypeFromJSON(object.messageType);
    } else {
      message.messageType = 0;
    }
    if (object.messageText !== undefined && object.messageText !== null) {
      message.messageText = String(object.messageText);
    } else {
      message.messageText = '';
    }
    return message;
  },

  toJSON(message: UserInboxMessage): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.messageType !== undefined &&
      (obj.messageType = userInboxMessageTypeToJSON(message.messageType));
    message.messageText !== undefined && (obj.messageText = message.messageText);
    return obj;
  },

  fromPartial(object: DeepPartial<UserInboxMessage>): UserInboxMessage {
    const message = { ...baseUserInboxMessage } as UserInboxMessage;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    if (object.messageType !== undefined && object.messageType !== null) {
      message.messageType = object.messageType;
    } else {
      message.messageType = 0;
    }
    if (object.messageText !== undefined && object.messageText !== null) {
      message.messageText = object.messageText;
    } else {
      message.messageText = '';
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
