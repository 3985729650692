import React from 'react';
import styled from 'styled-components';
import { ModalDialog, StyledDialog, DialogBody } from '@zillow/constellation';

/** A controlled Modal */
export interface ModalProps {
  /** if modal is open */
  isOpen: boolean;
  /** modal header */
  header?: React.ReactNode;
  /** modal children */
  body: React.ReactNode;
  /** modal footer */
  footer?: React.ReactNode;
  /** function to handle close */
  handleClose: () => void;
  /** should the modal close on outside click */
  shouldCloseOnOutsideClick?: boolean;
  /** should the modal close on ESC key press */
  shouldCloseOnESCKeyPress?: boolean;
  /** The size of modal */
  size?: string | { [size: string]: string };
  /** wide sized modal - deprecated */
  wide?: boolean;
  /** fullScreen on breakpoints, i.e. { sm_lte: true }, or true for all width - deprecated*/
  fullScreen?: { [key: string]: boolean } | boolean;
  /** override overflow-y on modal body */
  overflowY?: string;
}

const ModalWrapper = styled(ModalDialog)<{ overflowY: string }>`
  ${StyledDialog} ${DialogBody} {
    ${(props) => props.overflowY && `overflow-y: ${props.overflowY};`}
  }
`;

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  header,
  body,
  footer,
  handleClose,
  shouldCloseOnOutsideClick,
  shouldCloseOnESCKeyPress,
  size,
  wide,
  fullScreen,
  overflowY,
}: ModalProps) => {
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={handleClose}
      renderHeader={header}
      renderBody={body}
      renderFooter={footer}
      shouldCloseOnOutsideClick={shouldCloseOnOutsideClick}
      shouldCloseOnESCKeyPress={shouldCloseOnESCKeyPress}
      size={size}
      wide={wide}
      fullScreen={fullScreen}
      overflowY={overflowY}
    />
  );
};
