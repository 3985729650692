import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Card,
  Paragraph,
  IconSuccess,
  IconError,
  Anchor,
  Flex,
  mediaBreakpointMixin,
  spaceMixin,
} from '@zillow/constellation';
import {
  ackReservation,
  AckReservationResultTypeValue,
  AckReservationResult,
} from '../../store/office';
import { Loader } from '../../components/Loader';
import DynamicConfig from '../../config/DynamicConfig';
import { DateUtils } from '../../utilities/dateutils';
import { AckReservationContainerLogic } from './AckReservationContainer.hooks';

interface StateProps {
  /** action to confirm or cancel a reservation */
  ackReservation: (token: string) => Promise<AckReservationResult>;
}

interface OwnProps {
  action: 'confirm' | 'cancel';
}

const HeaderHeight = '85px';

export type AckReservationContainerProps = StateProps & OwnProps;

const CardFlexWrapper = styled(Flex)`
  height: calc(100vh - ${HeaderHeight});
`;

const CardWrapper = styled(Card)`
  @media ${mediaBreakpointMixin('xl')} {
    width: 450px;
    padding: ${spaceMixin('xl')};
  }
  width: 80vw;
  padding: ${spaceMixin('md')};
`;

const AckReservationContainerBase: React.FC<AckReservationContainerProps> = (
  props: AckReservationContainerProps,
) => {
  const { action } = props;
  const { isLoaded, officeName, reservation, resultType } = AckReservationContainerLogic(props);

  const confirmSuccess = (
    <CardWrapper cardType="secondary" cardElevation={0}>
      <IconSuccess fontColor="green500" size="md" />
      <Paragraph data-testid="confirm-success" fontType={{ default: 'h5', xl: 'h3' }} marginY="sm">
        Great, we&apos;ll see you tomorrow!
      </Paragraph>
      <Paragraph data-testid="booking-confirmation-message">
        Looking forward to having you at {officeName} office tomorrow. Don’t forget to bring your
        badge, laptop, and headphones to support One Zoom All Zoom.
      </Paragraph>
    </CardWrapper>
  );

  const cancelSuccess = (
    <CardWrapper cardType="secondary" cardElevation={0}>
      <IconSuccess fontColor="green500" size="md" />
      <Paragraph data-testid="cancel-success" fontType={{ default: 'h5', xl: 'h3' }} marginY="sm">
        Cancellation complete
      </Paragraph>
      <Paragraph>
        No further action is needed. To book a different day in the office, use the day pass{' '}
        <Anchor data-testid="booking-tool-link-cancel" href={DynamicConfig.GetConfig().HQENGINE_PROXY}>booking tool</Anchor>.
      </Paragraph>
    </CardWrapper>
  );

  const alreadyCanceled = (
    <CardWrapper cardType="secondary" cardElevation={0}>
      <IconError fontColor="red500" size="md" />
      <Paragraph data-testid="already-canceled" fontType={{ default: 'h5', xl: 'h3' }} marginY="sm">
        Booking no longer exists
      </Paragraph>
      <Paragraph>
        You have already canceled your {officeName} office day pass for{' '}
        {reservation && reservation.visitDate
          ? DateUtils.DateToUSLocaleStr(reservation.visitDate)
          : 'the day'}
        . You can use the <Anchor data-testid="booking-link-already-canceled" href={DynamicConfig.GetConfig().HQENGINE_PROXY}>booking tool</Anchor> to rebook your
        day pass in the office if capacity is still available.
      </Paragraph>
    </CardWrapper>
  );

  const alreadyPassed = (
    <CardWrapper cardType="secondary" cardElevation={0}>
      <IconError fontColor="red500" size="md" />
      <Paragraph data-testid="already-passed" fontType={{ default: 'h5', xl: 'h3' }} marginY="sm">
        Past booking
      </Paragraph>
      <Paragraph data-testid="past-booking-message">
        We are unable to confirm or cancel a day pass booking that happened in the past.
      </Paragraph>
    </CardWrapper>
  );

  const genericError = (
    <CardWrapper cardType="secondary" cardElevation={0}>
      <IconError fontColor="red500" size="md" />
      <Paragraph data-testid="generic-error" fontType={{ default: 'h5', xl: 'h3' }} marginY="sm">
        Something went wrong
      </Paragraph>
      <Paragraph data-testid="generic-error-message">
        We are unable to confirm or cancel a day pass booking. Please try again later.
      </Paragraph>
    </CardWrapper>
  );

  return (
    <Loader loading={!isLoaded} centerOnPage={true}>
      <CardFlexWrapper
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {(action === 'confirm' &&
          resultType ===
            AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRM_SUCCEESS &&
          confirmSuccess) ||
          (action === 'confirm' &&
            resultType ===
              AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CONFIRMED_BEFORE &&
            confirmSuccess) ||
          (action === 'cancel' &&
            resultType ===
              AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_CANCEL_SUCCEESS &&
            cancelSuccess) ||
          (resultType ===
            AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_NOT_EXIST &&
            alreadyCanceled) ||
          (resultType === AckReservationResultTypeValue.ACKNOWLEDGE_BOOKING_RESPONSE_TYPE_PAST &&
            alreadyPassed) ||
          genericError}
      </CardFlexWrapper>
    </Loader>
  );
};

const mapDispatchToProps = {
  ackReservation: ackReservation,
};

type DispatchToPropsType = typeof mapDispatchToProps;

const AckReservationContainer = connect<unknown, DispatchToPropsType>(
  null,
  mapDispatchToProps,
)(AckReservationContainerBase);

export {AckReservationContainer as default, AckReservationContainerBase};
