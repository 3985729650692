import React, { useState, useEffect } from 'react';
import { Image } from '@zillow/constellation';

// ZImage is a component to detect broken image, and sawp with a default image
export interface ZImageProps {
  /** fallback image url or the element */
  fallbackUrl: string;
  /** alternative text */
  alt?: string;
  /** title, hover text */
  title?: string;
  /** image url */
  imageUrl?: string;
  /** any additional css style */
  className?: string;
}

export const ZImage: React.FC<ZImageProps> = ({
  alt,
  title,
  imageUrl,
  fallbackUrl,
  className,
}: ZImageProps) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [imageUrl]);

  return !imageUrl || hasError ? (
    <Image data-testid="render-no-url-image-test" alt={alt} title={title} src={fallbackUrl} className={className} />
  ) : (
    <Image
      data-testid="render-regular-image-test"
      alt={alt}
      title={title}
      src={imageUrl}
      className={className}
      onError={() => setHasError(true)}
    />
  );
};
