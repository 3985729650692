import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { MassUploadReport, getAllMassUploadReports } from '../../../store/office';
import { Heading, Flex, Table, TextButton, Alert } from '@zillow/constellation';
import { Modal } from '../../../components/Modal';
import { Loader } from '../../../components/Loader';
import { MassUploadReportsModalContainerLogic } from './MassUploadReportsModalContainer.hooks';
import { reportStatusToString } from '../MassUploadReportContainer/MassUploadReportContainer';
import { DateUtils } from '../../../utilities/dateutils';

interface StateProps {
  getAllMassUploadReports: () => Promise<MassUploadReport[] | undefined>;
}

interface OwnProps {
  /** if modal is open */
  isOpen: boolean;
  /** function to handle close */
  onClose: () => void;
}

export type MassUploadReportsModalContainerProps = StateProps & OwnProps & RouteComponentProps;

const ModalBodyFlexWrapper = styled(Flex)`
  min-height: 400px;
`;

export type SortDirectionType = 'ascending' | 'descending';

const MassUploadReportsModalContainer: React.FC<MassUploadReportsModalContainerProps> = (
  props: MassUploadReportsModalContainerProps,
) => {
  const { isOpen, onClose } = props;
  const { isLoaded, sortedReports, onSortCallback, handleReportClick, loadReportsErr } =
    MassUploadReportsModalContainerLogic(props);

  return (
    <Modal
      wide
      fullScreen={{ sm_lte: true }}
      header={<Heading level={6}>Mass Upload Reports</Heading>}
      body={
        <ModalBodyFlexWrapper display="flex" justifyContent="center">
          <Loader loading={!isLoaded}>
            <Flex>
              {loadReportsErr ? (
                <Alert
                  appearance="error"
                  body="Unable to load mass upload reports. Please try again later."
                />
              ) : sortedReports.length ? (
                <Table.Sortable
                  defaultSortDirections={[null, 'ascending', null, null]}
                  onSortChange={({ nextSortDirection }: { nextSortDirection: SortDirectionType }) =>
                    onSortCallback(nextSortDirection)
                  }
                >
                  <Table aria-label="Mass upload reports" appearance="zebra">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Report</Table.HeaderCell>
                        <Table.SortableHeaderCell>Uploaded date</Table.SortableHeaderCell>
                        <Table.HeaderCell>Uploaded by</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {sortedReports.map((row, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            <TextButton
                              fontType="bodySmallHeading"
                              onClick={() => handleReportClick(row.id)}
                            >
                              {row.filename.split('.csv')[0]}_report
                            </TextButton>
                          </Table.Cell>
                          <Table.Cell>
                            {row.uploadedDate &&
                              DateUtils.DateToUSLocaleStr(DateUtils.DateFromUTC(row.uploadedDate))}
                          </Table.Cell>
                          <Table.Cell>{row.uploader?.username}</Table.Cell>
                          <Table.Cell>{reportStatusToString(row.status)}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Table.Sortable>
              ) : (
                <Alert appearance="info" body="No mass upload reports found" />
              )}
            </Flex>
          </Loader>
        </ModalBodyFlexWrapper>
      }
      isOpen={isOpen}
      handleClose={onClose}
      shouldCloseOnOutsideClick={false}
    />
  );
};

const mapDispatchToProps = {
  getAllMassUploadReports: getAllMassUploadReports,
};

type DispatchToPropsType = typeof mapDispatchToProps;

export default withRouter(
  connect<null, DispatchToPropsType, unknown>(
    null,
    mapDispatchToProps,
  )(MassUploadReportsModalContainer),
);
