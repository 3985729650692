import {
  GetAllOfficesResponse,
  GetAllOfficesBlockedDatesResponse,
  GetAllOfficeFloorsResponse,
  GetEmployeeVisitsResponse,
  BookOfficeVisitResponse,
  CancelOfficeVisitResponse,
  GetFullyBookedDatesResponse,
  BlockedDates,
  OfficeFloors,
  UploadOfficeVisitsInBulkResponse,
  GetMassUploadReportResponse,
  GetAllMassUploadReportsResponse,
  GetOfficeUsagesResponse,
} from '../../contract/hqengine_hqweb';
import { Office, Reservation, RejectedBooking, OfficeContractToOffice, OfficeUsage } from './index';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { HQEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';
import { AcknowledgeOfficeVisitResponse } from '../../contract/hqengine_hqweb_external';
import { MassUploadReport } from '../../contract/hqengine_mass_upload';

export const validateGetOfficesResp = (
  resp: GetAllOfficesResponse,
): [{ [key: string]: Office }, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.offices || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{}, errResp];
  }

  const officesRes: { [key: string]: Office } = {};
  for (const k of Object.keys(resp.offices)) {
    officesRes[k] = OfficeContractToOffice(resp.offices[k]);
  }

  return [officesRes, errResp];
};

export const validateGetOfficesBlockedDatesResp = (
  resp: GetAllOfficesBlockedDatesResponse,
): [{ [key: string]: BlockedDates }, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    (resp.status !== StatusType.STATUS_TYPE_SUCCESS &&
      resp.status !== StatusType.STATUS_TYPE_NOT_FOUND)
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{}, errResp];
  }

  return [resp.blockedDates, errResp];
};

export const validateGetOfficesFloorsResp = (
  resp: GetAllOfficeFloorsResponse,
): [{ [key: string]: OfficeFloors }, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.officeFloors || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{}, errResp];
  }

  return [resp.officeFloors, errResp];
};

export const validateGetReservationsResp = (
  resp: GetEmployeeVisitsResponse,
): [Reservation[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    (resp.status !== StatusType.STATUS_TYPE_SUCCESS &&
      resp.status !== StatusType.STATUS_TYPE_NOT_FOUND)
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.visits, errResp];
};

export const validateBookingOfficeVisitResp = (
  resp: BookOfficeVisitResponse,
): [Reservation[], RejectedBooking[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], [], errResp];
  }

  return [resp.bookedVisits, resp.rejectedBookings, errResp];
};

export const validateCancelOfficeVisitResp = (
  resp: CancelOfficeVisitResponse,
): [Reservation[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.canceledVisits || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.canceledVisits, errResp];
};

export const validateGetOfficeFullyBookedDatesResp = (
  resp: GetFullyBookedDatesResponse,
): [Date[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    (resp.status !== StatusType.STATUS_TYPE_SUCCESS &&
      resp.status !== StatusType.STATUS_TYPE_NOT_FOUND)
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.fullyBookedDates, errResp];
};

export const validateGetOfficeUsagesResp = (
  resp: GetOfficeUsagesResponse,
): [OfficeUsage[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    (resp.status !== StatusType.STATUS_TYPE_SUCCESS &&
      resp.status !== StatusType.STATUS_TYPE_NOT_FOUND)
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.officeUsages, errResp];
};

export const validateAckReservationResp = (
  resp: AcknowledgeOfficeVisitResponse,
): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return errResp;
  }

  return errResp;
};

export const validateUploadOfficeVisitsInBulkResp = (
  resp: UploadOfficeVisitsInBulkResponse,
): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateGetAllMassUploadReportsResp = (
  resp: GetAllMassUploadReportsResponse,
): [MassUploadReport[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (
    !resp ||
    !resp.reports ||
    (resp.status !== StatusType.STATUS_TYPE_SUCCESS &&
      resp.status !== StatusType.STATUS_TYPE_NOT_FOUND)
  ) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.reports, errResp];
};

export const validateGetMassUploadReportResp = (
  resp: GetMassUploadReportResponse,
): [MassUploadReport, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.report || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as MassUploadReport, errResp];
  }

  return [resp.report, errResp];
};
