/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Table, Checkbox, Text } from '@zillow/constellation';
import { Office, Reservation, Floor } from '../../store/office';
import { ZEvent } from '../../store/zevent';
import { DateUtils } from '../../utilities/dateutils';
import CapacityPopoverContainer from '../CapacityPopoverContainer';
import ZRetreatPopover from './ZRetreatPopover';
import { ZEventUtils } from '../../utilities/zeventutils';

export interface UpcomingVisitsTableProps {
  /** list of reservations */
  reservations: Reservation[];
  /** list of zRetreat events */
  zevents: ZEvent[];
  /** offices */
  officeMap: Map<string, Office>;
  /** floors office map */
  floorsOfficeMap: Map<string, Office | undefined>;
  /** office floors */
  floors: Map<string, Floor[]>;
  /** rows selected */
  isSelectedMap: Map<string, boolean>;
  /** rows selected state setter  */
  setIsSelectedMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
}

type SortColumnType = 'date' | 'office';
type SortDirectionType = 'ascending' | 'descending';
type FormattedData = {
  id: string;
  name: string;
  date: Date;
  bookedType: 'zretreat' | 'zallpass';
  meta: {
    officeId?: string;
    eventId?: string;
  };
};

export const UpcomingVisitsTable: React.FC<UpcomingVisitsTableProps> = (
  props: UpcomingVisitsTableProps,
) => {
  const {
    reservations,
    zevents,
    officeMap,
    floorsOfficeMap,
    floors,
    isSelectedMap,
    setIsSelectedMap,
  } = props;
  const [sortColumn, setSortColumn] = useState<SortColumnType>('date');
  const [sortDirection, setSortDirection] = useState<SortDirectionType>('ascending');
  const [sortedData, setSortedData] = useState([] as FormattedData[]);
  const memoizedAllCheckedState = useMemo(
    () => new Map<string, boolean>(reservations.map((res) => [res.id, true] as [string, boolean])),
    [reservations],
  );
  const memoizedAllUncheckedState = useMemo(
    () => new Map<string, boolean>(reservations.map((res) => [res.id, false] as [string, boolean])),
    [reservations],
  );

  const [indeterminate, setIndeterminate] = useState(false);

  const formattedData: FormattedData[] = useMemo(() => {
    const data = [...reservations].map(
      (res) =>
        ({
          id: res.id,
          name: officeMap.get(res.officeId)?.name,
          date: res.visitDate,
          bookedType: 'zallpass',
          meta: {
            officeId: res.officeId,
          },
        } as FormattedData),
    );

    /** TF-2491 only show zevent where current user has accepted invite */
    for (const e of zevents) {
      if (e.startDate) {
        const dates = DateUtils.GetDatesBetween(e.startDate, e.endDate || e.startDate);
        const zEventName = ZEventUtils.getZRetreatName(e.eventLocation, floorsOfficeMap, floors)
          .concat(' ', 'zRetreat')
          .trim();
        for (const d of dates) {
          data.push({
            id: `${e.id}-${DateUtils.DateToUSLocaleStr(d)}`,
            name: zEventName,
            date: d,
            bookedType: 'zretreat',
            meta: {
              eventId: e.id,
            },
          } as FormattedData);
        }
      }
    }

    return data;
  }, [reservations, zevents]);

  const onSelectAllCheckboxChange = useCallback(
    (checked: boolean) => {
      // if indeterminate, unselect all
      if (indeterminate || !checked) {
        setIsSelectedMap(memoizedAllUncheckedState);
      } else {
        setIsSelectedMap(memoizedAllCheckedState);
      }
      setIndeterminate(false);
    },
    [indeterminate, memoizedAllCheckedState, memoizedAllUncheckedState],
  );

  const onRowCheckboxChange = useCallback(
    (visitId: string) => {
      const nextState = new Map(isSelectedMap);
      if (nextState.get(visitId) !== undefined) {
        nextState.set(visitId, !nextState.get(visitId));
        setIsSelectedMap(nextState);
      }
      // not indeterminate if all selected or all unselected
      setIndeterminate(
        !Array.from(nextState.values()).reduce((acc, cur) => acc && cur) &&
          Array.from(nextState.values()).reduce((acc, cur) => acc || cur),
      );
    },
    [isSelectedMap],
  );

  const onDateSortCallback = useCallback(
    (nextSortDirection: SortDirectionType) => {
      setSortDirection(nextSortDirection);
      setSortedData(
        [...formattedData].sort((a, b) =>
          a.date && b.date
            ? nextSortDirection === 'ascending'
              ? a.date.getTime() - b.date.getTime()
              : b.date.getTime() - a.date.getTime()
            : 0,
        ),
      );
    },
    [formattedData],
  );

  const onOfficeSortCallback = useCallback(
    (nextSortDirection: SortDirectionType) => {
      setSortDirection(nextSortDirection);
      setSortedData(
        [...formattedData].sort((a, b) =>
          nextSortDirection === 'ascending'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name),
        ),
      );
    },
    [formattedData],
  );

  /** reset indeterminate when reservation/cancelation is made */
  useEffect(() => {
    setIndeterminate(false);
    if (sortColumn === 'date') onDateSortCallback(sortDirection);
    else onOfficeSortCallback(sortDirection);
  }, [reservations, zevents]);

  return formattedData.length > 0 ? (
    <Table.Sortable defaultSortDirections={[null, 'none', 'ascending']}>
      <Table appearance="zebra" aria-label="reservation table with selectable rows" size="md">
        <Table.Header appearance="dark">
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox
                aria-label="toggle selecting all rows"
                checked={
                  isSelectedMap.size > 0 &&
                  Array.from(isSelectedMap.values()).reduce((acc, cur) => acc && cur)
                }
                indeterminate={indeterminate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onSelectAllCheckboxChange(e.target.checked)
                }
              />
            </Table.HeaderCell>
            <Table.SortableHeaderCell
              sortOrder={['ascending', 'descending']}
              onSortChange={({
                nextSortDirection,
              }: {
                currentSortDirection: SortDirectionType;
                nextSortDirection: SortDirectionType;
              }) => {
                onOfficeSortCallback(nextSortDirection);
                setSortColumn('office');
              }}
            >
              Office
            </Table.SortableHeaderCell>
            <Table.SortableHeaderCell
              sortOrder={['ascending', 'descending']}
              onSortChange={({
                nextSortDirection,
              }: {
                currentSortDirection: SortDirectionType;
                nextSortDirection: SortDirectionType;
              }) => {
                onDateSortCallback(nextSortDirection);
                setSortColumn('date');
              }}
            >
              Date
            </Table.SortableHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedData.map((entry, i) => {
            if (entry.bookedType === 'zallpass') {
              return (
                <Table.Row interactive key={entry.id}>
                  <Table.Cell>
                    <Checkbox
                      aria-label={`select row ${i + 1}`}
                      checked={(isSelectedMap.size > 0 && isSelectedMap.get(entry.id)) || false}
                      onChange={() => onRowCheckboxChange(entry.id)}
                      onClick={(e: React.ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
                    />
                  </Table.Cell>
                  <Table.HeaderCell>{entry.name}</Table.HeaderCell>
                  <Table.Cell>
                    {entry.meta.officeId ? (
                      <CapacityPopoverContainer
                        officeId={entry.meta.officeId}
                        text={DateUtils.DateToUSLocaleStr(entry.date)}
                        dates={[entry.date]}
                      />
                    ) : (
                      <Text>{DateUtils.DateToUSLocaleStr(entry.date)}</Text>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            } else if (entry.bookedType === 'zretreat') {
              return (
                <Table.Row interactive key={entry.id}>
                  <Table.Cell>
                    <Checkbox aria-label={`disabled row ${i + 1}`} disabled />
                  </Table.Cell>
                  <Table.HeaderCell>
                    <ZRetreatPopover
                      text={'zRetreat'}
                      title={entry.name}
                      eventId={entry.meta.eventId}
                    />
                  </Table.HeaderCell>
                  <Table.Cell>{DateUtils.DateToUSLocaleStr(entry.date)}</Table.Cell>
                </Table.Row>
              );
            }
          })}
        </Table.Body>
      </Table>
    </Table.Sortable>
  ) : null;
};
