/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'commoncontract';

export enum ServiceName {
  SERVICE_NAME_UNSPECIFIED = 0,
  SERVICE_NAME_EMPLOYEE_API = 1,
  SERVICE_NAME_SERVICE_NOW = 2,
  UNRECOGNIZED = -1,
}

export function serviceNameFromJSON(object: any): ServiceName {
  switch (object) {
    case 0:
    case 'SERVICE_NAME_UNSPECIFIED':
      return ServiceName.SERVICE_NAME_UNSPECIFIED;
    case 1:
    case 'SERVICE_NAME_EMPLOYEE_API':
      return ServiceName.SERVICE_NAME_EMPLOYEE_API;
    case 2:
    case 'SERVICE_NAME_SERVICE_NOW':
      return ServiceName.SERVICE_NAME_SERVICE_NOW;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ServiceName.UNRECOGNIZED;
  }
}

export function serviceNameToJSON(object: ServiceName): string {
  switch (object) {
    case ServiceName.SERVICE_NAME_UNSPECIFIED:
      return 'SERVICE_NAME_UNSPECIFIED';
    case ServiceName.SERVICE_NAME_EMPLOYEE_API:
      return 'SERVICE_NAME_EMPLOYEE_API';
    case ServiceName.SERVICE_NAME_SERVICE_NOW:
      return 'SERVICE_NAME_SERVICE_NOW';
    default:
      return 'UNKNOWN';
  }
}

export enum HealthStatus {
  HEALTH_STATUS_UNSPECIFIED = 0,
  HEALTH_STATUS_OK = 1,
  HEALTH_STATUS_ERROR = 2,
  UNRECOGNIZED = -1,
}

export function healthStatusFromJSON(object: any): HealthStatus {
  switch (object) {
    case 0:
    case 'HEALTH_STATUS_UNSPECIFIED':
      return HealthStatus.HEALTH_STATUS_UNSPECIFIED;
    case 1:
    case 'HEALTH_STATUS_OK':
      return HealthStatus.HEALTH_STATUS_OK;
    case 2:
    case 'HEALTH_STATUS_ERROR':
      return HealthStatus.HEALTH_STATUS_ERROR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return HealthStatus.UNRECOGNIZED;
  }
}

export function healthStatusToJSON(object: HealthStatus): string {
  switch (object) {
    case HealthStatus.HEALTH_STATUS_UNSPECIFIED:
      return 'HEALTH_STATUS_UNSPECIFIED';
    case HealthStatus.HEALTH_STATUS_OK:
      return 'HEALTH_STATUS_OK';
    case HealthStatus.HEALTH_STATUS_ERROR:
      return 'HEALTH_STATUS_ERROR';
    default:
      return 'UNKNOWN';
  }
}

export interface ServiceStatus {
  name: ServiceName;
  status: HealthStatus;
  message: string;
}

const baseServiceStatus: object = { name: 0, status: 0, message: '' };

export const ServiceStatus = {
  encode(message: ServiceStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== 0) {
      writer.uint32(8).int32(message.name);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(26).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseServiceStatus } as ServiceStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.int32() as any;
          break;
        case 2:
          message.status = reader.int32() as any;
          break;
        case 3:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ServiceStatus {
    const message = { ...baseServiceStatus } as ServiceStatus;
    if (object.name !== undefined && object.name !== null) {
      message.name = serviceNameFromJSON(object.name);
    } else {
      message.name = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = healthStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    return message;
  },

  toJSON(message: ServiceStatus): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = serviceNameToJSON(message.name));
    message.status !== undefined && (obj.status = healthStatusToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial(object: DeepPartial<ServiceStatus>): ServiceStatus {
    const message = { ...baseServiceStatus } as ServiceStatus;
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
